import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {EmployeBoutique} from '../../shared/models/employe-boutique.model';
import {FreeMemoryService} from '../../reusable/services/free-memory.service';


/**
 * Permet de gérer les {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class RestoreEmployeService {
  constructor(private freeMemoryService: FreeMemoryService) {
  }

  private userChecked: EmployeBoutique;

  getUserChecked(): EmployeBoutique {
    return this.userChecked;
  }

  setUserChecked(user: EmployeBoutique) {
    this.userChecked = user;
  }

  /**
   * Permet de réinitialiser certaines variable à la fermeture
   * du popup.
   */
  restore(subs$: Subject<void>) {
    this.userChecked = undefined;
    this.freeMemoryService.free(subs$);
  }
}
