import {Injectable} from '@angular/core';
import {FormService} from '../../../shared/ services/form.service';
import {FormGroup} from '@angular/forms';
import {ConstantBoutiqueColumnName} from '../../../shared/constant/model/column-name/boutique-column-name.constant';
import {ConstantBoutiqueValidator} from '../../../shared/constant/validator/boutique-validator.constant';
import {Boutique} from '../../../shared/models/boutique.model';

/**
 * Offre plusieurs types utilisés pour se connecter
 */
export namespace BoutiqueCreateNm {
  /**
   * Permet d'avoir le nom des champs pour créer un compte.
   */
  export interface ColumnName {
    nom: string;
    devise: string;
    pays: string;
    ville: string;
    adresse: string;
    description: string;
    telephone1: string;
    telephone2: string;
    telephone3: string;
  }
}

/**
 * Permet d'initialiser le component {@link LoginComponent}
 */
@Injectable({providedIn: 'root'})
export class BoutiqueCreateInit {
  constructor(
    private formService: FormService,
    private userColName: ConstantBoutiqueColumnName,
    private userValidator: ConstantBoutiqueValidator
  ) {
  }

  /**
   * récupère les données d'authentification du formulaire.
   */
  public getAuthFromForm(editForm: FormGroup): Boutique {
    const entity = {
      ...new Boutique(),
      nom: this.formService.getData(this.userColName.NOM, editForm, this.userColName.fields),
      devise: this.formService.getData(this.userColName.DEVISE, editForm, this.userColName.fields),
      pays: this.formService.getData(this.userColName.PAYS, editForm, this.userColName.fields),
      ville: this.formService.getData(this.userColName.VILLE, editForm, this.userColName.fields),
      adresse: this.formService.getData(this.userColName.ADRESSE, editForm, this.userColName.fields),
      description: this.formService.getData(this.userColName.DESCRIPTION, editForm, this.userColName.fields),
      telephone1: this.formService.getData(this.userColName.TELEPHONE1, editForm, this.userColName.fields),
      telephone2: this.formService.getData(this.userColName.TELEPHONE2, editForm, this.userColName.fields),
      telephone3: this.formService.getData(this.userColName.TELEPHONE3, editForm, this.userColName.fields),
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour se connecter.
   */
  getAuthColumnName(): BoutiqueCreateNm.ColumnName {
    return {
      nom: this.userColName.NOM,
      devise: this.userColName.DEVISE,
      pays: this.userColName.PAYS,
      ville: this.userColName.VILLE,
      adresse: this.userColName.ADRESSE,
      description: this.userColName.DESCRIPTION,
      telephone1: this.userColName.TELEPHONE1,
      telephone2: this.userColName.TELEPHONE2,
      telephone3: this.userColName.TELEPHONE3,
    };
  }

  /**
   * @returns Formulaire de connexion.
   */
  getForm(): any {
    return {
      nom: this.userValidator.NOM,
      devise: this.userValidator.DEVISE,
      pays: this.userValidator.PAYS,
      ville: this.userValidator.VILLE,
      adresse: this.userValidator.ADRESSE,
      description: this.userValidator.DESCRIPTION,
      telephone1: this.userValidator.TELEPHONE,
      telephone2: this.userValidator.TELEPHONE,
      telephone3: this.userValidator.TELEPHONE,
    };
  }
}
