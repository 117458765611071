export const slashRoute = '/';

export namespace RGlobal {
  export const url = {
    LOGIN: '', // page d'authentification.
    ACCUEIL: 'accueil',
    ABONNEMENT: 'abonnement',
    EMPLOYE: {
      EMPLOYE_: 'employe',
      LIST: 'liste',
      SEARCH: 'search',
      CREATE: 'create',
      UPDATE: 'update'
    },
    USER_ACCOUNT: {
      USER_ACCOUNT_: 'user-account',
      CREATE: 'create',
      UPDATE: 'update',
      VALIDATE: 'validate',
      RESET_PASSWORD: 'reset-password',
      RESET_PASSWORD_VALIDATE: 'reset-password/validate'
    },
    SORTIE: {
      SORTIE_: 'sortie',
      LIST: 'liste',
      SEARCH: 'search',
      CREATE: 'create',
      UPDATE: 'update'
    },
    ARTICLE: {
      ARTICLE_: 'article',
      LIST: 'liste',
      SEARCH: 'search',
      CREATE: 'create',
      UPDATE: 'update'
    },
    ARTICLE_INFOS: {
      ARTICLE_INFOS_: 'article-infos',
      LIST: 'liste',
      SEARCH: 'search',
      CREATE: 'create',
      UPDATE: 'update'
    },
    USER: {
      USER_: 'utilisateurs',
      LIST: 'liste',
      SEARCH: 'search',
      CREATE: 'create',
      UPDATE: 'update'
    },
    CATEGORIE_ARTICLE: {
      CATEGORIE_ARTICLE_: 'categorie-article',
      LIST: 'liste',
      SEARCH: 'search',
      CREATE: 'create',
      UPDATE: 'update'
    },
    PAGE_NOT_FOUND: 'page-not-found',
    PAGE_RESTRICTED: 'access-non-autorise'
  };

  export const urlFull = {
    EMPLOYE: {
      LIST: RGlobal.url.EMPLOYE.EMPLOYE_
        .concat(slashRoute).concat(RGlobal.url.EMPLOYE.LIST),
      SEARCH: RGlobal.url.EMPLOYE.EMPLOYE_
        .concat(slashRoute).concat(RGlobal.url.EMPLOYE.SEARCH),
      CREATE: RGlobal.url.EMPLOYE.EMPLOYE_
        .concat(slashRoute).concat(RGlobal.url.EMPLOYE.CREATE),
      UPDATE: RGlobal.url.EMPLOYE.EMPLOYE_
        .concat(slashRoute).concat(RGlobal.url.EMPLOYE.UPDATE)
    },
    USER_ACCOUNT: {
      CREATE: RGlobal.url.USER_ACCOUNT.USER_ACCOUNT_
        .concat(slashRoute).concat(RGlobal.url.USER_ACCOUNT.CREATE)
    },
    SORTIE: {
      LIST: RGlobal.url.SORTIE.SORTIE_
        .concat(slashRoute).concat(RGlobal.url.SORTIE.LIST),
      SEARCH: RGlobal.url.SORTIE.SORTIE_
        .concat(slashRoute).concat(RGlobal.url.SORTIE.SEARCH),
      CREATE: RGlobal.url.SORTIE.SORTIE_
        .concat(slashRoute).concat(RGlobal.url.SORTIE.CREATE),
      UPDATE: RGlobal.url.SORTIE.SORTIE_
        .concat(slashRoute).concat(RGlobal.url.SORTIE.UPDATE)
    },
    ARTICLE: {
      LIST: RGlobal.url.ARTICLE.ARTICLE_
        .concat(slashRoute).concat(RGlobal.url.ARTICLE.LIST),
      SEARCH: RGlobal.url.ARTICLE.ARTICLE_
        .concat(slashRoute).concat(RGlobal.url.ARTICLE.SEARCH),
      CREATE: RGlobal.url.ARTICLE.ARTICLE_
        .concat(slashRoute).concat(RGlobal.url.ARTICLE.CREATE),
      UPDATE: RGlobal.url.ARTICLE.ARTICLE_
        .concat(slashRoute).concat(RGlobal.url.ARTICLE.UPDATE)
    },
    ARTICLE_INFOS: {
      LIST: RGlobal.url.ARTICLE_INFOS.ARTICLE_INFOS_
        .concat(slashRoute).concat(RGlobal.url.ARTICLE_INFOS.LIST),
      SEARCH: RGlobal.url.ARTICLE_INFOS.ARTICLE_INFOS_
        .concat(slashRoute).concat(RGlobal.url.ARTICLE_INFOS.SEARCH),
      CREATE: RGlobal.url.ARTICLE_INFOS.ARTICLE_INFOS_
        .concat(slashRoute).concat(RGlobal.url.ARTICLE_INFOS.CREATE),
      UPDATE: RGlobal.url.ARTICLE_INFOS.ARTICLE_INFOS_
        .concat(slashRoute).concat(RGlobal.url.ARTICLE_INFOS.UPDATE)
    },
    CATEGORIE_ARTICLE: {
      LIST: RGlobal.url.CATEGORIE_ARTICLE.CATEGORIE_ARTICLE_
        .concat(slashRoute).concat(RGlobal.url.CATEGORIE_ARTICLE.LIST),
      SEARCH: RGlobal.url.CATEGORIE_ARTICLE.CATEGORIE_ARTICLE_
        .concat(slashRoute).concat(RGlobal.url.CATEGORIE_ARTICLE.SEARCH),
      CREATE: RGlobal.url.CATEGORIE_ARTICLE.CATEGORIE_ARTICLE_
        .concat(slashRoute).concat(RGlobal.url.CATEGORIE_ARTICLE.CREATE),
      UPDATE: RGlobal.url.CATEGORIE_ARTICLE.CATEGORIE_ARTICLE_
        .concat(slashRoute).concat(RGlobal.url.CATEGORIE_ARTICLE.UPDATE)
    },
    USER: {
      LIST: RGlobal.url.USER.USER_.concat(slashRoute).concat(RGlobal.url.USER.LIST),
      SEARCH: RGlobal.url.USER.USER_.concat(slashRoute).concat(RGlobal.url.USER.SEARCH),
      CREATE: RGlobal.url.USER.USER_.concat(slashRoute).concat(RGlobal.url.USER.CREATE),
      UPDATE: RGlobal.url.USER.USER_.concat(slashRoute).concat(RGlobal.url.USER.UPDATE)
    }
  };
}
