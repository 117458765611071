import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SignUp, SignUpInit} from './user-account-create.init';
import {FormService} from '../../../shared/ services/form.service';
import {IUtilisateur, SexeType} from '../../../shared/models/utilisateur.model';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {Observable} from 'rxjs';
import {UserAccountService} from '../user-account.service';
import {AppInfos} from '../../../shared/models/app-infos.model';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {RsaService} from '../../../shared/ services/rsa.service';
import {LanguageModel} from '../../../shared/models/language.model';
import {Lang, langs, storage} from '../../../shared/constant/model/global.constant';
import {MatSelectChange} from '@angular/material/select';
import {TranslateService} from '@ngx-translate/core';

/**
 * Permet de créer un compte utilisateur.
 */
@Component({
  selector: 'app-user-account-create',
  templateUrl: './user-account-create.component.html'
})
export class UserAccountCreateComponent implements OnInit, OnDestroy {
  constructor(
    private translateService: TranslateService,
    private rsaService: RsaService,
    private accountService: AccountService,
    private userAccountService: UserAccountService,
    private formService: FormService,
    private signInInit: SignUpInit,
    private formSignIn: FormBuilder,
    private errorServ: ConstantErrorServ,
    private http: HttpClient
  ) {
    this.signInColName = this.signInInit.getAuthColumnName();
  }

  // VAR.
  code: string;
  key: string;
  codeTaped: string;
  hasCodeError = true;
  codeInvalid = false;
  codeIsValid = false;

  appInfos: AppInfos;
  editForm = this.formSignIn.group(this.signInInit.getForm());
  signInColName: SignUp.ColumnName;
  spinner = false;
  validationHasSucceed = false;
  onValidate = false;
  showPassword = false;
  hasLoginError = false;
  hasEmailError = false;
  // SEXE
  sexeHomme = SexeType.HOMME;
  sexeFemme = SexeType.FEMME;

  langues: LanguageModel[] = langs;
  defaultLang = Lang.DEFAULT;

  ngOnInit(): void {
    this.accountService.getAppInfos().subscribe(res => {
      this.appInfos = res.body;
      console.log('app: ' + this.appInfos.pageFacebook);
    });
  }

  ngOnDestroy(): void {
    this.removeMsgError();
    this.validationHasSucceed = false;
  }

  getDefault() {
    const key = localStorage.getItem(storage.myLang);
    return key ? key : this.defaultLang;
  }

  changeLang(l: MatSelectChange) {
    console.log('## change: ', l);
    if (l && l.value) {
      localStorage.setItem(storage.myLang, l.value);
      this.translateService.setDefaultLang(l.value);
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  removeMsgError() {
    this.hasEmailError = false;
    this.hasLoginError = false;
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit avec le formulaire en tapant au moins un caractère.
   */
  reset(editForm: FormGroup) {
    editForm.get(this.signInColName.login).markAsPristine();
  }

  /**
   * récupère les données de l'utilisateur du formulaire.
   */
  getUserFromForm(editForm: FormGroup): IUtilisateur {
    return this.signInInit.getAuthFromForm(editForm);
  }

  register() {
    console.log('register');
    this.onValidate = true;
    this.spinner = true;
    this.removeMsgError();
    this.reset(this.editForm);
    const user: IUtilisateur = this.getUserFromForm(this.editForm);
    user.password = this.rsaService.crypt(user.password);
    const result = this.userAccountService.save(user);
    this.subscribeToSaveResponse(result, this.editForm);
  }


  /**
   * Gère les erreurs lors de l'enregistrement d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromSavingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      const fields = erreur.fields;
      this.hasLoginError = fields.login ? true : false;
      this.hasEmailError = fields.email ? true : false;
    }
  }

  validateCode() {
    const codeCrypted = this.rsaService.crypt(this.codeTaped);
    const data = {
      code: codeCrypted,
      key: this.key
    };
    this.userAccountService.validate(data).subscribe( res => {
      this.hasCodeError = false;
      this.codeIsValid = true;
      this.codeInvalid = false;
      this.code = undefined;
    }, err => {
      this.codeIsValid = false;
      this.codeInvalid = true;
      this.hasCodeError = true;
    });
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<IUtilisateur>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<any>) => {
        console.log('**** success save user account: ');
        this.validationHasSucceed = true;
        this.onValidate = false;
        this.editForm.reset();
        this.spinner = false;
        const body = res.body;
        this.code = body.code;
        this.key = body.key;
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save user: ');
        this.validationHasSucceed = false;
        this.onValidate = false;
        this.spinner = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        try {
          this.manageErrorFromSavingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

  /**
   * @returns true si l'utilisateur à authentifier est bloqué.
   */
  /*hasEtatError(): boolean {
    return this.loginService.getEtatError();
  }

  hasSubscriptionError(): boolean {
    return this.loginService.getSubscriptionError();
  }*/

  /**
   * @returns true si le serveur n'a pas pu identifier l'utilisalteur.
   */

  /*hasAuthError(): boolean {
    return this.loginService.getAuthError();
  }
*/

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   */
  isEmailInvalid(field: string): boolean {
    return this.formService.isEmailInvalid(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorPattern(field: string): boolean {
    return this.formService.hasErrorPattern(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

}
