import {AbsCatArtVerification} from './abs-cat-art-verification';
import {ConstantCategorieArticleColumnName} from '../../../../shared/constant/model/column-name/categorie-column-name.constant';
import {CodeVerification} from './code-verification';

/**
 * Permet de vérifier si le code fait partir des filtres de recherche
 * et de l'ajouter dans les paramètres dans ce cas.
 */
export class CodeSortieVerification extends AbsCatArtVerification {
  constructor() {
    super();
  }

  private codeSortie: string;
  private userColName: ConstantCategorieArticleColumnName;

  codeSet(code: string): CodeSortieVerification {
    this.codeSortie = code;
    return this;
  }

  catArtColNameSet(userColName: ConstantCategorieArticleColumnName): CodeSortieVerification {
    this.userColName = userColName;
    return this;
  }


  /**
   * Vérifie si le code fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  check(first: AbsCatArtVerification) {
    if (this.codeSortie) {
      first.getDataSearch()[this.userColName.CODE_SORTIE] = this.codeSortie;
    }
    super.checkNext(first);
  }
}
