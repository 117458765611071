import {Routes} from '@angular/router';
import {RGlobal} from '../../shared/constant/global.url';
import {HomeComponent} from './home.component';
import {PageNotFoundComponent} from '../../shared/layouts/error/page-not-found.component';
import {GGuardIsLoginService} from '../../shared/ services/guard/is-login/gguard-is-login.service';
import {employeRoute} from '../../modules/employe/employe.route';
import {categorieArticleRoute} from '../../modules/categorie-article/categorie-article.route';
import {articleInfosRoute} from '../../modules/article-infos/article-infos.route';
import {PageRestrictedComponent} from '../../shared/layouts/restricted/page-restricted.component';
import {articleRoute} from '../../modules/article/article.route';
import {sortieRoute} from '../../modules/sortie/sortie.route';

/**
 * routes de la page d'accueil.
 */
export const homeRoute: Routes = [
  {
    path: RGlobal.url.ACCUEIL,
    component: HomeComponent,
    canActivate: [GGuardIsLoginService]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [GGuardIsLoginService],
    children: [...sortieRoute, ...articleRoute, ...articleInfosRoute,
      ...employeRoute, ...categorieArticleRoute]
  },
  {path: RGlobal.url.PAGE_RESTRICTED, component: PageRestrictedComponent},
  {path: '**', component: PageNotFoundComponent},
  {path: RGlobal.url.PAGE_NOT_FOUND, redirectTo: '**'}
];
