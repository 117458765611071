import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IRestoreWhenDisconnectService} from '../../shared/ services/restore/irestore-when-disconnect.service';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {ISortieDetails} from '../../shared/models/sortie-details.model';
import {IArticleInfos} from '../../shared/models/article-infos.model';

@Injectable({providedIn: 'root'})
export class DataShareWhenCheckedArtInfos implements IRestoreWhenDisconnectService {
  constructor(
    private restore: RestoreWhenDisconnectService) {
    this.restore.add(this);
  }

  // VAR.
  // private artInfosListCheckedObs = new BehaviorSubject<IArticleInfos[]>(undefined);
  artInfosList: ISortieDetails[] = [];
  catArtCheckedObs = new BehaviorSubject<IArticleInfos>(undefined);
  isCatArtCheckedObs = new BehaviorSubject<boolean>(false);
  sortieDetailsCheckedObs = new BehaviorSubject<ISortieDetails>(undefined);
  isSortieDetailsCheckedObs = new BehaviorSubject<boolean>(undefined);


  /*public getArtInfosListCheckedObs(): Observable<IArticleInfos[]> {
    return this.artInfosListCheckedObs.asObservable();
  }*/

  public getSortieDetailsCheckedObs(): Observable<ISortieDetails> {
    return this.sortieDetailsCheckedObs.asObservable();
  }

  public setSortieDetailsCheckedObs(sortie: ISortieDetails) {
    this.sortieDetailsCheckedObs.next(sortie);
  }

  public setIsSortieDetailsCheckedObs(sortie: boolean) {
    this.isSortieDetailsCheckedObs.next(sortie);
  }

  public getIsSortieDetailsCheckedObs() {
    return this.isSortieDetailsCheckedObs.asObservable();
  }

  /**
   * @return l'utilisateur coché.
   */
  public getCatArtcheckedObs(): Observable<IArticleInfos> {
    return this.catArtCheckedObs.asObservable();
  }

  /**
   * Permet de modifier la valeur de l'utilisateur
   * coché.
   * @param user le nouvel utilisateur.
   */
  public setCatArtcheckedObs(user: IArticleInfos) {
    this.catArtCheckedObs.next(user);
  }

  /**
   * @return Observable<boolean> true indique l'utilisateur
   * est coché, false sinon.
   */
  public getIsCatArtCheckedObs(): Observable<boolean> {
    return this.isCatArtCheckedObs.asObservable();
  }

  /**
   * @param checked true indique l'utilisateur
   * est coché, false sinon.
   */
  public setIsCatArtChecked(checked: boolean) {
    this.isCatArtCheckedObs.next(checked);
  }

  /**
   * Utilisé à chaque initialisation du component user.
   */
  clearCatArtChecked() {
    this.setCatArtcheckedObs(undefined);
    this.setIsCatArtChecked(false);
  }

  /**
   * Utilisé à chaque initialisation du component user.
   */
  clearSortieDetailsChecked() {
    this.setSortieDetailsCheckedObs(undefined);
    this.setIsSortieDetailsCheckedObs(false);
  }

  /**
   * La liste des éléments du panier est supprimé soit par
   * l'utilisateur ou automatiquement lors de la déconnexion.
   */
  clear() {
    this.clearCatArtChecked();
    this.clearSortieDetailsChecked();
    this.artInfosList = []; // liste des éléments du panier.
  }
}
