import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {SignIn, SignInInit} from './login.init';
import {LoginService} from './services/login.service';
import {Router} from '@angular/router';
import {ManageService} from '../../shared/ services/manage.service';
import {FormService} from '../../shared/ services/form.service';
import {RGlobal, slashRoute} from '../../shared/constant/global.url';
import {AccountService} from '../../shared/ services/auth/account/account.service';
import {AppInfos} from '../../shared/models/app-infos.model';
import {PopupReusableService} from '../../reusable/services/popup-reusable.service';
import {AuthPopupComponent} from '../auth-popup/auth-popup.component';
import {JWT} from '../../shared/constant/model/server.constant';
import {AuthPopupService} from '../auth-popup/auth-popup.service';
import {LanguageModel} from '../../shared/models/language.model';
import {Lang, langs, storage} from '../../shared/constant/model/global.constant';
import {MatSelectChange} from '@angular/material/select';
import {TranslateService} from '@ngx-translate/core';
import {LocaleCsService} from '../../shared/ services/localecs.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private localeCsService : LocaleCsService,
    private translateService: TranslateService,
    private authPopupService: AuthPopupService,
    private popupReusableService: PopupReusableService,
    private accountService: AccountService,
    private router: Router,
    private manageService: ManageService,
    private loginService: LoginService,
    private http: HttpClient,
    private signInInit: SignInInit,
    private formService: FormService,
    private formSignIn: FormBuilder,
  ) {
    console.log('on construct login');
    this.signInColName = this.signInInit.getAuthColumnName();
    //  localStorage.removeItem(JWT.auth);
  }

  // VAR.
  appInfos: AppInfos;
  editForm = this.formSignIn.group(this.signInInit.getForm());
  // Ne pas supprimé, utilisé côté Html.
  signInColName: SignIn.ColumnName;
  showPassword = false;
  resetPass = RGlobal.url.USER_ACCOUNT.USER_ACCOUNT_ + slashRoute +
    RGlobal.url.USER_ACCOUNT.RESET_PASSWORD;
  createAccount = RGlobal.url.USER_ACCOUNT.USER_ACCOUNT_ + slashRoute +
    RGlobal.url.USER_ACCOUNT.CREATE;

  langues: LanguageModel[] = langs;
  defaultLang = Lang.DEFAULT;

  ngOnInit() {
    console.log('** onInit login');
    // this.checkAuthPopup();
    this.accountService.getAppInfos().subscribe(res => {
      this.appInfos = res.body;
      console.log('app: ' + this.appInfos.pageFacebook);
    });
  }

  ngOnDestroy(): void {
    console.log('** on destroy login');
    localStorage.removeItem(JWT.auth);
    localStorage.removeItem(JWT.accueil);
  }

  getDefault() {
    const key = localStorage.getItem(storage.myLang);
    return key ? key : this.defaultLang;
  }

  changeLang(l: MatSelectChange) {
    console.log('## change: ', l);
    if (l && l.value) {
      localStorage.setItem(storage.myLang, l.value);
      this.translateService.setDefaultLang(l.value);
      this.localeCsService.registerCulture(l.value);
    }
  }

  checkAuthPopup() {
    const showAuth = localStorage.getItem(JWT.auth);
    const fromAccueil = localStorage.getItem(JWT.accueil);
    if (!showAuth && !fromAccueil) {
      console.log('défini: ' + showAuth);
      localStorage.removeItem(JWT.accueil);
      this.authPopupService.open(AuthPopupComponent);
      // this.popupReusableService.open(null, AuthPopupComponent);
    } else {
      localStorage.removeItem(JWT.auth);
      localStorage.removeItem(JWT.accueil);
    }
  }

  getSpinner(): boolean {
    return this.loginService.spinner;
  }

  /**
   * @returns true si le bouton d'authentification est activé, sinon
   * false.
   */
  isLoginButtonActivated(): boolean {
    return this.loginService.getActivatedLoginButton();
  }

  /**
   *
   */
  login() {
    console.log('** login');
    this.loginService.login(this.editForm);
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  /**
   * @returns true si l'utilisateur à authentifier est bloqué.
   */
  hasEtatError(): boolean {
    return this.loginService.getEtatError();
  }

  hasSubscriptionError(): boolean {
    return this.loginService.getSubscriptionError();
  }

  /**
   * @returns true si le serveur n'a pas pu identifier l'utilisalteur.
   */
  hasAuthError(): boolean {
    return this.loginService.getAuthError();
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }
}
