import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {ConstantUserColumnName} from '../../../shared/constant/model/column-name/user-column-name.constant';
import {ConstantBoutiqueColumnName} from '../../../shared/constant/model/column-name/boutique-column-name.constant';
import {BoutiqueService} from '../../boutique/boutique.service';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {RestoreEmployeService} from '../restore-employe.service';
import {DataShareWhenCheckedUser} from '../data-share-when-checked-user.service';
import {ManageService} from '../../../shared/ services/manage.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EmployeService} from '../employe.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';
import {RequestService} from '../../../shared/ services/request/request.service';
import {RGlobal} from '../../../shared/constant/global.url';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {NotificationService} from '../../../reusable/services/notification.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-employe-add',
  templateUrl: './employe-add.component.html'
})
export class EmployeAddComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private errorServ: ConstantErrorServ,
    private requestService: RequestService,
    private popupReusableService: PopupReusableService,
    private userColumnName: ConstantUserColumnName,
    private boutiqueColumnName: ConstantBoutiqueColumnName,
    private boutiqueService: BoutiqueService,
    private dateFormatService: DateFormatService,
    private restoreUserService: RestoreEmployeService,
    private dataShareWhenCheckedUser: DataShareWhenCheckedUser,
    private manageService: ManageService,
    private activeModal: NgbActiveModal,
    private employeService: EmployeService) {
  }

  // VAR.
  isLoading = false;
  boutiqueSelected: Boutique;
  loadWhenDeleting = false;
  login: string;
  hasLoginError = false;
  isLoginExist = false;
  alreadyOwner = false;
  shouldIShow = false;
  subs$ = new Subject<void>();
  open = true;
  activatedValidationButton = true;

  ngOnInit(): void {
    console.log('init add employe');
    this.popupReusableService.getPromise().then(res => {
      this.shouldIShow = true;
    });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
  }

  ngOnDestroy(): void {
  }

  add() {
    console.log('add user');
    this.reset();
    if (this.login && this.boutiqueSelected) {
      this.isLoading = true;
      const data = {
        login: this.login,
        boutique: this.boutiqueSelected
      };
      const result = this.employeService.add(data);
      this.subscribeToSaveResponse(result);
    }
  }

  reset() {
    this.hasLoginError = false;
    this.alreadyOwner = false;
    this.isLoginExist = false;
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromSavingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    if (erreur.errorCode === this.errorServ.NOT_EXIST) {
      const fields = erreur.fields;
      this.hasLoginError = fields.login ? true : false;
    }
    if (erreur === this.errorServ.ALREADY_OWNER) {
      console.log('déjà propriétaire');
      this.alreadyOwner = true;
    }
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      console.log('existe déjà');
      this.isLoginExist = true;
    }
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<EmployeBoutique>>
  ) {
    result.subscribe(
      (res: HttpResponse<EmployeBoutique>) => {
        console.log('**** success add user account: ');
        this.isLoading = false;
        this.login = undefined;
        this.notificationService.showSuccess('Une demande a été envoyée à l\'utilisateur',
          'Ajout effectuée avec succès');
        this.router.navigate([RGlobal.urlFull.EMPLOYE.LIST]);
        this.popupReusableService.dismiss(this.activeModal);
      },
      (err: HttpErrorResponse) => {
        console.log('**** error add user: ');
        this.isLoading = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        try {
          this.manageErrorFromSavingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

}
