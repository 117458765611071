import {Routes} from '@angular/router';
import {RGlobal} from '../../shared/constant/global.url';
import {UserAccountCreateComponent} from './user-account-create/user-account-create.component';
import {UserAccountValidateComponent} from './user-account-validate/user-account-validate.component';
import {UserAccountResetPasswordComponent} from './user-account-reset-password/user-account-reset-password.component';
import {ResetPasswordValidateComponent} from './reset-password-validate/reset-password-validate.component';
import {AbonnementValidateComponent} from '../abonnement/abonnement-validate/abonnement-validate.component';

/**
 * Routes gestion des utilisateurs.
 */
export const userAccountRoute: Routes = [
  {
    path: RGlobal.url.ABONNEMENT,
    component: AbonnementValidateComponent,
  },
  {
    path: RGlobal.url.USER_ACCOUNT.USER_ACCOUNT_,
    children: [
      {
        path: RGlobal.url.USER_ACCOUNT.RESET_PASSWORD_VALIDATE,
        component: ResetPasswordValidateComponent
      },
      {
        path: RGlobal.url.USER_ACCOUNT.RESET_PASSWORD,
        component: UserAccountResetPasswordComponent
      },
      {
        path: RGlobal.url.USER_ACCOUNT.CREATE,
        component: UserAccountCreateComponent
      },
      {
        path: RGlobal.url.USER_ACCOUNT.VALIDATE,
        component: UserAccountValidateComponent
      }
    ]
  }
];
