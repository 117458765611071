import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortieCreateForInit, SortieCreateInit} from '../sortie-create.init';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {IArticleInfos} from '../../../../shared/models/article-infos.model';
import {PopupReusableService} from '../../../../reusable/services/popup-reusable.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {ISortieDetails, SortieDetails} from '../../../../shared/models/sortie-details.model';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {NRestricted} from '../../../../shared/constant/restricted.msg';
import {AccountUser} from '../../../../shared/models/account.model';
import {PageRestrictedService} from '../../../../shared/layouts/restricted/page-restricted.service';
import {Router} from '@angular/router';
import {FreeMemoryService} from '../../../../reusable/services/free-memory.service';
import {ConstantErrorServ} from '../../../../shared/constant/error.constant';
import {RequestService} from '../../../../shared/ services/request/request.service';
import {ArticleInfosService} from '../../../article-infos/article-infos.service';
import {Boutique} from '../../../../shared/models/boutique.model';
import {BoutiqueService} from '../../../boutique/boutique.service';
import {Uri} from '../../../../shared/constant/model/global.constant';
import {RestoreArtInfosService} from '../../../article-infos/restore-art-infos.service';
import {DataShareWhenCheckedArtInfos} from '../../../article-infos/data-share-when-checked-art-infos.service';
import {FormService} from '../../../../shared/ services/form.service';
import {AccountService} from '../../../../shared/ services/auth/account/account.service';

@Component({
  selector: 'app-panier-create',
  templateUrl: './panier-create.component.html'
})
export class PanierCreateComponent implements OnInit, OnDestroy {
  constructor(
    private boutiqueService: BoutiqueService,
    private articleInfosService: ArticleInfosService,
    private pageRestrictedService: PageRestrictedService,
    private requestService: RequestService,
    private router: Router,
    private freeMemoryService: FreeMemoryService,
    private catInit: SortieCreateInit,
    private errorServ: ConstantErrorServ,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
    private restoreArtInfosService: RestoreArtInfosService,
    private dataShareArtInfos: DataShareWhenCheckedArtInfos,
    private accountService: AccountService,
    private categorieArticleInit: SortieCreateInit,
    private formCategorieArticleCreate: FormBuilder,
    private formService: FormService
  ) {
    this.catColName = this.catInit.getCategorieArticleColumnName();
  }

  // VAR.
  isLoading = true;
  sortieDetailsChecked: SortieDetails;
  boutiqueSelected: Boutique;
  activatedValidationButton = true;
  catColName: SortieCreateForInit.ColumnName;
  hasQtEnregistreError = false;
  hasQtInsufficientError = false;
  serverHasError = false;
  accountUser: AccountUser;
  catArtList: IArticleInfos[];
  needToCreateCatArt = false;
  hasGetArtInfos = false;
  filteredOptions: Observable<IArticleInfos[]>;
  open = true;
  editForm = this.formCategorieArticleCreate.group(
    this.categorieArticleInit.getForm()
  );
  subs$ = new Subject<void>();


  ngOnInit() {
    console.log('*** sortie create onInit');
    this.open = true;
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getUserIdentityObs(this.accountService);
      this.getAllCatArt();
    });
    this.getCatArtChecked(this.dataShareArtInfos,
      this.editForm, this.subs$);
  }

  ngOnDestroy(): void {
    console.log('destroy');
    this.restore();
  }

  isOpen() {
    return this.open;
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  compareFn(obj1, obj2): boolean {
    return obj1 && obj2 && (obj1.id === obj2.id);
  }

  displayFn(subject) {
    return subject ? subject.designation : undefined;
  }

  getArtInfosSelected(event) {
    console.log('event');
    if (event) {
      this.removeMsgError();
      this.sortieDetailsChecked.articleInfos = event.option.value;
    }
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'maxlength'.
   */
  hasErrorMaxLength(field: string): boolean {
    return this.formService.hasErrorMaxLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  getCatArtChecked(dataShare: DataShareWhenCheckedArtInfos,
                   form: FormGroup,
                   subs$: Subject<void>) {
    dataShare.getCatArtcheckedObs()
      .pipe(takeUntil(subs$)).subscribe((data: IArticleInfos) => {
      if (data) {
        this.restoreArtInfosService.setArtInfosChecked(data);
        this.sortieDetailsChecked = new SortieDetails();
        this.sortieDetailsChecked.articleInfos = data;
        form.patchValue({
          [this.catColName.idArticleInfos]: data
        });
      }
      this.hasGetArtInfos = true;
    });
  }

  restore() {
    this.catArtList = undefined;
    this.editForm.reset();
    this.removeMsgError();
    this.hasGetArtInfos = false;
    this.restoreArtInfosService.restore(this.subs$);
    this.freeMemoryService.free(this.subs$);
  }

  getFilteredOptions(): Observable<IArticleInfos[]> {
    return this.filteredOptions;
  }

  getArtInfosChecked(): IArticleInfos {
    return this.restoreArtInfosService.getArtInfosChecked();
  }

  shouldIShow(): boolean {
    return this.getCatArtList() && this.getCatArtList().length !== 0
      && this.hasGetArtInfos;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

  filterOption() {
    this.filteredOptions = this.editForm.get('id_article_infos').valueChanges.pipe(
      startWith(''),
      map(((value: any) => {
          if (value) {
            if (typeof value === 'object') {
              return this._filter(value.designation);
            } else {
              // value is a string.
              return this._filter(value);
            }
          }
          return this._filter('');
        })
      ));
  }

  _filter(value: string): IArticleInfos[] {
    const filterValue = value.toLocaleLowerCase();
    return this.getCatArtList().filter(option =>
      option.designation.toLowerCase().includes(filterValue));
  }


  removeMsgError() {
    this.serverHasError = false;
    this.hasQtEnregistreError = false;
    this.hasQtInsufficientError = false;
  }


  /**
   * Permet de désactiver le formulaire s'il contient des erreurs.
   */
  validationHasError() {
    return this.editForm.invalid || !this.hasGetArtInfos || !this.activatedValidationButton;
  }

  /**
   * récupère les données de la catégorie article du formulaire.
   */
  getCatFromForm(form: FormGroup): ISortieDetails {
    return this.catInit.getCategorieFromForm(form);
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que, comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit(en tapant au moins un caractère) avec le formulaire .
   */
  reset(form: FormGroup) {
    form.get(this.catColName.quantiteSortie).markAsPristine();
  }

  getCatArtList(): IArticleInfos[] {
    return this.catArtList;
  }

  doINeedToCreateCatArt(): boolean {
    return this.needToCreateCatArt;
  }

  getQtEnregistreError(): boolean {
    return this.hasQtEnregistreError;
  }

  getQtInsufficientError(): boolean {
    return this.hasQtInsufficientError;
  }

  getAllCatArt() {
    this.needToCreateCatArt = false;
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.articleInfosService.getListCalculate(option);
    result.subscribe(
      (r: HttpResponse<IArticleInfos[]>) => {
        console.log('** succès récupération article infos');
        this.isLoading = false;
        const data = r.body;
        this.catArtList = data;
        this.filterOption();
        if (!data || data.length === 0) {
          this.needToCreateCatArt = true;
        }
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération article infos');
        this.isLoading = false;
        this.pageRestrictedService.goToRestrictedAccess(NRestricted.msgFull.ARTICLE_INFOS.LIST);
      }
    );
  }

  addDetails(sortieDetails: ISortieDetails, articleInfos: IArticleInfos) {
    if (sortieDetails && articleInfos) {
      sortieDetails.articleInfos.designation = articleInfos.designation;
      sortieDetails.articleInfos.code = articleInfos.code;
      sortieDetails.articleInfos.prixUnitaire = articleInfos.prixUnitaire;
      sortieDetails.articleInfos.prixUnitaireVente = articleInfos.prixUnitaireVente;
    }
  }

  validate(artInfosList: ISortieDetails[], sortieDetails: ISortieDetails,
           quantiteSortie: number) {
    if (!this.hasQtEnregistreError && !this.hasQtInsufficientError) {
      let foundSortie = false;
      for (const artInfos of artInfosList) {
        if (artInfos.articleInfos.id === sortieDetails.articleInfos.id) {
          foundSortie = true;
          artInfos.quantiteSortie = quantiteSortie;
          artInfos.montant = quantiteSortie * this.restoreArtInfosService
            .getArtInfosChecked().prixUnitaireVente;
        }
      }
      if (!foundSortie) {
        sortieDetails.montant = quantiteSortie * this.restoreArtInfosService
          .getArtInfosChecked().prixUnitaireVente;
        this.dataShareArtInfos.artInfosList.push(sortieDetails);
      }
      this.open = false;
      this.popupReusableService.dismiss(this.activeModal);
    }
    console.log('sortieDetails:', this.dataShareArtInfos.artInfosList);
  }

  validateQt(sortieDetails: ISortieDetails, quantiteSortie: number) {
    if (sortieDetails.quantiteSortie <= 0) {
      this.hasQtEnregistreError = true;
    }
    if (quantiteSortie > sortieDetails.articleInfos.quantiteDisponible) {
      this.hasQtInsufficientError = true;
    }
  }

  /**
   * Crée un utilisateur.
   * @param user l'utilisateur à créer.
   */
  create() {
    this.activatedValidationButton = false;
    console.log('ajout panier');
    this.removeMsgError();
    this.reset(this.editForm);
    const sortieDetails: ISortieDetails = this.getCatFromForm(this.editForm);
    this.addDetails(sortieDetails, this.restoreArtInfosService.getArtInfosChecked());
    console.log('sortie: ', sortieDetails);
    let quantiteSortie = (+sortieDetails.quantiteSortie);
    const artInfosList: ISortieDetails[] = this.dataShareArtInfos.artInfosList;
    for (const artInfos of artInfosList) {
      if (artInfos.articleInfos.id === sortieDetails.articleInfos.id) {
        quantiteSortie += (+artInfos.quantiteSortie);
        console.log('qtsortie: ' + quantiteSortie);
      }
    }
    this.validateQt(sortieDetails, quantiteSortie);
    this.validate(artInfosList, sortieDetails, quantiteSortie);
    this.activatedValidationButton = true;
  }
}
