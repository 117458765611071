// Fournit plusieurs API.

// AUTH API.
export const API_AUTH = '/authenticate'; // authentification des utilisateurs
export const API_ACCOUNT = '/account'; // permet de gérer le compte utilisateur
export const API_ACCOUNT_APP_INFOS_LIST = '/app/infos/liste'; // permet de gérer le compte utilisateur
export const API_ACCOUNT_ACTION = '/account/actions'; // permet de gérer le compte utilisateur
export const API_ACCOUNT_ADMIN = '/admin/account/pro'; // permet de gérer le compte utilisateur
// connecté.

// Employe
export const API_EMPLOYE_SEARCH = '/employes/search';
export const API_EMPLOYE = '/employes';
export const API_EMPLOYE_ALL = '/employes/all';
export const API_EMPLOYE_ALL_ACCEPTED = '/employes/all/accepted';
export const API_EMPLOYE_ACCEPTED = '/employes/accepted/';
export const API_EMPLOYE_DELETE_DEMANDE = '/employes/delete/demande/';
export const API_EMPLOYE_DELETE = '/employes/delete';
export const API_EMPLOYE_PAGES = '/employes/pages';

// Abonnement
export const API_ABONNEMENT_APPLICATION_PAY = '/abonnement/pay';
export const API_ABONNEMENT_USER_PAY = '/abonnement/user/pay';
export const API_ABONNEMENT_APPLICATION_VERIFY = '/abonnement/application/verify';
export const API_ABONNEMENT_APPLICATION = '/abonnement/application/user';
export const API_ABONNEMENT_PRODUIT = '/paypal/produit_plus_achete/payment';
export const API_ABONNEMENT_BOUTIQUE = '/paypal/boutique/payment';
export const API_ABONNEMENT_BOUTIQUE_SUCCESS = '/paypal/boutique/payment/success';
export const API_ABONNEMENT_BILAN = '/paypal/bilan/payment';
export const API_ABONNEMENT_FACTURE = '/paypal/facture/payment';
export const API_ABONNEMENT_BILAN_SUCCESS = '/paypal/bilan/payment/success';
export const API_ABONNEMENT_FACTURE_SUCCESS = '/paypal/facture/payment/success';
export const API_ABONNEMENT_PRODUIT_SUCCESS = '/paypal/produit_plus_achete/payment/success';

// BOUTIQUE
export const API_BOUTIQUE_ALL = '/boutiques/all';
export const API_BOUTIQUE = '/boutiques';
export const API_BOUTIQUE_MAX = '/boutiques/maximum';
export const API_BOUTIQUE_NB_CREATE = '/boutiques/nombre_cree';

// USER ACCOUNT
export const API_USER_ACCOUNT_CREATE_MARCHAND_ANONYME = '/utilisateurs/account/create/marchand/anonyme';
export const API_USER_ACCOUNT_CREATE = '/utilisateurs/account/create';
export const API_USER_ACCOUNT_CREATE_RESET = '/utilisateurs/account/create/reset';
export const API_USER_ACCOUNT_RESET = '/utilisateurs/account/reset';
export const API_USER_ACCOUNT_VALIDATE = '/utilisateurs/account/validate';
export const API_USER_ACCOUNT_UPDATE_EMAIL = '/utilisateurs/email';
export const API_USER_ACCOUNT_UPDATE = '/utilisateurs/account/update';
export const API_USER_ACCOUNT_DELETE = '/utilisateurs/account/delete/';

// SORTIE API.
export const API_SORTIE_DONNEE_TAKE_AB = '/sortie/donnee/take_abonnement';

export const API_SORTIE_PAGE = '/sortie/pages';
export const API_SORTIE_DELETE = '/sortie/delete';
export const API_SORTIE = '/sortie';
export const API_SORTIE_FACTURE_ABONNEMENT = '/sortie/facture/abonnement';
export const API_SORTIE_PRODUIT_ABONNEMENT = '/sortie/produit_les_plus_vendu/abonnement';
export const API_SORTIE_FACTURE = '/sortie/facture';
export const API_SORTIE_FACTURE_TAKE_AB = '/sortie/facture/take_abonnement';
export const API_SORTIE_BILAN_ABONNEMENT = '/sortie/bilan/abonnement';
export const API_SORTIE_BILAN = '/sortie/bilan';
export const API_SORTIE_BILAN_TAKE_AB = '/sortie/bilan/take_abonnement';

export const API_SORTIE_PROD_VENDU_TAKE_AB = '/sortie/produit/take_abonnement';
export const API_SORTIE_PROD_VENDU = '/sortie/produit_les_plus_vendu';

export const API_SORTIE_MONTANT_TOTAL_ACHAT = '/sortie/montant_total_achat';
export const API_SORTIE_MONTANT_TOTAL = '/sortie/montant_total';

export const API_SORTIE_SEARCH_AVANCE = '/sortie/search/avance';
export const API_SORTIE_SEARCH = '/sortie/search';

export const API_SORTIE_SEARCH_MONTANT_TOTAL_ACHAT_AVANCE = '/sortie/search/montant_total_achat/avance';
export const API_SORTIE_SEARCH_MONTANT_TOTAL_ACHAT = '/sortie/search/montant_total_achat';

export const API_SORTIE_SEARCH_MONTANT_TOTAL_AVANCE = '/sortie/search/montant_total/avance';
export const API_SORTIE_SEARCH_MONTANT_TOTAL = '/sortie/search/montant_total';

// USER API.
export const API_USER = '/utilisateurs';
export const API_USER_SEARCH = '/utilisateurs/search';

// ARTICLE API.
export const API_ARTICLE_PAGE = '/article/pages';
export const API_ARTICLE_DELETE = '/article/delete';
export const API_ARTICLE = '/article';
export const API_ARTICLE_MONTANT_TOTAL_VENTE = '/article/montant_total_vente';
export const API_ARTICLE_MONTANT_TOTAL = '/article/montant_total';

export const API_ARTICLE_SEARCH_AVANCE = '/article/search/avance';
export const API_ARTICLE_SEARCH = '/article/search';

export const API_ARTICLE_SEARCH_MONTANT_TOTAL_VENTE_AVANCE = '/article/search/montant_total_vente/avance';
export const API_ARTICLE_SEARCH_MONTANT_TOTAL_VENTE = '/article/search/montant_total_vente';

export const API_ARTICLE_SEARCH_MONTANT_TOTAL_AVANCE = '/article/search/montant_total/avance';
export const API_ARTICLE_SEARCH_MONTANT_TOTAL = '/article/search/montant_total';


// ARTICLE INFOS API.
export const API_ART_INFOS_PAGES = '/article_infos/pages';
export const API_ART_INFOS_DELETE = '/article_infos/delete';
export const API_ART_INFOS = '/article_infos';
export const API_ART_INFOS_MONTANT_TOTAL = '/article_infos/montant_total';
export const API_ART_INFOS_MONTANT_TOTAL_VENTE = '/article_infos/montant_total_vente';
export const API_ART_INFOS_ALL = '/article_infos/all';
export const API_ART_INFOS_CALCULATE = '/article_infos/calculate';
export const API_ART_INFOS_SEARCH_AVANCE = '/article_infos/search/avance';
export const API_ART_INFOS_SEARCH = '/article_infos/search';

export const API_ART_INFOS_SEARCH_MONTANT_TOTAL_AVANCE = '/article_infos/search/montant_total/avance';
export const API_ART_INFOS_SEARCH_MONTANT_TOTAL = '/article_infos/search/montant_total';

export const API_ART_INFOS_SEARCH_MONTANT_TOTAL_VENTE_AVANCE = '/article_infos/search/montant_total_vente/avance';
export const API_ART_INFOS_SEARCH_MONTANT_TOTAL_VENTE = '/article_infos/search/montant_total_vente';

// CATEGORIE ARTICLE API.
export const API_CAT_ARTICLE_PAGES = '/categorie_article/pages';
export const API_CAT_ARTICLE_DELETE = '/categorie_article/delete';
export const API_CAT_ARTICLE = '/categorie_article';
export const API_CAT_ARTICLE_ALL = '/categorie_article/all';
export const API_CAT_ARTICLE_SEARCH = '/categorie_article/search';

// ACTION API
export const API_ACTION = '/actions';
