import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Boutique} from '../../models/boutique.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AccountUser} from '../../models/account.model';
import {AccountService} from '../auth/account/account.service';
import {ICheckingPrivilegeService} from '../../../modules/employe/ichecking-privilege.service';

@Injectable({providedIn: 'root'})
export class PrivilegeService {
  constructor(
    private accountService: AccountService,
  ) {
    console.log('construct priv service');
  }

  private iCheckingPrivilegeService: ICheckingPrivilegeService;

  setICheckingPriv(i: ICheckingPrivilegeService) {
    this.iCheckingPrivilegeService = i;
  }

  /**
   * Autorise ou de restreint l'accès aux routes du module utilisateur en fonction
   * des privilèges d'un utilisateur.
   * @param route les données de l'url.
   */
  hasPrivilege(route: ActivatedRouteSnapshot, boutiqueSelected: Boutique): Observable<boolean> {
    return this.accountService.identityWithObservable().pipe(map((data: HttpResponse<AccountUser>) => {
      if (data) {
        return this.checkPrivilege(data, route, boutiqueSelected);
      }
      console.log('** failed privilege user');
      return false;
    }));
  }

  /**
   * Vérifie si les privilèges de l'utilisateur lui donnent accès aux routes.
   *
   * @param data les informations du compte de l'utilisateur connecté.
   * @param route les informations de la route que l'utilisateur veut accèder.
   */
  private checkPrivilege(data: HttpResponse<AccountUser>, route: ActivatedRouteSnapshot,
                         boutiqueSelected: Boutique): boolean {
    const account: AccountUser = data.body;
    if (account) {
      return this.iCheckingPrivilegeService.checkPrivilegeForAccount(false, account, route,
        boutiqueSelected);
    }
    return false;
  }
}
