import {Moment} from 'moment';
import {IAction} from './action.model';
import {IBoutique} from './boutique.model';

export const enum ValidationType {
  VALIDATE = 'VALIDATE',
  NOT_VALIDATE = 'NOT_VALIDATE'
}

export const enum SexeType {
  HOMME = 'HOMME',
  FEMME = 'FEMME'
}

export const enum UtilisateurType {
  ENREGISTREUR = 'ENREGISTREUR',
  VENDEUR = 'VENDEUR',
  ADMINISTRATEUR = 'ADMINISTRATEUR'
}

export const enum EtatUserType {
  ACTIF = 'ACTIF',
  BLOQUE = 'BLOQUE'
}

export interface IUtilisateur {
  id?: number;
  login?: string;
  password?: string;
  oldPassword?: string;
  nom?: string;
  prenom?: string;
  dateNaissance?: Moment;
  adresse?: string;
  sexe?: SexeType;
  telephone?: string;
  email?: string;
  identifiant?: string;
  nationalite?: string;
  etat?: EtatUserType;
  isDelete?: string;
  dateEnregistrement?: Moment;
  dateModification?: Moment;
  validation?: ValidationType;
}

export interface IUserUpdateDTO {
  id?: number;
  login?: string;
  password?: string;
  newPassword?: string;
  nom?: string;
  prenom?: string;
  dateNaissance?: Moment;
  adresse?: string;
  sexe?: SexeType;
  type?: UtilisateurType;
  telephone?: string;
  email?: string;
  nationalite?: string;
  etat?: EtatUserType;
  actions?: IAction[];
  isDelete?: string;
  dateEnregistrement?: Moment;
  dateModification?: Moment;
}

export class Utilisateur implements IUtilisateur {
  constructor(
    public id?: number,
    public login?: string,
    public password?: string,
    public oldPassword?: string,
    public nom?: string,
    public prenom?: string,
    public dateNaissance?: Moment,
    public adresse?: string,
    public sexe?: SexeType,
    public type?: UtilisateurType,
    public telephone?: string,
    public email?: string,
    public identifiant?: string,
    public nationalite?: string,
    public etat?: EtatUserType,
    public isDelete?: string,
    public dateEnregistrement?: Moment,
    public dateModification?: Moment,
    public boutique?: IBoutique
  ) {
  }
}

export class UserUpdateDTO implements IUserUpdateDTO {
  constructor(public id?: number,
              public login?: string,
              public password?: string,
              public newPassword?: string,
              public nom?: string,
              public prenom?: string,
              public dateNaissance?: Moment,
              public adresse?: string,
              public sexe?: SexeType,
              public type?: UtilisateurType,
              public telephone?: string,
              public email?: string,
              public nationalite?: string,
              public etat?: EtatUserType,
              public actions?: IAction[],
              public isDelete?: string,
              public dateEnregistrement?: Moment,
              public dateModification?: Moment
  ) {
  }

}
