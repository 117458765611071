import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {NotificationCs, ToasterPosition} from '../../shared/constant/model/notification.constant';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

/**
 * permet de gérer les notifications de messages.
 */
@Injectable({providedIn: 'root'})
export class NotificationService {
  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService) {
  }

  /**
   * Permet de changer le title du navigateur.
   * @param title
   * @param messageKey
   * @param subs$
   */
  setTitle(title: Title, messageKey: string, subs$: Subject<void>) {
    this.translateService.get([messageKey]).pipe(takeUntil(subs$))
      .subscribe(data => {
        title.setTitle(data[messageKey]);
      });
  }

  /**
   * Affiche un message de succès.
   * @param message le message à afficher
   * @param title le titre du message.
   * @param position la position de la notification.
   */
  showError(message: string, title: string, position?: ToasterPosition) {
    const pos = position ? position : NotificationCs.DEFAULT_POSITION;
    this.toastr.error(title, message, {positionClass: pos});
  }

  /**
   * Affiche un message de succès.
   * @param message le message à afficher
   * @param title le titre du message.
   * @param position la position de la notification.
   */
  showSuccess(message: string, title: string, position?: ToasterPosition) {
    const pos = position ? position : NotificationCs.DEFAULT_POSITION;
    this.toastr.success(title, message, {positionClass: pos});
  }

  /**
   * Affiche une notification d'ajout de succès.
   * @param subs$ va permettre de libérer les ressources.
   * @param position la position du message.
   */
  public notifyError(titleKey: string,
                     messageKey: string, position?: ToasterPosition) {
    this.showError(titleKey, messageKey, position);
  }

  /**
   * Affiche une notification d'ajout de succès.
   * @param subs$ va permettre de libérer les ressources.
   * @param position la position du message.
   */
  public notifyDeleteSuccessful(subs$: Subject<void>, position?: ToasterPosition) {
    const titleKey = 'message.success.title';
    const messageKey = 'message.success.supprimer';
    this.translateService.get([titleKey, messageKey]).pipe(takeUntil(subs$))
      .subscribe(data => {
        this.showSuccess(data[titleKey], data[messageKey], position);
      });
  }

  /**
   * Affiche une notification d'ajout de succès.
   * @param subs$ va permettre de libérer les ressources.
   * @param position la position du message.
   */
  public notifyCreateSuccessful(subs$: Subject<void>, position?: ToasterPosition) {
    const titleKey = 'message.success.title';
    const messageKey = 'message.success.ajouter';
    this.translateService.get([titleKey, messageKey]).pipe(takeUntil(subs$))
      .subscribe(data => {
        this.showSuccess(data[titleKey], data[messageKey], position);
      });
  }

  /**
   * Affiche une notification de modification de succès.
   * @param subs$ va permettre de libérer les ressources.
   * @param position la position du message.
   */
  public notifyUpdateSuccessful(subs$: Subject<void>, position?: ToasterPosition) {
    const titleKey = 'message.success.title';
    const messageKey = 'message.success.modifier';
    this.translateService.get([titleKey, messageKey]).pipe(takeUntil(subs$))
      .subscribe(data => {
        this.showSuccess(data[titleKey], data[messageKey], position);
      });
  }

  /**
   * Affiche un message d'erreur.
   * @param message le message à afficher
   * @param title le titre du message.
   * @param position la position de la notification.
   */
  showInfos(message: string, title: string, position?: ToasterPosition) {
    const pos = position ? position : NotificationCs.DEFAULT_POSITION;
    this.toastr.info(title, message, {positionClass: pos});
  }
}
