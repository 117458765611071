import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserAccountService} from '../user-account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {FormService} from '../../../shared/ services/form.service';
import {UpdateProfileInit, UpdateProfileNm} from '../user-account-update/user-account-update.init';
import {FormBuilder} from '@angular/forms';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {AccountUser} from '../../../shared/models/account.model';
import {Observable, Subject} from 'rxjs';
import {SexeType} from '../../../shared/models/utilisateur.model';
import {UserAccountDeleteComponent} from '../user-account-delete/user-account-delete.component';
import {takeUntil} from 'rxjs/operators';
import {TIME_WAIT_MAX_ATTEMPT} from '../../../shared/constant/model/server.constant';
import {NRequest} from '../../../shared/ services/request/request.service';
import {RsaService} from '../../../shared/ services/rsa.service';

/**
 * Permet de modifier le profile un compte utilisateur.
 */
@Component({
  selector: 'app-user-update-email',
  templateUrl: './user-update-email.component.html'
})
export class UserUpdateEmailComponent implements OnInit, OnDestroy {
  constructor(
    private rsaService: RsaService,
    private userAccountService: UserAccountService,
    private activeModal: NgbActiveModal,
    private accountService: AccountService,
    private popupReusableService: PopupReusableService,
    private formService: FormService,
    private signInInit: UpdateProfileInit,
    private formSignIn: FormBuilder,
    private errorServ: ConstantErrorServ,
    private http: HttpClient
  ) {
    this.signInColName = this.signInInit.getAuthColumnName();
  }

  // VAR.
  editForm = this.formSignIn.group(this.signInInit.getFormEmail());
  hasEmailError = false;
  validationHasSucceed = false;
  signInColName: UpdateProfileNm.ColumnName;
  accountUser: AccountUser;
  open = true;
  subs$ = new Subject<void>();
  shouldIShow = false;

  ngOnInit(): void {
    console.log('user update email');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
      this.editForm.patchValue({
        [this.signInColName.email]: this.accountUser.email
      });
    });
  }

  ngOnDestroy(): void {
  }

  update() {
    console.log('update');
    this.hasEmailError = false;
    this.validationHasSucceed = false;
    const user = this.signInInit.getAuthFromFormEmail(this.editForm);
    user.oldPassword = this.rsaService.crypt(user.oldPassword);
    const result = this.userAccountService.updateEmail(user);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<any>>
  ) {
    result.subscribe(
      (res: HttpResponse<any>) => {
        console.log('**** success update user email: ');
        // this.reset();
        this.editForm.get('oldPassword').reset();
        this.validationHasSucceed = true;
        this.hasEmailError = false;
        this.accountService.forceIdentity();
      },
      (err: HttpErrorResponse) => {
        console.log('**** error update user email: ');
        this.validationHasSucceed = false;
        this.hasEmailError = true;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
      }
    );
  }

  delete() {
    console.log('delete');
    this.popupReusableService.open(null, UserAccountDeleteComponent);
  }

  getPassword() {
    return this.formService.getData(this.signInColName.oldPassword,
      this.editForm, [], false);
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
        this.editForm.patchValue({
          login: accountUser.login,
          nom: accountUser.nom,
          prenom: accountUser.prenom,
          adresse: accountUser.adresse,
          nationalite: accountUser.nationalite,
          telephone: accountUser.telephone,
          dateNaissance: accountUser.dateNaissance,
          sexe: accountUser.sexe
        });
      });
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   */
  isEmailInvalid(field: string): boolean {
    return this.formService.isEmailInvalid(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorPattern(field: string): boolean {
    return this.formService.hasErrorPattern(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }
}
