import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {RGlobal} from '../../constant/global.url';
import {PrivilegeEmployeService} from '../../../modules/employe/privilege-employe.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Boutique} from '../../models/boutique.model';
import {PrivilegeService} from '../privilege/privilege.service';
import {PrivilegeCatArtService} from '../../../modules/categorie-article/privilege-cat-art.service';
import {PrivilegeArtInfosService} from '../../../modules/article-infos/privilege-art-infos.service';
import {PrivilegeArticleService} from '../../../modules/article/privilege-article.service';
import {PrivilegeSortieService} from '../../../modules/sortie/privilege-sortie.service';

/**
 * Permet d'autoriser ou de restreindre l'accès aux routes du module utilisateur en fonction
 * des privilèges d'un utilisateur.
 */
@Injectable({providedIn: 'root'})
export class GuardPrivilegeImplService {
  constructor(
    private privilegeSortieService: PrivilegeSortieService,
    private privilegeUserService: PrivilegeEmployeService,
    private privilegeArticleService: PrivilegeArticleService,
    private privilegeCatArtService: PrivilegeCatArtService,
    private privilegeArtInfosService: PrivilegeArtInfosService,
    private privilegeService: PrivilegeService,
    private router: Router) {
  }

  private redirectToRestrictedAccess(result: any): boolean {
    console.log('ici: jamais');
    if (!result) {
      this.router.navigate([RGlobal.url.PAGE_RESTRICTED]);
      return false;
    }
    return true;
  }

  /**
   * Autorise ou restreint l'accès au route du module utilisateur en fonction
   * privilèges de l'utilisateur.
   * @param route la route.
   * @param state l'état.
   */
  canActivateSortie(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
                    boutiqueSelected: Boutique): Observable<boolean> {
    console.log('** guard privilege sortie impl');
    this.privilegeService.setICheckingPriv(this.privilegeSortieService);
    return this.privilegeService.hasPrivilege(route, boutiqueSelected).pipe(map(result => {
      return this.redirectToRestrictedAccess(result);
    }));
  }

  /**
   * Autorise ou restreint l'accès au route du module utilisateur en fonction
   * privilèges de l'utilisateur.
   * @param route la route.
   * @param state l'état.
   */
  canActivateArticle(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
                     boutiqueSelected: Boutique): Observable<boolean> {
    console.log('** guard privilege art impl');
    this.privilegeService.setICheckingPriv(this.privilegeArticleService);
    return this.privilegeService.hasPrivilege(route, boutiqueSelected).pipe(map(result => {
      return this.redirectToRestrictedAccess(result);
    }));
  }

  /**
   * Autorise ou restreint l'accès au route du module utilisateur en fonction
   * privilèges de l'utilisateur.
   * @param route la route.
   * @param state l'état.
   */
  canActivateArtInfos(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
                      boutiqueSelected: Boutique): Observable<boolean> {
    console.log('** guard privilege art infos impl');
    this.privilegeService.setICheckingPriv(this.privilegeArtInfosService);
    return this.privilegeService.hasPrivilege(route, boutiqueSelected).pipe(map(result => {
      return this.redirectToRestrictedAccess(result);
    }));
  }

  /**
   * Autorise ou restreint l'accès au route du module utilisateur en fonction
   * privilèges de l'utilisateur.
   * @param route la route.
   * @param state l'état.
   */
  canActivateCatAart(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
                     boutiqueSelected: Boutique): Observable<boolean> {
    console.log('** guard privilege cat art impl');
    this.privilegeService.setICheckingPriv(this.privilegeCatArtService);
    return this.privilegeService.hasPrivilege(route, boutiqueSelected).pipe(map(result => {
      return this.redirectToRestrictedAccess(result);
    }));
  }

  /**
   * Autorise ou restreint l'accès au route du module utilisateur en fonction
   * privilèges de l'utilisateur.
   * @param route la route.
   * @param state l'état.
   */
  canActivateUser(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
                  boutiqueSelected: Boutique): Observable<boolean> {
    console.log('** guard privilege user impl');
    this.privilegeService.setICheckingPriv(this.privilegeUserService);
    return this.privilegeService.hasPrivilege(route, boutiqueSelected).pipe(map(result => {
      return this.redirectToRestrictedAccess(result);
    }));
  }

}
