import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ICategorieArticle} from '../../shared/models/categorie-article.model';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {IRestoreWhenDisconnectService} from '../../shared/ services/restore/irestore-when-disconnect.service';

@Injectable({providedIn: 'root'})
export class DataShareWhenCheckedCatArt implements IRestoreWhenDisconnectService {
  constructor(
    private restore: RestoreWhenDisconnectService) {
    this.restore.add(this);
  }

  // VAR.
  private catArtCheckedObs = new BehaviorSubject<ICategorieArticle>(undefined);
  private isCatArtCheckedObs = new BehaviorSubject<boolean>(false);


  /**
   * @return l'utilisateur coché.
   */
  public getCatArtcheckedObs(): Observable<ICategorieArticle> {
    return this.catArtCheckedObs.asObservable();
  }

  /**
   * Permet de modifier la valeur de l'utilisateur
   * coché.
   * @param user le nouvel utilisateur.
   */
  public setCatArtcheckedObs(user: ICategorieArticle) {
    this.catArtCheckedObs.next(user);
  }

  /**
   * @return Observable<boolean> true indique l'utilisateur
   * est coché, false sinon.
   */
  public getIsCatArtCheckedObs(): Observable<boolean> {
    return this.isCatArtCheckedObs.asObservable();
  }

  /**
   * @param checked true indique l'utilisateur
   * est coché, false sinon.
   */
  public setIsCatArtChecked(checked: boolean) {
    this.isCatArtCheckedObs.next(checked);
  }

  /**
   * Utilisé à chaque initialisation du component user.
   */
  clearCatArtChecked() {
    this.setCatArtcheckedObs(undefined);
    this.setIsCatArtChecked(false);
  }

  clear() {
    this.clearCatArtChecked();
  }
}
