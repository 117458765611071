import {AbsVerification} from './abs-verification';
import {ConstantUserColumnName} from '../../../../shared/constant/model/column-name/user-column-name.constant';

/**
 * Permet de vérifier si le prénom fait partir des filtres de recherche
 * et de l'ajouter dans les paramètres dans ce cas.
 */
export class PrenomVerification extends AbsVerification {
  constructor() {
    super();
  }

  private prenom: string;
  private userColName: ConstantUserColumnName;

  prenomSet(prenom: string): PrenomVerification {
    this.prenom = prenom;
    return this;
  }

  userColNameSet(userColName: ConstantUserColumnName): PrenomVerification {
    this.userColName = userColName;
    return this;
  }


  /**
   * Vérifie si le prénom fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  check(first: AbsVerification) {
    if (this.prenom) {
      first.getDataSearch()[this.userColName.PRENOM] = this.prenom;
    }
    super.checkNext(first);
  }
}
