import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AbonnementNm, AbonnementValidateInit} from './abonnement-validate.init';
import {AbonnementService} from '../abonnement.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {RGlobal} from '../../../shared/constant/global.url';
import {NotificationService} from '../../../reusable/services/notification.service';
import {ConstantAbonnementNombreBoutiquePay} from '../../../shared/constant/abonnement/abonnement-nombre-boutique-pay.constant';
import {ConstantAbonnementFacturePay} from '../../../shared/constant/abonnement/abonnement-facture-pay.constant';
import {Uri} from '../../../shared/constant/model/global.constant';
import {ConstantAbonnementProduitPay} from '../../../shared/constant/abonnement/abonnement-produit-pay.constant';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {ConstantAbonnementBilanPay} from '../../../shared/constant/abonnement/abonnement-bilan-pay.constant';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-abonnement-validate',
  templateUrl: './abonnement-validate.component.html'
})
export class AbonnementValidateComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private freeMemoryService: FreeMemoryService,
    private abonnementBilanPay: ConstantAbonnementBilanPay,
    private abonnementFacturePay: ConstantAbonnementFacturePay,
    private abonnementNombreBoutiquePay: ConstantAbonnementNombreBoutiquePay,
    private abonnementProduitPay: ConstantAbonnementProduitPay,
    private notificationService: NotificationService,
    private requestService: RequestService,
    private abonnementService: AbonnementService,
    private abonnementValidateInit: AbonnementValidateInit,
    private activatedRoute: ActivatedRoute) {
    this.abonnementInit = this.abonnementValidateInit.getAuthColumnName();
  }

  paymentId: string;
  payerId: string;
  token: string;
  code: string;
  abonnementInit: AbonnementNm.ColumnName;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init abonnement');
    this.activatedRoute.queryParams.pipe(takeUntil(
      this.subs$
    )).subscribe(param => {
      if (param) {
        console.log('json: ' + JSON.stringify(param));
        this.getRequired(param);
        if (param[this.abonnementFacturePay.CODE]) {
          this.validateAbonnementFacture(param);
        } else if (param[this.abonnementBilanPay.CODE]) {
          this.validateAbonnementBilan(param);
        } else if (param[this.abonnementNombreBoutiquePay.CODE]) {
          this.validateAbonnementNombreBoutique(param);
        } else if (param[this.abonnementProduitPay.CODE]) {
          this.validateAbonnementProduit(param);
        } else {
          this.notificationService.notifyError('Erreur abonnement',
            'Une erreur est survenue');
          this.router.navigate([RGlobal.url.ACCUEIL]);
        }
      } else {
        this.notificationService.notifyError('Erreur abonnement',
          'Une erreur est survenue');
        this.router.navigate([RGlobal.url.ACCUEIL]);
      }
    });
  }

  getRequired(param) {
    if (param) {
      this.paymentId = param[this.abonnementInit.paymentId] ? param[this.abonnementInit.paymentId] : this.paymentId;
      this.payerId = param[this.abonnementInit.payerId] ? param[this.abonnementInit.payerId] : this.payerId;
      this.token = param[this.abonnementInit.token] ? param[this.abonnementInit.token] : this.token;
      this.code = param[this.abonnementInit.code] ? param[this.abonnementInit.code] : this.code;
    }
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  /*  checkParamAbonnementNombreBoutique(param): boolean {
      if (param) {
        if (param[this.abonnementInit.code] && param[this.abonnementInit.paymentId]
          && param[this.abonnementInit.payerId] && param[this.abonnementInit.token]) {
          return true;
        }
      }
      return false;
    }*/

  validateAbonnementProduit(param) {
    const data = {
      [Uri.BOUTIQUE]: param[Uri.BOUTIQUE],
      [this.abonnementInit.code]: this.code,
      [this.abonnementInit.paymentId]: this.paymentId,
      [this.abonnementInit.payerId]: this.payerId,
      [this.abonnementInit.token]: this.token
    };
    console.log('data json: ' + JSON.stringify(data));
    const result = this.abonnementService.validateAbonnementProduit(data);
    this.subscribeToSaveResponseProduit(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseProduit(
    result: Observable<HttpResponse<string>>
  ) {
    result.subscribe(
      (res: HttpResponse<string>) => {
        const data = res.body;
        if (data === 'success') {
          console.log('**** success create abonnement produit: ');
          console.log('data: ' + data);
          // this.validationHasSucceed = true;
          this.router.navigate([RGlobal.url.ACCUEIL]);
          this.requestService.successWithoutPath(NRequest.ERequest.CREATE,
            this.subs$);
        } else {
          this.notificationService.notifyError('Erreur abonnement',
            'Une erreur est survenue');
          this.router.navigate([RGlobal.url.ACCUEIL]);
        }

      },
      (err: HttpErrorResponse) => {
        console.log('**** error create produit: ');
        console.log('**** error: ' + err.message);
        // this.validationHasSucceed = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
      }
    );
  }

  validateAbonnementBilan(param) {
    const data = {
      [Uri.BOUTIQUE]: param[Uri.BOUTIQUE],
      [this.abonnementInit.code]: this.code,
      [this.abonnementInit.paymentId]: this.paymentId,
      [this.abonnementInit.payerId]: this.payerId,
      [this.abonnementInit.token]: this.token
    };
    console.log('data json: ' + JSON.stringify(data));
    const result = this.abonnementService.validateAbonnementBilan(data);
    this.subscribeToSaveResponseBilan(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseBilan(
    result: Observable<HttpResponse<string>>
  ) {
    result.subscribe(
      (res: HttpResponse<string>) => {
        const data = res.body;
        if (data === 'success') {
          console.log('**** success create abonnement bilan: ');
          console.log('data: ' + data);
          // this.validationHasSucceed = true;
          this.router.navigate([RGlobal.url.ACCUEIL]);
          this.requestService.successWithoutPath(NRequest.ERequest.CREATE,
            this.subs$);
        } else {
          this.notificationService.notifyError('Erreur abonnement',
            'Une erreur est survenue');
          this.router.navigate([RGlobal.url.ACCUEIL]);
        }

      },
      (err: HttpErrorResponse) => {
        console.log('**** error create bilan: ');
        // this.validationHasSucceed = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
      }
    );
  }

  validateAbonnementFacture(param) {
    const data = {
      [Uri.BOUTIQUE]: param[Uri.BOUTIQUE],
      [this.abonnementInit.code]: this.code,
      [this.abonnementInit.paymentId]: this.paymentId,
      [this.abonnementInit.payerId]: this.payerId,
      [this.abonnementInit.token]: this.token
    };
    console.log('data json: ' + JSON.stringify(data));
    const result = this.abonnementService.validateAbonnementFacture(data);
    this.subscribeToSaveResponseFacture(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseFacture(
    result: Observable<HttpResponse<string>>
  ) {
    result.subscribe(
      (res: HttpResponse<string>) => {
        const data = res.body;
        if (data === 'success') {
          console.log('**** success create abonnement facture: ');
          console.log('data: ' + data);
          // this.validationHasSucceed = true;
          this.router.navigate([RGlobal.url.ACCUEIL]);
          this.requestService.successWithoutPath(NRequest.ERequest.CREATE,
            this.subs$);
        } else {
          this.notificationService.notifyError('Erreur abonnement',
            'Une erreur est survenue');
          this.router.navigate([RGlobal.url.ACCUEIL]);
        }

      },
      (err: HttpErrorResponse) => {
        console.log('**** error create facture: ');
        // this.validationHasSucceed = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
      }
    );
  }

  validateAbonnementNombreBoutique(param) {
    const data = {
      [this.abonnementInit.code]: this.code,
      [this.abonnementInit.paymentId]: this.paymentId,
      [this.abonnementInit.payerId]: this.payerId,
      [this.abonnementInit.token]: this.token
    };
    const result = this.abonnementService.validateAbonnementNombreBoutique(data);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<string>>
  ) {
    result.subscribe(
      (res: HttpResponse<string>) => {
        const data = res.body;
        if (data === 'success') {
          console.log('**** success create abonnement boutique: ');
          // this.validationHasSucceed = true;
          this.router.navigate([RGlobal.url.ACCUEIL]);
          this.requestService.successWithoutPath(NRequest.ERequest.CREATE,
            this.subs$);
        } else {
          this.notificationService.notifyError('Erreur abonnement',
            'Une erreur est survenue');
          this.router.navigate([RGlobal.url.ACCUEIL]);
        }

      },
      (err: HttpErrorResponse) => {
        console.log('**** error create boutique: ');
        // this.validationHasSucceed = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
      }
    );
  }

}
