import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DataShareWhenCheckedCatArt} from '../data-share-when-checked-cat-art.service';
import {Observable, Subject} from 'rxjs';
import {CatArtDeleteInit} from './cat-art-delete.init';
import {IDeleteSuccessService} from '../../../shared/ services/delete/idelete-success.service';
import {RGlobal} from '../../../shared/constant/global.url';
import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {takeUntil} from 'rxjs/operators';
import {RestoreCatArtService} from '../restore-cat-art.service';
import {DeleteSuccessService} from '../../../shared/ services/delete/delete-success.service';
import {ICategorieArticle} from '../../../shared/models/categorie-article.model';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {TranslateService} from '@ngx-translate/core';
import {CatArtService} from '../cat-art.service';
import {ConstantCategorieArticleColumnName} from '../../../shared/constant/model/column-name/categorie-column-name.constant';
import {ConstantBoutiqueColumnName} from '../../../shared/constant/model/column-name/boutique-column-name.constant';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';

@Component({
  selector: 'app-cat-art-delete',
  templateUrl: './cat-art-delete.component.html'
})
export class CatArtDeleteComponent implements OnInit, OnDestroy,
  IDeleteSuccessService {
  constructor(
    private boutiqueService: BoutiqueService,
    private boutiqueColumnName: ConstantBoutiqueColumnName,
    private categorieArticleColumnName: ConstantCategorieArticleColumnName,
    private catArtService: CatArtService,
    private deleteSuccessService: DeleteSuccessService,
    private restoreUserService: RestoreCatArtService,
    private translateService: TranslateService,
    private popupReusableService: PopupReusableService,
    private http: HttpClient,
    private dataShareWhenCheckedUser: DataShareWhenCheckedCatArt,
    private activeModal: NgbActiveModal,
    private userDeleteInit: CatArtDeleteInit) {
  }

  // VAR.
  boutiqueSelected: Boutique;
  open = true;
  subs$ = new Subject<void>();
  activatedValidationButton = true;
  loadWhenDeleting = false;

  ngOnInit() {
    console.log('** init cat art delete');
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
    this.dataShareWhenCheckedUser.getCatArtcheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: ICategorieArticle) => {
        if (data) {
          this.restoreUserService.setCatArtChecked(data);
        }
      });
  }

  ngOnDestroy(): void {
    this.restoreUserService.restore(this.subs$);
  }

  isActivated() {
    return this.activatedValidationButton;
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.userDeleteInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  getUser() {
    return this.userDeleteInit.getUserData(this.restoreUserService.getCatArtChecked());
  }

  delete() {
    this.loadWhenDeleting = true;
    console.log('** suppression cat art');
    let option = new HttpParams();
    option = option.set(this.categorieArticleColumnName.CODE, this.getUser().code);
    option = option.set(this.boutiqueColumnName.NAME, this.boutiqueSelected.id.toString());
    const result = this.catArtService.delete(option);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        this.loadWhenDeleting = false;
        const data = res.body;
        if (data) {
          console.log('**** success delete cat art: ' + res);
          this.deleteSuccessService.deleteSuccess(this, this.subs$,
            'cat art supprimé');
        }
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save cat art: ' + err);
        this.activatedValidationButton = true;
        this.loadWhenDeleting = false;
      }
    );
  }

  clearItemChecked() {
    this.dataShareWhenCheckedUser.clearCatArtChecked();
  }

  getActiveModal(): NgbActiveModal {
    return this.activeModal;
  }

  getPath(): string {
    return RGlobal.urlFull.CATEGORIE_ARTICLE.LIST;
  }
}
