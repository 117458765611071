import {Injectable} from '@angular/core';
import {FreeMemoryService} from '../../../../reusable/services/free-memory.service';
import {Router} from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AccountUser} from '../../../models/account.model';
import {SERVER_API_URL} from '../../../constant/model/server.constant';
import {API_ACCOUNT_ADMIN} from '../../../constant/model/api.constant';
import {takeUntil} from 'rxjs/operators';
import {AccountAdmin} from '../../../models/account-admin.model';
import {RGlobalAdmin} from '../../../constant/global.url.admin';

/**
 * Permet de gérer {@link AccountUser} le compte de l'utilisateur connecté.
 */
@Injectable({providedIn: 'root'})
export class AccountAdminService {
  constructor(
    private freeMemoryService: FreeMemoryService,
    private router: Router, private http: HttpClient) {
  }

  // VAR.
  private userAccountObs = new BehaviorSubject<AccountAdmin>(undefined);


  /**
   * Récupère le compte de l'utilisateur connecté depuis le serveur.
   */
  private fetch(): Observable<HttpResponse<AccountAdmin>> {
    return this.http.get<AccountAdmin>(SERVER_API_URL + API_ACCOUNT_ADMIN, {
      observe: 'response'
    });
  }

  getUserIdentityObs(): Observable<AccountAdmin> {
    return this.userAccountObs.asObservable();
  }

  /**
   * Efface les informations de l'utilisateur connecté.
   * Utilisé lors de la déconnexiond de l'utilisateur.
   */
  clearUserInfos() {
    this.userAccountObs = new BehaviorSubject<AccountAdmin>(undefined);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté depuis le serveur.
   */
  identityWithPromise(): Promise<HttpResponse<AccountAdmin>> {
    return this.fetch().toPromise();
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté depuis le serveur.
   * Si le compte n'existe pas, redirige vers la page d'authentification.
   */
  identity(subs$: Subject<void>) {
    console.log('** identity');
    // retrieve the userIdentity data from the server, update the identity object, and then resolve.
    this.userAccountObs.pipe(takeUntil(subs$)).subscribe(userAccount => {
      if (!userAccount) {
        console.log('** je pars chercher account');
        this.fetch().toPromise().then(response => {
          this.fetchSuccess(response);
        }).catch(err => {
          this.fetchFailed(err);
        });
      }
    });
  }

  /**
   * Récupère le compte de l'utilisateur connecté
   *
   * @param response la réponse du serveur en cas de succès.
   */
  private fetchSuccess(response) {
    const account: AccountAdmin = response.body;
    if (account) {
      this.userAccountObs.next(account);
    } else {
      this.userAccountObs.next(null);
      this.router.navigate([RGlobalAdmin.url.LOGIN]);
    }
  }

  /**
   * Redirige vers la page d'authentification si le compte de l'utilisateur
   * connecté n'existe pas.
   * @param err la réponse du serveur en cas de d'échec.
   */
  private fetchFailed(err) {
    this.userAccountObs.next(null);
    this.router.navigate([RGlobalAdmin.url.LOGIN]);
  }
}
