import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {ArtInfosShowInit} from '../art-infos-show/art-infos-show.init';
import {takeUntil} from 'rxjs/operators';
import {IArticleInfos} from '../../../shared/models/article-infos.model';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {RGlobal} from '../../../shared/constant/global.url';
import {TranslateService} from '@ngx-translate/core';
import {IDeleteSuccessService} from '../../../shared/ services/delete/idelete-success.service';
import {RestoreArtInfosService} from '../restore-art-infos.service';
import {DeleteSuccessService} from '../../../shared/ services/delete/delete-success.service';
import {ArticleInfosService} from '../article-infos.service';
import {ConstantArticleInfosColumnName} from '../../../shared/constant/model/column-name/article-infos-column-name.constant';
import {Uri} from '../../../shared/constant/model/global.constant';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {FormService} from '../../../shared/ services/form.service';
import {DataShareWhenCheckedArtInfos} from '../data-share-when-checked-art-infos.service';

@Component({
  selector: 'app-art-infos-delete',
  templateUrl: './art-infos-delete.component.html'
})
export class ArtInfosDeleteComponent implements OnInit, OnDestroy,
  IDeleteSuccessService {
  constructor(
    private boutiqueService: BoutiqueService,
    private articleInfosColumnName: ConstantArticleInfosColumnName,
    private articleInfosService: ArticleInfosService,
    private deleteSuccessService: DeleteSuccessService,
    private restoreArtInfosService: RestoreArtInfosService,
    private translateService: TranslateService,
    private popupReusableService: PopupReusableService,
    private formService: FormService,
    private dataShare: DataShareWhenCheckedArtInfos,
    private activeModal: NgbActiveModal,
    private artInfosShowInit: ArtInfosShowInit) {
  }

  // VAR.
  boutiqueSelected: Boutique;
  open = true;
  subs$ = new Subject<void>();
  activatedValidationButton = true;
  loadWhenDeleting = false;

  ngOnInit() {
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
    this.dataShare.getCatArtcheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: IArticleInfos) => {
        if (data) {
          this.restoreArtInfosService.setArtInfosChecked(data);
        }
      });
  }

  ngOnDestroy(): void {
    this.restoreArtInfosService.restore(this.subs$);
  }

  isActivated() {
    return this.activatedValidationButton;
  }

  formatQt(num: number): string {
    return this.formService.formatUsWithNoFractionDigit(num);
  }

  format(num: number): string {
    return this.formService.formatUs(num);
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.artInfosShowInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  getUser() {
    return this.artInfosShowInit.getUser(this.restoreArtInfosService.getArtInfosChecked());
  }


  delete() {
    this.activatedValidationButton = false;
    this.loadWhenDeleting = true;
    console.log('** suppression art infos');
    let option = new HttpParams();
    option = option.set(this.articleInfosColumnName.CODE, this.getUser().code);
    option = option.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.articleInfosService.delete(option);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        const data = res.body;
        if (data) {
          console.log('**** success delete art infos: ' + res);
          this.deleteSuccessService.deleteSuccess(this, this.subs$,
            'art infos supprimé');
        }
        this.loadWhenDeleting = false;
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save art infos: ' + err);
        this.activatedValidationButton = true;
        this.loadWhenDeleting = false;
      }
    );
  }

  clearItemChecked() {
    this.dataShare.clearCatArtChecked();
  }

  getActiveModal(): NgbActiveModal {
    return this.activeModal;
  }

  getPath(): string {
    return RGlobal.urlFull.ARTICLE_INFOS.LIST;
  }
}
