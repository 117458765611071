import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Article} from '../../../shared/models/article.model';
import {ConstantSortieColumnName} from '../../../shared/constant/model/column-name/sortie-column-name.constant';
import {ConstantSortieValidator} from '../../../shared/constant/validator/sortie-validator.constant';
import {SortieDetails} from '../../../shared/models/sortie-details.model';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Offre plusieurs types utilisés dans la création de catégories d'article.
 */
export namespace SortieCreateForInit {
  /**
   * Permet d'avoir le nom des champs pour une {@link CategorieArticle}.
   */
  export interface ColumnName {
    name: string;
    id: string;
    quantiteSortie: string;
    idArticleInfos: string;
  }
}

/**
 * Permet d'initialiser le component {@link CategorieArticleCreateComponent}
 */
@Injectable({providedIn: 'root'})
export class SortieCreateInit {
  constructor(
    private formService: FormService,
    private categorieColName: ConstantSortieColumnName,
    private categorieValidator: ConstantSortieValidator
  ) {
  }

  /**
   * récupère les données de la catégorie du formulaire.
   */
  public getCategorieFromForm(editForm: FormGroup): Article {
    const entity = {
      ...new SortieDetails(),
      quantiteSortie: +this.formService.getData(this.categorieColName.QUANTITE_SORTIE,
        editForm, []),
      articleInfos: {
        id: this.formService.getDataObj(this.categorieColName.ID_ARTICLE_INFOS, editForm).id,
        quantiteDisponible: this.formService.getDataObj(this.categorieColName.ID_ARTICLE_INFOS, editForm).quantiteDisponible
      }
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour une {@link CategorieArticle}
   */
  getCategorieArticleColumnName(): SortieCreateForInit.ColumnName {
    return {
      name: this.categorieColName.NAME,
      id: this.categorieColName.ID,
      quantiteSortie: this.categorieColName.QUANTITE_SORTIE,
      idArticleInfos: this.categorieColName.ID_ARTICLE_INFOS
    };
  }

  /**
   * @returns Formulaire de création d'une {@link CategorieArticle}.
   */
  getForm(): any {
    return {
      quantite_sortie: this.categorieValidator.QUANTITE_SORTIE,
      id_article_infos: this.categorieValidator.ID_ARTICLE_INFOS
    };
  }
}
