import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {ArtInfosShowInit} from './art-infos-show.init';
import {takeUntil} from 'rxjs/operators';
import {IArticleInfos} from '../../../shared/models/article-infos.model';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {RestoreArtInfosService} from '../restore-art-infos.service';
import {FormService} from '../../../shared/ services/form.service';
import {DataShareWhenCheckedArtInfos} from '../data-share-when-checked-art-infos.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';

@Component({
  selector: 'app-art-infos-show',
  templateUrl: './art-infos-show.component.html'
})
export class ArtInfosShowComponent implements OnInit, OnDestroy {
  constructor(
    private boutiqueService: BoutiqueService,
    private restoreArtInfosService: RestoreArtInfosService,
    private popupReusableService: PopupReusableService,
    private formService: FormService,
    private dataShare: DataShareWhenCheckedArtInfos,
    private activeModal: NgbActiveModal,
    private userInit: ArtInfosShowInit
  ) {
  }

  // VAR.
  open = true;
  subs$ = new Subject<void>();
  boutiqueSelected: Boutique;

  ngOnInit() {
    console.log('*** art-infos-show onInit');
    this.dataShare.getCatArtcheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: IArticleInfos) => {
        if (data) {
          this.restoreArtInfosService.setArtInfosChecked(data);
        }
      });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
  }

  ngOnDestroy() {
    console.log('*** art-infos-show onDestroy');
    this.restoreArtInfosService.restore(this.subs$);
  }

  formatQt(num: number): string {
    return this.formService.formatUsWithNoFractionDigit(num);
  }

  format(num: number): string {
    return this.formService.formatUs(num);
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.userInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer les données de l'utilisateur recherché.
   */
  user() {
    return this.userInit.getUser(this.getCatArt());
  }

  /**
   * Permet de récupérer l'utilisateur recherché.
   */
  getUser() {
    console.log('*** getuser is call!');
    // return this.userInit.getUser(this.catArtShowService.getUser());
    return this.getCatArt();
  }

  getCatArt(): IArticleInfos {
    return this.restoreArtInfosService.getArtInfosChecked();
  }
}
