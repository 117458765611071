import {Injectable} from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour une {@link CategorieArticle}
 */
@Injectable({providedIn: 'root'})
export class ConstantAbonnementColumnName {
  readonly NAME = 'abonnement';
  readonly CODE = 'code';
  readonly PAYMENT_ID = 'paymentId';
  readonly PAYER_ID = 'PayerID';
  readonly TOKEN = 'token';
}
