import {Component, OnDestroy, OnInit} from '@angular/core';
import {CheckingBoutique} from '../../employe/checking-boutique.service';
import {Router} from '@angular/router';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {BoutiqueService} from '../../boutique/boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {AccountUser} from '../../../shared/models/account.model';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Uri} from '../../../shared/constant/model/global.constant';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {SortieService} from '../sortie.service';
import {ProduitVendu} from '../../../shared/models/produit-vendu.model';
import {DateFormat} from '../../../shared/constant/model/date.constant';
import {GestionType, PrivilegeType} from '../../../shared/models/action.model';
import {GettingPrivilegeService} from '../../../shared/ services/privilege/getting/getting-privilege.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-meilleur-produit-vendu',
  templateUrl: './meilleur-produit-vendu.component.html'
})
export class MeilleurProduitVenduComponent implements OnInit, OnDestroy {
  constructor(
    private gettingPrivilegeService: GettingPrivilegeService,
    private dateFormat: DateFormat,
    private sortieService: SortieService,
    private checkingBoutique: CheckingBoutique,
    private router: Router,
    private freeMemoryService: FreeMemoryService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
    this.dateDebut = this.dateFormat.getLastDate();
    this.dateFin = this.dateFormat.getNewDate();
  }


  iCanDownload = false;
  iCanPrint = false;
  dateDebut = '';
  dateFin = '';
  produitVendus: ProduitVendu[];
  boutiqueSelected: Boutique;
  open = true;
  shouldIShow = false;
  accountUser: AccountUser;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('meilleur produit');
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getUserIdentityObs(this.accountService, res);
      this.getProduits();
    });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }


  getProduits() {
    if (this.checkingBoutique.isSelected()) {
      console.log('get offer');
      const param = {
        dateFin: this.dateFin,
        [Uri.BOUTIQUE]: this.boutiqueSelected.id.toString()
      };
      const result = this.sortieService.getProduitVendu(param);
      this.subscribeToSaveResponse(result);
    }
  }

  multi(num: number) {
    let r = num * 100;
    r = +((Math.round(r * 100) / 100).toFixed(4));
    return r;
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */

  subscribeToSaveResponse(
    result: Observable<HttpResponse<any>>,
  ) {
    result.subscribe(
      (res: HttpResponse<any>) => {
        console.log('**** success produit vendu: ');
        this.shouldIShow = true;
        console.log('res: ' + res.body);
        console.log('res: ' + JSON.stringify(res.body));
        this.produitVendus = res.body;
        console.log('res: ' + res.body);
      },
      (err: HttpErrorResponse) => {
        this.shouldIShow = true;
        console.log('**** error produit vendu: ');
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  getTel3() {
    return this.boutiqueSelected.telephone3;
  }

  getTel2() {
    return this.boutiqueSelected.telephone2;
  }

  getTel1() {
    return this.boutiqueSelected.telephone1;
  }

  getEntete(doc) {
    const array = [];
    if (doc) {
      doc.text('Liste des articles \n' +
        'les plus vendus', 400, 20);
      doc.text('' + this.boutiqueSelected.nom, 40, 20);
      array.push('Adresse: ' + this.boutiqueSelected.adresse);
      array.push('Ville: ' + this.boutiqueSelected.ville);
      array.push('Pays: ' + this.boutiqueSelected.pays);
      if (this.getTel1() || this.getTel2() || this.getTel3()) {
        array.push('Contact(s): ');
        if (this.getTel1()) {
          array.push(this.getTel1());
        }
        if (this.getTel2()) {
          array.push(this.getTel2());
        }
        if (this.getTel3()) {
          array.push(this.getTel3());
        }
      }
      array.push('Identifiant: ' + this.boutiqueSelected.identifiant);
    }
    return array;
  }

  print() {
    console.log('printing..');
    const doc = new jsPDF('p', 'pt', 'a4');
    const array = this.getEntete(doc);
    doc.text(array, 40, 60);
    doc.autoTable({html: '#produit', startY: 200});
    doc.autoPrint();
    doc.save('produit.pdf');
  }

  download() {
    console.log('saving..');
    const doc = new jsPDF('p', 'pt', 'a4');
    const array = this.getEntete(doc);
    doc.text(array, 40, 60);
    doc.autoTable({html: '#produit', startY: 200});
    doc.save('produit.pdf');
  }


  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService,
                     boutiqueSelected: Boutique) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
        console.log('account: ' + accountUser.login);
        this.gettingPrivilegeService.canIDoThis(accountUser, GestionType.PRODUIT_PLUS_ACHETE,
          PrivilegeType.TELECHARGER, boutiqueSelected).pipe(takeUntil(
          this.subs$
        )).subscribe((res: boolean) => {
          console.log('res: ' + res);
          this.iCanDownload = res;
        });
        this.gettingPrivilegeService.canIDoThis(accountUser, GestionType.PRODUIT_PLUS_ACHETE,
          PrivilegeType.IMPRIMER, boutiqueSelected).pipe(takeUntil(
          this.subs$
        )).subscribe((res: boolean) => {
          this.iCanPrint = res;
        });
      });
  }
}
