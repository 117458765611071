import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';

/**
 * Permet la validation des champs pour un {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class ConstantValidator {
  constructor() {
  }

  readonly CODE = [
    null,
    [
      Validators.required
    ]
  ];
}
