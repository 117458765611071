import {NgModule} from '@angular/core';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {ArticleListeComponent} from './article-liste/article-liste.component';
import {ArticleShowComponent} from './article-show/article-show.component';
import {ArticleDeleteComponent} from './article-delete/article-delete.component';
import {ArticleUpdateComponent} from './article-update/article-update.component';
import {ArticleCreateComponent} from './article-create/article-create.component';
import {ArticleSearchComponent} from './article-search/article-search.component';

/**
 * module pour la gestion des catégories d'article.
 */
@NgModule({
  declarations: [
    ArticleSearchComponent,
    ArticleCreateComponent,
    ArticleUpdateComponent,
    ArticleDeleteComponent,
    ArticleListeComponent,
    ArticleShowComponent],
  imports: [GestionStockSharedModule],
  entryComponents: [
    ArticleCreateComponent,
    ArticleUpdateComponent,
    ArticleDeleteComponent,
    ArticleShowComponent]
})
export class ArticleModule {
}
