import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'negativeNumber'})
export class NegativeNumber implements PipeTransform {
  transform(value: any): any {
    if (value === '' || value === null || value === undefined ||
      value <= 0) {
      return 0;
    }
    return value;
  }
}
