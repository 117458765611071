import {Injectable} from '@angular/core';
import {EtatUserType, SexeType, UtilisateurType} from '../models/utilisateur.model';
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {Uri} from '../constant/model/global.constant';
import {Location} from '@angular/common';
import {RGlobal} from '../constant/global.url';
import {DateFormat} from '../constant/model/date.constant';
import {RGlobalAdmin} from '../constant/global.url.admin';

/**
 * Fournit plusieurs services.
 */
@Injectable({providedIn: 'root'})
export class ManageService {

  constructor(
    private dateFormat: DateFormat,
    private location: Location,
    private router: Router) {
  }

  /**
   * initialise la donnée à rechercher. tous les champs
   * contiennent la même donnée à rechercher.
   * @param data contient la donnée à rechercher.
   */
  initData(data) {
    if (data) {
      Object.keys(data).forEach(key => {
        data[key] = undefined;
      });
    }
  }

  /**
   * Vérifie si il n'existe pas de paramètres non autorisés.
   * @param paramsExpected les paramètres attendus.
   * @param params les paramèètres de la recherche.
   */
  checkParams(paramsExpected, params) {
    for (const key of Object.keys(params)) {
      let thereIsUnknownKey = true;
      for (const keyExpected of Object.keys(paramsExpected)) {
        if (keyExpected === key) {
          thereIsUnknownKey = false;
          break;
        }
      }
      if (thereIsUnknownKey) {
        this.router.navigate([RGlobal.url.PAGE_NOT_FOUND]);
        break;
      }
    }
  }

  /**
   * @param from une copie des paramètres.
   */
  getCopyParam(from: Params): Params {
    const to = {};
    Object.keys(from).forEach(key => {
      to[key] = from[key];
    });
    return to;
  }

  getFirstOfUrl(router: Router): string {
    let firstOfUrl = '';
    if (router) {
      firstOfUrl = router.url.split('/')[1];
    }
    return firstOfUrl;
  }

  /**
   * Permet de rédiriger vers une page d'erreur.
   */
  redirectToPageNotFound() {
    this.router.navigate([RGlobal.url.PAGE_NOT_FOUND]);
  }

  /**
   * Permet de rédiriger vers une page d'erreur.
   */
  redirectToPageNotFoundAdmin() {
    this.router.navigate([RGlobalAdmin.url.PAGE_NOT_FOUND_ADMIN]);
  }

  /**
   * @param routeSnapshot la route.
   * @returns string le path complet.
   */
  getFullPathSnap(routeSnapshot: ActivatedRouteSnapshot): string {
    let path: string;
    if (routeSnapshot) {
      const parent = routeSnapshot.routeConfig.path;
      const child = routeSnapshot.firstChild.routeConfig.path;
      path = parent.concat('/').concat(child);
    }
    return path;
  }

  /**
   * @param activatedRoute la route.
   * @returns string le path parent.
   */
  getParentPath(activatedRoute: ActivatedRoute): string {
    let path = '';
    if (activatedRoute) {
      try {
        path = activatedRoute.parent.routeConfig.path;
      } catch (e) {
        console.log('** error: ' + (e as Error).message);
      }
    }
    return path;
  }

  /**
   * @param activatedRoute la route.
   * @returns string le path complet.
   */
  getFullPath(activatedRoute: ActivatedRoute): string {
    let path: string;
    let parent = '';
    let child = '';
    if (activatedRoute) {
      try {
        parent = activatedRoute.parent.routeConfig.path;
        child = activatedRoute.routeConfig.path;
      } catch (e) {
        console.log('** error: ' + (e as Error).message);
      }
      path = parent.concat('/').concat(child);
    }
    return path;
  }

  setQueryParams(path: string, params: Params) {
    const queryParams = params;
    const urlTree = this.router.createUrlTree(
      [path],
      {
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.location.go(urlTree.toString());
  }

  /**
   * Permet d'afficher les paramètres de l'url.
   * @param pageNumber le numéro de la page.
   * @param activatedRoute permet la modification des paramètres
   * de l'url.
   * @param router permet la navigation vers les paramètres de l'url.
   */
  navigateWithQueryParams(pageNumber: number, dateDebut: string,
                          dateFin: string, activatedRoute: ActivatedRoute,
                          router: Router, path?: string, params?: Params) {
    if (!params) {
      console.log('params undefined');
      params = {[Uri.PAGE]: pageNumber};
      params = {[Uri.DATE_DEBUT]: this.dateFormat.getLastDate()};
      params = {[Uri.DATE_FIN]: this.dateFormat.getNewDate()};
      console.log('params undefined: value ', params);
    } else {
      console.log('params exist');
      params[Uri.PAGE] = pageNumber;
      params[Uri.DATE_DEBUT] = dateDebut;
      params[Uri.DATE_FIN] = dateFin;
      console.log('params exist: value ', params);
    }
    path = path ? path : this.getFullPath(activatedRoute);
    router.navigate(
      [path],
      {
        queryParams: params,
      });
  }

  /**
   * Permet d'avoir une valeur par défaut si le sexe n'est pas renseigné.
   * @param sexe la valeur du sexe.
   */
  getSexe(sexe: SexeType): string {
    let unknown = 'Inconnu';
    if (sexe) {
      unknown = sexe === SexeType.HOMME ? 'Homme' : 'Femme';
    }
    return unknown;
  }

  /**
   * Permet d'avoir une valeur par défaut si le type n'est pas renseigné.
   * @param type la valeur du type.
   */
  getType(type: UtilisateurType): string {
    const unknown = 'Inconnu';
    if (type) {
      switch (type) {
        case UtilisateurType.ADMINISTRATEUR:
          return 'Administrateur';
          break;
        case UtilisateurType.ENREGISTREUR:
          return 'Enregistreur';
          break;
        case UtilisateurType.VENDEUR:
          return 'Vendeur';
          break;
      }
    }
    return unknown;
  }

  /**
   * Permet d'avoir une valeur par défaut si l'état n'est pas renseigné.
   * @param etat la valeur de l'état.
   */
  getEtat(etat: EtatUserType): string {
    let unknown = 'Inconnu';
    if (etat) {
      unknown = etat === EtatUserType.ACTIF ? 'Actif' : 'Bloque';
    }
    return unknown;
  }

  /**
   * Permet d'avoir une valeur par défaut si une donnée n'est pas renseignée.
   * NB: éviter d'utiliser sur du Html, préférer utiliser un Pipe.
   * @param data la donnée.
   */
  getData(data: string): string {
    return data ? data : '';
  }
}
