import {Injectable} from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour une {@link CategorieArticle}
 */
@Injectable({providedIn: 'root'})
export class ConstantAbonnementNombreBoutiquePay {
  readonly ONE_BOUTIQUE = 1;
  readonly TWO_BOUTIQUE = 2;
  readonly THREE_BOUTIQUE = 3;

  readonly CODE = 'nombreBoutique';
  readonly PRIX = 10000.00;
  readonly REDUCTION_FIRST = 0;
  readonly REDUCTION_SECOND = 0;

  calculatePrice(nombre: number) {
    if (nombre === this.ONE_BOUTIQUE) {
      return this.PRIX;
    } else if (nombre === this.TWO_BOUTIQUE) {
      const prixReduction = (this.PRIX * nombre * this.REDUCTION_FIRST) / 100;
      return ((this.PRIX * nombre) - prixReduction);
    } else if (nombre === this.THREE_BOUTIQUE) {
      const prixReduction = (this.PRIX * nombre * this.REDUCTION_SECOND) / 100;
      return ((this.PRIX * nombre) - prixReduction);
    }
    return this.PRIX * nombre;
  }
}
