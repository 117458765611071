import {Injectable} from '@angular/core';
import {ICategorieArticle} from '../../../shared/models/categorie-article.model';
import {DateFormatService} from '../../../shared/ services/date-format.service';

/**
 * Permet d'initialiser le component {@link UserDeleteComponent}
 */
@Injectable({providedIn: 'root'})
export class CatArtDeleteInit {
  constructor(private date: DateFormatService) {
  }

  /**
   * @returns le nom des champs pour un {@link Utilisateur}
   */
  getUserData(user: ICategorieArticle) {
    if (user) {
      return {
        id: user.id,
        designation: user.designation,
        code: user.code,
        utilisateur: user.utilisateur,
        dateEnregistrement: user.dateEnregistrement,
        dateModification: user.dateModification
      };
    }
    return;
  }

  /**
   * Permet de convertir une date
   */
  getDate() {
    return this.date;
  }
}
