import {Routes} from '@angular/router';
import {RGlobal} from '../../shared/constant/global.url';
import {SortieListeComponent} from './sortie-liste/sortie-liste.component';
import {GuardCheckingBoutiqueService} from '../../shared/ services/guard/login/guard-checking-boutique.service';
import {GGuardIsLoginService} from '../../shared/ services/guard/is-login/gguard-is-login.service';
import {GGuardPrivilegeSortieService} from '../../shared/ services/guard/gguard-privilege-sortie.service';

//
export const sortieRoute: Routes = [
  {
    path: RGlobal.url.SORTIE.SORTIE_,
    canActivate: [GGuardIsLoginService, GGuardPrivilegeSortieService],
    children: [
      {
        path: RGlobal.url.SORTIE.LIST, component: SortieListeComponent,
        canActivate: [GuardCheckingBoutiqueService]
      },
      {
        path: RGlobal.url.SORTIE.SEARCH, component: SortieListeComponent,
        canActivate: [GuardCheckingBoutiqueService]
      }
    ]
  }
];
