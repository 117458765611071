import {NgModule} from '@angular/core';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {CategorieArticleListComponent} from './categorie-article-list/categorie-article-list.component';
import {CategorieArticleShowComponent} from './categorie-article-show/categorie-article-show.component';
import {CatArtDeleteComponent} from './cat-art-delete/cat-art-delete.component';
import {CategorieArticleUpdateComponent} from './cat-art-update/categorie-article-update.component';
import {CategorieArticleCreateComponent} from './categorie-article-create/categorie-article-create.component';
import {CategorieArticleSearchComponent} from './cat-art-search/categorie-article-search.component';

/**
 * module pour la gestion des catégories d'article.
 */
@NgModule({
  declarations: [
    CategorieArticleSearchComponent,
    CategorieArticleCreateComponent,
    CategorieArticleUpdateComponent,
    CatArtDeleteComponent,
    CategorieArticleShowComponent,
    CategorieArticleListComponent],
  imports: [GestionStockSharedModule],
  entryComponents: [
    CategorieArticleCreateComponent,
    CategorieArticleUpdateComponent,
    CatArtDeleteComponent,
    CategorieArticleShowComponent]
})
export class CategorieArticleModule {
}
