import {ConstantCategorieArticleColumnName} from '../../../../shared/constant/model/column-name/categorie-column-name.constant';
import {AbsCatArtVerification} from './abs-cat-art-verification';

/**
 * Permet de vérifier si l'designation fait partir des filtres de recherche
 * et de l'ajouter dans les paramètres dans ce cas.
 */
export class DesignationVerification extends AbsCatArtVerification {
  constructor() {
    super();
  }

  private designation: string;
  private catArtColName: ConstantCategorieArticleColumnName;

  designationSet(designation: string): DesignationVerification {
    this.designation = designation;
    return this;
  }

  catArtColNameSet(catArtColName: ConstantCategorieArticleColumnName): DesignationVerification {
    this.catArtColName = catArtColName;
    return this;
  }


  /**
   * Vérifie si l'designation fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  check(first: AbsCatArtVerification) {
    if (this.designation) {
      first.getDataSearch()[this.catArtColName.DESIGNATION] = this.designation;
    }
    super.checkNext(first);
  }
}
