/**
 * type de vérification.
 */

import {ConstantCategorieArticleColumnName} from '../../../../shared/constant/model/column-name/categorie-column-name.constant';
import {AbsCatArtVerification} from './abs-cat-art-verification';

export const enum ECatArtVerification {
  DESIGNATION, CODE
}

/**
 * Type de vérification.
 */
export const enum ETypeCatArtVerification {
  SEARCH
}

/**
 * Service factory permettant de créer {@link AbsVerification}
 */
export abstract class AbsFactoryCatArtVerification {

  /**
   * Vérifie si le login fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param login login de l'utilisateur à rechercher.
   * @param catArtColName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkDesignation(designation: string,
                            catArtcolName: ConstantCategorieArticleColumnName): AbsCatArtVerification;

  /**
   * Vérifie si l'code fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param code l'code de l'utilisateur à rechercher.
   * @param catArtcolName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkCode(code: string,
                     catArtcolName: ConstantCategorieArticleColumnName): AbsCatArtVerification;

  /**
   * Vérifie si l'code fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param code l'code de l'utilisateur à rechercher.
   * @param catArtcolName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkCodeSortie(codeSortie: string,
                           catArtcolName: ConstantCategorieArticleColumnName): AbsCatArtVerification;
}
