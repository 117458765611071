import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ConstantAbonnementBilanPay {
  readonly THREE_MONTHS = 3;
  readonly SIX_MONTHS = 6;
  readonly NINE_MONTHS = 9;

  readonly CODE = 'bilan';
  readonly PRIX = 15000.00;
  readonly REDUCTION_FIRST = 0;
  readonly REDUCTION_SECOND = 0;

  calculatePrice(nombre: number) {
    if (nombre === 1) {
      return this.PRIX;
    } else if (nombre === 2) {
      const prixReduction = (this.PRIX * nombre * this.REDUCTION_FIRST) / 100;
      return ((this.PRIX * nombre) - prixReduction);
    } else if (nombre === 3) {
      const prixReduction = (this.PRIX * nombre * this.REDUCTION_SECOND) / 100;
      return ((this.PRIX * nombre) - prixReduction);
    }
    return this.PRIX * nombre;
  }
}
