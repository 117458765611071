import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ArtInfosListeInit} from './art-infos-liste.init';
import {IArticleInfos} from '../../../shared/models/article-infos.model';
import {PaginationService} from '../../../shared/layouts/pagination/service/pagination.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {Uri} from '../../../shared/constant/model/global.constant';
import {takeUntil} from 'rxjs/operators';
import {GestionType} from '../../../shared/models/action.model';
import {AccountUser} from '../../../shared/models/account.model';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {RGlobal} from '../../../shared/constant/global.url';
import {IPaginateService} from '../../../shared/layouts/pagination/service/ipaginate.service';
import {MatTableDataSource} from '@angular/material';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {IrouteCheckService} from '../../../shared/ services/route/checking/iroute-check.service';
import {RouteCheckParam} from '../../../shared/ services/route/checking/route-check-param';
import {DataPageRequest} from '../../../shared/ services/request/data-page-request.service';
import {ActivatedRouteService} from '../../../shared/ services/route/activated-route.service';
import {RouteCheckService} from '../../../shared/ services/route/checking/route-check.service';
import {IGettingPrivilegeService} from '../../../shared/ services/privilege/getting/igetting-privilege.service';
import {IrouteCheckInitService} from '../../../shared/ services/route/checking/iroute-check-init.service';
import {ManageService} from '../../../shared/ services/manage.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {IMatTableService} from '../../../shared/ services/mat-table/imat-table.service';
import {GettingPrivilegeService} from '../../../shared/ services/privilege/getting/getting-privilege.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {ArtInfosDataShare} from '../art-infos-data.share';
import {DataShareWhenCheckedArtInfos} from '../data-share-when-checked-art-infos.service';
import {FormService} from '../../../shared/ services/form.service';
import {MatTableService} from '../../../shared/ services/mat-table/mat-table.service';
import {BoutiqueService} from '../../boutique/boutique.service';
import {ArticleInfosService} from '../article-infos.service';
import {ArtInfosShowComponent} from '../art-infos-show/art-infos-show.component';
import {ArtInfosDeleteComponent} from '../art-infos-delete/art-infos-delete.component';
import {ArtInfosUpdateComponent} from '../art-infos-update/art-infos-update.component';
import {ArtInfosCreateComponent} from '../art-infos-create/art-infos-create.component';
import {PanierCreateComponent} from '../../sortie/panier/panier-create/panier-create.component';
import {ArticleCreateComponent} from '../../article/article-create/article-create.component';
import {TIME_WAIT_REFRESH} from '../../../shared/constant/model/server.constant';

@Component({
  selector: 'app-art-infos-liste',
  templateUrl: './art-infos-liste.component.html'
})
export class ArtInfosListeComponent implements OnInit, OnDestroy,
  IPaginateService, IrouteCheckService, IrouteCheckInitService,
  IGettingPrivilegeService, IMatTableService {
  constructor(
    private articleInfosService: ArticleInfosService,
    private boutiqueService: BoutiqueService,
    private gettingPrivilegeService: GettingPrivilegeService,
    private dataPageRequest: DataPageRequest,
    private activatedRouteService: ActivatedRouteService,
    private freeMemoryService: FreeMemoryService,
    private userListRouteCheckService: RouteCheckService,
    private catArtListInit: ArtInfosListeInit,
    private manageService: ManageService,
    private accountService: AccountService,
    private http: HttpClient,
    private popupReusableService: PopupReusableService,
    private paginationService: PaginationService,
    public artInfosDataShare: ArtInfosDataShare,
    private formService: FormService,
    private matTableService: MatTableService,
    private dataShare: DataShareWhenCheckedArtInfos,
    private title: Title,
    private catArtInit: ArtInfosListeInit,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    console.log('** construct art infos list');
  }

  // VAR.
  boutiqueSelected: Boutique;
  hasFinishedLoading = false;
  subs$ = new Subject<void>();
  iCanAddArt = false;
  iCanAddCatArt = false;
  iCanAddSortie = false;
  iCanSearchCatArt = false;
  iCanModifyCatArt = false;
  iCanDeleteCatArt = false;
  iCanShowCatArt = false;
  catArtList: IArticleInfos[];
  nbCatArt: number;
  totalPages: number;
  accountUser: AccountUser;
  params: Params = {};
  catArtIsChecked = false;


  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   */
  ngOnInit() {
    console.log('** init art infos list');
    this.dataShare.clearCatArtChecked();
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getUserIdentityObs(this.accountService, res);
    });
    this.checkParams(this.activatedRoute, {
        [Uri.PAGE]: undefined,
        [Uri.DATE_DEBUT]: undefined, [Uri.DATE_FIN]: undefined
      },
      this, this.getPath(), this.subs$,
      new RouteCheckParam().setMatTableService(this.matTableService));
    this.verifyIsCatArtIsChecked(this.dataShare, this.subs$);
    this.dataPageRequest.initDataPage(this, this.subs$);
    this.matTableService.init(this);
  }

  /**
   * Permet de réinitialiser les boutons lors du changement de la route.
   */
  ngOnDestroy(): void {
    this.restore();
  }

  getMontantTotal() {
    return this.artInfosDataShare.montantTotal;
  }

  getBeneficeTotal() {
    return this.artInfosDataShare.beneficeTotal;
  }

  getMontantTotalVente() {
    return this.artInfosDataShare.montantTotalVente;
  }

  openPanier() {
    this.popupReusableService.open(null, PanierCreateComponent);
  }

  openPopupArticleCreate() {
    this.popupReusableService.open(null, ArticleCreateComponent);
  }

  getListUsers() {
    this.paginationService.setPageQueryParams(1);
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.catArtInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  getPrevious() {
    return this.matTableService.getPrevious();
  }

  getDataSourceMat() {
    return this.matTableService.getDataSource();
  }

  checked(event, row, i) {
    return this.matTableService.checked(event, row, i);
  }

  clicked(row, i) {
    return this.matTableService.clicked(row, i);
  }

  getSelected() {
    return this.matTableService.getSelected();
  }

  getDisplayColumnsMat() {
    return this.matTableService.getDisplayColumns();
  }

  /**
   * Permet de savoir si l'utilisateur est coché.
   */
  isUserChecked(): boolean {
    return this.isCatArtChecked();
  }


  /**
   * Permet d'indiquer à l'enfant que la liste des données a été affichée,
   * afin d'afficher ensuite la pagination.
   */
  finishedLoading() {
    this.hasFinishedLoading = true;
  }

  verifyIsCatArtIsChecked(dataShare: DataShareWhenCheckedArtInfos,
                          subs$: Subject<void>) {
    dataShare.getIsCatArtCheckedObs()
      .pipe(takeUntil(subs$)).subscribe((v: boolean) => {
      this.catArtIsChecked = v;
    });
  }

  checkParams(activatedRoute: ActivatedRoute, paramsExpected: any,
              iRouteCheck: IrouteCheckInitService, path: string,
              subs$: Subject<void>, r: RouteCheckParam) {
    this.userListRouteCheckService.checkParams(activatedRoute, paramsExpected,
      iRouteCheck, path, this.subs$, r);
  }

  /**
   *
   */
  getTitle(): Title {
    return this.title;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService,
                     boutiqueSelected: Boutique) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
        this.gettingPrivilegeService.getPrivilege(accountUser, this,
          GestionType.ARTICLE_INFOS, boutiqueSelected, this.subs$);
        if (accountUser) {
          const result = this.boutiqueService.isUserOwner(accountUser.boutique,
            boutiqueSelected);
          if (result) {
            this.iCanAddSortie = true;
            this.iCanAddArt = true;
          } else {
            this.gettingPrivilegeService.canIAdd(accountUser, GestionType.SORTIE,
              boutiqueSelected)
              .pipe(takeUntil(this.subs$)).subscribe(res => {
              this.iCanAddSortie = res;
            });
            this.gettingPrivilegeService.canIAdd(accountUser, GestionType.ARTICLE,
              boutiqueSelected)
              .pipe(takeUntil(this.subs$)).subscribe(res => {
              this.iCanAddArt = res;
            });
          }
        }
      });
  }

  /**
   * Libère les ressources.
   */
  restore() {
    this.userListRouteCheckService.restore();
    this.matTableService.clearAll();
    // this.dataShare.clearCatArtChecked();
    this.freeMemoryService.free(this.subs$);
  }

  /**
   * @param catArtList la liste des utilisateurs.
   */
  setUsersList(catArtList: IArticleInfos[]) {
    this.catArtList = catArtList;
  }

  /**
   * @param nbUsers le nombre d'utilisateurs.
   */
  setNbUsers(nbUsers: number) {
    this.nbCatArt = nbUsers;
  }

  /**
   * Permet de savoir si l'utilisateur est coché.
   */
  isCatArtChecked(): boolean {
    return this.catArtIsChecked;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur coché dans la tableau.
   * @param user l'utilisateur coché.
   * @param event permet d'indiquer si la case est cochée ou pas.
   */
  hasChecked(user: any) {
    if (user) {
      this.dataShare.setCatArtcheckedObs(user);
      this.dataShare.setIsCatArtChecked(true);
    } else {
      this.dataShare.clearCatArtChecked();
    }
  }

  canIAddArt(): boolean {
    return this.iCanAddArt;
  }

  /**
   * @returns true l'utilisateur peut ajouter, false sinon.
   */
  canIAddCatArt(): boolean {
    return this.iCanAddCatArt;
  }

  canIAddSortie(): boolean {
    return this.iCanAddSortie;
  }

  canISearchCatArt(): boolean {
    return this.iCanSearchCatArt;
  }

  canIDelete(): boolean {
    return this.iCanDeleteCatArt;
  }

  canIModify(): boolean {
    return this.iCanModifyCatArt;
  }

  canIShow(): boolean {
    return this.iCanShowCatArt;
  }

  getAccount(): AccountUser {
    return this.accountUser;
  }

  /**
   * @returns le nombre total de pages.
   */
  getTotalPagesData(): number {
    return this.getTotalPages();
  }

  /**
   * @returns le nombre d'utilisateurs.
   */
  getNbCatArtData(): number {
    return this.getNbCatArt();
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getCatArtListData(): IArticleInfos[] {
    return this.getCatArtList();
  }

  /**
   * @returns le nombre total de pages.
   */
  getTotalPages(): number {
    return this.totalPages;
  }

  /**
   * @returns le nombre d'utilisateurs.
   */
  getNbCatArt(): number {
    return this.nbCatArt;
  }

  openPopupUserAdd() {
    this.popupReusableService.open(null, ArtInfosCreateComponent);
  }


  openPopupUserShow() {
    this.popupReusableService.open(null, ArtInfosShowComponent);
  }

  openPopupUserDelete() {
    this.popupReusableService.open(null, ArtInfosDeleteComponent);
  }

  openPopupUserUpdate() {
    this.popupReusableService.open(null, ArtInfosUpdateComponent);
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getCatArtList(): IArticleInfos[] {
    return this.catArtList;
  }

  /**
   * Permet de récupérer au serveur la liste des utilisateurs par page.
   */
  findMontantTotalVente() {
    let params = new HttpParams();
    params = params.set(Uri.DATE_DEBUT, this.artInfosDataShare.dateDebut);
    params = params.set(Uri.DATE_FIN, this.artInfosDataShare.dateFin);
    params = params.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.articleInfosService.getMontantVente(params);
    result.subscribe(
      (res: any) => {
        console.log('** succès récupération montant total vente art infos: ', res.body);
        this.artInfosDataShare.montantTotalVente = res.body;
        this.artInfosDataShare.beneficeTotal = this.artInfosDataShare.montantTotalVente - this.artInfosDataShare.montantTotal;
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération montant total vente art infos');
      }
    );
  }

  /**
   * Permet de récupérer au serveur la liste des utilisateurs par page.
   */
  findMontantTotal() {
    let params = new HttpParams();
    params = params.set(Uri.DATE_DEBUT, this.artInfosDataShare.dateDebut);
    params = params.set(Uri.DATE_FIN, this.artInfosDataShare.dateFin);
    params = params.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.articleInfosService.getMontant(params);
    result.subscribe(
      (res: any) => {
        console.log('** succès récupération montant total art infos: ', res.body);
        this.artInfosDataShare.montantTotal = res.body;
        this.findMontantTotalVente();
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération montant total art infos');
      }
    );
  }

  /**
   * Permet de récupérer au serveur la liste des utilisateurs par page.
   */
  getListCatArt(pageNumber: string) {
    let params = new HttpParams();
    this.artInfosDataShare.spinnerTable = true;
    params = params.set(Uri.PAGE, pageNumber);
    params = params.set(Uri.DATE_DEBUT, this.artInfosDataShare.dateDebut);
    params = params.set(Uri.DATE_FIN, this.artInfosDataShare.dateFin);
    params = params.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.articleInfosService.getList(params);
    result.subscribe(
      (res: any) => {
        console.log('** succès récupération article infos par page');
        this.artInfosDataShare.spinnerTable = false;
        setTimeout(() => {
          this.articleInfosService.refreshBtnActive = true;
        }, TIME_WAIT_REFRESH);
        this.dataPageRequest.getDataPage(res.body);
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération article infos par page');
        this.artInfosDataShare.spinnerTable = false;
        setTimeout(() => {
          this.articleInfosService.refreshBtnActive = true;
        }, TIME_WAIT_REFRESH);
        this.manageService.redirectToPageNotFound();
      }
    );
  }

  /**
   * @return les données récupérées du serveur.
   */
  getData() {
    return this.getCatArtList();
  }

  /**
   * Permet de récupérer les données du serveur.
   * @param pageNumber le numéro de la page.
   */
  getDataServ(pageNumber: string) {
    this.artInfosDataShare.montantTotal = 0;
    this.artInfosDataShare.montantTotalVente = 0;
    this.getListCatArt(pageNumber);
    this.popupReusableService.getPromiseForRequest().then(res => {
      this.findMontantTotal();
    });
  }

  /**
   * @return le nombre d'utilisateurs.
   */
  getNbItems(): number {
    return this.getNbCatArt();
  }

  /**
   * @return les paramètres de l'url.
   */
  getParams(): Params {
    return this.params;
  }

  /**
   * @return le path de la liste des utilisateurs.
   */
  getPath(): string {
    return RGlobal.urlFull.ARTICLE_INFOS.LIST;
  }

  getPathRoute(): string {
    return this.getPath();
  }

  getService(): IPaginateService {
    return this;
  }

  setParamsRoute(params: any) {
    this.params = params;
    const date = this.userListRouteCheckService.setDate(params, {
      dateDebut: this.artInfosDataShare.dateDebut,
      dateFin: this.artInfosDataShare.dateFin
    });
    this.artInfosDataShare.dateDebut = date.dateDebut;
    this.artInfosDataShare.dateFin = date.dateFin;
  }

  getTitlePath(): string {
    return 'Liste des types d\'articles';
  }

  setData(data: any[]) {
    this.catArtList = data;
  }

  setNbItems(n: number) {
    this.nbCatArt = n;
  }

  /**
   * @param totalPages le nombre de page.
   */
  setTotalPages(t: number) {
    this.totalPages = t;
  }

  iCanAdd(b: boolean) {
    this.iCanAddCatArt = b;
  }

  iCanDelete(b: boolean) {
    this.iCanDeleteCatArt = b;
  }

  iCanModify(b: boolean) {
    this.iCanModifyCatArt = b;
  }

  iCanSearch(b: boolean) {
    this.iCanSearchCatArt = b;
  }

  iCanShow(b: boolean) {
    if (!b) {
      this.router.navigate([RGlobal.url.PAGE_RESTRICTED]);
    }
    this.iCanShowCatArt = b;
  }

  getDisplayColumns(): any[] {
    return ['select', 'designation', 'code', 'qtDisponible', 'prixUnitaire',
      'montant', 'prixUnitaireVente', 'montantVente', 'beneficeTotal', 'qtAlerte',
      'description', 'designationCat', 'createBy', 'date_modification',
      'date_enregistrement'];
  }

  getDataSource(): MatTableDataSource<any> {
    return new MatTableDataSource<IArticleInfos>(this.getCatArtList());
  }

  getDateDebut(): string {
    return this.artInfosDataShare.dateDebut;
  }

  getDateFin(): string {
    return this.artInfosDataShare.dateFin;
  }
}
