import {Injectable} from '@angular/core';
import {AcceptAction, GestionType, IAction, PrivilegeType} from '../../models/action.model';
import {Boutique} from '../../models/boutique.model';

/**
 * Permet d'indiquer si l'utilisateur possède
 * les privilèges.
 */
interface PrivilegeResult {
  userHasPrivilege: boolean;
  hasFoundPrivilege: boolean;
}

/**
 * Permet de vérifier que l'utilisateur connecté possède les privilèges
 * nécessaires pour accéder à certaines pages.
 */
@Injectable({providedIn: 'root'})
export class CheckingPrivilegeService {

  /**
   * Vérifie que l'utilisateur connecté possède les privilèges
   * nécessaires pour accéder à certaines pages.
   *
   * @param actions les différents privilèges de l'utilisateur.
   * @param gestionType le type de privilège.
   * @param privilegeType option du privilège.
   */
  hasPrivilege(actions: IAction[], gestionType: GestionType,
               privilegeType: PrivilegeType, boutiqueSelected: Boutique) {
    const privilegeResult: PrivilegeResult = {
      userHasPrivilege: false,
      hasFoundPrivilege: false
    };
    if (actions.length === 0) {
      // l'utilisateur ne possède aucun privilège.
      return false;
    }
    this.verifyPrivilege(actions, gestionType,
      privilegeType, privilegeResult, boutiqueSelected);
    return privilegeResult.userHasPrivilege;
  }

  /**
   * Permet de vérifier les privilèges de l'utilisateur.
   *
   * @param actions tous privilèges de l'utilisateur.
   * @param gestionType le module à vérifier.
   * @param privilegeType le type d'action.
   * @param privilegeResult indique si l'utilisateur possède les privilèges.
   */
  private verifyPrivilege(actions: IAction[], gestionType: GestionType,
                          privilegeType: PrivilegeType, privilegeResult: PrivilegeResult,
                          boutiqueSelected: Boutique) {
    for (const actionUser of actions) {
      if (actionUser.boutique && boutiqueSelected &&
        actionUser.boutique.id === boutiqueSelected.id) {
        if (actionUser.gestion === gestionType) {
          privilegeResult.hasFoundPrivilege = true;
          this.checkPrivilege(privilegeType, actionUser, privilegeResult);
        }
        if (privilegeResult.hasFoundPrivilege) {
          break;
        }
      }
    }
  }

  /**
   * Permet de vérifier les privilèges de l'utilisateur.
   *
   * @param privilegeType le type d'action.
   * @param actionUser contient les privilèges de l'utilisateur.
   * @param privilegeResult indique si l'utilisateur possède les privilèges.
   */
  private checkPrivilege(privilegeType: PrivilegeType, actionUser: IAction,
                         privilegeResult: PrivilegeResult) {
    switch (privilegeType) {
      case PrivilegeType.AJOUTER:
        if (actionUser.ajouter === AcceptAction.OUI) {
          privilegeResult.userHasPrivilege = true;
        }
        break;
      case PrivilegeType.MODIFIER:
        if (actionUser.modifier === AcceptAction.OUI) {
          privilegeResult.userHasPrivilege = true;
        }
        break;
      case PrivilegeType.SUPPRIMER:
        if (actionUser.supprimer === AcceptAction.OUI) {
          privilegeResult.userHasPrivilege = true;
        }
        break;
      case PrivilegeType.CONSULTER:
        if (actionUser.consulter === AcceptAction.OUI) {
          privilegeResult.userHasPrivilege = true;
        }
        break;
      case PrivilegeType.RECHERCHER:
        if (actionUser.rechercher === AcceptAction.OUI) {
          privilegeResult.userHasPrivilege = true;
        }
        break;
      case PrivilegeType.TELECHARGER:
        if (actionUser.telecharger === AcceptAction.OUI) {
          privilegeResult.userHasPrivilege = true;
        }
        break;
      case PrivilegeType.IMPRIMER:
        if (actionUser.imprimer === AcceptAction.OUI) {
          privilegeResult.userHasPrivilege = true;
        }
        break;
    }
  }

}
