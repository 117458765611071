import {Routes} from '@angular/router';
import {RGlobal} from '../../shared/constant/global.url';
import {ArtInfosListeComponent} from './art-infos-liste/art-infos-liste.component';
import {GuardCheckingBoutiqueService} from '../../shared/ services/guard/login/guard-checking-boutique.service';
import {GGuardIsLoginService} from '../../shared/ services/guard/is-login/gguard-is-login.service';
import {GGuardPrivilegeArtInfosService} from '../../shared/ services/guard/gguard-privilege-art-infos.service';

export const articleInfosRoute: Routes = [
  {
    path: RGlobal.url.ARTICLE_INFOS.ARTICLE_INFOS_,
    canActivate: [GGuardIsLoginService, GGuardPrivilegeArtInfosService],
    children: [
      {
        path: RGlobal.url.ARTICLE_INFOS.LIST,
        component: ArtInfosListeComponent,
        canActivate: [GuardCheckingBoutiqueService]
      },
      {
        path: RGlobal.url.ARTICLE_INFOS.SEARCH,
        component: ArtInfosListeComponent,
        canActivate: [GuardCheckingBoutiqueService]
      }
    ]
  }
];
