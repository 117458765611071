import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthHttpInterceptorImplService} from './auth-interceptor-impl.service';
import {Observable} from 'rxjs';

/**
 * Permet de vérifier si la session a un nom d'utilisateur et un token valide
 * lors de l'envoie des requêtes au serveur et mettra à jour toutes les requêtes
 * http sortantes.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthHttpInterceptorService implements HttpInterceptor {
  constructor(private authHttp: AuthHttpInterceptorImplService) {
  }

  /**
   * Permet de vérifier si la session a un nom d'utilisateur et un token valide
   * lors de l'envoie des requêtes au serveur et mettra à jour toutes les requêtes
   * http sortantes.
   *
   * @param req req.
   * @param next next.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authHttp.intercept(req, next);
  }
}
