import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {NotificationService} from '../../../reusable/services/notification.service';

export namespace NRequest {
  export enum ERequest {
    CREATE, UPDATE, DELETE
  }
}

@Injectable({providedIn: 'root'})
export class RequestService {
  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {
  }

  private redirect(form: FormGroup, path: string) {
    this.router.navigate([path]);
    if (form) {
      form.reset();
    }
  }

  successWithoutPath(e: NRequest.ERequest, subs$: Subject<void>) {
    if (e === NRequest.ERequest.CREATE) {
      this.notificationService.notifyCreateSuccessful(subs$);
    } else if (e === NRequest.ERequest.UPDATE) {
      this.notificationService.notifyUpdateSuccessful(subs$);
    } else if (e === NRequest.ERequest.DELETE) {
      this.notificationService.notifyDeleteSuccessful(subs$);
    }
  }

  success(e: NRequest.ERequest, form: FormGroup, path: string,
          subs$: Subject<void>) {
    if (e === NRequest.ERequest.CREATE) {
      this.notificationService.notifyCreateSuccessful(subs$);
      this.redirect(form, path);
    } else if (e === NRequest.ERequest.UPDATE) {
      this.notificationService.notifyUpdateSuccessful(subs$);
      this.redirect(form, path);
    }
  }
}
