import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {Injectable} from '@angular/core';
import {IRestoreForSearchService} from './irestore-for-search.service';
import {ManageService} from '../manage.service';

@Injectable({providedIn: 'root'})
export class RestoreForSearchService {
  constructor(
    private freeMemoryService: FreeMemoryService,
    private manageService: ManageService) {
  }

  restore(i: IRestoreForSearchService, subs$) {
    i.searchData = undefined;
    this.manageService.initData(i.getDataFilterValueToRestore());
    this.manageService.initData(i.getDataFilterToRestore());
    this.freeMemoryService.free(subs$);
  }

}
