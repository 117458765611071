import {NgModule} from '@angular/core';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {BoutiqueListeComponent} from './boutique-liste/boutique-liste.component';
import {BoutiqueShowComponent} from './boutique-show/boutique-show.component';
import {BoutiqueCreateComponent} from './boutique-create/boutique-create.component';
import {AbonnementNombreBoutiqueComponent} from '../abonnement/abonnement-nombre-boutique/abonnement-nombre-boutique.component';
import {AbonnementInfosComponent} from '../abonnement/abonnement-infos/abonnement-infos.component';
import {BoutiqueUpdateComponent} from './boutique-update/boutique-update.component';
import {BoutiqueDeleteComponent} from './boutique-delete/boutique-delete.component';
import {AbonnementFactureComponent} from '../abonnement/abonnement-facture/abonnement-facture.component';
import {AbonnementProduitComponent} from '../abonnement/abonnement-produit/abonnement-produit.component';
import {AbonnementBilanComponent} from '../abonnement/abonnement-bilan/abonnement-bilan.component';
import {AbonnementApplicationComponent} from '../abonnement/abonnement-application/abonnement-application.component';

/**
 * modules pour la page d'accueil.
 */
@NgModule({
  declarations: [
    AbonnementApplicationComponent,
    AbonnementBilanComponent,
    AbonnementProduitComponent,
    AbonnementFactureComponent,
    BoutiqueDeleteComponent,
    BoutiqueUpdateComponent,
    AbonnementInfosComponent,
    AbonnementNombreBoutiqueComponent,
    BoutiqueCreateComponent,
    BoutiqueShowComponent,
    BoutiqueListeComponent],
  imports: [
    GestionStockSharedModule,
    RouterModule.forRoot([])
  ],
  exports: [],
  entryComponents: [
    AbonnementApplicationComponent,
    AbonnementBilanComponent,
    AbonnementProduitComponent,
    AbonnementFactureComponent,
    BoutiqueDeleteComponent,
    BoutiqueUpdateComponent,
    AbonnementInfosComponent,
    AbonnementNombreBoutiqueComponent,
    BoutiqueCreateComponent,
    BoutiqueShowComponent,
    BoutiqueListeComponent]
})
export class BoutiqueModule {
}
