/**
 * Permet d'initialiser le component {@link UserCreateComponent}
 */
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class EmployeShowInit {
  constructor() {
  }

  /**
   * Permet de récupérer les valeurs d'un utilisateur pouvant
   * être affichées sur du html.
   * @param user l'utilisateur.
   */
  getUser(user: EmployeBoutique) {
    if (user) {
      return {
        id: user.utilisateur ? user.utilisateur.id : null,
        login: user.utilisateur ? user.utilisateur.login : null,
        password: user.utilisateur ? user.utilisateur.password : null,
        nom: user.utilisateur ? user.utilisateur.nom : null,
        prenom: user.utilisateur ? user.utilisateur.prenom : null,
        dateNaissance: user.utilisateur ? user.utilisateur.dateNaissance : null,
        adresse: user.utilisateur ? user.utilisateur.adresse : null,
        sexe: user.utilisateur ? user.utilisateur.sexe : null,
        telephone: user.utilisateur ? user.utilisateur.telephone : null,
        email: user.utilisateur ? user.utilisateur.email : null,
        nationalite: user.utilisateur ? user.utilisateur.nationalite : null,
        dateEnregistrement: user ? user.dateEnregistrement : null,
        dateModification: user ? user.dateModification : null
      };
    }
    return {};
  }
}
