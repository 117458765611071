import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

/**
 * permet de gérer la validité des informations d'un formulaire.
 */
@Injectable({providedIn: 'root'})
export class FormReusableService {
  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  markAbstractControlTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markAbstractControlTouched(control);
      }
    });
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   */
  isEmailInvalid(field: string, form: AbstractControl): boolean {
    return form.get(field).hasError('email');
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string, form: AbstractControl): boolean {
    return form.get(field).pristine;
  }

  /**
   *
   * @param field champ à vérifier.
   * @param form formulaire contenant le champ.
   * @returns ..
   */
  isUnTouched(field: string, form: AbstractControl): boolean {
    return form.get(field).untouched;
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns true si le champs à perdu le focus.
   */
  isTouched(field: string, form: AbstractControl): boolean {
    return form.get(field).touched;
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @return true si l'utilisateur à effectuer une saisie sur le champs.
   */
  isDirty(field: string, form: AbstractControl): boolean {
    return form.get(field).dirty;
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @return true si le champs est invalide.
   */
  isInvalid(field: string, form: AbstractControl): boolean {
    return form.get(field).invalid;
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contnant le champs.
   * @returns true si le formulaire est invalide.
   */
  isInvalidAndDirtyOrTouched(field: string, form: AbstractControl): boolean {
    return (
      this.isInvalid(field, form) &&
      (this.isDirty(field, form) || this.isTouched(field, form))
    );
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contnant le champs.
   * @returns true si le formulaire est invalide.
   */
  isInvalidAndDirty(field: string, form: AbstractControl): boolean {
    return this.isInvalid(field, form) && this.isDirty(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns le nombre maximum de caractères autorisés pour le champ.
   */
  getMaxLength(field: string, form: AbstractControl): number {
    return form.get(field).errors.maxlength.requiredLength;
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string, form: AbstractControl): number {
    return form.get(field).errors.minlength.requiredLength;
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns true si l'utilisateur a dépassé le nombre maximum de caractères autorisés.
   */
  hasErrorMaxLength(field: string, form: AbstractControl): boolean {
    return form.get(field).hasError('maxlength');
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns true si l'utilisateur a dépassé le nombre minimum de caractères autorisés.
   */
  hasErrorMinLength(field: string, form: AbstractControl): boolean {
    return form.get(field).hasError('minlength');
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs à vérifier.
   * @returns true si le champs field est requis.
   */
  hasErrorRequired(field: string, form: AbstractControl): boolean {
    return form.get(field).hasError('required');
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs à vérifier.
   * @returns true si le champs field est requis.
   */
  hasErrorPattern(field: string, form: AbstractControl): boolean {
    return form.get(field).hasError('pattern');
  }

  /**
   * détermine si deux chaînes sont égales.
   * @param ch1 la chaîne à comparer.
   * @param ch2 la chaîne à comparer
   * @returns true si les deux chaines sont égales
   * false sinon.
   */
  isEqual(ch1: string, ch2: string) {
    return ch1 === ch2;
  }

  /**
   * détermine si la chaine en paramètre ne contient que des nombres.
   * @param number la chaîne à vérifier.
   * @returns true si la chaîne passée en paramètre ne contient que
   * des nombres sinon false.
   */
  hasOnlyNumber(nombre: string): boolean {
    return /^\d+$/.test(nombre);
  }

  /**
   * permet de récupérer les données d'un formulaire.
   *
   * @param form le formulaire
   * @param name le nom du chanmp dans le formulaire.
   * @param trimSpace par défaut vaut true. si true, enlève les espaces au début et à la fin
   * de la chaîne, si false n'enlève pas les espaces.
   */
  getDataObj(name: string, form: AbstractControl) {
    const field: AbstractControl = form.get(name);
    return field.value;
  }

  /**
   * permet de récupérer les données d'un formulaire.
   *
   * @param form le formulaire
   * @param name le nom du chanmp dans le formulaire.
   * @param trimSpace par défaut vaut true. si true, enlève les espaces au début et à la fin
   * de la chaîne, si false n'enlève pas les espaces.
   */
  getData(name: string, form: AbstractControl, fields: string[],
          trimSpace = true) {
    let formValue: string;
    const field: AbstractControl = form.get(name);
    if (fields && fields.includes(name)) {
      formValue = field.value ? field.value.toString().toLocaleLowerCase() : null;
    } else {
      formValue = field.value ? field.value.toString() : null;
    }
    return this.getDataValue(formValue, trimSpace);
  }

  getValue(name: string, form: AbstractControl) {
    const field: AbstractControl = form.get(name);
    return field.value;
  }

  /**
   * permet de récupérer les données d'un formulaire.
   *
   * @param form le formulaire
   * @param name le nom du chanmp dans le formulaire.
   * @param trimSpace par défaut vaut true. si true, enlève les espaces au début et à la fin
   * de la chaîne, si false n'enlève pas les espaces.
   */
  private getDataValue(value: string, trimSpace = true) {
    if (trimSpace === true) {
      return value ? value.toString().trim() : null;
    } else {
      return value ? value.toString() : null;
    }
  }

}
