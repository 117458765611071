import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserAccountValidateInit, UserAccountValidateNm} from './user-account-validate.init';
import {FormService} from '../../../shared/ services/form.service';
import {ActivatedRoute} from '@angular/router';
import {ValidateEmail} from '../../../shared/models/validate-email.model';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {takeUntil} from 'rxjs/operators';
import {UserAccountService} from '../user-account.service';
import {RsaService} from '../../../shared/ services/rsa.service';
import {AppInfos} from '../../../shared/models/app-infos.model';
import {AccountService} from '../../../shared/ services/auth/account/account.service';

/**
 * Permet de valider un compte utilisateur.
 */
@Component({
  selector: 'app-user-account-validate',
  templateUrl: './user-account-validate.component.html'
})
export class UserAccountValidateComponent implements OnInit, OnDestroy {
  constructor(
    private rsaService: RsaService,
    private userAccountService: UserAccountService,
    private freeMemoryService: FreeMemoryService,
    private http: HttpClient,
    private accountService: AccountService,
    private errorServ: ConstantErrorServ,
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private signInInit: UserAccountValidateInit,
    private formSignIn: FormBuilder,
  ) {
    this.signInColName = this.signInInit.getAuthColumnName();
  }

  // VAR.
  editForm = this.formSignIn.group(this.signInInit.getForm());
  signInColName: UserAccountValidateNm.ColumnName;
  key: string;
  appInfos: AppInfos;
  hasCodeError = false;
  validationHasSucceed = false;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init validate account');
    this.accountService.getAppInfos().subscribe(res => {
      this.appInfos = res.body;
      console.log('app: ' + this.appInfos.pageFacebook);
    });
    this.activatedRoute.queryParams.pipe(takeUntil(this.subs$))
      .subscribe(params => {
        this.key = params[this.signInColName.key];
        console.log('key: ' + this.key);
      });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
    this.reset(this.editForm);
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }


  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit avec le formulaire en tapant au moins un caractère.
   */
  reset(editForm: FormGroup) {
    editForm.get(this.signInColName.code).markAsPristine();
    this.validationHasSucceed = false;
    this.hasCodeError = false;
  }

  validate() {
    console.log('validate');
    this.reset(this.editForm);
    const user: ValidateEmail = this.signInInit.getAccountFromForm(this.editForm);
    user.key = this.key;
    user.code = this.rsaService.crypt(user.code);
    const result = this.userAccountService.validate(user);
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<ValidateEmail>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<ValidateEmail>) => {
        console.log('**** success validate user account: ');
        this.validationHasSucceed = true;
        this.editForm.reset();
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error validate user: ');
        this.validationHasSucceed = false;
        this.hasCodeError = true;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
      }
    );
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

}
