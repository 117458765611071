import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConstantArticleColumnName} from '../../../shared/constant/model/column-name/article-column-name.constant';
import {ConstantArticleValidator} from '../../../shared/constant/validator/article-validator.constant';
import {Article} from '../../../shared/models/article.model';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Offre plusieurs types utilisés dans la création de catégories d'article.
 */
export namespace ArticleCreateForInit {
  /**
   * Permet d'avoir le nom des champs pour une {@link CategorieArticle}.
   */
  export interface ColumnName {
    name: string;
    id: string;
    quantiteEnregistre: string;
    idArticleInfos: string;
  }
}

/**
 * Permet d'initialiser le component {@link CategorieArticleCreateComponent}
 */
@Injectable({providedIn: 'root'})
export class ArticleCreateInit {
  constructor(
    private date: DateFormatService,
    private formService: FormService,
    private categorieColName: ConstantArticleColumnName,
    private categorieValidator: ConstantArticleValidator
  ) {
  }

  /**
   * Permet de convertir une date
   */
  getDate() {
    return this.date;
  }

  /**
   * récupère les données de la catégorie du formulaire.
   */
  public getCategorieFromForm(editForm: FormGroup): Article {
    const entity = {
      ...new Article(),
      quantiteEnregistre: +this.formService.getData(this.categorieColName.QUANTITE_ENREGISTRE,
        editForm, []),
      articleInfos: {
        id: this.formService.getDataObj(this.categorieColName.ID_ARTICLE_INFOS, editForm).id
      }
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour une {@link CategorieArticle}
   */
  getCategorieArticleColumnName(): ArticleCreateForInit.ColumnName {
    return {
      name: this.categorieColName.NAME,
      id: this.categorieColName.ID,
      quantiteEnregistre: this.categorieColName.QUANTITE_ENREGISTRE,
      idArticleInfos: this.categorieColName.ID_ARTICLE_INFOS
    };
  }

  /**
   * @returns Formulaire de création d'une {@link CategorieArticle}.
   */
  getForm(): any {
    return {
      quantite_enregistre: this.categorieValidator.QUANTITE_ENREGISTRE,
      id_article_infos: this.categorieValidator.ID_ARTICLE_INFOS
    };
  }
}
