import {AbsVerification} from './abs-verification';
import {ConstantUserColumnName} from '../../../../shared/constant/model/column-name/user-column-name.constant';

/**
 * Permet de vérifier si le login fait partir des filtres de recherche
 * et de l'ajouter dans les paramètres dans ce cas.
 */
export class LoginVerification extends AbsVerification {
  constructor() {
    super();
  }

  private login: string;
  private userColName: ConstantUserColumnName;

  loginSet(login: string): LoginVerification {
    this.login = login;
    return this;
  }

  userColNameSet(userColName: ConstantUserColumnName): LoginVerification {
    this.userColName = userColName;
    return this;
  }


  /**
   * Vérifie si le login fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  check(first: AbsVerification) {
    if (this.login) {
      first.getDataSearch()[this.userColName.LOGIN] = this.login;
    }
    super.checkNext(first);
  }
}
