import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IRestoreWhenDisconnectService} from '../../shared/ services/restore/irestore-when-disconnect.service';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {EmployeBoutique} from '../../shared/models/employe-boutique.model';

@Injectable({providedIn: 'root'})
export class DataShareWhenCheckedUser implements IRestoreWhenDisconnectService {
  constructor(
    private restore: RestoreWhenDisconnectService) {
    this.restore.add(this);
  }

  // VAR.
  private userCheckedObs = new BehaviorSubject<EmployeBoutique>(undefined);
  private isUserCheckedObs = new BehaviorSubject<boolean>(false);


  /**
   * @return l'utilisateur coché.
   */
  public getUsercheckedObs(): Observable<EmployeBoutique> {
    return this.userCheckedObs.asObservable();
  }

  /**
   * Permet de modifier la valeur de l'utilisateur
   * coché.
   * @param user le nouvel utilisateur.
   */
  public setUsercheckedObs(user: EmployeBoutique) {
    this.userCheckedObs.next(user);
  }

  /**
   * @return Observable<boolean> true indique l'utilisateur
   * est coché, false sinon.
   */
  public getIsUserCheckedObs(): Observable<boolean> {
    return this.isUserCheckedObs.asObservable();
  }

  /**
   * @param checked true indique l'utilisateur
   * est coché, false sinon.
   */
  public setIsUserChecked(checked: boolean) {
    this.isUserCheckedObs.next(checked);
  }

  /**
   * Utilisé à chaque initialisation du component user.
   */
  clearUserChecked() {
    this.setUsercheckedObs(undefined);
    this.setIsUserChecked(false);
  }

  clear() {
    this.clearUserChecked();
  }
}
