import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopupReusableService} from '../../../../reusable/services/popup-reusable.service';
import {PaginationService} from '../../../../shared/layouts/pagination/service/pagination.service';
import {ArtInfosDataShare} from '../../../article-infos/art-infos-data.share';
import {Title} from '@angular/platform-browser';
import {ArtInfosListeInit} from '../../../article-infos/art-infos-liste/art-infos-liste.init';
import {Observable, Subject} from 'rxjs';
import {ISortieDetails, SortieDetails, SortieDTO} from '../../../../shared/models/sortie-details.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {RGlobal} from '../../../../shared/constant/global.url';
import {takeUntil} from 'rxjs/operators';
import {IArticleInfos} from '../../../../shared/models/article-infos.model';
import {AccountUser} from '../../../../shared/models/account.model';
import {MatTableDataSource} from '@angular/material';
import {ConstantErrorServ} from '../../../../shared/constant/error.constant';
import {Router} from '@angular/router';
import {NRequest, RequestService} from '../../../../shared/ services/request/request.service';
import {ActivatedRouteService} from '../../../../shared/ services/route/activated-route.service';
import {RouteCheckService} from '../../../../shared/ services/route/checking/route-check.service';
import {AccountService} from '../../../../shared/ services/auth/account/account.service';
import {GettingPrivilegeService} from '../../../../shared/ services/privilege/getting/getting-privilege.service';
import {SortieService} from '../../sortie.service';
import {BoutiqueService} from '../../../boutique/boutique.service';
import {Boutique} from '../../../../shared/models/boutique.model';
import {SortieCreateForInit, SortieCreateInit} from '../sortie-create.init';
import {DataShareWhenCheckedArtInfos} from '../../../article-infos/data-share-when-checked-art-infos.service';
import {FormService} from '../../../../shared/ services/form.service';
import {MatTablePopupService} from '../../../../shared/ services/mat-table/mat-table-popup.service';

@Component({
  selector: 'app-panier-liste',
  templateUrl: './panier-liste.component.html'
})
export class PanierListeComponent implements OnInit, OnDestroy {
  constructor(
    private boutiqueService: BoutiqueService,
    private sortieService: SortieService,
    private errorServ: ConstantErrorServ,
    private requestService: RequestService,
    private gettingPrivilegeService: GettingPrivilegeService,
    private activatedRouteService: ActivatedRouteService,
    private userListRouteCheckService: RouteCheckService,
    private catArtListInit: ArtInfosListeInit,
    private router: Router,
    private catInit: SortieCreateInit,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
    private paginationService: PaginationService,
    private artInfosDataShare: ArtInfosDataShare,
    private formService: FormService,
    private matTableService: MatTablePopupService,
    private dataShare: DataShareWhenCheckedArtInfos,
    private title: Title,
    private catArtInit: ArtInfosListeInit) {
    console.log('** construct art infos list');
    this.catColName = this.catInit.getCategorieArticleColumnName();
  }

  // VAR.
  savedDate;
  checkedDate = true;

  calculateTimeOut;
  montantRecu = 0;
  remboursement = 0;
  boutiqueSelected: Boutique;
  hasFinishedLoading = false;
  subs$ = new Subject<void>();
  activatedValidationButton = true;
  catArtList: IArticleInfos[];
  hasQtEnregistreError = false;
  hasQtInsufficientError = false;
  open = true;
  dataSource: MatTableDataSource<ISortieDetails>;
  catColName: SortieCreateForInit.ColumnName;
  totalPaid = 0;
  loadWhenCreating = false;
  indexChecked = -1;
  accountUser: AccountUser;
  sortieDetailsIsChecked = false;
  sortieDetailsChecked: ISortieDetails;

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   */
  ngOnInit() {
    console.log('** init panier list');
    this.open = true;
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
    this.verifyIsCatArtIsChecked(this.dataShare, this.subs$);
    this.getSortieDetailsChecked(this.dataShare, this.subs$);
    this.calculTotalPaid(this.dataShare);
    this.matTableService.init(this);
  }

  getNbElementPanier() {
    return this.dataShare.artInfosList.length;
  }

  /**
   * Permet de réinitialiser les boutons lors du changement de la route.
   */
  ngOnDestroy(): void {
    this.restore();
  }

  calculateRemboursement(event: any) {
    clearTimeout(this.calculateTimeOut);
    const montant = event.target.value;
    this.calculateTimeOut = setTimeout(() => {
      console.log('event: ' + montant);
      this.remboursement = montant - this.getTotalPaid();
    }, 1000);
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  isEmpty() {
    return this.isPanierEmpty() || !this.activatedValidationButton;
  }

  isOpen() {
    return this.open;
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '0';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '0';
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.catArtInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  getPrevious() {
    return this.matTableService.getPrevious();
  }

  getDataSourceMat() {
    return this.matTableService.getDataSource();
  }

  checked(event, row, i) {
    return this.matTableService.checked(event, row, i);
  }

  clicked(row, i) {
    return this.matTableService.clicked(row, i);
  }

  getSelected() {
    return this.matTableService.getSelected();
  }

  getDisplayColumnsMat() {
    return this.matTableService.getDisplayColumns();
  }

  /**
   * Permet de savoir si l'utilisateur est coché.
   */
  isUserChecked(): boolean {
    return this.isCatArtChecked();
  }

  /**
   * Permet d'indiquer à l'enfant que la liste des données a été affichée,
   * afin d'afficher ensuite la pagination.
   */
  finishedLoading() {
    this.hasFinishedLoading = true;
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getUsersList(): ISortieDetails[] {
    return this.getCatArtListData();
  }

  /**
   * Libère les ressources.
   */
  restore() {
    // this.open = true;
    this.removeMsgError();
    this.dataShare.clearSortieDetailsChecked();
    // this.iFreeMemoryService.free(this.subs$);
  }

  removeMsgError() {
    this.hasQtEnregistreError = false;
    this.hasQtInsufficientError = false;
  }

  initDate() {
    this.savedDate = undefined;
  }

  save() {
    this.activatedValidationButton = false;
    console.log('creating sortie');
    this.loadWhenCreating = true;
    this.removeMsgError();
    const sortieDTO = new SortieDTO();
    sortieDTO.sortieListDTOS = this.dataShare.artInfosList;
    sortieDTO.boutique = this.boutiqueSelected;
    sortieDTO.savedDate = this.savedDate;
    const result = this.sortieService.create(sortieDTO);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'une {@link CategorieArticle}.
   * @param err les erreurs.
   */
  manageErrorFromSavingCatArticle(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    this.checkForNegativeValueServ(erreur);
  }

  checkForNegativeValueServ(erreur: any) {
    if (erreur.errorCode === this.errorServ.NEGATIVE_VALUE) {
      const fields = erreur.fields;
      this.hasQtEnregistreError = fields[this.catColName.quantiteSortie]
        ? true
        : false;
    } else {
      this.hasQtInsufficientError = true;
    }
  }

  /**
   * Permet récupérer la réponse lors de la création d'une {@link CategorieArticle}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<SortieDetails[]>>
  ) {
    result.subscribe(
      (res: HttpResponse<SortieDetails[]>) => {
        console.log('**** success save sortie: ');
        this.loadWhenCreating = false;
        this.closePopup();
        this.deleteAllFromCart();
        this.requestService.success(NRequest.ERequest.CREATE, null,
          RGlobal.urlFull.SORTIE.LIST, this.subs$);
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save article infos: ');
        this.activatedValidationButton = true;
        this.loadWhenCreating = false;
        try {
          this.manageErrorFromSavingCatArticle(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

  getTotalPaid() {
    return this.totalPaid;
  }

  calculTotalPaid(dataShare: DataShareWhenCheckedArtInfos) {
    const sortieDetailsList: ISortieDetails[] = dataShare.artInfosList;
    let total = 0;
    for (const artInfos of sortieDetailsList) {
      total += artInfos.montant;
    }
    this.totalPaid = total;
  }

  isPanierEmpty() {
    const size = this.dataShare.artInfosList.length;
    if (size > 0) {
      return false;
    }
    return true;
  }

  deleteAllFromCart() {
    if (this.dataSource) {
      console.log('index: ' + this.indexChecked);
      console.log('data1: ' + JSON.stringify(this.dataSource.data));
      this.dataSource.data.splice(0, this.dataSource.data.length);
      this.calculTotalPaid(this.dataShare);
      console.log('data2: ' + JSON.stringify(this.dataSource.data));
    }
    console.log('index last: ' + this.indexChecked);
  }

  deleteFromCart() {
    if (this.dataSource && this.sortieDetailsChecked && (this.indexChecked !== -1)) {
      console.log('index: ' + this.indexChecked);
      console.log('data1: ' + JSON.stringify(this.dataSource.data));
      this.dataSource.data.splice(this.indexChecked, 1);
      this.dataShare.clearSortieDetailsChecked();
      this.calculTotalPaid(this.dataShare);
      console.log('data2: ' + JSON.stringify(this.dataSource.data));
    }
    console.log('index last: ' + this.indexChecked);
  }

  verifyIsCatArtIsChecked(dataShare: DataShareWhenCheckedArtInfos,
                          subs$: Subject<void>) {
    dataShare.getIsSortieDetailsCheckedObs()
      .pipe(takeUntil(subs$)).subscribe((v: boolean) => {
      this.sortieDetailsIsChecked = v;
    });
  }

  getSortieDetailsChecked(dataShare: DataShareWhenCheckedArtInfos,
                          subs$: Subject<void>) {
    dataShare.getSortieDetailsCheckedObs()
      .pipe(takeUntil(subs$)).subscribe((s: ISortieDetails) => {
      this.sortieDetailsChecked = s;
    });
  }

  /**
   * @param catArtList la liste des utilisateurs.
   */
  setUsersList(catArtList: IArticleInfos[]) {
    this.catArtList = catArtList;
  }

  /**
   * Permet de savoir si l'utilisateur est coché.
   */
  isCatArtChecked(): boolean {
    return this.sortieDetailsIsChecked && (this.dataShare.artInfosList.length > 0);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur coché dans la tableau.
   * @param user l'utilisateur coché.
   * @param event permet d'indiquer si la case est cochée ou pas.
   */
  hasChecked(user: any, index?: number) {
    if (user) {
      this.indexChecked = index;
      this.dataShare.setSortieDetailsCheckedObs(user);
      this.dataShare.setIsSortieDetailsCheckedObs(true);
    } else {
      this.indexChecked = -1;
      this.dataShare.clearSortieDetailsChecked();
    }
  }

  getAccount(): AccountUser {
    return this.accountUser;
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getCatArtListData(): ISortieDetails[] {
    return this.dataShare.artInfosList;
  }

  getDisplayColumns(): any[] {
    return ['select', 'designation', 'code', 'quantite', 'prixUnitaire',
      'montant'];
  }

  getDataSource(): MatTableDataSource<any> {
    this.dataSource = new MatTableDataSource<ISortieDetails>(this.dataShare.artInfosList);
    return this.dataSource;
  }
}
