import {Component, OnDestroy, OnInit} from '@angular/core';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {BoutiqueService} from '../boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {AccountUser} from '../../../shared/models/account.model';
import {BoutiqueCreateInit, BoutiqueCreateNm} from '../boutique-create/boutique-create.init';
import {Observable, Subject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {FormService} from '../../../shared/ services/form.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Boutique} from '../../../shared/models/boutique.model';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-boutique-update',
  templateUrl: './boutique-update.component.html'
})
export class BoutiqueUpdateComponent implements OnInit, OnDestroy {
  constructor(
    private errorServ: ConstantErrorServ,
    private requestService: RequestService,
    private formService: FormService,
    private freeMemoryService: FreeMemoryService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private boutiqueCreateInit: BoutiqueCreateInit,
    private formSignIn: FormBuilder,
    private popupReusableService: PopupReusableService,
  ) {
    this.signInColName = this.boutiqueCreateInit.getAuthColumnName();
  }

  isLoading = false;
  boutiqueChecked: Boutique;
  hasNomError = false;
  signInColName: BoutiqueCreateNm.ColumnName;
  editForm = this.formSignIn.group(this.boutiqueCreateInit.getForm());
  subs$ = new Subject<void>();
  accountUser: AccountUser;
  shouldIShow = false;
  open = true;

  ngOnInit(): void {
    console.log('init boutique update');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
    });
    this.boutiqueService.getBoutiqueCheckedDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe(data => {
      this.boutiqueChecked = data;
      this.putDataInForm(data);
    });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  putDataInForm(boutique: Boutique) {
    if (boutique) {
      this.editForm.patchValue({
        nom: boutique.nom,
        devise: boutique.devise,
        pays: boutique.pays,
        ville: boutique.ville,
        adresse: boutique.adresse,
        description: boutique.description,
        telephone1: boutique.telephone1,
        telephone2: boutique.telephone2,
        telephone3: boutique.telephone3,
      });
    }
  }

  reset() {
    this.hasNomError = false;
  }

  update() {
    console.log('update boutique');
    this.isLoading = true;
    this.reset();
    this.editForm.get(this.signInColName.nom).markAsPristine();
    const boutique = this.boutiqueCreateInit.getAuthFromForm(this.editForm);
    boutique.id = this.boutiqueChecked.id;
    const result = this.boutiqueService.update(boutique);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<Boutique>>
  ) {
    result.subscribe(
      (res: HttpResponse<Boutique>) => {
        console.log('**** success update boutique: ');
        this.isLoading = false;
        this.reset();
        // this.validationHasSucceed = true;
        this.requestService.successWithoutPath(NRequest.ERequest.UPDATE,
          this.subs$);
        this.popupReusableService.dismiss(this.activeModal);
        this.boutiqueService.getListEvent().emit('list');
      },
      (err: HttpErrorResponse) => {
        console.log('**** error update boutique: ');
        this.isLoading = false;
        // this.validationHasSucceed = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        try {
          this.manageErrorFromSavingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromSavingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    console.log('err: ' + JSON.stringify(erreur));
    const fields = erreur.fields;
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      console.log('nom exist');
      this.hasNomError = fields.nom ? true : false;
    }
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   */
  isEmailInvalid(field: string): boolean {
    return this.formService.isEmailInvalid(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }
}
