import {Injectable} from '@angular/core';

/**
 * Permet d'avoir la taille des champs pour un {@link Utilisateur}
 */
@Injectable({providedIn: 'root'})
export class ConstantBoutiqueSize {
  readonly NOM_MIN_SIZE = 1;
  readonly NOM_MAX_SIZE = 200;

  readonly PAYS_MIN_SIZE = 1;
  readonly PAYS_MAX_SIZE = 200;

  readonly DESCRIPTION_MAX_SIZE = 200;

  readonly VILLE_MIN_SIZE = 1;
  readonly VILLE_MAX_SIZE = 200;

  readonly ADRESSE_MIN_SIZE = 1;
  readonly ADRESSE_MAX_SIZE = 200;

  readonly TELEPHONE_MAX_SIZE = 100;

  readonly DEVISE_MAX_SIZE = 50;
}
