import {AbsVerification} from './abs-verification';
import {ConstantUserColumnName} from '../../../../shared/constant/model/column-name/user-column-name.constant';

/**
 * Permet de vérifier si l'email fait partir des filtres de recherche
 * et de l'ajouter dans les paramètres dans ce cas.
 */
export class EmailVerification extends AbsVerification {
  constructor() {
    super();
  }

  private email: string;
  private userColName: ConstantUserColumnName;

  emailSet(email: string): EmailVerification {
    this.email = email;
    return this;
  }

  userColNameSet(userColName: ConstantUserColumnName): EmailVerification {
    this.userColName = userColName;
    return this;
  }


  /**
   * Vérifie si l'email fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  check(first: AbsVerification) {
    if (this.email) {
      first.getDataSearch()[this.userColName.EMAIL] = this.email;
    }
    super.checkNext(first);
  }
}
