import { Injectable } from '@angular/core';

/**
 * Permet d'avoir la taille des champs pour une {@link CategorieArticle}
 */
@Injectable({ providedIn: 'root' })
export class ConstantCategorieArticleSize {
  readonly DESIGNATION_MIN_SIZE = 1;
  readonly DESIGNATION_MAX_SIZE = 200;

  readonly CODE_MIN_SIZE = 1;
  readonly CODE_MAX_SIZE = 100;
}
