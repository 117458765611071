import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {GuardIsLoginImplService} from './guard-is-login-impl.service';

/**
 * vérifier que l'utilisateur est connecté pour pouvoir
 * accèder aux routes.
 */
@Injectable({providedIn: 'root'})
export class GGuardIsLoginService implements CanActivate {
  constructor(private guardIsLoginImplService: GuardIsLoginImplService) {
  }

  /**
   * vérifier que l'utilisateur est connecté pour pouvoir accèder aux routes.
   *
   * @param route la route.
   * @param state l'état.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guardIsLoginImplService.canActivate(route, state);
  }
}
