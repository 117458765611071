import {Injectable} from '@angular/core';

/**
 * Permet de récupérer les erreurs de validation de formulaire
 * côté serveur.
 */
@Injectable({providedIn: 'root'})
export class ConstantErrorServ {
  readonly SUBSCRIPTION_EXPIRED = 'subscription expired';

  readonly DATA_LIMIT_REACHED = 'data_limit';
  readonly ALREADY_EXIST = 'already_exist';
  readonly PAYMENT_ERROR = 'payment_error';

  readonly NOT_EXIST = 'not_exist';
  readonly ALREADY_OWNER = 'already_owner';

  readonly QUANTITY_INSUFFICIENT = 'quantity_insufficient';
  readonly NEGATIVE_VALUE = 'negative_value';

  readonly BAD_REQUEST = 'bad_request';
  readonly BLOCKED = 'blocked';
  readonly TOO_ATTEMPT = 'too_attempt';
}
