import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {SortieShowInit} from './sortie-show.init';
import {takeUntil} from 'rxjs/operators';
import {ISortie} from '../../../shared/models/sortie.model';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {RestoreSortieService} from '../restore-sortie.service';
import {FormService} from '../../../shared/ services/form.service';
import {DataShareWhenCheckedSortie} from '../data-share-when-checked-sortie.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';

@Component({
  selector: 'app-sortie-show',
  templateUrl: './sortie-show.component.html'
})
export class SortieShowComponent implements OnInit, OnDestroy {
  constructor(
    private boutiqueService: BoutiqueService,
    private restoreArtInfosService: RestoreSortieService,
    private popupReusableService: PopupReusableService,
    private formService: FormService,
    private dataShare: DataShareWhenCheckedSortie,
    private activeModal: NgbActiveModal,
    private userInit: SortieShowInit
  ) {
  }

  // VAR.
  open = true;
  subs$ = new Subject<void>();
  boutiqueSelected: Boutique;


  ngOnInit() {
    console.log('*** sortie-show onInit');
    this.dataShare.getSortiecheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: ISortie) => {
        if (data) {
          this.restoreArtInfosService.setSortieChecked(data);
        }
      });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
  }

  ngOnDestroy() {
    console.log('*** sortie-show onDestroy');
    this.restore();
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.userInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  formatQt(num: number): string {
    return this.formService.formatUsWithNoFractionDigit(num);
  }

  format(num: number): string {
    return this.formService.formatUs(num);
  }

  /**
   * Permet de récupérer les données de l'utilisateur recherché.
   */
  user() {
    return this.userInit.getUser(this.getCatArt());
  }

  /**
   * Permet de récupérer l'utilisateur recherché.
   */
  getUser() {
    return this.getCatArt();
  }

  getCatArt(): ISortie {
    return this.restoreArtInfosService.getSortieChecked();
  }

  /**
   * Permet de réinitialiser certaines variable à la fermeture
   * du popup.
   */
  restore() {
    console.log('** restore sortie show');
    this.restoreArtInfosService.restore(this.subs$);
  }
}
