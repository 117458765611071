import {EventEmitter, Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class AuthPopupService {
  constructor(
    private modalService: NgbModal
  ) {
    console.log('construct auth-popup service');
  }

  private listChangeEvent = new EventEmitter<string>();

  getListEvent(): EventEmitter<string> {
    return this.listChangeEvent;
  }

  open(component: any) {
    console.log('** open spinner');
    const modalRef = this.modalService.open(component, {
      size: 'lg',
      windowClass: 'modal-cs',
    });
    this.checkModalRef(modalRef);
    if (!modalRef.componentInstance) {
      modalRef.close();
    }
    return modalRef;
  }

  checkModalRef(modalRef: any) {
    if (modalRef) {
      modalRef.result.then(
        result => {
          console.log('** in result: ' + result);
        },
        reason => {
          console.log('in reason: ' + reason);
          this.getListEvent().emit('auth marchand');
        }
      );
    }
  }
}
