import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormService} from '../../../shared/ services/form.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../../shared/constant/model/server.constant';
import {API_USER_ACCOUNT_CREATE_RESET} from '../../../shared/constant/model/api.constant';

/**
 * Permet de créer un compte utilisateur.
 */
@Component({
  selector: 'app-user-account-reset-password',
  templateUrl: './user-account-reset-password.component.html'
})
export class UserAccountResetPasswordComponent implements OnInit, OnDestroy {
  constructor(
    private formService: FormService,
    private formSignIn: FormBuilder,
    private errorServ: ConstantErrorServ,
    private http: HttpClient
  ) {
  }


  // VAR.
  onValidate = false;
  resourceUrl = SERVER_API_URL + API_USER_ACCOUNT_CREATE_RESET;
  createResetHasSucceed = false;
  spinner = false;
  hasEmailError = false;
  emailKey = 'email';
  editForm = this.formSignIn.group({
    email: [
      null,
      [
        Validators.required,
      ]
    ]
  });


  ngOnInit(): void {
    console.log('on init reset password');
  }

  ngOnDestroy(): void {
    this.removeMsgError();
    this.createResetHasSucceed = false;
  }

  removeMsgError() {
    this.hasEmailError = false;
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit avec le formulaire en tapant au moins un caractère.
   */
  reset(editForm: FormGroup) {
    editForm.get(this.emailKey).markAsPristine();
  }

  resetPassword() {
    console.log('reset');
    this.onValidate = true;
    this.spinner = true;
    this.removeMsgError();
    this.reset(this.editForm);
    const user = {
      email: this.formService.getData(this.emailKey,
        this.editForm, [])
    };
    const result = this.http.post<any>(this.resourceUrl, user, {
      observe: 'response'
    });
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<any>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<any>) => {
        console.log('**** success reset password: ');
        this.onValidate = false;
        this.spinner = false;
        this.createResetHasSucceed = true;
        this.editForm.reset();
      },
      (err: HttpErrorResponse) => {
        console.log('**** error reset password: ');
        this.onValidate = false;
        this.spinner = false;
        this.createResetHasSucceed = false;
        this.hasEmailError = true;
      }
    );
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }


}
