import {NgModule} from '@angular/core';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {ArtInfosListeComponent} from './art-infos-liste/art-infos-liste.component';
import {ArtInfosShowComponent} from './art-infos-show/art-infos-show.component';
import {ArtInfosDeleteComponent} from './art-infos-delete/art-infos-delete.component';
import {ArtInfosUpdateComponent} from './art-infos-update/art-infos-update.component';
import {ArtInfosCreateComponent} from './art-infos-create/art-infos-create.component';
import {ArtInfosSearchComponent} from './art-infos-search/art-infos-search.component';
import {PanierListeComponent} from '../sortie/panier/panier-liste/panier-liste.component';
import {PanierCreateComponent} from '../sortie/panier/panier-create/panier-create.component';

/**
 * module pour la gestion des catégories d'article.
 */
@NgModule({
  declarations: [
    PanierCreateComponent,
    PanierListeComponent,
    ArtInfosSearchComponent,
    ArtInfosCreateComponent,
    ArtInfosUpdateComponent,
    ArtInfosDeleteComponent,
    ArtInfosShowComponent,
    ArtInfosListeComponent],
  imports: [GestionStockSharedModule],
  entryComponents: [
    PanierCreateComponent,
    PanierListeComponent,
    ArtInfosCreateComponent,
    ArtInfosUpdateComponent,
    ArtInfosDeleteComponent,
    ArtInfosShowComponent]
})
export class ArticleInfosModule {
}
