import {Injectable} from '@angular/core';
import {RGlobal, slashRoute} from '../../../shared/constant/global.url';
import {DateFormatService} from '../../../shared/ services/date-format.service';

/**
 * Permet d'initialiser le component {@link CategorieArticleListeComponent}
 */
@Injectable({providedIn: 'root'})
export class SortieListeInit {

  constructor(
    private date: DateFormatService
  ) {
  }

  // VAR.
  readonly SORTIE_CREATE_URL = slashRoute + RGlobal.urlFull.SORTIE.CREATE;
  readonly SORTIE_UPDATE_URL = slashRoute + RGlobal.urlFull.SORTIE.UPDATE;

  /**
   * Permet de convertir une date
   */
  getDate() {
    return this.date;
  }
}
