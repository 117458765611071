import {Injectable} from '@angular/core';
import {DateFormat} from '../constant/model/date.constant';

@Injectable({providedIn: 'root'})
export class DateFormatService {
  constructor(
    private date: DateFormat
  ) {
    this.full = this.date.FULL;
    this.medium = this.date.MEDIUM;
    this.hours = this.date.HOURS;
    this.shortDate = this.date.SHORT_DATE;
  }

  readonly full;
  readonly medium;
  readonly hours;
  readonly shortDate;
}
