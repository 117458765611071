import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConstantUserColumnName} from '../../shared/constant/model/column-name/user-column-name.constant';
import {ConstantSignInValidator} from '../../shared/constant/validator/signin-validator.constant';
import {AccountUserAuth, IAccountUserAuth} from '../../shared/models/account-auth.model';
import {FormService} from '../../shared/ services/form.service';

/**
 * Offre plusieurs types utilisés pour se connecter
 */
export namespace SignIn {
  /**
   * Permet d'avoir le nom des champs pour se connecter.
   */
  export interface ColumnName {
    login: string;
    password: string;
  }
}

/**
 * Permet d'initialiser le component {@link LoginComponent}
 */
@Injectable({providedIn: 'root'})
export class SignInInit {
  constructor(
    private formService: FormService,
    private userColName: ConstantUserColumnName,
    private signInValidator: ConstantSignInValidator
  ) {
  }

  /**
   * récupère les données d'authentification du formulaire.
   */
  public getAuthFromForm(editForm: FormGroup): IAccountUserAuth {
    const entity = {
      ...new AccountUserAuth(),
      login: this.formService.getData(this.userColName.LOGIN, editForm, this.userColName.fields),
      password: this.formService.getData(
        this.userColName.PASSWORD,
        editForm, this.userColName.fields,
        false
      )
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour se connecter.
   */
  getAuthColumnName(): SignIn.ColumnName {
    return {
      login: this.userColName.LOGIN,
      password: this.userColName.PASSWORD
    };
  }

  /**
   * @returns Formulaire de connexion.
   */
  getForm(): any {
    return {
      login: this.signInValidator.LOGIN,
      password: this.signInValidator.PASSWORD
    };
  }
}
