import {DataShareWhenCheckedCatArt} from '../data-share-when-checked-cat-art.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {CatArtShowInit} from './cat-art-show.init';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {ICategorieArticle} from '../../../shared/models/categorie-article.model';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {RestoreCatArtService} from '../restore-cat-art.service';

@Component({
  selector: 'app-categorie-article-show',
  templateUrl: './categorie-article-show.component.html'
})
export class CategorieArticleShowComponent implements OnInit, OnDestroy {
  constructor(
    private restoreCatArtService: RestoreCatArtService,
    private translateService: TranslateService,
    private popupReusableService: PopupReusableService,
    private dataShare: DataShareWhenCheckedCatArt,
    private activeModal: NgbActiveModal,
    private userInit: CatArtShowInit,
  ) {
  }

  // VAR.
  open = true;
  subs$ = new Subject<void>();

  ngOnInit() {
    console.log('*** cat-art-show onInit');
    this.dataShare.getCatArtcheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: ICategorieArticle) => {
        if (data) {
          this.restoreCatArtService.setCatArtChecked(data);
        }
      });
  }

  ngOnDestroy() {
    console.log('*** cat-art-show onDestroy');
    this.restoreCatArtService.restore(this.subs$);
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.userInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer les données de l'utilisateur recherché.
   */
  user() {
    return this.userInit.getUser(this.getCatArt());
  }

  /**
   * Permet de récupérer l'utilisateur recherché.
   */
  getUser() {
    console.log('*** getuser is call!');
    return this.getCatArt();
  }

  getCatArt(): ICategorieArticle {
    return this.restoreCatArtService.getCatArtChecked();
  }
}
