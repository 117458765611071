import {Component, OnDestroy, OnInit} from '@angular/core';
import {BoutiqueService} from '../../boutique/boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AccountUser} from '../../../shared/models/account.model';
import {ConstantAbonnementNombreBoutiquePay} from '../../../shared/constant/abonnement/abonnement-nombre-boutique-pay.constant';
import {AbonnementService} from '../abonnement.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {AppInfos} from '../../../shared/models/app-infos.model';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-abonnement-nombre-boutique',
  templateUrl: './abonnement-nombre-boutique.component.html'
})
export class AbonnementNombreBoutiqueComponent implements OnInit, OnDestroy {
  constructor(
    private formService: FormService,
    private router: Router,
    private abonnementService: AbonnementService,
    private constantAbonnementNombreBoutiquePay: ConstantAbonnementNombreBoutiquePay,
    private freeMemoryService: FreeMemoryService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
  }

  appInfos: AppInfos;
  showLoading = false;
  open = true;
  shouldIShow = false;
  accountUser: AccountUser;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('abonnement nombre boutique');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
    });
    this.accountService.getAppInfos().subscribe(res => {
      this.appInfos = res.body;
    });
  }

  getAbonnement() {
    return this.constantAbonnementNombreBoutiquePay;
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  getOffer(nombreBoutique: number) {
    console.log('get offer: ' + nombreBoutique);
    this.showLoading = true;
    const data = {
      nombre: nombreBoutique
    };
    const result = this.abonnementService.getOfferBoutique(data);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<string>>,
  ) {
    result.subscribe(
      (res: HttpResponse<string>) => {
        console.log('**** success abonnement boutique: ');
        console.log('res: ' + res.body);
        try {
          window.location.href = res.body;
        } catch (e) {
          console.log('erreur validation: ' + (e as Error).message);
        }
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error abonnement boutique: ');
        this.showLoading = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

}
