import {Moment} from 'moment';
import {IUtilisateur} from './utilisateur.model';
import {IBoutique} from './boutique.model';


export interface ICategorieArticle {
  id?: number;
  designation?: string;
  code?: string;
  description?: string;
  isDelete?: string;
  dateEnregistrement?: Moment;
  dateModification?: Moment;
  utilisateur?: IUtilisateur;
  boutique?: IBoutique;
}

export interface ICategorieArticleUpdateDTO {
  id?: number;
  designation?: string;
  code?: string;
  description?: string;
  isDelete?: string;
  dateEnregistrement?: Moment;
  dateModification?: Moment;
  boutique?: IBoutique;
}

export class CategorieArticle implements ICategorieArticle {
  constructor(
    public id?: number,
    public designation?: string,
    public code?: string,
    public description?: string,
    public isDelete?: string,
    public dateEnregistrement?: Moment,
    public dateModification?: Moment,
    public utilisateur?: IUtilisateur,
    public boutique?: IBoutique
  ) {
  }

}

export class CategorieArticleUpdateDTO implements ICategorieArticleUpdateDTO {
  constructor(
    public id?: number,
    public designation?: string,
    public description?: string,
    public code?: string,
    public isDelete?: string,
    public dateEnregistrement?: Moment,
    public dateModification?: Moment,
    public boutique?: IBoutique
  ) {
  }

}
