import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {HomeComponent} from './home.component';
import {homeRoute} from './home.route';
import {UserAccountModule} from '../../modules/user-account/user-account.module';
import {PageNotFoundComponent} from '../../shared/layouts/error/page-not-found.component';
import {UserAccountUpdateComponent} from '../../modules/user-account/user-account-update/user-account-update.component';
import {NavComponent} from '../../shared/layouts/nav/nav.component';
import {BoutiqueModule} from '../../modules/boutique/boutique.module';
import {EmployeModule} from '../../modules/employe/employe.module';
import {CategorieArticleModule} from '../../modules/categorie-article/categorie-article.module';
import {ArticleInfosModule} from '../../modules/article-infos/article-infos.module';
import {PageRestrictedComponent} from '../../shared/layouts/restricted/page-restricted.component';
import {ArticleModule} from '../../modules/article/article.module';
import {SortieModule} from '../../modules/sortie/sortie.module';
import {UserUpdateEmailComponent} from '../../modules/user-account/update-email/user-update-email.component';

/**
 * modules pour la page d'accueil.
 */
@NgModule({
  declarations: [
    PageRestrictedComponent,
    NavComponent,
    UserUpdateEmailComponent,
    UserAccountUpdateComponent,
    HomeComponent,
    PageNotFoundComponent],
  imports: [
    GestionStockSharedModule,
    SortieModule,
    ArticleModule,
    ArticleInfosModule,
    CategorieArticleModule,
    EmployeModule,
    BoutiqueModule,
    UserAccountModule,
    RouterModule.forRoot([...homeRoute])
  ],
  exports: [PageRestrictedComponent,
    PageNotFoundComponent],
  entryComponents: [
    UserUpdateEmailComponent,
    UserAccountUpdateComponent]
})
export class HomeModule {
}
