import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EtatUserType, SexeType, UtilisateurType} from '../../../shared/models/utilisateur.model';
import {DataShareWhenCheckedUser} from '../data-share-when-checked-user.service';
import {ManageService} from '../../../shared/ services/manage.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';
import {RestoreEmployeService} from '../restore-employe.service';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {EmployeDeleteInit} from './employe-delete.init';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {EmployeService} from '../employe.service';
import {BoutiqueService} from '../../boutique/boutique.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {ConstantUserColumnName} from '../../../shared/constant/model/column-name/user-column-name.constant';
import {ConstantBoutiqueColumnName} from '../../../shared/constant/model/column-name/boutique-column-name.constant';
import {DeleteSuccessService} from '../../../shared/ services/delete/delete-success.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {IDeleteSuccessService} from '../../../shared/ services/delete/idelete-success.service';
import {RGlobal} from '../../../shared/constant/global.url';

@Component({
  selector: 'app-employe-delete',
  templateUrl: './employe-delete.component.html'
})
export class EmployeDeleteComponent implements OnInit, OnDestroy,
  IDeleteSuccessService {
  constructor(
    private popupReusableService: PopupReusableService,
    private deleteSuccessService: DeleteSuccessService,
    private userColumnName: ConstantUserColumnName,
    private boutiqueColumnName: ConstantBoutiqueColumnName,
    private boutiqueService: BoutiqueService,
    private dateFormatService: DateFormatService,
    private restoreUserService: RestoreEmployeService,
    private dataShareWhenCheckedUser: DataShareWhenCheckedUser,
    private manageService: ManageService,
    private activeModal: NgbActiveModal,
    private userDeleteInit: EmployeDeleteInit,
    private employeService: EmployeService) {
  }

  // VAR.
  boutiqueSelected: Boutique;
  loadWhenDeleting = false;
  subs$ = new Subject<void>();
  open = true;
  activatedValidationButton = true;

  ngOnInit() {
    console.log('** init user delete');
    this.dataShareWhenCheckedUser.getUsercheckedObs().pipe(
      takeUntil(this.subs$))
      .subscribe((data: EmployeBoutique) => {
        if (data) {
          this.restoreUserService.setUserChecked(data);
        }
      });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
  }

  ngOnDestroy(): void {
    this.restoreUserService.restore(this.subs$);
  }

  isActivated() {
    return this.activatedValidationButton;
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.dateFormatService; // NE PAS SUPPRIMER, utilisé côté Html.
  }

  delete() {
    this.activatedValidationButton = false;
    this.loadWhenDeleting = true;
    const idEmploye = this.restoreUserService.getUserChecked().id;
    const idBoutique = this.boutiqueSelected.id;
    console.log('** suppression user');
    let option = new HttpParams();
    option = option.set(this.userColumnName.NAME, idEmploye.toString());
    option = option.set(this.boutiqueColumnName.NAME, idBoutique.toString());
    const result = this.employeService.delete(option);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        this.loadWhenDeleting = false;
        const d = res.body;
        if (d) {
          console.log('**** success delete user: ' + res);
          this.deleteSuccessService.deleteSuccess(this,
            this.subs$, 'user supprimé');
        }
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save user: ' + err);
        this.activatedValidationButton = true;
        this.loadWhenDeleting = false;
      }
    );
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  getUser() {
    return this.userDeleteInit.getUser(this.restoreUserService.getUserChecked());
  }

  /**
   * Permet d'avoir une valeur par défaut si le type n'est pas renseigné.
   * @param type le type d'utilisateur.
   */
  convertType(type: UtilisateurType) {
    return this.manageService.getType(type);
  }

  /**
   * Permet d'avoir une valeur par défaut si l'état n'est pas renseigné.
   * @param etat l'état de l'utilisateur.
   */
  convertEtat(etat: EtatUserType) {
    return this.manageService.getEtat(etat);
  }

  /**
   * Permet d'avoir une valeur par défaut si le sexe n'est pas renseigné.
   * @param sexe le sexe.
   */
  convertSexe(sexe: SexeType) {
    return this.manageService.getSexe(sexe);
  }

  clearItemChecked() {
    this.dataShareWhenCheckedUser.clearUserChecked();
  }

  getActiveModal(): NgbActiveModal {
    return this.activeModal;
  }

  getPath(): string {
    return RGlobal.urlFull.EMPLOYE.LIST;
  }

}
