import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {ConstantUserSize} from '../model/size/user-size.constant';

/**
 * Permet la validation des champs pour un {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class ConstantUserValidator {
  constructor(private userSize: ConstantUserSize) {
  }

  readonly LOGIN = [
    null,
    [
      Validators.required,
      Validators.minLength(this.userSize.LOGIN_MIN_SIZE),
      Validators.maxLength(this.userSize.LOGIN_MAX_SIZE)
    ]
  ];

  readonly PASSWORD = [
    null,
    [
      Validators.required,
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\'#$%&\'()*+,-./:;<=>?@^_{|}~]).{1,}'),
      Validators.minLength(this.userSize.PASSWORD_MIN_SIZE),
      Validators.maxLength(this.userSize.PASSWORD_MAX_SIZE)
    ]
  ];

  readonly PASSWORD_UPDATE_USER = [
    null,
    [
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\'#$%&\'()*+,-./:;<=>?@^_{|}~]).{1,}'),
      Validators.minLength(this.userSize.PASSWORD_MIN_SIZE),
      Validators.maxLength(this.userSize.PASSWORD_MAX_SIZE)
    ]
  ];

  readonly NOM = [
    null,
    [
      Validators.required,
      Validators.minLength(this.userSize.NOM_MIN_SIZE),
      Validators.maxLength(this.userSize.NOM_MAX_SIZE)
    ]
  ];

  readonly PRENOM = [
    null,
    [
      Validators.required,
      Validators.minLength(this.userSize.PRENOM_MIN_SIZE),
      Validators.maxLength(this.userSize.PRENOM_MAX_SIZE)
    ]
  ];

  readonly DATE_NAISSANCE = [null, [Validators.required]];
  readonly ADRESSE = [
    null,
    [Validators.maxLength(this.userSize.ADRESSE_MAX_SIZE)]
  ];
  readonly SEXE = [null, [Validators.required]];
  readonly TYPE = [null, [Validators.required]];
  readonly ETAT = [null, [Validators.required]];
  readonly TELEPHONE = [
    null,
    [Validators.maxLength(this.userSize.TELEPHONE_MAX_SIZE)]
  ];
  readonly EMAIL = [
    null,
    [Validators.required,
      Validators.maxLength(this.userSize.EMAIL_MAX_SIZE), Validators.email
    ]
  ];
  readonly NATIONALITE = [
    null,
    [Validators.maxLength(this.userSize.NATIONALITE_MAX_SIZE)]
  ];
}
