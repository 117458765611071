import {Injectable} from '@angular/core';
import {AcceptAction, Action, IAction, PrivilegeType} from '../models/action.model';
import {Boutique} from '../models/boutique.model';

/**
 * Fournit plusieurs fonctionnalités de manipulation des actions.
 */
@Injectable({providedIn: 'root'})
export class ActionManageService {

  /**
   * Permet d'affecter un privilège en fonction du type.
   * @param action le privilège
   * @param priv le type de privilège.
   * @param value la valeur à affecter.
   */
  affect(action: IAction, priv: PrivilegeType, value: AcceptAction) {
    switch (priv) {
      case PrivilegeType.AJOUTER:
        action.ajouter = value;
        break;
      case PrivilegeType.MODIFIER:
        action.modifier = value;
        break;
      case PrivilegeType.SUPPRIMER:
        action.supprimer = value;
        break;
      case PrivilegeType.CONSULTER:
        action.consulter = value;
        break;
      case PrivilegeType.RECHERCHER:
        action.rechercher = value;
        break;
      case PrivilegeType.TELECHARGER:
        action.telecharger = value;
        break;
      case PrivilegeType.IMPRIMER:
        action.imprimer = value;
        break;
    }
  }


  /**
   * copy toutes les données de actionsFromServ vers actions.
   * @param actions les actions de l'utilisateur à mettre jour.
   * @param actionsFromServ les actions de l'utilisateur du serveur.
   * @returns IAction[] les privilèges copiés.
   */
  getCopyByBoutique(from: IAction[], boutique: Boutique): IAction[] {
    const to: IAction[] = [];
    console.log('ici1: ');
    if (from && boutique) {
      for (const actionFrom of from) {
        if (actionFrom.boutique && actionFrom.boutique.id ===
          boutique.id) {
          const actionTo = new Action();
          actionTo.id = actionFrom.id;
          actionTo.gestion = actionFrom.gestion;
          actionTo.ajouter = actionFrom.ajouter;
          actionTo.modifier = actionFrom.modifier;
          actionTo.supprimer = actionFrom.supprimer;
          actionTo.consulter = actionFrom.consulter;
          actionTo.rechercher = actionFrom.rechercher;
          actionTo.telecharger = actionFrom.telecharger;
          actionTo.imprimer = actionFrom.imprimer;
          actionTo.utilisateur = actionFrom.utilisateur;
          actionTo.dateModification = actionFrom.dateModification;
          actionTo.dateEnregistrement = actionFrom.dateEnregistrement;
          to.push(actionTo);
        }
      }
    }
    return to;
  }

  /**
   * copy toutes les données de actionsFromServ vers actions.
   * @param actions les actions de l'utilisateur à mettre jour.
   * @param actionsFromServ les actions de l'utilisateur du serveur.
   * @returns IAction[] les privilèges copiés.
   */
  getCopy(from: IAction[]): IAction[] {
    const to: IAction[] = [];
    if (from) {
      for (const actionFrom of from) {
        const actionTo = new Action();
        actionTo.id = actionFrom.id;
        actionTo.gestion = actionFrom.gestion;
        actionTo.ajouter = actionFrom.ajouter;
        actionTo.modifier = actionFrom.modifier;
        actionTo.supprimer = actionFrom.supprimer;
        actionTo.consulter = actionFrom.consulter;
        actionTo.rechercher = actionFrom.rechercher;
        actionTo.utilisateur = actionFrom.utilisateur;
        actionTo.dateModification = actionFrom.dateModification;
        actionTo.dateEnregistrement = actionFrom.dateEnregistrement;
        to.push(actionTo);
      }
    }
    return to;
  }

  /**
   * Affecte tous les id de actionsFromServ vers actionsDefault.
   * @param actionsDefault les actions de l'utilisateur à mettre jour.
   * @param actionsFromServ les actions de l'utilisateur du serveur.
   */
  affectId(actionsDefault: IAction[], actionsFromServ: IAction[]) {
    if (actionsDefault && actionsFromServ) {
      for (const actionDf of actionsDefault) {
        for (const actionServ of actionsFromServ) {
          if (actionDf.gestion === actionServ.gestion) {
            actionDf.id = actionServ.id;
            actionDf.utilisateur.id = actionServ.utilisateur.id;
            break;
          }
        }
      }
    }
  }
}
