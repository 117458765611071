// for developpement.
import {environment} from '../../../../../environments/environment.prod';

export const SERVER_API_URL = environment.apiUrl;

// for production.
// export const SERVER_API_URL = 'http://127.0.0.1:8080/api';

// temps en milliseconde
export const TIME_WAIT_MAX_ATTEMPT = 20000;
export const TIME_WAIT_REFRESH = 1000;

export const JWT = {
  // JWT authentification.
  auth: 'auth',
  accueil: 'accueil',
  anonyme: 'anonyme',
  default_name: 'default',
  username: 'username',
  usernameAdmin: 'username admin',
  bearer: 'Bearer ',
  token: 'token',
  tokenAdmin: 'tokenAdmin'
};
