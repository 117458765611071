import {Component, OnDestroy, OnInit} from '@angular/core';
import {BoutiqueService} from '../boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {AccountUser} from '../../../shared/models/account.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Boutique} from '../../../shared/models/boutique.model';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-boutique-show',
  templateUrl: './boutique-show.component.html'
})
export class BoutiqueShowComponent implements OnInit, OnDestroy {
  constructor(
    private formService: FormService,
    private freeMemoryService: FreeMemoryService,
    private dateFormatService: DateFormatService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
  }

  shouldIShow = false;
  boutique: Boutique;
  accountUser: AccountUser;
  open = true;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init boutique show');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
    });
    this.boutiqueService.getBoutiqueCheckedDataObs().pipe(
      takeUntil(this.subs$)).subscribe(res => {
      this.boutique = res;
    });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  formatId(id: string) {
    return this.formService.formatId(id);
  }

  date() {
    return this.dateFormatService;
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }
}
