import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {IPaginateService} from '../../layouts/pagination/service/ipaginate.service';
import {takeUntil} from 'rxjs/operators';
import {PageCs} from '../../constant/model/page.constant';

/**
 *  Permet d'afficher les informations sur les données des utilisateurs
 *  récupérés.
 *  NB: Tout service nécessitant la récupération des utilisateurs afin d'afficher
 *  le résultat devra utiliser cette classe.
 */
@Injectable({providedIn: 'root'})
export class DataPageRequest {

  constructor() {
  }

  private dataListObs = new BehaviorSubject<any[]>(undefined);
  private totalPagesObs = new BehaviorSubject<number>(0);
  private nbItemsObs = new BehaviorSubject<number>(0);

  getDataListObs(): Observable<any[]> {
    return this.dataListObs.asObservable();
  }

  getTotalPagesObs(): Observable<number> {
    return this.totalPagesObs.asObservable();
  }

  getNbItemsObs(): Observable<number> {
    return this.nbItemsObs.asObservable();
  }

  /**
   * Permet d'initialiser les données du tableau
   * à afficher. NB: doit être appelé à chaque initialisation
   * du component.
   * @param i ..
   * @param subs$ ..
   */
  initDataPage(i: IPaginateService, subs$: Subject<void>) {
    if (i) {
      this.getDataListObs().pipe(takeUntil(subs$))
        .subscribe((data: any[]) => {
          i.setData(data);
        });
      this.getTotalPagesObs().pipe(takeUntil(subs$))
        .subscribe((t: number) => {
          i.setTotalPages(t);
        });
      this.getNbItemsObs().pipe(takeUntil(subs$))
        .subscribe((n: number) => {
          i.setNbItems(n);
        });
    }
  }

  getDataPage(res: any) {
    if (res) {
      this.dataListObs.next(res[PageCs.DATA_CONTENT]);
      this.totalPagesObs.next(res[PageCs.TOTAL_PAGES]);
      this.nbItemsObs.next(res[PageCs.TOTAL_ITEMS]);
    }
  }

}
