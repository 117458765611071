import {Moment} from 'moment';
import {IArticleInfos} from './article-infos.model';
import {IUtilisateur} from './utilisateur.model';
import {IBoutique} from './boutique.model';

export interface IArticle {
  id?: number;
  quantiteEnregistre?: number;
  montant?: number;
  isDelete?: string;
  dateEnregistrement?: Moment;
  dateModification?: Moment;
  utilisateur?: IUtilisateur;
  articleInfos?: IArticleInfos;
  boutique?: IBoutique;
  savedDate?: string;
}

export class Article implements IArticle {
  constructor(
    public id?: number,
    public quantiteEnregistre?: number,
    public montant?: number,
    public isDelete?: string,
    public dateEnregistrement?: Moment,
    public dateModification?: Moment,
    public utilisateur?: IUtilisateur,
    public articleInfos?: IArticleInfos,
    public boutique?: IBoutique,
    public savedDate?: string
  ) {
  }
}
