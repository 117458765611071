import {Injectable} from '@angular/core';

/**
 * Permet de partager les données dans le module utilisateur.
 */
@Injectable({providedIn: 'root'})
export class SortieDataShare {
  constructor() {
  }

  montantTotal = 0;
  montantTotalAchat = 0;
  beneficeTotal = 0;
  spinnerTable = false;
  dateDebut = '';
  dateFin = '';
}
