import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {AccountUser} from '../../models/account.model';

/**
 * Permet de gérer {@link AccountUser} le compte de l'utilisateur connecté.
 */
@Injectable({providedIn: 'root'})
export class ActivatedRouteService {
  constructor() {
  }

  // VAR.
  private activatedRouteObs = new BehaviorSubject<ActivatedRoute>(undefined);

  getActivatedRouteObs(): Observable<ActivatedRoute> {
    return this.activatedRouteObs.asObservable();
  }

  setActivatedRouteObs(activatedRoute: ActivatedRoute) {
    this.activatedRouteObs.next(activatedRoute);
  }

}
