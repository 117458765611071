import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {ArticleInfos, IArticleInfos} from '../../../shared/models/article-infos.model';
import {Article, IArticle} from '../../../shared/models/article.model';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {NRestricted} from '../../../shared/constant/restricted.msg';
import {RGlobal} from '../../../shared/constant/global.url';
import {PageRestrictedService} from '../../../shared/layouts/restricted/page-restricted.service';
import {Router} from '@angular/router';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleService} from '../article.service';
import {ArticleInfosService} from '../../article-infos/article-infos.service';
import {Uri} from '../../../shared/constant/model/global.constant';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {RestoreArticleService} from '../restore-article.service';
import {FormService} from '../../../shared/ services/form.service';
import {DataShareWhenCheckedArticle} from '../data-share-when-checked-article.service';
import {ArticleCreateForInit, ArticleCreateInit} from '../article-create/article-create.init';

@Component({
  selector: 'app-article-update',
  templateUrl: './article-update.component.html'
})
export class ArticleUpdateComponent implements OnInit, OnDestroy {

  constructor(
    private boutiqueService: BoutiqueService,
    private articleInfosService: ArticleInfosService,
    private articleService: ArticleService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
    private pageRestrictedService: PageRestrictedService,
    private restoreArtInfosService: RestoreArticleService,
    private requestService: RequestService,
    private userInit: ArticleCreateInit,
    private router: Router,
    private errorServ: ConstantErrorServ,
    private restoreArticleService: RestoreArticleService,
    private dataShare: DataShareWhenCheckedArticle,
    private formUserUpdate: FormBuilder,
    private catArtUpdateInit: ArticleCreateInit,
    private formService: FormService) {
    this.catArtColName = this.userInit.getCategorieArticleColumnName();
  }

  // VAR.
  savedDate;
  checkedDate = true;

  isLoading = true;
  open = true;
  isShow = false;
  articleChecked: IArticle;
  boutiqueSelected: Boutique;
  activatedValidationButton = true;
  hasQtEnregistreError = false;
  needToCreateCatArt = false;
  filteredOptions: Observable<IArticleInfos[]>;
  catArtList: IArticleInfos[];
  catArtUpdateDTO: IArticle;
  catArtColName: ArticleCreateForInit.ColumnName;
  editForm = this.formUserUpdate
    .group(this.catArtUpdateInit.getForm());
  // Ne pas supprimé, utilisé côté Html.
  artInfosColName = this.catArtUpdateInit.getCategorieArticleColumnName();
  subs$ = new Subject<void>(); // VAR END.

  /**
   * Permet de remplir le formulaire avec les données de l'utilisateur
   * à mettre à jour.
   * NB: le mot de passe n'est pas rempli pour des mesures de sécurité.
   */
  ngOnInit() {
    console.log('** on init article update');
    this.popupReusableService.getPromise().then(res => {
      this.isShow = true;
    });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getAllCatArt();
    });
    this.dataShare.getArticlecheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: IArticle) => {
        if (data) {
          this.restoreArtInfosService.setArticleChecked(data);
          this.articleChecked = new Article();
          this.articleChecked.id = data.id;
          this.articleChecked.boutique = data.boutique;
          this.articleChecked.montant = data.montant;
          this.articleChecked.dateEnregistrement = data.dateEnregistrement;
          this.articleChecked.dateModification = data.dateModification;
          this.articleChecked.quantiteEnregistre = data.quantiteEnregistre;
          this.articleChecked.utilisateur = data.utilisateur;
          this.articleChecked.isDelete = data.isDelete;
          this.articleChecked.articleInfos = data.articleInfos;
          console.log('data checked id art infos: ' + JSON.stringify(data.articleInfos.id));
          this.editForm.patchValue({
            [this.catArtColName.quantiteEnregistre]: data.quantiteEnregistre,
            [this.catArtColName.idArticleInfos]: data.articleInfos,
          });
        }
      });
  }

  /**
   * Permet de réinitialiser certaines variable au changement
   * de route.
   */
  ngOnDestroy(): void {
    console.log('article update is destroyed');
    this.restore();
  }

  initDate() {
    this.savedDate = undefined;
  }

  onSelectionChange(event) {
    console.log('event');
    if (event && this.getArtChecked() && this.articleChecked) {
      this.articleChecked.articleInfos = event.option.value;
      /* const art: IArticle = this.getArtChecked();
       art.articleInfos.id = event.option.value.id;*/
    }
    /*if (artInfos && this.getArtChecked()) {
      const iArt: IArticle = this.getArtChecked();
      iArt.articleInfos = artInfos;
    }*/
  }

  displayFn(subject) {
    return subject ? subject.designation : undefined;
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  compareFn(obj1, obj2): boolean {
    return obj1 && obj2 && (obj1.id === obj2.id);
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.catArtUpdateInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  getArtChecked(): IArticle {
    return this.getCatArtChecked();
  }

  getArticleChecked(artInfos: IArticleInfos) {
    if (artInfos && this.getArtChecked()) {
      const iArt: IArticle = this.getArtChecked();
      iArt.articleInfos = artInfos;
    }
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   */
  isEmailInvalid(field: string): boolean {
    return this.formService.isEmailInvalid(field, this.editForm);
  }

  /**
   * Permet d'indiquer si le formulaire de modification contient des erreurs.
   * @returns true si le formulaire contient des erreurs, false sinon.
   */
  validationHasError() {
    return this.editForm.invalid || !this.activatedValidationButton;
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalidButNotRequired(field: string) {
    return this.formService.isInvalidAndDirty(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'maxlength'.
   */
  hasErrorMaxLength(field: string): boolean {
    return this.formService.hasErrorMaxLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  getFilteredOptions(): Observable<IArticleInfos[]> {
    return this.filteredOptions;
  }

  filterOption() {
    this.filteredOptions = this.editForm.get('id_article_infos').valueChanges.pipe(
      startWith(''),
      map(((value: any) => {
          if (value) {
            if (typeof value === 'object') {
              return this._filter(value.designation);
            } else {
              // value is a string.
              return this._filter(value);
            }
          }
          return this._filter('');
        })
      ));
  }

  _filter(value: string): IArticleInfos[] {
    const filterValue = value.toLocaleLowerCase();
    return this.getCatArtList().filter(option =>
      option.designation.toLowerCase().includes(filterValue));
  }

  shouldIShow(): boolean {
    return this.getCatArtList() && this.isShow && this.getCatArtChecked()
      && this.getCatArtList().length !== 0;
  }

  getCatArtList(): IArticleInfos[] {
    return this.catArtList;
  }

  doINeedToCreateCatArt(): boolean {
    return this.needToCreateCatArt;
  }

  /**
   * @returns l'utilisateur coché pour la modification de ses informations.
   */
  getCatArtChecked(): IArticle {
    return this.restoreArtInfosService.getArticleChecked();
  }

  removeMsgError() {
    this.hasQtEnregistreError = false;
  }

  /**
   * Permet de réinitialiser certaines variable au changement
   * de route.
   */
  restore() {
    this.editForm.reset();
    this.removeMsgError();
    this.restoreArtInfosService.restore(this.subs$);
  }

  getAllCatArt() {
    this.needToCreateCatArt = false;
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.articleInfosService.getAll(option);
    result.subscribe(
      (r: HttpResponse<IArticleInfos[]>) => {
        console.log('** succès récupération article infos');
        this.isLoading = false;
        const data = r.body;
        this.catArtList = data;
        this.filterOption();
        if (!data || data.length === 0) {
          this.needToCreateCatArt = true;
        }
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération article infos');
        this.isLoading = false;
        this.pageRestrictedService.goToRestrictedAccess(NRestricted.msgFull.ARTICLE_INFOS.LIST);
      }
    );
  }

  /**
   * Permet de copier les informations de l'utilisateurs à mettre
   * à jour. Il s'agit des informations du formulaire.
   *
   */
  copy(catArt: IArticle): IArticle {
    const newUser = new Article();
    newUser.id = catArt.id;
    newUser.quantiteEnregistre = catArt.quantiteEnregistre;
    // CREATE CATEGORIE.
    const cat = new ArticleInfos();
    cat.id = catArt.articleInfos.id;
    // AFFECT CATEGORIE
    newUser.articleInfos = cat;
    return newUser;
  }

  /**
   * récupère les données de l'utilisateur du formulaire.
   */
  getUserFromForm(editForm: FormGroup): IArticle {
    return this.userInit.getCategorieFromForm(editForm);
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit avec le formulaire en tapant au moins un caractère.
   */
  reset(editForm: FormGroup) {
    editForm.get(this.catArtColName.quantiteEnregistre).markAsPristine();
  }

  getQtEnregistreError(): boolean {
    return this.hasQtEnregistreError;
  }

  /**
   * Met à jour un utilisateur.
   * @param user l'utilisateur à mettre à jour.
   */
  update() {
    this.activatedValidationButton = false;
    console.log('** updating article');
    this.resetFormValue(this.editForm);
    const userDataForm: IArticle = this.getUserFromForm(this.editForm);
    this.catArtUpdateDTO = this.copy(userDataForm);
    this.catArtUpdateDTO.id = this.restoreArtInfosService.getArticleChecked().id;
    this.catArtUpdateDTO.boutique = this.boutiqueSelected;
    this.catArtUpdateDTO.savedDate = this.savedDate;
    const result = this.articleService.update(this.catArtUpdateDTO);
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Réinitialise certaines données du formulaire.
   * @param form le formulaire.
   */
  resetFormValue(form: FormGroup) {
    this.hasQtEnregistreError = false;
    this.reset(form);
  }

  /**
   * Gère les erreurs lors de la modification d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromUpdatingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    this.checkForNegativeValueServ(erreur);
  }

  checkForNegativeValueServ(erreur: any) {
    if (erreur.errorCode === this.errorServ.NEGATIVE_VALUE) {
      const fields = erreur.fields;
      this.hasQtEnregistreError = fields[this.catArtColName.quantiteEnregistre]
        ? true
        : false;
    }
  }

  /**
   * Permet récupérer la réponse lors de la modification
   * d'un {@link Utilisateur}
   *
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        const data = res.body;
        if (data) {
          console.log('**** success update article: ');
          this.requestService.success(NRequest.ERequest.UPDATE, form,
            RGlobal.urlFull.ARTICLE.LIST, this.subs$);
          this.popupReusableService.dismiss(this.activeModal);
        }
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error update article: ');
        this.activatedValidationButton = true;
        try {
          this.manageErrorFromUpdatingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

}
