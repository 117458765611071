import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

/**
 * Permet la validation des champs pour se connecter.
 */
@Injectable({ providedIn: 'root' })
export class ConstantSignInValidator {
  constructor() {}

  readonly LOGIN = [null, [Validators.required]];

  readonly PASSWORD = [null, [Validators.required]];
}
