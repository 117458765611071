import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../../shared/constant/model/server.constant';
import {
  API_CAT_ARTICLE,
  API_CAT_ARTICLE_ALL,
  API_CAT_ARTICLE_DELETE,
  API_CAT_ARTICLE_PAGES,
  API_CAT_ARTICLE_SEARCH
} from '../../shared/constant/model/api.constant';
import {Observable} from 'rxjs';
import {CategorieArticle, ICategorieArticle} from '../../shared/models/categorie-article.model';

@Injectable({providedIn: 'root'})
export class CatArtService {
  constructor(
    private http: HttpClient,
  ) {
    console.log('construct cat art service');

  }

  // VAR.
  private resourceUrlSearch = SERVER_API_URL + API_CAT_ARTICLE_SEARCH;
  private resourceUrlAll = SERVER_API_URL + API_CAT_ARTICLE_ALL;
  private resourceUrl = SERVER_API_URL + API_CAT_ARTICLE;
  private resourceUrlPages = SERVER_API_URL + API_CAT_ARTICLE_PAGES;
  private resourceUrlDelete = SERVER_API_URL + API_CAT_ARTICLE_DELETE;

  getAll(params): Observable<HttpResponse<CategorieArticle[]>> {
    return this.http.get<CategorieArticle[]>(this.resourceUrlAll, {
      observe: 'response',
      params
    });
  }

  search(params): Observable<HttpResponse<CategorieArticle[]>> {
    return this.http.get<CategorieArticle[]>(this.resourceUrlSearch, {
      observe: 'response',
      params
    });
  }

  add(data): Observable<HttpResponse<ICategorieArticle>> {
    return this.http.post<ICategorieArticle>(this.resourceUrl, data, {
      observe: 'response',
    });
  }

  update(data): Observable<HttpResponse<ICategorieArticle>> {
    return this.http.put<ICategorieArticle>(this.resourceUrl, data, {
      observe: 'response',
    });
  }

  delete(params): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>(this.resourceUrlDelete, {
      observe: 'response',
      params
    });
  }

  getList(params): Observable<HttpResponse<CategorieArticle[]>> {
    return this.http.get<CategorieArticle[]>(this.resourceUrlPages, {
      observe: 'response',
      params
    });
  }

}
