/**
 * Permet créer des objets permettant d'effectuer des vérifications
 * lors de la recherche par filtre.
 */
import {SearchCatArtVerification} from './search-cat-art-verification';
import {AbsFactoryCatArtVerification, ETypeCatArtVerification} from './abs-factory-cat-art-verification';

export class FactoryCreateCatArtVerification {
  static getCheck(v: ETypeCatArtVerification): AbsFactoryCatArtVerification {
    if (v === ETypeCatArtVerification.SEARCH) {
      return new SearchCatArtVerification();
    }
    return new SearchCatArtVerification();
  }
}
