import {AbsVerification} from './abs-verification';
import {ConstantUserColumnName} from '../../../../shared/constant/model/column-name/user-column-name.constant';

/**
 * Permet de vérifier si l'adresse fait partir des filtres de recherche
 * et de l'ajouter dans les paramètres dans ce cas.
 */
export class AdresseVerification extends AbsVerification {
  constructor() {
    super();
  }

  private adresse: string;
  private userColName: ConstantUserColumnName;

  adresseSet(adresse: string): AdresseVerification {
    this.adresse = adresse;
    return this;
  }

  userColNameSet(userColName: ConstantUserColumnName): AdresseVerification {
    this.userColName = userColName;
    return this;
  }


  /**
   * Vérifie si l'adresse fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  check(first: AbsVerification) {
    if (this.adresse) {
      first.getDataSearch()[this.userColName.ADRESSE] = this.adresse;
    }
    super.checkNext(first);
  }
}
