import {Injectable} from '@angular/core';
import {RGlobal, slashRoute} from '../../constant/global.url';

/**
 * Permet d'initialiser le component {@link LoginComponent}
 */
@Injectable({providedIn: 'root'})
export class NavInit {
  constructor() {
  }

  // EMPLOYE.
  readonly EMPLOYE_LIST_URL = slashRoute + RGlobal.urlFull.EMPLOYE.LIST;

  // USER.
  readonly USER_CREATE_URL = slashRoute + RGlobal.urlFull.USER.CREATE;
  readonly USER_LIST_URL = slashRoute + RGlobal.urlFull.USER.LIST;

  // CATEGORIE ARTICLE.
  readonly CAT_ART_LIST_URL = slashRoute + RGlobal.urlFull.CATEGORIE_ARTICLE.LIST;
  readonly CAT_ART_CREATE_URL = slashRoute + RGlobal.urlFull.CATEGORIE_ARTICLE.CREATE;

  // ARTICLE INFOS.
  readonly ART_INFOS_LIST_URL = slashRoute + RGlobal.urlFull.ARTICLE_INFOS.LIST;
  readonly ART_INFOS_CREATE_URL = slashRoute + RGlobal.urlFull.ARTICLE_INFOS.CREATE;
  readonly ARTICLE_LIST_URL = slashRoute + RGlobal.urlFull.ARTICLE.LIST;

  // SORTIE.
  readonly SORTIE_LIST_URL = slashRoute + RGlobal.urlFull.SORTIE.LIST;
  readonly SORTIE_CREATE_URL = slashRoute + RGlobal.urlFull.SORTIE.CREATE;

}
