import {Component, OnDestroy, OnInit} from '@angular/core';
import {BoutiqueService} from '../boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {FormBuilder} from '@angular/forms';
import {BoutiqueCreateInit, BoutiqueCreateNm} from './boutique-create.init';
import {FormService} from '../../../shared/ services/form.service';
import {takeUntil} from 'rxjs/operators';
import {AccountUser} from '../../../shared/models/account.model';
import {Observable, Subject} from 'rxjs';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {ConstantBoutiqueColumnName} from '../../../shared/constant/model/column-name/boutique-column-name.constant';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {AbonnementNombreBoutiqueComponent} from '../../abonnement/abonnement-nombre-boutique/abonnement-nombre-boutique.component';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-boutique-create',
  templateUrl: './boutique-create.component.html'
})
export class BoutiqueCreateComponent implements OnInit, OnDestroy {
  constructor(
    private freeMemoryService: FreeMemoryService,
    private columnNameBoutique: ConstantBoutiqueColumnName,
    private errorServ: ConstantErrorServ,
    private requestService: RequestService,
    private formService: FormService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private boutiqueCreateInit: BoutiqueCreateInit,
    private formSignIn: FormBuilder,
    private popupReusableService: PopupReusableService,
  ) {
    this.signInColName = this.boutiqueCreateInit.getAuthColumnName();
  }

  isLoading = false;
  hasAbonnementError = false;
  accountUser: AccountUser;
  shouldIShow = false;
  open = true;
  hasNomError = false;
  signInColName: BoutiqueCreateNm.ColumnName;
  editForm = this.formSignIn.group(this.boutiqueCreateInit.getForm());
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init create boutique');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
    });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }


  save() {
    console.log('create boutique');
    this.isLoading = true;
    this.reset();
    this.editForm.get(this.signInColName.nom).markAsPristine();
    const boutique = this.boutiqueCreateInit.getAuthFromForm(this.editForm);
    console.log('nom: ' + boutique.nom);
    const result = this.boutiqueService.create(boutique);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromSavingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    console.log('err: ' + JSON.stringify(erreur));
    const fields = erreur.fields;
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      console.log('nom exist');
      this.hasNomError = fields.nom ? true : false;
    }
    if (erreur.errorCode === this.errorServ.BAD_REQUEST) {
      this.hasAbonnementError = fields[this.columnNameBoutique.NOMBRE_AUTORISE] ? true : false;
      if (this.hasAbonnementError) {
        this.popupReusableService.open(null, AbonnementNombreBoutiqueComponent);
      }
    }
    //  this.hasPasswordError = fields.jhi_password ? true : false;
  }

  reset() {
    this.hasNomError = false;
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<Boutique>>
  ) {
    result.subscribe(
      (res: HttpResponse<Boutique>) => {
        console.log('**** success create boutique: ');
        this.isLoading = false;
        this.accountService.forceIdentity();
        this.reset();
        // this.validationHasSucceed = true;
        this.requestService.successWithoutPath(NRequest.ERequest.CREATE,
          this.subs$);
        this.popupReusableService.dismiss(this.activeModal);
        this.boutiqueService.getListEvent().emit('list');
      },
      (err: HttpErrorResponse) => {
        console.log('**** error create boutique: ');
        this.isLoading = false;
        // this.validationHasSucceed = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        try {
          this.manageErrorFromSavingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   */
  isEmailInvalid(field: string): boolean {
    return this.formService.isEmailInvalid(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

}
