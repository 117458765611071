export enum ToasterPosition {
  TOP_RIGHT = 'toast-top-right',
  TOP_LEFT = 'toast-top-left',
  BOTTOM_RIGHT = 'toast-bottom-right',
  BOTTOM_LEFT = 'toast-bottom-left',
  TOP_CENTER = 'toast-top-center',
}

//
export namespace NotificationCs {
  export const TIMEOUT = 60000;
  export const DEFAULT_POSITION = 'toast-top-center';
  export const IS_DUPLICATE = false;
}
