import {Injectable} from '@angular/core';
import {IRestoreWhenDisconnectService} from './irestore-when-disconnect.service';

/**
 * Permet de réinitialiser les données lors
 * de la déconnexion.
 */
@Injectable({providedIn: 'root'})
export class RestoreWhenDisconnectService {
  constructor() {
  }

  private iRestore: IRestoreWhenDisconnectService[] = [];

  add(i: IRestoreWhenDisconnectService) {
    this.iRestore.push(i);
  }

  restoreAll() {
    for (const i of this.iRestore) {
      if (i) {
        i.clear();
      }
    }
  }
}
