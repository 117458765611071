import {HttpHandler, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../../auth.service';
import {Router} from '@angular/router';
import {ManageService} from '../../../manage.service';
import {RGlobalAdmin} from '../../../../constant/global.url.admin';

/**
 * Permet de traiter une partie des erreurs liées à l'authentification
 * des utilisateurs.
 */
@Injectable({providedIn: 'root'})
export class ErrorInterceptorImpl {
  constructor(
    private manageService: ManageService,
    private router: Router,
    private authService: AuthService) {
  }

  /**
   * Permet de traiter une partie des erreurs liées à l'authentification
   * des utilisateurs. Par exemple lors de l'envoie d'une requête si
   * le serveur, il y a une erreur liée à l'authentification, on déconnecte
   * l'utilisateur et le redirige vers la page d'accueil.
   *
   * @param request request.
   * @param next next.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    console.log('intercept error');
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          console.log('erreur 401');
          if (this.manageService.getFirstOfUrl(this.router) === RGlobalAdmin.url.LOGIN) {
            this.authService.logoutAdminAndRedirectToLogin();
          } else {
            this.authService.logoutAndRedirectToLogin();
          }
        }
        return next.handle(request);
      })
    );
  }
}
