import {Moment} from 'moment';
import {IUtilisateur} from './utilisateur.model';
import {ICategorieArticle} from './categorie-article.model';
import {IBoutique} from './boutique.model';

export interface IArticleInfos {
  id?: number;
  designation?: string;
  code?: string;
  description?: string;
  quantiteAlerte?: number;
  quantiteDisponible?: number;
  prixUnitaire?: number;
  prixUnitaireVente?: number;
  montant?: number;
  isDelete?: string;
  dateEnregistrement?: Moment;
  dateModification?: Moment;
  utilisateur?: IUtilisateur;
  categorieArticle?: ICategorieArticle;
  boutique?: IBoutique;
}

export class ArticleInfos implements IArticleInfos {
  constructor(
    public id?: number,
    public designation?: string,
    public code?: string,
    public description?: string,
    public quantiteAlerte?: number,
    public quantiteDisponible?: number,
    public prixUnitaire?: number,
    public prixUnitaireVente?: number,
    public montant?: number,
    public isDelete?: string,
    public dateEnregistrement?: Moment,
    public dateModification?: Moment,
    public utilisateur?: IUtilisateur,
    public categorieArticle?: ICategorieArticle,
    public boutique?: IBoutique
  ) {
  }
}
