import {Component, OnDestroy, OnInit} from '@angular/core';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {BoutiqueService} from '../boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {AccountUser} from '../../../shared/models/account.model';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-boutique-delete',
  templateUrl: './boutique-delete.component.html'
})
export class BoutiqueDeleteComponent implements OnInit, OnDestroy {
  constructor(
    private requestService: RequestService,
    private freeMemoryService: FreeMemoryService,
    private dateFormatService: DateFormatService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
  }

  shouldIShow = false;
  boutique: Boutique;
  accountUser: AccountUser;
  open = true;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init boutique delete');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      this.shouldIShow = true;
    });
    this.boutiqueService.getBoutiqueCheckedDataObs().pipe(
      takeUntil(this.subs$)).subscribe(res => {
      this.boutique = res;
    });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  delete() {
    console.log('delete boutique');
    const result = this.boutiqueService.delete(this.boutique.id.toString());
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        const value = res.body;
        console.log('value: ' + value);
        if (value) {
          console.log('**** success delete boutique: ');
          this.requestService.successWithoutPath(NRequest.ERequest.DELETE,
            this.subs$);
          this.popupReusableService.dismiss(this.activeModal);
          this.boutiqueService.getListEvent().emit('list');
        } else {
          console.log('**** error delete boutique: ');
        }
      },
      (err: HttpErrorResponse) => {
        console.log('**** error delete boutique: ');
        // this.validationHasSucceed = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
      }
    );
  }

  date() {
    return this.dateFormatService;
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }
}
