import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AccountUser} from '../../../shared/models/account.model';
import {BoutiqueService} from '../boutique.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueShowComponent} from '../boutique-show/boutique-show.component';
import {BoutiqueCreateComponent} from '../boutique-create/boutique-create.component';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {BoutiqueUpdateComponent} from '../boutique-update/boutique-update.component';
import {BoutiqueDeleteComponent} from '../boutique-delete/boutique-delete.component';
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';
import {EmployeService} from '../../employe/employe.service';
import {Router} from '@angular/router';
import {PaginationService} from '../../../shared/layouts/pagination/service/pagination.service';
import {FormService} from '../../../shared/ services/form.service';

export interface BoutiqueListData {
  isSelected: boolean;
}

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-boutique-liste',
  templateUrl: './boutique-liste.component.html'
})
export class BoutiqueListeComponent implements OnInit, OnDestroy {
  constructor(
    private formService: FormService,
    private paginationService: PaginationService,
    private router: Router,
    private employeService: EmployeService,
    private freeMemoryService: FreeMemoryService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
  }

  data: BoutiqueListData;
  dataSelected = {
    selected: -1,
    selectedEmploye: -1
  };
  boutiqueChecked: Boutique;
  employeChecked: EmployeBoutique;
  boutiques: Boutique[];
  // boutiquesLength: number;;
  employeBoutiques: EmployeBoutique[];
  shouldIShow = false;
  accountUser: AccountUser;
  open = true;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init boutique liste');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
      this.getList();
      this.getListEmployeBoutique();
    });
    this.boutiqueService.getBoutiqueListDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe(res => {
      this.data = res;
    });
    this.boutiqueService.getListEvent().pipe(takeUntil(this.subs$))
      .subscribe(res => {
        console.log('emitting: ' + res);
        this.initSelected();
        this.boutiqueService.setBoutiqueCheckedDataObs(undefined);
        this.getList();
        this.getListEmployeBoutique();
      });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
    this.boutiqueService.setBoutiqueCheckedDataObs(undefined);
  }

  formatId(id: string) {
    return this.formService.formatId(id);
  }

  /* check(path?: string) {
     if (path && (this.boutiqueChecked ||
       (this.employeChecked && this.employeChecked.boutique))) {
       this.router.navigate([path]);
     } else {
       this.popupReusableService.open(null, this);
     }
   }*/

  initSelected() {
    Object.keys(this.dataSelected).forEach(key => {
      this.dataSelected[key] = -1;
    });
    this.boutiqueChecked = undefined;
    this.employeChecked = undefined;
  }

  addBoutique() {
    console.log('add boutique');
    this.popupReusableService.open(null, BoutiqueCreateComponent);
  }

  showBoutique() {
    console.log('show boutique');
    this.popupReusableService.open(null, BoutiqueShowComponent);
  }

  updateBoutique() {
    console.log('update boutique');
    this.popupReusableService.open(null, BoutiqueUpdateComponent);
  }

  deleteBoutique() {
    console.log('delete boutique');
    this.popupReusableService.open(null, BoutiqueDeleteComponent);
  }

  change(event, i) {
    if (event) {
      if (event.checked) {
        this.initSelected();
        this.dataSelected.selected = i;
        console.log('change');
        this.boutiqueChecked = event.source.value;
        this.boutiqueService.setBoutiqueCheckedDataObs(this.boutiqueChecked);
      } else {
        this.dataSelected.selected = -1;
        this.boutiqueChecked = undefined;
        this.boutiqueService.setBoutiqueCheckedDataObs(undefined);
      }
    }
  }

  changeEmploye(event, i) {
    if (event) {
      if (event.checked) {
        this.initSelected();
        this.dataSelected.selectedEmploye = i;
        console.log('change employe');
        this.employeChecked = event.source.value;
        // this.boutiqueService.setBoutiqueCheckedDataObs(this.boutiqueChecked);
      } else {
        this.dataSelected.selectedEmploye = -1;
        this.employeChecked = undefined;
        // this.boutiqueChecked = undefined;
        // this.boutiqueService.setBoutiqueCheckedDataObs(undefined);
      }
    }
  }

  select() {
    console.log('select');
    const path = this.paginationService.getPath();
    if (this.boutiqueChecked) {
      console.log('nom: ' + this.boutiqueChecked.nom);
      this.selectData();
      if (path) {
        console.log('path b: ' + this.paginationService.getPath());
        this.router.navigate([path]);
      }
      this.boutiqueService.setBoutiqueSelectDataObs(this.boutiqueChecked);
      this.closePopup();
    } else if (this.employeChecked) {
      console.log('nom emp: ' + this.employeChecked.boutique.nom);
      this.selectData();
      if (path) {
        console.log('path b2: ' + this.paginationService.getPath());
        this.router.navigate([path]);
      }
      this.boutiqueService.setBoutiqueSelectDataObs(this.employeChecked.boutique);
      this.closePopup();
    }
  }

  selectData() {
    this.data.isSelected = true;
    this.boutiqueService.setBoutiqueListDataObs(this.data);
  }

  getList() {
    const result = this.boutiqueService.getList();
    this.subscribeToSaveResponse(result);
  }

  getListEmployeBoutique() {
    const result = this.employeService.getListAllAccepted();
    this.subscribeToSaveResponseEmployeBoutique(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseEmployeBoutique(
    result: Observable<HttpResponse<EmployeBoutique[]>>,
  ) {
    result.subscribe(
      (res: HttpResponse<EmployeBoutique[]>) => {
        console.log('**** success list employe boutique: ');
        this.employeBoutiques = res.body;
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error list employe boutique: ');
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<Boutique[]>>,
  ) {
    result.subscribe(
      (res: HttpResponse<Boutique[]>) => {
        console.log('**** success list boutique: ');
        this.boutiques = res.body;
        //   this.boutiquesLength = res.body.length + 1;
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error list boutique: ');
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

}
