import {Injectable} from '@angular/core';

/**
 * Permet d'avoir la taille des champs pour un {@link Utilisateur}
 */
@Injectable({providedIn: 'root'})
export class ConstantUserSize {
  readonly LOGIN_MIN_SIZE = 1;
  readonly LOGIN_MAX_SIZE = 50;

  readonly PASSWORD_MIN_SIZE = 8;
  readonly PASSWORD_MAX_SIZE = 50;

  readonly NOM_MIN_SIZE = 1;
  readonly NOM_MAX_SIZE = 50;

  readonly PRENOM_MIN_SIZE = 1;
  readonly PRENOM_MAX_SIZE = 50;

  readonly ADRESSE_MAX_SIZE = 150;

  readonly TELEPHONE_MAX_SIZE = 50;

  readonly EMAIL_MAX_SIZE = 200;

  readonly NATIONALITE_MAX_SIZE = 100;
}
