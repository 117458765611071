import {IBoutique} from './boutique.model';
import {Moment} from 'moment';
import {IUtilisateur} from './utilisateur.model';

export const enum DemandeAjoutType {
  ACCEPTE, EN_ATTENTE
}

export class EmployeBoutique {
  constructor(
    public id?: number,
    public isAdd?: DemandeAjoutType,
    public isDelete?: string,
    public dateEnregistrement?: Moment,
    public dateModification?: Moment,
    public utilisateur?: IUtilisateur,
    public boutique?: IBoutique,
  ) {
  }

}
