import {Injectable} from '@angular/core';
import {RGlobal, slashRoute} from '../../../shared/constant/global.url';
import {DateFormatService} from '../../../shared/ services/date-format.service';

/**
 * Permet d'initialiser le component {@link CategorieArticleListeComponent}
 */
@Injectable({providedIn: 'root'})
export class ArticleListeInit {

  constructor(
    private date: DateFormatService
  ) {
  }

  // VAR.
  readonly ARTICLE_CREATE_URL = slashRoute + RGlobal.urlFull.ARTICLE.CREATE;
  readonly ARTICLE_UPDATE_URL = slashRoute + RGlobal.urlFull.ARTICLE.UPDATE;

  /**
   * Permet de convertir une date
   */
  getDate() {
    return this.date;
  }
}
