import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {ConstantArticleInfosSize} from '../model/size/article-infos-size.constant';

/**
 * Permet la validation des champs pour une {@link CategorieArticle}.
 */
@Injectable({providedIn: 'root'})
export class ConstantArticleInfosValidator {
  constructor(private categorieSize: ConstantArticleInfosSize) {
  }

  readonly DESIGNATION = [
    null,
    [
      Validators.required,
      Validators.minLength(this.categorieSize.DESIGNATION_MIN_SIZE),
      Validators.maxLength(this.categorieSize.DESIGNATION_MAX_SIZE)
    ]
  ];

  readonly CODE = [
    null,
    [
      Validators.required,
      Validators.minLength(this.categorieSize.CODE_MIN_SIZE),
      Validators.maxLength(this.categorieSize.CODE_MAX_SIZE)
    ]
  ];

  readonly QUANTITE_ALERTE = [
    null,
    [
      Validators.required
    ]
  ];

  readonly DESCRIPTION = [
    null,
    []
  ];

  readonly PRIX_UNITAIRE = [
    null,
    [
      Validators.required
    ]
  ];

  readonly PRIX_UNITAIRE_VENTE = [
    null,
    [
      Validators.required
    ]
  ];

  readonly ID_CATEGORIE_ARTICLE = [
    null,
    [
      Validators.required
    ]
  ];
}
