import {Injectable} from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour un {@link Utilisateur}
 */
@Injectable({providedIn: 'root'})
export class ConstantValidateEmailColumnName {

  readonly ID = 'id';
  readonly CODE = 'code';
  readonly KEY = 'key';
}
