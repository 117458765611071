/**
 * Permet de créer les {@link CategorieArticle}.
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IRestoreWhenDisconnectService} from '../../shared/ services/restore/irestore-when-disconnect.service';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {IUtilisateur} from '../../shared/models/utilisateur.model';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../../shared/constant/model/server.constant';
import {
  API_USER_ACCOUNT_CREATE,
  API_USER_ACCOUNT_CREATE_MARCHAND_ANONYME,
  API_USER_ACCOUNT_DELETE,
  API_USER_ACCOUNT_RESET,
  API_USER_ACCOUNT_UPDATE, API_USER_ACCOUNT_UPDATE_EMAIL,
  API_USER_ACCOUNT_VALIDATE
} from '../../shared/constant/model/api.constant';
import {ValidateEmail} from '../../shared/models/validate-email.model';

@Injectable({providedIn: 'root'})
export class UserAccountService implements IRestoreWhenDisconnectService {
  constructor(
    private http: HttpClient,
    private restore: RestoreWhenDisconnectService,
  ) {
    console.log('construct user account service');
    this.restore.add(this);
  }

  // VAR.
  private resourceUrlDelete = SERVER_API_URL + API_USER_ACCOUNT_DELETE;
  private resourceUrlUpdateEmail = SERVER_API_URL + API_USER_ACCOUNT_UPDATE_EMAIL;
  private resourceUrlUpdate = SERVER_API_URL + API_USER_ACCOUNT_UPDATE;
  private resourceUrlReset = SERVER_API_URL + API_USER_ACCOUNT_RESET;
  private resourceUrlValidate = SERVER_API_URL + API_USER_ACCOUNT_VALIDATE;
  private resourceUrlCreate = SERVER_API_URL + API_USER_ACCOUNT_CREATE;
  private resourceUrlCreateMarchand = SERVER_API_URL + API_USER_ACCOUNT_CREATE_MARCHAND_ANONYME;
  // Permet de récupérer les données pour modifier
  // un compte utilisateur.
  private userUpdateDataObs = new BehaviorSubject<any>(undefined);

  updateEmail(user) {
    return this.http.put<any>(this.resourceUrlUpdateEmail, user, {
      observe: 'response'
    });
  }

  update(user) {
    return this.http.put<any>(this.resourceUrlUpdate, user, {
      observe: 'response'
    });
  }

  resetPassword(user) {
    return this.http.post<any>(this.resourceUrlReset, user, {
      observe: 'response'
    });
  }

  delete(password: string) {
    return this.http.delete<any>(this.resourceUrlDelete + password, {
      observe: 'response'
    });
  }

  validate(user): Observable<HttpResponse<ValidateEmail>> {
    return this.http.post<ValidateEmail>(this.resourceUrlValidate, user, {
      observe: 'response'
    });
  }

  save(user: IUtilisateur): Observable<HttpResponse<IUtilisateur>> {
    return this.http.post<IUtilisateur>(this.resourceUrlCreate, user, {
      observe: 'response'
    });
  }

  saveMarchand(): Observable<any> {
    return this.http.get<any>(this.resourceUrlCreateMarchand, {
      observe: 'response'
    });
  }

  getUserUpdateDataObs(): Observable<any> {
    return this.userUpdateDataObs.asObservable();
  }

  setUserUpdateDataObs(data: any) {
    this.userUpdateDataObs.next(data);
  }

  clear() {
    console.log('clear user account service');
    this.setUserUpdateDataObs(undefined);
  }
}
