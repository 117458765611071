import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {ArticleInfos, IArticleInfos} from '../../../shared/models/article-infos.model';
import {SortieUpdateForInit, SortieUpdateInit} from './sortie-update.init';
import {ISortieDetails, SortieDetails} from '../../../shared/models/sortie-details.model';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {NRestricted} from '../../../shared/constant/restricted.msg';
import {ISortie, Sortie} from '../../../shared/models/sortie.model';
import {RGlobal} from '../../../shared/constant/global.url';
import {PageRestrictedService} from '../../../shared/layouts/restricted/page-restricted.service';
import {Router} from '@angular/router';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {SortieService} from '../sortie.service';
import {ArticleInfosService} from '../../article-infos/article-infos.service';
import {Uri} from '../../../shared/constant/model/global.constant';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {RestoreSortieService} from '../restore-sortie.service';
import {FormService} from '../../../shared/ services/form.service';
import {DataShareWhenCheckedSortie} from '../data-share-when-checked-sortie.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sortie-update',
  templateUrl: './sortie-update.component.html'
})
export class SortieUpdateComponent implements OnInit, OnDestroy {

  constructor(
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
    private boutiqueService: BoutiqueService,
    private articleInfosService: ArticleInfosService,
    private sortieService: SortieService,
    private pageRestrictedService: PageRestrictedService,
    private restoreArtInfosService: RestoreSortieService,
    private requestService: RequestService,
    private userInit: SortieUpdateInit,
    private router: Router,
    private errorServ: ConstantErrorServ,
    private restoreArticleService: RestoreSortieService,
    private dataShare: DataShareWhenCheckedSortie,
    private formUserUpdate: FormBuilder,
    private catArtUpdateInit: SortieUpdateInit,
    private formService: FormService) {
    this.catArtColName = this.userInit.getCategorieArticleColumnName();
  }

  // VAR.
  savedDate;
  checkedDate = true;

  isLoading = true;
  sortieChecked: ISortieDetails;
  open = true;
  boutiqueSelected: Boutique;
  loadWhenUpdating = false;
  isBtnActif = true;
  hasQtEnregistreError = false;
  hasQtInsufficientError = false;
  hasPrixUnitaireError = false;
  needToCreateCatArt = false;
  filteredOptions: Observable<IArticleInfos[]>;
  catArtList: IArticleInfos[];
  catArtUpdateDTO: ISortieDetails;
  catArtColName: SortieUpdateForInit.ColumnName;
  editForm = this.formUserUpdate
    .group(this.catArtUpdateInit.getForm());
  artInfosColName = this.catArtUpdateInit.getCategorieArticleColumnName();
  subs$ = new Subject<void>(); // VAR END.

  /**
   * Permet de remplir le formulaire avec les données de l'utilisateur
   * à mettre à jour.
   * NB: le mot de passe n'est pas rempli pour des mesures de sécurité.
   */
  ngOnInit() {
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getAllCatArt();
    });
    this.dataShare.getSortiecheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: ISortieDetails) => {
        if (data) {
          this.sortieChecked = new SortieDetails();
          this.sortieChecked.id = data.id;
          this.sortieChecked.sortie = data.sortie;
          this.sortieChecked.code = data.code;
          this.sortieChecked.quantiteSortie = data.quantiteSortie;
          this.sortieChecked.montant = data.montant;
          this.sortieChecked.articleInfos = data.articleInfos;
          this.sortieChecked.dateModification = data.dateModification;
          this.sortieChecked.isDelete = data.isDelete;
          this.sortieChecked.montantTotal = data.montantTotal;
          this.sortieChecked.montantTotalAchat = data.montantTotalAchat;
          this.sortieChecked.pourcentage = data.pourcentage;
          this.sortieChecked.prixUnitaireVendu = data.prixUnitaireVendu;
          this.sortieChecked.prixUnitaireAchat = data.prixUnitaireAchat;
          this.restoreArtInfosService.setSortieChecked(data);
          this.editForm.patchValue({
            [this.catArtColName.idArticleInfos]: data.articleInfos,
            [this.catArtColName.prixUnitaireVendu]: data.prixUnitaireVendu,
            [this.catArtColName.prixUnitaireAchat]: data.prixUnitaireAchat,
            [this.catArtColName.quantiteSortie]: data.quantiteSortie
          });
        }
      });
  }

  /**
   * Permet de réinitialiser certaines variable au changement
   * de route.
   */
  ngOnDestroy(): void {
    console.log('article update is destroyed');
    this.restore();
  }

  initDate() {
    this.savedDate = undefined;
  }

  compareFn(obj1: IArticleInfos, obj2: IArticleInfos): boolean {
    if (obj1 && obj2) {
      obj2.quantiteDisponible = obj1.quantiteDisponible;
    }
    return obj1 && obj2 && (obj1.id === obj2.id);
  }

  displayFn(subject) {
    return subject ? subject.designation : undefined;
  }

  getArtInfosSelected(event) {
    if (event && event.option && this.sortieChecked) {
      this.sortieChecked.articleInfos = event.option.value;
    }
  }

  getArtInfosChecked(): IArticleInfos {
    if (this.restoreArticleService.getSortieChecked()) {
      return this.restoreArticleService.getSortieChecked().articleInfos;
    }
    return undefined;
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   */
  isEmailInvalid(field: string): boolean {
    return this.formService.isEmailInvalid(field, this.editForm);
  }

  /**
   * Permet d'indiquer si le formulaire de modification contient des erreurs.
   * @returns true si le formulaire contient des erreurs, false sinon.
   */
  validationHasError() {
    return this.editForm.invalid;
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalidButNotRequired(field: string) {
    return this.formService.isInvalidAndDirty(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'maxlength'.
   */
  hasErrorMaxLength(field: string): boolean {
    return this.formService.hasErrorMaxLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  shouldIShow(): boolean {
    return this.getCatArtList() && this.getCatArtChecked()
      && this.getCatArtList().length !== 0;
  }

  getCatArtList(): IArticleInfos[] {
    return this.catArtList;
  }

  doINeedToCreateCatArt(): boolean {
    return this.needToCreateCatArt;
  }

  getPrixUnitaireError(): boolean {
    return this.hasPrixUnitaireError;
  }

  /**
   * @returns l'utilisateur coché pour la modification de ses informations.
   */
  getCatArtChecked(): ISortieDetails {
    return this.restoreArtInfosService.getSortieChecked();
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  removeMsgError() {
    this.hasQtEnregistreError = false;
    this.hasQtInsufficientError = false;
    this.hasPrixUnitaireError = false;
  }

  /**
   * Permet de réinitialiser certaines variable au changement
   * de route.
   */
  restore() {
    this.editForm.reset();
    this.removeMsgError();
    this.restoreArtInfosService.restore(this.subs$);
  }

  getFilteredOptions(): Observable<IArticleInfos[]> {
    return this.filteredOptions;
  }

  filterOption() {
    this.filteredOptions = this.editForm.get('id_article_infos').valueChanges.pipe(
      startWith(''),
      map(((value: any) => {
          if (value) {
            if (typeof value === 'object') {
              return this._filter(value.designation);
            } else {
              // value is a string.
              return this._filter(value);
            }
          }
          return this._filter('');
        })
      ));
  }

  _filter(value: string): IArticleInfos[] {
    const filterValue = value.toLocaleLowerCase();
    return this.getCatArtList().filter(option =>
      option.designation.toLowerCase().includes(filterValue));
  }

  getAllCatArt() {
    this.needToCreateCatArt = false;
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.articleInfosService.getListCalculate(option);
    result.subscribe(
      (r: HttpResponse<IArticleInfos[]>) => {
        console.log('** succès récupération article infos');
        this.isLoading = false;
        const data = r.body;
        this.catArtList = data;
        this.filterOption();
        if (!data || data.length === 0) {
          this.needToCreateCatArt = true;
        }
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération article infos');
        this.isLoading = false;
        this.pageRestrictedService.goToRestrictedAccess(NRestricted.msgFull.ARTICLE_INFOS.LIST);
      }
    );
  }

  /**
   * Permet de copier les informations de l'utilisateurs à mettre
   * à jour. Il s'agit des informations du formulaire.
   */
  copy(catArt: ISortieDetails): ISortieDetails {
    const newUser = new SortieDetails();
    newUser.id = catArt.id;
    newUser.quantiteSortie = catArt.quantiteSortie;
    newUser.prixUnitaireVendu = catArt.prixUnitaireVendu;
    newUser.prixUnitaireAchat = catArt.prixUnitaireAchat;
    // CREATE CATEGORIE.
    const cat = new ArticleInfos();
    cat.id = catArt.articleInfos.id;
    newUser.articleInfos = cat;
    // CREATE SORTIE
    const sortie = new Sortie();
    sortie.id = this.restoreArtInfosService.getSortieChecked().sortie.id;
    newUser.sortie = sortie;
    return newUser;
  }

  /**
   * récupère les données de l'utilisateur du formulaire.
   */
  getUserFromForm(editForm: FormGroup): ISortie {
    return this.userInit.getCategorieFromForm(editForm);
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit avec le formulaire en tapant au moins un caractère.
   */
  reset(editForm: FormGroup) {
    editForm.get(this.catArtColName.quantiteSortie).markAsPristine();
  }

  getQtEnregistreError(): boolean {
    return this.hasQtEnregistreError;
  }

  getQtInsufficientError(): boolean {
    return this.hasQtInsufficientError;
  }

  /**
   * Met à jour un utilisateur.
   * @param user l'utilisateur à mettre à jour.
   */
  update() {
    this.isBtnActif = false;
    this.loadWhenUpdating = true;
    console.log('** updating sortie');
    this.resetFormValue(this.editForm);
    const userDataForm: ISortie = this.getUserFromForm(this.editForm);
    this.catArtUpdateDTO = this.copy(userDataForm);
    this.catArtUpdateDTO.id = this.restoreArtInfosService.getSortieChecked().id;
    this.catArtUpdateDTO.code = this.restoreArtInfosService.getSortieChecked().code;
    this.catArtUpdateDTO.boutique = this.boutiqueSelected;
    this.catArtUpdateDTO.savedDate = this.savedDate;
    const result = this.sortieService.update(this.catArtUpdateDTO);
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Réinitialise certaines données du formulaire.
   * @param form le formulaire.
   */
  resetFormValue(form: FormGroup) {
    this.hasQtEnregistreError = false;
    this.hasQtInsufficientError = false;
    this.reset(form);
  }

  /**
   * Gère les erreurs lors de la modification d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromUpdatingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    this.checkForNegativeValueServ(erreur);
  }

  checkForNegativeValueServ(erreur: any) {
    if (erreur.errorCode === this.errorServ.NEGATIVE_VALUE) {
      const fields = erreur.fields;
      this.hasQtEnregistreError = fields[this.catArtColName.quantiteSortie]
        ? true
        : false;
      this.hasPrixUnitaireError = fields[this.catArtColName.prixUnitaireVendu] ? true : false;
    }
    if (erreur.errorCode === this.errorServ.QUANTITY_INSUFFICIENT) {
      const fields = erreur.fields;
      this.hasQtInsufficientError = fields[this.catArtColName.quantiteSortie]
        ? true
        : false;
    }
  }

  /**
   * Permet récupérer la réponse lors de la modification
   * d'un {@link Utilisateur}
   *
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        const data = res.body;
        if (data) {
          console.log('**** success update sortie: ');
          this.closePopup();
          this.requestService.success(NRequest.ERequest.UPDATE, form,
            RGlobal.urlFull.SORTIE.LIST, this.subs$);
        }
        this.loadWhenUpdating = false;
        this.isBtnActif = true;
      },
      (err: HttpErrorResponse) => {
        this.isBtnActif = true;
        this.loadWhenUpdating = false;
        console.log('**** error update sortie: ');
        try {
          this.manageErrorFromUpdatingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }
}
