import {Component, OnDestroy, OnInit} from '@angular/core';
import {GettingPrivilegeService} from '../../../shared/ services/privilege/getting/getting-privilege.service';
import {DateFormat} from '../../../shared/constant/model/date.constant';
import {SortieService} from '../sortie.service';
import {CheckingBoutique} from '../../employe/checking-boutique.service';
import {Router} from '@angular/router';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {BoutiqueService} from '../../boutique/boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {AccountUser} from '../../../shared/models/account.model';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Uri} from '../../../shared/constant/model/global.constant';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {GestionType, PrivilegeType} from '../../../shared/models/action.model';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {Bilan, BilanEnregistreDTO, BilanVenduDTO} from '../../../shared/models/bilan.model';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-bilan',
  templateUrl: './bilan.component.html'
})
export class BilanComponent implements OnInit, OnDestroy {
  constructor(
    private formService: FormService,
    private dateFormatService: DateFormatService,
    private gettingPrivilegeService: GettingPrivilegeService,
    private dateFormat: DateFormat,
    private sortieService: SortieService,
    private checkingBoutique: CheckingBoutique,
    private router: Router,
    private freeMemoryService: FreeMemoryService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
    this.dateDebut = this.dateFormat.getLastDate();
    this.dateFin = this.dateFormat.getNewDate();
    // this.lastDate = '2018';
    this.lastDate = this.dateFormat.getNewDateByYeader();
    this.dateSelected = this.lastDate;
  }



  iCanDownload = false;
  iCanPrint = false;
  dateDebut = '';
  dateFin = '';
  lastDate = '';
  dateSelected = '';
  bilan: Bilan;
  boutiqueSelected: Boutique;
  open = true;
  shouldIShow = false;
  accountUser: AccountUser;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('bilan');
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getUserIdentityObs(this.accountService, res);
      this.getBilan();
    });
    this.popupReusableService.getPromise().then(res => {
      this.shouldIShow = true;
    });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  getBeneficeTotal(sortieFacture: BilanVenduDTO[]) {
    let beneficeTotal = 0.00;
    if (sortieFacture) {
      for (const f of sortieFacture) {
        beneficeTotal += f.benefice;
      }
    }
    return beneficeTotal;
  }

  getMontantTotalVendu(sortieFacture: BilanVenduDTO[]) {
    let montantTotal = 0.00;
    if (sortieFacture) {
      for (const f of sortieFacture) {
        montantTotal += f.montant;
      }
    }
    return montantTotal;
  }

  getMontantTotalEnregistre(sortieFacture: BilanEnregistreDTO[]) {
    let montantTotal = 0.00;
    if (sortieFacture) {
      for (const f of sortieFacture) {
        montantTotal += f.montant;
      }
    }
    return montantTotal;
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  date() {
    return this.dateFormatService;
  }

  getBilan() {
    if (this.checkingBoutique.isSelected()) {
      console.log('get offer');
      const theYear = this.dateSelected ? this.dateSelected : this.lastDate;
      const param = {
        annee: theYear,
        [Uri.BOUTIQUE]: this.boutiqueSelected.id.toString()
      };
      const result = this.sortieService.getBilan(param);
      this.subscribeToSaveResponse(result);
    }
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */

  subscribeToSaveResponse(
    result: Observable<HttpResponse<Bilan>>,
  ) {
    result.subscribe(
      (res: HttpResponse<Bilan>) => {
        console.log('**** success bilan article: ');
        this.shouldIShow = true;
        this.bilan = res.body;
        console.log('bilan: ', this.bilan);
      },
      (err: HttpErrorResponse) => {
        this.shouldIShow = true;
        console.log('**** error bilan article: ');
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  getTel3() {
    return this.boutiqueSelected.telephone3;
  }

  getTel2() {
    return this.boutiqueSelected.telephone2;
  }

  getTel1() {
    return this.boutiqueSelected.telephone1;
  }

  getEntete(doc) {
    const array = [];
    if (doc) {
      doc.text('Bilan des articles', 400, 20);
      doc.text('' + this.boutiqueSelected.nom, 40, 20);
      array.push('Adresse: ' + this.boutiqueSelected.adresse);
      array.push('Ville: ' + this.boutiqueSelected.ville);
      array.push('Pays: ' + this.boutiqueSelected.pays);
      if (this.getTel1() || this.getTel2() || this.getTel3()) {
        array.push('Contact(s): ');
        if (this.getTel1()) {
          array.push(this.getTel1());
        }
        if (this.getTel2()) {
          array.push(this.getTel2());
        }
        if (this.getTel3()) {
          array.push(this.getTel3());
        }
      }
      array.push('Identifiant: ' + this.boutiqueSelected.identifiant);
    }
    return array;
  }

  print() {
    console.log('printing..');
    const doc = new jsPDF('p', 'pt', 'a4');
    const array = this.getEntete(doc);
    doc.text(array, 40, 60);
    doc.autoTable({html: '#bilan', startY: 200});
    doc.autoPrint();
    doc.save('bilan.pdf');
  }

  download() {
    console.log('saving..');
    const doc = new jsPDF('p', 'pt', 'a4');
    const array = this.getEntete(doc);
    doc.text(array, 40, 60);
    doc.setFontSize(11);
    doc.autoTable({html: '#bilan', startY: 200});
    doc.save('bilan.pdf');
  }


  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService,
                     boutiqueSelected: Boutique) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
        console.log('account: ' + accountUser.login);
        this.gettingPrivilegeService.canIDoThis(accountUser, GestionType.BILAN,
          PrivilegeType.TELECHARGER, boutiqueSelected).pipe(takeUntil(
          this.subs$
        )).subscribe((res: boolean) => {
          console.log('res: ' + res);
          this.iCanDownload = res;
        });
        this.gettingPrivilegeService.canIDoThis(accountUser, GestionType.BILAN,
          PrivilegeType.IMPRIMER, boutiqueSelected).pipe(takeUntil(
          this.subs$
        )).subscribe((res: boolean) => {
          this.iCanPrint = res;
        });
      });
  }
}
