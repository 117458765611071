import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LocaleCsService {

  constructor() { }

  private _locale: string;

  set locale(value: string) {
    this._locale = value;
  }
  get locale(): string {
    return this._locale || 'en-US';
  }

  public registerCulture(culture: string) {
    if (!culture) {
      return;
    }
    switch (culture) {
      case 'en': {
        this._locale = 'en-US';
        console.log('Application Culture Set to English');
        break;
      }
      case 'fr': {
        this._locale = 'fr-FR';
        console.log('Application Culture Set to french');
        break;
      }
      default: {
        this._locale = 'en-US';
        console.log('Application Culture Set to English');
        break;
      }
    }
  }
}
