/**
 * Permet d'initialiser le component {@link UserCreateComponent}
 */
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {Injectable} from '@angular/core';
import {ICategorieArticle} from '../../../shared/models/categorie-article.model';

@Injectable({providedIn: 'root'})
export class CatArtShowInit {
  constructor(
    private date: DateFormatService
  ) {
  }

  /**
   * Permet de convertir une date
   */
  getDate() {
    return this.date;
  }

  /**
   * Permet de récupérer les valeurs d'un utilisateur pouvant
   * être affichées sur du html.
   * @param user l'utilisateur.
   */
  getUser(catArt: ICategorieArticle) {
    return {
      id: catArt ? catArt.id : null,
      designation: catArt ? catArt.designation : null,
      code: catArt ? catArt.code : null,
      dateEnregistrement: catArt ? catArt.dateEnregistrement : null,
      dateModification: catArt ? catArt.dateModification : null,
      utilisateur: catArt ? catArt.utilisateur : null
    };
  }
}
