import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ArticleSearchForInit, ArticleSearchInit} from './article-search.init';
import {ArticleDataShare} from '../article-data.share';
import {advancedSearchKey, Uri} from '../../../shared/constant/model/global.constant';
import {FactoryCreateCatArtVerification} from '../../categorie-article/cat-art-search/verification/factory-create-cat-art-verification';
import {ETypeCatArtVerification} from '../../categorie-article/cat-art-search/verification/abs-factory-cat-art-verification';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {RGlobal} from '../../../shared/constant/global.url';
import {IPaginateService} from '../../../shared/layouts/pagination/service/ipaginate.service';
import {IArticle} from '../../../shared/models/article.model';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {DataSharePaginateService} from '../../../shared/layouts/pagination/service/data-share-paginate.service';
import {ConstantCategorieArticleColumnName} from '../../../shared/constant/model/column-name/categorie-column-name.constant';
import {PaginationService} from '../../../shared/layouts/pagination/service/pagination.service';
import {IrouteCheckService} from '../../../shared/ services/route/checking/iroute-check.service';
import {RouteCheckParam} from '../../../shared/ services/route/checking/route-check-param';
import {RestoreForSearchService} from '../../../shared/ services/restore/restore-for-search.service';
import {DataPageRequest} from '../../../shared/ services/request/data-page-request.service';
import {RouteCheckService} from '../../../shared/ services/route/checking/route-check.service';
import {IRestoreForSearchService} from '../../../shared/ services/restore/irestore-for-search.service';
import {IrouteCheckInitService} from '../../../shared/ services/route/checking/iroute-check-init.service';
import {ManageService} from '../../../shared/ services/manage.service';
import {BoutiqueService} from '../../boutique/boutique.service';
import {ArticleService} from '../article.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {takeUntil} from 'rxjs/operators';
import {MatTableService} from '../../../shared/ services/mat-table/mat-table.service';
import {DataShareWhenCheckedArticle} from '../data-share-when-checked-article.service';
import {ArticleInfosService} from '../../article-infos/article-infos.service';
import {NavInit} from '../../../shared/layouts/nav/nav.init';
import {CheckingBoutique} from '../../employe/checking-boutique.service';

/**
 * Permet la recherche des utilisateurs.
 */
@Component({
  selector: 'app-article-search',
  templateUrl: './article-search.component.html'
})
export class ArticleSearchComponent implements OnInit, OnDestroy,
  IPaginateService,
  IrouteCheckService, IrouteCheckInitService, IRestoreForSearchService {
  constructor(
    private navInit: NavInit,
    private checkingBoutique: CheckingBoutique,
    private matTableService: MatTableService,
    private dataShare: DataShareWhenCheckedArticle,
    public articleDataShare: ArticleDataShare,
    private articleService: ArticleService,
    private boutiqueService: BoutiqueService,
    private popupReusableService: PopupReusableService,
    private restoreForSearchService: RestoreForSearchService,
    private dataSharePaginateService: DataSharePaginateService,
    private dataPageRequest: DataPageRequest,
    private userListRouteCheckService: RouteCheckService,
    private catArtColumnName: ConstantCategorieArticleColumnName,
    private catArtSearchInit: ArticleSearchInit,
    private router: Router,
    private articleInfosService: ArticleInfosService,
    private paginationService: PaginationService,
    private manageService: ManageService,
    private title: Title,
    private activatedRoute: ActivatedRoute,
  ) {
    this.route = navInit;
    this.dataFilter = this.catArtSearchInit.getDataFilter();
    this.dataFilterValue = this.catArtSearchInit.getDataFilterValue();
  }


  // VAR.
  route: NavInit;

  usersList: IArticle[];
  nbUsers: number;
  totalPages: number;
  boutiqueSelected: Boutique;
  options: HttpParams = new HttpParams();
  params: Params = {};
  _searchData: string;
  dataFilter: ArticleSearchForInit.DataFilter;
  dataFilterValue: ArticleSearchForInit.DataFilterValue;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('## on init search');
    this.dataFilter.advancedSearch = this.articleInfosService.isSearchAdvanced();
    console.log('## searchAdv: ', this.dataFilter.advancedSearch);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
    this.checkParams(this.activatedRoute,
      this.dataFilterValue, this, this.getPath(),
      this.subs$);
    this.dataPageRequest.initDataPage(this, this.subs$);
  }

  ngOnDestroy(): void {
    this.restore();
  }

  listeArticle() {
    this.articleService.refreshBtnActive = false;
    console.log('liste article');
    this.checkingBoutique.check(this.route.ARTICLE_LIST_URL);
  }

  getArticleService() {
    return this.articleService;
  }

  changeAdvSearch() {
    console.log('## model change');
    this.dataFilter.advancedSearch = !this.dataFilter.advancedSearch;
    this.articleInfosService.setAdvancedSearch(this.dataFilter.advancedSearch);
  }

  restore() {
    this.restoreForSearchService.restore(this, this.subs$);
  }

  checkParams(activatedRoute: ActivatedRoute, paramsExpected: any,
              iRouteCheck: IrouteCheckInitService, path: string,
              subs$: Subject<void>) {
    this.userListRouteCheckService.checkParams(activatedRoute, paramsExpected,
      iRouteCheck, path, subs$, new RouteCheckParam());
  }

  hasEnterData(): boolean {
    if (this._searchData) {
      return true;
    }
    return false;
  }

  /**
   * @return la donnée à rechercher.
   */
  get searchData(): string {
    return this._searchData;
  }

  /**
   * @param value la donnée à rechercher.
   */
  set searchData(value: string) {
    this._searchData = value;
  }

  /**
   * @return filtres pour la recherche
   * cases cochés par l'utilisateur.
   */
  getDataFilter(): ArticleSearchForInit.DataFilter {
    return this.dataFilter;
  }

  /**
   * @returns un objet contenant la valeur de la recherche
   * seulement pour les champs les cochés.
   */
  getDataFilterValue(): ArticleSearchForInit.DataFilterValue {
    return this.dataFilterValue;
  }


  /**
   * Permet d'effectuer une rechercher par filtre.
   */
  search() {
    this.searchData = this.searchData.trim();
    this.dataShare.clearArticleChecked();
    this.matTableService.initValue();

    this.manageService.initData(this.dataFilterValue);
    if (this.dataFilter.designation || this.dataFilter.code) {
      this.atLeastOneCheckboxHasBeenChecked(this.dataFilter, this.dataFilterValue, this.searchData);
      this.dataFilterValue.advancedSearch = this.articleInfosService.isSearchAdvanced() + '';
    } else {
      this.affectAll(this.dataFilterValue, this._searchData);
      this.dataFilterValue.advancedSearch = this.articleInfosService.isSearchAdvanced() + '';
    }
    const params = this.createParam(this.dataFilterValue);
    this.setQueryParam(1 + '', params);
  }

  /**
   * Permet d'affecter la donnée à rechercher à tous les différents champs
   * de la recherche sauf pour la page.
   * @param dataFilterValue champs de la rechercher permettant de contenir la donnée
   * à rechercher.
   * @param searchData la donnée à erchercher.
   */
  affectAll(dataFilterValue: ArticleSearchForInit.DataFilterValue, searchData: string) {
    Object.keys(dataFilterValue).forEach(key => {
      if (key !== (Uri.PAGE && Uri.DATE_DEBUT && Uri.DATE_FIN)) {
        dataFilterValue[key] = searchData;
      }
    });
  }

  /**
   * Permet d'affecter la valeur à rechercher aux champs cochés.
   * @param dataFilter permet d'indiquer les champs cochés.
   * @param dataFilterValue Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   * @param searchData la donnée à rechercher.
   */
  atLeastOneCheckboxHasBeenChecked(dataFilter: ArticleSearchForInit.DataFilter,
                                   dataFilterValue: ArticleSearchForInit.DataFilterValue,
                                   searchData: string) {
    this.firstChecked(dataFilter, dataFilterValue, searchData);
  }

  /**
   *
   * Permet d'affecter la valeur à rechercher aux champs cochés.
   * @param dataFilter permet d'indiquer les champs cochés.
   * @param dataFilterValue Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   * @param searchData la donnée à rechercher.
   */
  firstChecked(dataFilter: ArticleSearchForInit.DataFilter,
               dataFilterValue: ArticleSearchForInit.DataFilterValue, searchData: string) {
    if (dataFilter.designation) {
      dataFilterValue.designation = searchData;
    }
    if (dataFilter.code) {
      dataFilterValue.code = searchData;
    }
  }

  /**
   * Permet de déterminer les différents champs que l'utilisateur a cochés.
   * @param dataFilterValue Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   * @return les paramètres de la recherche.
   */
  createParam(dataFilterValue: ArticleSearchForInit.DataFilterValue): any {
    const searchCatArtVerification = FactoryCreateCatArtVerification.getCheck(ETypeCatArtVerification.SEARCH);
    const designationVerification = searchCatArtVerification.checkDesignation(dataFilterValue.designation, this.catArtColumnName);
    const codeVerification = designationVerification.linkWith(searchCatArtVerification.checkCode(
      dataFilterValue.code, this.catArtColumnName));
    designationVerification.check(designationVerification);
    const dataSearch = designationVerification.getDataSearch();
    dataSearch[advancedSearchKey] = dataFilterValue.advancedSearch;
    return dataSearch;
  }


  /**
   * Permet de modifier les paramètres de la recherche.
   * NB: la modification des paramètres de la recherche entraînera automatiquement
   * la recherche de l'utilisateur à travers le service de la pagination.
   * @param pageNumber le numéro de la page.
   * @param req les paramètres.
   */
  setQueryParam(pageNumber: string, req?: any) {
    req[Uri.PAGE] = pageNumber;
    req[Uri.DATE_DEBUT] = this.articleDataShare.dateDebut;
    req[Uri.DATE_FIN] = this.articleDataShare.dateFin;
    this.params = req;
    this.paginationService.setIPaginateService(this);
    this.paginationService.setPageQueryParams(+pageNumber);
  }

  /**
   * Permet de récupérer du serveur la liste des utilisateurs par page.
   */
  findMontantTotalVente(req: any) {
    console.log('** try to search montant total vente article');
    if (req) {
      this.options = this.createRequestOption(req);
    }
    this.options = this.options.set(Uri.DATE_DEBUT, this.articleDataShare.dateDebut);
    this.options = this.options.set(Uri.DATE_FIN, this.articleDataShare.dateFin);
    this.options = this.options.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const params = this.options;
    const a = this.articleInfosService.isSearchAdvanced();
    let result;
    if (a) {
      result = this.articleService.searchMontantTotalVenteAvance(params);
    } else {
      result = this.articleService.searchMontantTotalVente(params);
    }
    result.subscribe(
      (res: any) => {
        console.log('** succès recherche montant total vente article par page');
        this.articleDataShare.montantTotalVente = res.body;
        this.articleDataShare.beneficeTotal =
          this.articleDataShare.montantTotalVente - this.articleDataShare.montantTotal;
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération montant total vente article par page');
      }
    );
  }

  /**
   * Permet de récupérer du serveur la liste des utilisateurs par page.
   */
  findMontantTotal(req: any) {
    console.log('** try to search montant total article');
    if (req) {
      this.options = this.createRequestOption(req);
    }
    this.options = this.options.set(Uri.DATE_DEBUT, this.articleDataShare.dateDebut);
    this.options = this.options.set(Uri.DATE_FIN, this.articleDataShare.dateFin);
    this.options = this.options.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const params = this.options;
    const a = this.articleInfosService.isSearchAdvanced();
    let result;
    if (a) {
      result = this.articleService.searchMontantTotalAvance(params);
    } else {
      result = this.articleService.searchMontantTotal(params);
    }
    result.subscribe(
      (res: any) => {
        console.log('** succès recherche montant total article par page');
        this.articleDataShare.montantTotal = res.body;
        this.findMontantTotalVente(this.params);
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération montant total article par page');
      }
    );
  }

  /**
   * Permet de récupérer du serveur la liste des utilisateurs par page.
   */
  getListUsers(pageNumber: string, req?: any) {
    console.log('** try to search article');
    this.articleDataShare.spinnerTable = true;
    if (req) {
      this.options = this.createRequestOption(req);
    }
    this.options = this.options.set(Uri.PAGE, pageNumber);
    this.options = this.options.set(Uri.DATE_DEBUT, this.articleDataShare.dateDebut);
    this.options = this.options.set(Uri.DATE_FIN, this.articleDataShare.dateFin);
    this.options = this.options.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const params = this.options;
    const a = this.articleInfosService.isSearchAdvanced();
    let result;
    if (a) {
      result = this.articleService.searchAvance(params);
    } else {
      result = this.articleService.search(params);
    }
    result.subscribe(
      (res: any) => {
        console.log('** succès recherche article par page');
        this.articleDataShare.spinnerTable = false;
        this.dataPageRequest.getDataPage(res.body);
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération article par page');
        this.articleDataShare.spinnerTable = false;
        this.manageService.redirectToPageNotFound();
      }
    );
  }

  /**
   * permet de créer un objet Param à partir de la liste
   * des filtres pour effectuer la recherche par filtre.
   * @param req contient les différentes filtres pour la recherche.
   */
  createRequestOption(req?: any): HttpParams {
    console.log('*** creating request.');
    if (req) {
      this.options = new HttpParams();
      Object.keys(req).forEach(key => {
        this.options = this.options.set(key, req[key]);
      });
    }
    return this.options;
  }


  /**
   * Permet de récupérer les utilisateurs du serveur.
   * @param pageNumber le numéro de la page.
   */
  getDataServ(pageNumber: string) {
    this.articleDataShare.montantTotal = 0;
    this.articleDataShare.montantTotalVente = 0;
    this.getListUsers(pageNumber, this.params);
    this.popupReusableService.getPromiseForRequest().then(res => {
      this.findMontantTotal(this.params);
    });
  }

  /**
   * @return le nombre d'utilisateurs.
   */
  getNbItems(): number {
    return this.getNbUsers();
  }

  /**
   * @return la liste des utilisateurs récupérées du serveur.
   */
  getData() {
    return this.getUsersList();
  }

  /**
   * @return la liste des utilisateurs récupérées du serveur.
   */
  getUsersList() {
    // return this.dataPageRequest.getDataList();
    return this.usersList;
  }

  /**
   * @return le nombre d'utilisateurs.
   */
  getNbUsers(): number {
    //  return this.dataPageRequest.getNbItems();
    return this.nbUsers;
  }

  /**
   * @return le nombre de pages.
   */
  getTotalPages(): number {
    // return this.dataPageRequest.getTotalPages();
    return this.totalPages;
  }

  /**
   * @return les paramètres de l'url.
   */
  getParams(): Params {
    return this.params;
  }

  /**
   * @return le path de la recherche des utilisateurs.
   */
  getPath(): string {
    return RGlobal.urlFull.ARTICLE.SEARCH;
  }

  getService(): IPaginateService {
    return this;
  }

  getPathRoute(): string {
    return this.getPath();
  }

  setParamsRoute(params: any) {
    this.params = params;
    const date = this.userListRouteCheckService.setDate(params, {
      dateDebut: this.articleDataShare.dateDebut,
      dateFin: this.articleDataShare.dateFin
    });
    this.articleDataShare.dateDebut = date.dateDebut;
    this.articleDataShare.dateFin = date.dateFin;
  }

  getTitle(): Title {
    return this.title;
  }

  getTitlePath(): string {
    return 'Rechercher détails articles';
  }

  setData(data: any[]) {
    this.usersList = data;
  }

  setNbItems(n: number) {
    this.nbUsers = n;
  }

  setTotalPages(t: number) {
    this.totalPages = t;
  }

  getDataFilterToRestore() {
    return this.getDataFilter();
  }

  getDataFilterValueToRestore() {
    return this.getDataFilterValue();
  }

  getDateDebut(): string {
    return this.articleDataShare.dateDebut;
  }

  getDateFin(): string {
    return this.articleDataShare.dateFin;
  }
}
