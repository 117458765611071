/**
 * Permet la recherche des utilisateurs.
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {IrouteCheckService} from '../../../shared/ services/route/checking/iroute-check.service';
import {IrouteCheckInitService} from '../../../shared/ services/route/checking/iroute-check-init.service';
import {IPaginateService} from '../../../shared/layouts/pagination/service/ipaginate.service';
import {RGlobal} from '../../../shared/constant/global.url';
import {ConstantUserColumnName} from '../../../shared/constant/model/column-name/user-column-name.constant';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ETypeVerification} from './verification/abs-factory-verification';
import {RouteCheckService} from '../../../shared/ services/route/checking/route-check.service';
import {FactoryCreateVerification} from './verification/factory-create-verification';
import {Uri} from '../../../shared/constant/model/global.constant';
import {DataSharePaginateService} from '../../../shared/layouts/pagination/service/data-share-paginate.service';
import {MatTableService} from '../../../shared/ services/mat-table/mat-table.service';
import {ManageService} from '../../../shared/ services/manage.service';
import {DataPageRequest} from '../../../shared/ services/request/data-page-request.service';
import {RouteCheckParam} from '../../../shared/ services/route/checking/route-check-param';
import {EmployeDataShare} from '../employe-data-share.service';
import {RestoreForSearchService} from '../../../shared/ services/restore/restore-for-search.service';
import {EmployeSearchInit, UserSearchForInit} from './employe-search.init';
import {PaginationService} from '../../../shared/layouts/pagination/service/pagination.service';
import {IRestoreForSearchService} from '../../../shared/ services/restore/irestore-for-search.service';
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';
import {takeUntil} from 'rxjs/operators';
import {Boutique} from '../../../shared/models/boutique.model';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {BoutiqueService} from '../../boutique/boutique.service';
import {EmployeService} from '../employe.service';

@Component({
  selector: 'app-employe-search',
  templateUrl: './employe-search.component.html'
})
export class EmployeSearchComponent implements OnInit, OnDestroy,
  IPaginateService,
  IrouteCheckService, IrouteCheckInitService, IRestoreForSearchService {
  constructor(
    private employeService: EmployeService,
    private accountService: AccountService,
    private boutiqueService: BoutiqueService,
    public userDataShare: EmployeDataShare,
    private matTableService: MatTableService,
    private restoreForSearchService: RestoreForSearchService,
    private dataSharePaginateService: DataSharePaginateService,
    private dataPageRequest: DataPageRequest,
    private userListRouteCheckService: RouteCheckService,
    private userColumnName: ConstantUserColumnName,
    private userSearchInit: EmployeSearchInit,
    private router: Router,
    private paginationService: PaginationService,
    private manageService: ManageService,
    private http: HttpClient,
    private title: Title,
    private activatedRoute: ActivatedRoute
  ) {
    this.dataFilter = this.userSearchInit.getDataFilter();
    this.dataFilterValue = this.userSearchInit.getDataFilterValue();
  }

// VAR.
  subs$ = new Subject<void>();
  options: HttpParams = new HttpParams();
  params: Params = {};
  _searchData: string; // Données à rechercher.
  /* Filtres pour la recherche
    cochés par l'utilisateur. */
  dataFilter: UserSearchForInit.DataFilter;
  /* Permet de contenir
     la donnée à rechercher */
  dataFilterValue: UserSearchForInit.DataFilterValue;
  boutiqueSelected: Boutique;
  usersList: EmployeBoutique[];
  nbUsers: number;
  totalPages: number;

  ngOnInit(): void {
    console.log('** oninit user search');
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
    this.checkParams(this.activatedRoute, this.dataFilterValue, this,
      this.getPath(), this.subs$);
    this.dataPageRequest.initDataPage(this, this.subs$);
  }

  ngOnDestroy(): void {
    this.restore();
  }

  checkParams(activatedRoute: ActivatedRoute, paramsExpected: any,
              iRouteCheck: IrouteCheckInitService, path: string,
              subs$: Subject<void>) {
    this.userListRouteCheckService.checkParams(activatedRoute, paramsExpected,
      iRouteCheck, path, subs$,
      new RouteCheckParam().setMatTableService(this.matTableService));
  }

  hasEnterData(): boolean {
    if (this._searchData) {
      return true;
    }
    return false;
  }

  restore() {
    console.log('restore user search');
    this.restoreForSearchService.restore(this, this.subs$);
  }

  /**
   * @return la donnée à rechercher.
   */
  get searchData(): string {
    return this._searchData;
  }

  /**
   * @param value la donnée à rechercher.
   */
  set searchData(value: string) {
    this._searchData = value;
  }

  /**
   * @return filtres pour la recherche
   * cases cochés par l'utilisateur.
   */
  getDataFilter(): UserSearchForInit.DataFilter {
    return this.dataFilter;
  }

  /**
   * @returns un objet contenant la valeur de la recherche
   * seulement pour les champs les cochés.
   */
  getDataFilterValue(): UserSearchForInit.DataFilterValue {
    return this.dataFilterValue;
  }


  /**
   * Permet d'effectuer une rechercher par filtre.
   */
  search() {
    this.manageService.initData(this.dataFilterValue);
    if (
      this.dataFilter.loginCheckbox || this.dataFilter.nomCheckbox || this.dataFilter.prenomCheckbox ||
      this.dataFilter.emailCheckbox || this.dataFilter.adresseCheckbox || this.dataFilter.telephoneCheckbox
    ) {
      /* l'utilisateur a cocher sur au moins  un checkbox.
         détection des différents checkbox cochés et affectation de la valeur
         à rechercher */
      this.atLeastOneCheckboxHasBeenChecked(this.dataFilter, this.dataFilterValue, this.searchData);
    } else {
      /* l'utilisateur n'a coché aucun checkbox, donc recherche
      pour tout les filtres. */
      this.affectAll(this.dataFilterValue, this._searchData);
    }
    const params = this.createParam(this.dataFilterValue);
    this.setQueryParam(1 + '', params);
  }

  /**
   * Permet d'affecter la donnée à rechercher à tous les différents champs
   * de la recherche sauf pour la page.
   * @param dataFilterValue champs de la rechercher permettant de contenir la donnée
   * à rechercher.
   * @param searchData la donnée à erchercher.
   */
  affectAll(dataFilterValue: UserSearchForInit.DataFilterValue, searchData: string) {
    Object.keys(dataFilterValue).forEach(key => {
      if (key !== (Uri.PAGE && Uri.DATE_DEBUT && Uri.DATE_FIN)) {
        dataFilterValue[key] = searchData;
      }
    });
  }

  /**
   * Permet d'affecter la valeur à rechercher aux champs cochés.
   * @param dataFilter permet d'indiquer les champs cochés.
   * @param dataFilterValue Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   * @param searchData la donnée à rechercher.
   */
  atLeastOneCheckboxHasBeenChecked(dataFilter: UserSearchForInit.DataFilter,
                                   dataFilterValue: UserSearchForInit.DataFilterValue,
                                   searchData: string) {
    this.firstChecked(dataFilter, dataFilterValue, searchData);
    this.secondChecked(dataFilter, dataFilterValue, searchData);
  }

  /**
   *
   * Permet d'affecter la valeur à rechercher aux champs cochés.
   * @param dataFilter permet d'indiquer les champs cochés.
   * @param dataFilterValue Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   * @param searchData la donnée à rechercher.
   */
  firstChecked(dataFilter: UserSearchForInit.DataFilter,
               dataFilterValue: UserSearchForInit.DataFilterValue, searchData: string) {
    if (dataFilter.loginCheckbox) {
      dataFilterValue.login = searchData;
    }
    if (dataFilter.nomCheckbox) {
      dataFilterValue.nom = searchData;
    }
    if (dataFilter.prenomCheckbox) {
      dataFilterValue.prenom = searchData;
    }
  }

  /**
   * Permet d'affecter la valeur à rechercher aux champs cochés.
   * @param dataFilter permet d'indiquer les champs cochés.
   * @param dataFilterValue Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   * @param searchData la donnée à rechercher.
   */
  secondChecked(dataFilter: UserSearchForInit.DataFilter,
                dataFilterValue: UserSearchForInit.DataFilterValue, searchData: string) {
    if (dataFilter.emailCheckbox) {
      dataFilterValue.email = searchData;
    }
    if (dataFilter.adresseCheckbox) {
      dataFilterValue.adresse = searchData;
    }
    if (dataFilter.telephoneCheckbox) {
      dataFilterValue.telephone = searchData;
    }
  }

  /**
   * Permet de déterminer les différents champs que l'utilisateur a cochés.
   * @param dataFilterValue Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   * @return les paramètres de la recherche.
   */
  createParam(dataFilterValue: UserSearchForInit.DataFilterValue): any {
    const searchUserVerification = FactoryCreateVerification.getCheck(ETypeVerification.SEARCH);
    const loginVerification = searchUserVerification.checkLogin(dataFilterValue.login, this.userColumnName);
    const nomVerification = loginVerification.linkWith(searchUserVerification.checkNom(
      dataFilterValue.nom, this.userColumnName));
    const prenomVerification = nomVerification.linkWith(searchUserVerification.checkPrenom(
      dataFilterValue.prenom, this.userColumnName));
    const emailVerification = prenomVerification.linkWith(searchUserVerification.checkEmail(
      dataFilterValue.email, this.userColumnName));
    const adresseVerification = emailVerification.linkWith(searchUserVerification.checkAdresse(
      dataFilterValue.adresse, this.userColumnName));
    const telephoneVerification = adresseVerification.linkWith(searchUserVerification.checkTelephone(
      dataFilterValue.telephone, this.userColumnName));
    loginVerification.check(loginVerification);
    return loginVerification.getDataSearch();
  }


  /**
   * Permet de modifier les paramètres de la recherche.
   * NB: la modification des paramètres de la recherche entraînera automatiquement
   * la recherche de l'utilisateur à travers le service de la pagination.
   * @param pageNumber le numéro de la page.
   * @param req les paramètres.
   */
  setQueryParam(pageNumber: string, req?: any) {
    req[Uri.PAGE] = pageNumber;
    req[Uri.DATE_DEBUT] = this.userDataShare.dateDebut;
    req[Uri.DATE_FIN] = this.userDataShare.dateFin;
    this.params = req;
    // this.dataSharePaginateService.setIPaginate(this);
    this.paginationService.setIPaginateService(this);
    this.paginationService.setPageQueryParams(+pageNumber);
  }

  /**
   * Permet de récupérer du serveur la liste des utilisateurs par page.
   */
  getListUsers(pageNumber: string, req?: any) {
    console.log('** try to search users');
    this.userDataShare.spinnerTable = true;
    if (req) {
      this.options = this.createRequestOption(req);
    }
    this.options = this.options.set(Uri.PAGE, pageNumber);
    this.options = this.options.set(Uri.DATE_DEBUT, this.userDataShare.dateDebut);
    this.options = this.options.set(Uri.DATE_FIN, this.userDataShare.dateFin);
    this.options = this.options.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const params = this.options;
    const result = this.employeService.search(params);
    result.subscribe(
      (res: any) => {
        console.log('** succès recherche users par page');
        console.log('** res: ' + res);
        console.log('** res2: ' + JSON.stringify(res.body));
        this.userDataShare.spinnerTable = false;
        this.dataPageRequest.getDataPage(res.body);
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération users par page');
        this.userDataShare.spinnerTable = false;
        this.manageService.redirectToPageNotFound();
      }
    );
  }

  /**
   * permet de créer un objet Param à partir de la liste
   * des filtres pour effectuer la recherche par filtre.
   * @param req contient les différentes filtres pour la recherche.
   */
  createRequestOption(req?: any): HttpParams {
    console.log('*** creating request.');
    if (req) {
      this.options = new HttpParams();
      Object.keys(req).forEach(key => {
        this.options = this.options.set(key, req[key]);
      });
    }
    return this.options;
  }


  /**
   * Permet de récupérer les utilisateurs du serveur.
   * @param pageNumber le numéro de la page.
   */
  getDataServ(pageNumber: string) {
    this.getListUsers(pageNumber, this.params);
  }

  /**
   * @return le nombre d'utilisateurs.
   */
  getNbItems(): number {
    return this.nbUsers;
  }

  /**
   * @return la liste des utilisateurs récupérées du serveur.
   */
  getData() {
    return this.usersList;
  }

  /**
   * @return les paramètres de l'url.
   */
  getParams(): Params {
    return this.params;
  }

  /**
   * @return le path de la recherche des utilisateurs.
   */
  getPath(): string {
    return RGlobal.urlFull.EMPLOYE.SEARCH;
  }

  getService(): IPaginateService {
    return this;
  }

  getPathRoute(): string {
    return this.getPath();
  }

  setParamsRoute(params: any) {
    this.params = params;
    const date = this.userListRouteCheckService.setDate(params, {
      dateDebut: this.userDataShare.dateDebut,
      dateFin: this.userDataShare.dateFin
    });
    this.userDataShare.dateDebut = date.dateDebut;
    this.userDataShare.dateFin = date.dateFin;
  }

  getTitle(): Title {
    return this.title;
  }

  getTitlePath(): string {
    return 'title.user.search';
  }

  setData(data: any[]) {
    this.usersList = data;
  }

  setNbItems(n: number) {
    this.nbUsers = n;
  }

  getTotalPages(): number {
    return this.totalPages;
  }

  setTotalPages(t: number) {
    this.totalPages = t;
  }

  getDataFilterToRestore() {
    return this.getDataFilter();
  }

  getDataFilterValueToRestore() {
    return this.getDataFilterToRestore();
  }

  getDateDebut(): string {
    return this.userDataShare.dateDebut;
  }

  getDateFin(): string {
    return this.userDataShare.dateFin;
  }
}
