import {Routes} from '@angular/router';
import {RGlobal} from '../../shared/constant/global.url';
import {GGuardIsLoginService} from '../../shared/ services/guard/is-login/gguard-is-login.service';
import {ArticleListeComponent} from './article-liste/article-liste.component';
import {GGuardPrivilegeArticleService} from '../../shared/ services/guard/gguard-privilege-article.service';
import {GuardCheckingBoutiqueService} from '../../shared/ services/guard/login/guard-checking-boutique.service';

export const articleRoute: Routes = [
  {
    path: RGlobal.url.ARTICLE.ARTICLE_,
    canActivate: [GGuardIsLoginService, GGuardPrivilegeArticleService],
    children: [
      {
        path: RGlobal.url.ARTICLE.LIST, component: ArticleListeComponent,
        canActivate: [GuardCheckingBoutiqueService]
      },
      {
        path: RGlobal.url.ARTICLE.SEARCH, component: ArticleListeComponent,
        canActivate: [GuardCheckingBoutiqueService]
      }
    ]
  }
];
