import {Injectable} from '@angular/core';
import {BoutiqueService} from '../boutique/boutique.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Boutique} from '../../shared/models/boutique.model';
import {Router} from '@angular/router';
import {PopupReusableService} from '../../reusable/services/popup-reusable.service';
import {BoutiqueListData, BoutiqueListeComponent} from '../boutique/boutique-liste/boutique-liste.component';

@Injectable({providedIn: 'root'})
export class CheckingBoutique {
  constructor(
    private popupReusableService: PopupReusableService,
    private router: Router,
    private boutiqueService: BoutiqueService
  ) {
  }

  private boutiqueSelected: Boutique;
  private boutiqueListData: BoutiqueListData;

  init(subs$: Subject<void>) {
    if (subs$) {
      this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
        subs$
      )).subscribe(res => {
        console.log('init select from nav');
        this.boutiqueSelected = res;
      });
      this.boutiqueService.getBoutiqueListDataObs().pipe(takeUntil(
        subs$
      )).subscribe((res: BoutiqueListData) => {
        this.boutiqueListData = res;
      });
    }
  }

  getBoutiqueSelected() {
    return this.boutiqueSelected;
  }

  isSelected() {
    if (this.boutiqueSelected) {
      return true;
    } else {
      this.popupReusableService.open(null,
        BoutiqueListeComponent);
      this.boutiqueListData.isSelected = false;
      this.boutiqueService.setBoutiqueListDataObs(this.boutiqueListData);
      return false;
    }
  }

  /**
   * @param i la liste des boutiques
   */
  check(path: string) {
    if (this.boutiqueSelected && path) {
      this.router.navigate([path]);
    } else {
      this.popupReusableService.open(null,
        BoutiqueListeComponent);
      this.boutiqueListData.isSelected = false;
      this.boutiqueService.setBoutiqueListDataObs(this.boutiqueListData);
    }
  }
}
