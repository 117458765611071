import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AbonnementService} from '../abonnement.service';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {AppInfos} from '../../../shared/models/app-infos.model';
import {Boutique} from '../../../shared/models/boutique.model';
import {AccountUser} from '../../../shared/models/account.model';
import {Subject} from 'rxjs';
import {ConstantAbonnementAppPay} from '../../../shared/constant/abonnement/abonnement-app-pay.constant';
import {FormService} from '../../../shared/ services/form.service';
import {HomeService} from '../../../pages/home/home.service';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {Paydto} from '../../../shared/models/paydto.model';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-abonnement-application',
  templateUrl: './abonnement-application.component.html'
})
export class AbonnementApplicationComponent implements OnInit, OnDestroy {
  showLoadingApp = false;
  showLoadingUser = false;

  appInfos: AppInfos;
  boutiqueSelected: Boutique;
  open = true;
  accountUser: AccountUser;

  nombreMoisApp = 1;
  prixAppUnit = 4990;
  prixApp = 4990;

  youtubeLink = 'https://www.youtube.com/watch?v=fOcZA01MUeM&list=PLuJa5EhUHXi4amy7YoAHwmnzp3Lu7a0NR';

  subs$ = new Subject<void>();

  constructor(
    private homeService: HomeService,
    private formService: FormService,
    private constantAbonnementAppPay: ConstantAbonnementAppPay,
    private router: Router,
    private abonnementService: AbonnementService,
    private freeMemoryService: FreeMemoryService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
  }

  ngOnInit(): void {
    console.log('init abonnement app');
    this.accountService.getAppInfos().subscribe(res => {
      this.appInfos = res.body;
    });
  }

  ngOnDestroy(): void {
  }

  payAbApp() {
    this.showLoadingApp = true;
    let p = new HttpParams();
    p = p.set('nombreMois', this.nombreMoisApp.toString());
    console.log('## nombreMois: ', this.nombreMoisApp);

    this.abonnementService.createAbApp(p).subscribe((res: HttpResponse<Paydto>) => {
      console.log('## success create ab app: ', res);
      const d = res.body;
      console.log('## response text: ', d.redirect_url);
      window.open(d.redirect_url, '_blank');
      this.showLoadingApp = false;
    }, error => {
      console.log('## error create ab app: ', error);
      this.showLoadingApp = false;
    });
  }

  payAbUser() {
    this.showLoadingUser = true;
    this.abonnementService.createAbUser().subscribe((res: HttpResponse<Paydto>) => {
      console.log('## success create ab user: ', res);
      const d = res.body;
      window.open(d.response_text, '_blank');
      this.showLoadingUser = false;
    }, error => {
      console.log('## error create ab user: ', error);
      this.showLoadingUser = false;
    });
  }

  openYoutube() {
    window.open(this.youtubeLink, '_blanck');
  }

  plusApp() {
    this.nombreMoisApp++;
    this.prixApp = this.prixAppUnit * this.nombreMoisApp;
  }

  minusApp() {
    if (this.nombreMoisApp > 1) {
      this.nombreMoisApp--;
      this.prixApp = this.prixAppUnit * this.nombreMoisApp;
    }
  }

  getOffer() {
    console.log('## get offer');
  }

  formatId(id: string) {
    return this.formService.formatId(id);
  }

  getAccount(): AccountUser {
    return this.homeService.getAccount();
  }

  getAb() {
    return this.constantAbonnementAppPay;
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }
}
