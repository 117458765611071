import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {Observable, Subject} from 'rxjs';
import {CatArtCreate, CategorieArticleCreateInit} from '../categorie-article-create/categorie-article-create.init';
import {CategorieArticleUpdateDTO, ICategorieArticle, ICategorieArticleUpdateDTO} from '../../../shared/models/categorie-article.model';
import {takeUntil} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {RGlobal} from '../../../shared/constant/global.url';
import {Router} from '@angular/router';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {RestoreCatArtService} from '../restore-cat-art.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CatArtService} from '../cat-art.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {DataShareWhenCheckedCatArt} from '../data-share-when-checked-cat-art.service';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Permet la mise à jour des utilisateurs.
 */
@Component({
  selector: 'app-categorie-article-update',
  templateUrl: './categorie-article-update.component.html'
})
export class CategorieArticleUpdateComponent implements OnInit, OnDestroy {
  constructor(
    private boutiqueService: BoutiqueService,
    private catArtService: CatArtService,
    private activeModal: NgbActiveModal,
    private restoreCatArtService: RestoreCatArtService,
    private requestService: RequestService,
    private userInit: CategorieArticleCreateInit,
    private router: Router,
    private errorServ: ConstantErrorServ,
    private http: HttpClient,
    private dataShare: DataShareWhenCheckedCatArt,
    private popupReusableService: PopupReusableService,
    private formUserUpdate: FormBuilder,
    private catArtUpdateInit: CategorieArticleCreateInit,
    private formService: FormService) {
    this.catArtColName = this.userInit.getCategorieArticleColumnName();
  }

  shouldIShow = false;
  boutiqueSelected: Boutique;
  open = true;
  editForm = this.formUserUpdate
    .group(this.catArtUpdateInit.getForm());
  // Ne pas supprimé, utilisé côté Html.
  categorieArticleColName = this.catArtUpdateInit.getCategorieArticleColumnName();
  subs$ = new Subject<void>(); // VAR END.
  activatedValidationButton = true;
  hasDesignationError = false;
  hasCodeError = false;
  catArtUpdateDTO: ICategorieArticleUpdateDTO;
  catArtColName: CatArtCreate.ColumnName;

  /**
   * Permet de remplir le formulaire avec les données de l'utilisateur
   * à mettre à jour.
   * NB: le mot de passe n'est pas rempli pour des mesures de sécurité.
   */
  ngOnInit() {
    this.popupReusableService.getPromise().then(res => {
      this.shouldIShow = true;
    });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
    this.initForm(this.editForm, this.dataShare,
      this.subs$);
  }


  /**
   * Permet de réinitialiser certaines variable au changement
   * de route.
   */
  ngOnDestroy(): void {
    console.log('user update is destroyed');
    this.restore();
  }

  shoudlIShow() {
    return this.shouldIShow && this.getCatArtChecked();
  }

  /**
   * @returns l'utilisateur coché pour la modification de ses informations.
   */
  getCatArtChecked(): ICategorieArticle {
    return this.restoreCatArtService.getCatArtChecked();
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  removeMsgError() {
    this.hasDesignationError = false;
    this.hasCodeError = false;
  }

  /**
   * Permet de réinitialiser certaines variable au changement
   * de route.
   */
  restore() {
    this.editForm.reset();
    this.removeMsgError();
    this.restoreCatArtService.restore(this.subs$);
  }

  /**
   * Permet de remplir le formulaire avec les données de l'utilisateur
   * à mettre à jour.
   * NB: le mot de passe n'est pas rempli pour des mesures de sécurité.
   *
   * @param editForm le formulaire.
   * @param subs$ permet de libérer la mémoire.
   */
  initForm(editForm: FormGroup, dataShare: DataShareWhenCheckedCatArt,
           subs$: Subject<void>) {
    console.log('** on init user update');
    dataShare.getCatArtcheckedObs().pipe(takeUntil(subs$))
      .subscribe((data: ICategorieArticle) => {
        if (data) {
          this.restoreCatArtService.setCatArtChecked(data);
          editForm.patchValue({
            [this.catArtColName.designation]: data.designation,
            [this.catArtColName.code]: data.code,
            [this.catArtColName.description]: data.description,
          });
        }
      });
  }

  /**
   * Permet de copier les informations de l'utilisateurs à mettre
   * à jour. Il s'agit des informations du formulaire.
   *
   * @param user ..
   */
  copy(catArt: ICategorieArticle): ICategorieArticleUpdateDTO {
    const newUser = new CategorieArticleUpdateDTO();
    newUser.id = catArt.id;
    newUser.designation = catArt.designation;
    newUser.code = catArt.code;
    newUser.description = catArt.description;
    return newUser;
  }

  /**
   * récupère les données de l'utilisateur du formulaire.
   */
  getUserFromForm(editForm: FormGroup): ICategorieArticle {
    return this.userInit.getCategorieFromForm(editForm);
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit avec le formulaire en tapant au moins un caractère.
   */
  reset(editForm: FormGroup) {
    editForm.get(this.catArtColName.designation).markAsPristine();
    editForm.get(this.catArtColName.code).markAsPristine();
  }

  /**
   * Met à jour un utilisateur.
   * @param user l'utilisateur à mettre à jour.
   */
  update() {
    this.activatedValidationButton = false;
    console.log('** updating categorie article');
    this.resetFormValue(this.editForm);
    const userDataForm: ICategorieArticle = this.getUserFromForm(this.editForm);
    this.catArtUpdateDTO = this.copy(userDataForm);
    userDataForm.id = this.restoreCatArtService.getCatArtChecked().id;
    this.catArtUpdateDTO.id = this.restoreCatArtService.getCatArtChecked().id;
    this.catArtUpdateDTO.boutique = this.boutiqueSelected;
    const result = this.catArtService.update(this.catArtUpdateDTO);
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Réinitialise certaines données du formulaire.
   * @param form le formulaire.
   */
  resetFormValue(form: FormGroup) {
    this.hasCodeError = false;
    this.hasDesignationError = false;
    this.reset(form);
  }

  /**
   * Gère les erreurs lors de la modification d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromUpdatingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      const fields = erreur.fields;
      this.hasDesignationError = fields[this.catArtColName.designation] ? true : false;
      this.hasCodeError = fields[this.catArtColName.code] ? true : false;
    }
  }

  /**
   * Permet récupérer la réponse lors de la modification
   * d'un {@link Utilisateur}
   *
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<ICategorieArticle>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<ICategorieArticle>) => {
        console.log('**** success update cat art: ');
        this.closePopup();
        this.requestService.success(NRequest.ERequest.UPDATE, form,
          RGlobal.urlFull.CATEGORIE_ARTICLE.LIST, this.subs$);
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save cat art: ');
        this.activatedValidationButton = true;
        try {
          this.manageErrorFromUpdatingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

  /**
   * Permet d'indiquer si le formulaire de modification contient des erreurs.
   * @returns true si le formulaire contient des erreurs, false sinon.
   */
  validationHasError() {
    return this.editForm.invalid || !this.activatedValidationButton;
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalidButNotRequired(field: string) {
    return this.formService.isInvalidAndDirty(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'maxlength'.
   */
  hasErrorMaxLength(field: string): boolean {
    return this.formService.hasErrorMaxLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }
}
