import {Injectable} from '@angular/core';
import {RGlobal, slashRoute} from '../../../shared/constant/global.url';
import {DateFormat} from '../../../shared/constant/model/date.constant';

/**
 * Permet d'initialiser le component {@link CategorieArticleListeComponent}
 */
@Injectable({providedIn: 'root'})
export class ArtInfosListeInit {

  constructor(
    private date: DateFormat
  ) {
  }

  // VAR.
  readonly ARTICLE_CREATE_URL = slashRoute + RGlobal.urlFull.ARTICLE.CREATE;
  readonly ART_INFOS_CREATE_URL = slashRoute + RGlobal.urlFull.ARTICLE_INFOS.CREATE;
  readonly SORTIE_CREATE_URL = slashRoute + RGlobal.urlFull.SORTIE.CREATE;
  readonly ART_INFOS_UPDATE_URL = slashRoute + RGlobal.urlFull.ARTICLE_INFOS.UPDATE;

  /**
   * Permet de convertir une date
   */
  getDate() {
    return {
      full: this.date.FULL,
      medium: this.date.MEDIUM,
      hours: this.date.HOURS
    };
  }
}
