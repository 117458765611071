import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {CategorieArticle, ICategorieArticle} from '../../../shared/models/categorie-article.model';
import {ArtInfosCreateForInit, ArtInfosCreateInit} from '../art-infos-create/art-infos-create.init';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ArticleInfos, IArticleInfos} from '../../../shared/models/article-infos.model';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {NRestricted} from '../../../shared/constant/restricted.msg';
import {RGlobal} from '../../../shared/constant/global.url';
import {Router} from '@angular/router';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {PageRestrictedService} from '../../../shared/layouts/restricted/page-restricted.service';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {RestoreArtInfosService} from '../restore-art-infos.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CatArtService} from '../../categorie-article/cat-art.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {Uri} from '../../../shared/constant/model/global.constant';
import {ArticleInfosService} from '../article-infos.service';
import {DataShareWhenCheckedArtInfos} from '../data-share-when-checked-art-infos.service';
import {FormService} from '../../../shared/ services/form.service';

@Component({
  selector: 'app-art-infos-update',
  templateUrl: './art-infos-update.component.html'
})
export class ArtInfosUpdateComponent implements OnInit, OnDestroy {

  constructor(
    private articleInfosService: ArticleInfosService,
    private boutiqueService: BoutiqueService,
    private catArtService: CatArtService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
    private pageRestrictedService: PageRestrictedService,
    private restoreArtInfosService: RestoreArtInfosService,
    private requestService: RequestService,
    private userInit: ArtInfosCreateInit,
    private router: Router,
    private errorServ: ConstantErrorServ,
    private dataShare: DataShareWhenCheckedArtInfos,
    private formUserUpdate: FormBuilder,
    private catArtUpdateInit: ArtInfosCreateInit,
    private formService: FormService) {
    this.catArtColName = this.userInit.getCategorieArticleColumnName();
  }

  // VAR.
  isLoading = true;
  boutiqueSelected: Boutique;
  open = true;
  isShow = false;
  editForm = this.formUserUpdate
    .group(this.catArtUpdateInit.getForm());
  // Ne pas supprimé, utilisé côté Html.
  artInfosColName = this.catArtUpdateInit.getCategorieArticleColumnName();
  subs$ = new Subject<void>(); // VAR END.
  activatedValidationButton = true;
  loadWhenUpdating = false;
  hasDesignationError = false;
  hasCodeError = false;
  hasQtAlerteError = false;
  hasPrixUnitaireError = false;
  needToCreateCatArt = false;

  filteredOptions: Observable<ICategorieArticle[]>;
  catArtList: ICategorieArticle[];
  catArtUpdateDTO: IArticleInfos;
  catArtColName: ArtInfosCreateForInit.ColumnName;

  /**
   * Permet de remplir le formulaire avec les données de l'utilisateur
   * à mettre à jour.
   * NB: le mot de passe n'est pas rempli pour des mesures de sécurité.
   */
  ngOnInit() {
    console.log('** on init art infos update');
    this.popupReusableService.getPromise().then(res => {
      this.isShow = true;
    });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getAllCatArt();
    });
    this.dataShare.getCatArtcheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: IArticleInfos) => {
        if (data) {
          this.restoreArtInfosService.setArtInfosChecked(data);
          this.editForm.patchValue({
            [this.catArtColName.designation]: data.designation,
            [this.catArtColName.code]: data.code,
            [this.catArtColName.description]: data.description,
            [this.catArtColName.quantiteAlerte]: data.quantiteAlerte,
            [this.catArtColName.prixUnitaire]: data.prixUnitaire,
            [this.catArtColName.prixUnitaireVente]: data.prixUnitaireVente,
            [this.catArtColName.idCategorieArticle]: data.categorieArticle,
          });
        }
      });
  }

  /**
   * Permet de réinitialiser certaines variable au changement
   * de route.
   */
  ngOnDestroy(): void {
    console.log('art infos update is destroyed');
    this.restore();
  }

  displayFn(subject) {
    return subject ? subject.designation : undefined;
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '0';
  }

  getBenefice() {
    const prixUnitaire = this.editForm.get(this.artInfosColName.prixUnitaire).value;
    const prixVente = this.editForm.get(this.artInfosColName.prixUnitaireVente).value;
    let benefice = 0;
    if(prixUnitaire && prixVente) {
      benefice = prixVente - prixUnitaire;
    }
    return benefice;
  }

  /**
   * Permet d'indiquer si le formulaire de modification contient des erreurs.
   * @returns true si le formulaire contient des erreurs, false sinon.
   */
  validationHasError() {
    return this.editForm.invalid || !this.activatedValidationButton;
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalidButNotRequired(field: string) {
    return this.formService.isInvalidAndDirty(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'maxlength'.
   */
  hasErrorMaxLength(field: string): boolean {
    return this.formService.hasErrorMaxLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  filterOption() {
    this.filteredOptions = this.editForm.get('id_categorie_article').valueChanges.pipe(
      startWith(''),
      map(((value: any) => {
          if (value) {
            if (typeof value === 'object') {
              return this._filter(value.designation);
            } else {
              // value is a string.
              return this._filter(value);
            }
          }
          return this._filter('');
        })
      ));
  }

  _filter(value: string): ICategorieArticle[] {
    const filterValue = value.toLocaleLowerCase();
    return this.getCatArtList().filter(option =>
      option.designation.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(): Observable<ICategorieArticle[]> {
    return this.filteredOptions;
  }

  shouldIShow(): boolean {
    return this.getCatArtList() && this.getCatArtChecked()
      && this.isShow && this.getCatArtList().length !== 0;
  }

  getCatArtList(): ICategorieArticle[] {
    return this.catArtList;
  }

  doINeedToCreateCatArt(): boolean {
    return this.needToCreateCatArt;
  }

  /**
   * @returns l'utilisateur coché pour la modification de ses informations.
   */
  getCatArtChecked(): IArticleInfos {
    return this.restoreArtInfosService.getArtInfosChecked();
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  removeMsgError() {
    this.hasDesignationError = false;
    this.hasCodeError = false;
    this.hasQtAlerteError = false;
    this.hasPrixUnitaireError = false;
  }

  /**
   * Permet de réinitialiser certaines variable au changement
   * de route.
   */
  restore() {
    this.editForm.reset();
    this.removeMsgError();
    this.restoreArtInfosService.restore(this.subs$);
  }

  getAllCatArt() {
    this.needToCreateCatArt = false;
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.catArtService.getAll(option);
    result.subscribe(
      (r: HttpResponse<ICategorieArticle[]>) => {
        console.log('** succès récupération categorie article');
        this.isLoading = false;
        const data = r.body;
        this.catArtList = data;
        this.filterOption();
        if (!data || data.length === 0) {
          this.needToCreateCatArt = true;
        }
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération categorie article');
        this.isLoading = false;
        this.pageRestrictedService.goToRestrictedAccess(NRestricted.msgFull.ARTICLE_INFOS.LIST);
        // this.router.navigate([RGlobal.urlFull.ARTICLE_INFOS.LIST]);
      }
    );
  }

  /**
   * Permet de copier les informations de l'utilisateurs à mettre
   * à jour. Il s'agit des informations du formulaire.
   *
   */
  copy(catArt: IArticleInfos): IArticleInfos {
    const newUser = new ArticleInfos();
    newUser.id = catArt.id;
    newUser.designation = catArt.designation;
    newUser.description = catArt.description;
    newUser.code = catArt.code;
    newUser.prixUnitaireVente = catArt.prixUnitaireVente;
    newUser.quantiteAlerte = catArt.quantiteAlerte;
    newUser.prixUnitaire = catArt.prixUnitaire;
    // CREATE CATEGORIE.
    const cat = new CategorieArticle();
    cat.id = catArt.categorieArticle.id;
    // AFFECT CATEGORIE
    newUser.categorieArticle = cat;
    return newUser;
  }

  /**
   * récupère les données de l'utilisateur du formulaire.
   */
  getUserFromForm(editForm: FormGroup): IArticleInfos {
    return this.userInit.getCategorieFromForm(editForm);
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit avec le formulaire en tapant au moins un caractère.
   */
  reset(editForm: FormGroup) {
    editForm.get(this.catArtColName.designation).markAsPristine();
    editForm.get(this.catArtColName.code).markAsPristine();
    editForm.get(this.catArtColName.quantiteAlerte).markAsPristine();
    editForm.get(this.catArtColName.prixUnitaire).markAsPristine();
  }


  /**
   * Met à jour un utilisateur.
   * @param user l'utilisateur à mettre à jour.
   */
  update() {
    this.activatedValidationButton = false;
    console.log('** updating art infos');
    this.loadWhenUpdating = true;
    this.resetFormValue(this.editForm);
    const userDataForm: IArticleInfos = this.getUserFromForm(this.editForm);
    console.log('## form data: ', userDataForm);

    this.catArtUpdateDTO = this.copy(userDataForm);
    this.catArtUpdateDTO.id = this.restoreArtInfosService.getArtInfosChecked().id;
    this.catArtUpdateDTO.boutique = this.boutiqueSelected;
    const result = this.articleInfosService.update(this.catArtUpdateDTO);
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Réinitialise certaines données du formulaire.
   * @param form le formulaire.
   */
  resetFormValue(form: FormGroup) {
    this.hasCodeError = false;
    this.hasDesignationError = false;
    this.hasQtAlerteError = false;
    this.hasPrixUnitaireError = false;
    this.reset(form);
  }

  /**
   * Gère les erreurs lors de la modification d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromUpdatingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      const fields = erreur.fields;
      this.hasDesignationError = fields[this.catArtColName.designation] ? true : false;
      this.hasCodeError = fields[this.catArtColName.code] ? true : false;
    }
    this.checkForNegativeValueServ(erreur);
  }

  checkForNegativeValueServ(erreur: any) {
    if (erreur.errorCode === this.errorServ.NEGATIVE_VALUE) {
      const fields = erreur.fields;
      this.hasQtAlerteError = fields[this.catArtColName.quantiteAlerte]
        ? true
        : false;
      this.hasPrixUnitaireError = fields[this.catArtColName.prixUnitaire] ? true : false;
    }
  }

  /**
   * Permet récupérer la réponse lors de la modification
   * d'un {@link Utilisateur}
   *
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        const data = res.body;
        if (data) {
          console.log('**** success update art infos: ');
          this.closePopup();
          this.requestService.success(NRequest.ERequest.UPDATE, form,
            RGlobal.urlFull.ARTICLE_INFOS.LIST, this.subs$);
        }
        this.loadWhenUpdating = false;
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        this.activatedValidationButton = true;
        console.log('**** error update art infos: ', err);
        this.loadWhenUpdating = false;
        try {
          this.manageErrorFromUpdatingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }
}
