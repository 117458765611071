import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {AccountUser} from '../../shared/models/account.model';
import {RGlobal} from '../../shared/constant/global.url';
import {GestionType, PrivilegeType} from '../../shared/models/action.model';
import {ICheckingPrivilegeService} from '../employe/ichecking-privilege.service';
import {Boutique} from '../../shared/models/boutique.model';
import {BoutiqueService} from '../boutique/boutique.service';
import {ManageService} from '../../shared/ services/manage.service';
import {CheckingPrivilegeService} from '../../shared/ services/privilege/checking-privilege.service';
import {AccountService} from '../../shared/ services/auth/account/account.service';

/**
 * Permet d'autoriser ou de restreindre l'accès aux routes du module utilisateur en fonction
 * des privilèges d'un utilisateur.
 */
@Injectable({providedIn: 'root'})
export class PrivilegeArticleService implements ICheckingPrivilegeService {
  constructor(
    private boutiqueService: BoutiqueService,
    private manageService: ManageService,
    private accountService: AccountService,
    private checkingPrivilegeService: CheckingPrivilegeService
  ) {
  }

  /**
   * Vérifie si les privilèges de l'utilisateur lui donnent accès aux routes.
   *
   * @param resultPrevious si false, l'utilisateur n'a pas accès à la route, true
   * dans le cas contraire.
   * @param account le compte de l'utilisateur connecté.
   * @param route les informations de la route que l'utilisateur veut accèder.
   */
  checkPrivilegeForAccount(resultPrevious: boolean, account: AccountUser,
                           route: ActivatedRouteSnapshot,
                           boutiqueSelected: Boutique): boolean {
    let result = resultPrevious;
    if (account) {
      if (this.boutiqueService.isUserOwner(account.boutique,
        boutiqueSelected)) {
        result = true;
      } else {
        // const routePath = route.routeConfig.path;
        const routePath = this.manageService.getFullPathSnap(route);
        switch (routePath) {
          case RGlobal.urlFull.ARTICLE.LIST:
            result = this.checkingPrivilegeService
              .hasPrivilege(account.actions, GestionType.ARTICLE, PrivilegeType.CONSULTER,
                boutiqueSelected);
            break;
          case RGlobal.urlFull.ARTICLE.SEARCH:
            result = this.checkingPrivilegeService
              .hasPrivilege(account.actions, GestionType.ARTICLE, PrivilegeType.RECHERCHER,
                boutiqueSelected);
            break;
        }
      }
    }
    return result;
  }
}
