import {NgModule} from '@angular/core';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {LoginComponent} from './login.component';
import {GGuardLoginService} from '../../shared/ services/guard/login/gguard-login.service';
import {AuthPopupComponent} from '../auth-popup/auth-popup.component';

/**
 * module pour la gestion de l'authentification.
 */
@NgModule({
  declarations: [LoginComponent, AuthPopupComponent],
  imports: [GestionStockSharedModule],
  entryComponents: [AuthPopupComponent],
  providers: [GGuardLoginService]
})
export class LoginModule {
}
