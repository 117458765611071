import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {PaginationService} from './service/pagination.service';

/**
 * Permet l'affichage de la pagination.
 */
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnDestroy {
  constructor(
    private paginationService: PaginationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    console.log('** on construct pagination');
  }

  subs$ = new Subject<void>();

  /**
   * Permet la gestion des paramètres de l'url.
   */
  ngOnInit() {
    this.paginationService.onInit(this.activatedRoute, this.router,
      this.subs$);
  }

  ngOnDestroy(): void {
    this.paginationService.restore();
  }


  /**
   * Permet la récupération du service implémentant l'interface
   * {@link IPaginateService}, cela permet de récupérer certaines
   * données du service.
   * @param value le service à récupérer.
   */

  /*@Input('ipaginateService')
  set ipaginateService(value) {
    this.paginationService.setIPaginateService(value);
  }*/

  /**
   * Permet au parent utilisant la pagination d'indiquer
   * s'il a finit de charger ses données avant d'afficher
   * la pagination.
   * @param value vaut true si le parent a terminé de charger
   * ses données, false sinon.
   */
  @Input('parentHasFinishedLoading')
  set parentHasFinishedLoading(value: boolean) {
    this.paginationService.setParentHasFinishedLoading(value);
  }

  shouldIShowPrevious(): boolean {
    return this.paginationService.shouldIShowPrevious();
  }

  /**
   * @returns true pour afficher la pagination, false sinon.
   */
  shouldIShow(): boolean {
    return this.paginationService.shouldIShow();
  }

  /**
   * @returns le nombre totale de pages.
   */
  getTotalPages(): number {
    return this.paginationService.getTotalPages();
  }

  /**
   * Permet de récupérer les données de la page précédente.
   */
  getPrevious() {
    this.paginationService.getPrevious();
  }

  /**
   * Permet de revenir à la page précèdent si l'on est à la dernière
   * page. Ne fais rien si l'on est pas à la dernière page.
   */
  getPreviousPage() {
    this.paginationService.getPreviousPage();
  }

  /**
   * Permet de rendre le bouton précédent actif.
   * @returns true si le numéro de la page précédente ou courante est supérieure à 1.
   */
  isPreviousGreaterThanOne() {
    return this.paginationService.isPreviousGreaterThanOne();
  }


  /**
   * @returns true si l'on est pas encore à la dernière page,
   * sinon false.
   */
  isNotLastPage(): boolean {
    return this.paginationService.isNotLastPage();
  }


  /**
   * Permet de rendre le bouton suivant actif.
   * @returns true si la page courante est inférieur au nombre total
   * d'éléments.
   */
  isCurrentLessThanMax(): boolean {
    return this.paginationService.isCurrentLessThanMax();
  }

  /**
   * Permet de rendre le bouton de la page précédente actif.
   * @returns true si on est sur la page courante, false sinon.
   */
  getActive(): boolean {
    return this.paginationService.getActive();
  }

  /**
   * Permet de rendre le bouton de la page suivante actif.
   * @returns true si on est sur la page suivante, false sinon.
   */
  getActiveCurrent() {
    return this.paginationService.getActiveCurrent();
  }


  /**
   * Permet de récupérer la dernière page.
   */
  getLastPage() {
    return this.paginationService.getLastPage();
  }

  /**
   * @returns true si il y a un intervalle entre la page courante et la dernière
   * page, sinon false.
   */
  canILetThreeDots() {
    return this.paginationService.canILetThreeDots();
  }

  /**
   * @returns le numéro de la page précédente.
   */
  showPreviousPage(): number {
    return this.paginationService.showPreviousPage();
  }

  /**
   * @returns le numéro de la page suivante.
   */
  showCurrentPage() {
    return this.paginationService.showCurrentPage();
  }

  /**
   * Permet de récupérer les données de la page suivante.
   */
  getNext() {
    this.paginationService.getNext();
  }
}
