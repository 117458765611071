import {Moment} from 'moment';
import {IUtilisateur} from './utilisateur.model';
import {IBoutique} from './boutique.model';

export interface ISortie {
  id?: number;
  dateSortie?: Moment;
  utilisateur?: IUtilisateur;
  boutique?: IBoutique;
}

export class Sortie implements ISortie {
  constructor(
    public id?: number,
    public dateSortie?: Moment,
    public utilisateur?: IUtilisateur,
    public boutique?: IBoutique
  ) {
  }
}
