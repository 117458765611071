import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RGlobal} from '../../constant/global.url';

/**
 * Permet de rechercher les {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class PageRestrictedService {
  constructor(private router: Router) {
  }

  private motif: string;

  restore() {
    this.motif = undefined;
  }

  getMotif(): string {
    return this.motif;
  }

  goToRestrictedAccess(m: string) {
    this.motif = m;
    this.router.navigate([RGlobal.url.PAGE_RESTRICTED]);
  }

}
