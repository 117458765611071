import {HttpClient, HttpHandler, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JWT, SERVER_API_URL} from '../../../../constant/model/server.constant';
import {Router} from '@angular/router';
import {ManageService} from '../../../manage.service';
import {RGlobalAdmin} from '../../../../constant/global.url.admin';
import {
  API_AUTH,
  API_USER_ACCOUNT_CREATE,
  API_USER_ACCOUNT_CREATE_MARCHAND_ANONYME,
  API_USER_ACCOUNT_CREATE_RESET,
  API_USER_ACCOUNT_RESET,
  API_USER_ACCOUNT_VALIDATE
} from '../../../../constant/model/api.constant';

/**
 * Permet de vérifier si la session a un nom d'utilisateur et un token valide
 * lors de l'envoie des requêtes au serveur et mettra à jour toutes les requêtes
 * http sortantes.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthHttpInterceptorImplService {
  constructor(
    private http: HttpClient,
    private manageService: ManageService,
    private router: Router) {
    this.apiNotRequiredHeaders.push(SERVER_API_URL + API_AUTH,
      SERVER_API_URL + API_USER_ACCOUNT_CREATE,
      SERVER_API_URL + API_USER_ACCOUNT_CREATE_MARCHAND_ANONYME,
      SERVER_API_URL + API_USER_ACCOUNT_VALIDATE,
      SERVER_API_URL + API_USER_ACCOUNT_CREATE_RESET,
      SERVER_API_URL + API_USER_ACCOUNT_RESET);
  }

  private apiNotRequiredHeaders = [];

  /**
   * Permet de vérifier si la session a un nom d'utilisateur et un token valide
   * lors de l'envoie des requêtes au serveur et mettra à jour toutes les requêtes
   * http sortantes.
   *
   * @param req req.
   * @param next next.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    console.log('** intercept execute');
    if (this.manageService.getFirstOfUrl(this.router) === RGlobalAdmin.url.LOGIN) {
      req = this.createAdminHeader(req);
    } else {
      console.log('url: ' + req.urlWithParams);
      if (!this.apiNotRequiredHeaders.includes(req.urlWithParams)) {
        console.log('url2: ' + req.urlWithParams);
        req = this.createUserHeader(req);
      }
    }
    return next.handle(req);
  }

  private createAdminHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (
      localStorage.getItem(JWT.usernameAdmin) &&
      localStorage.getItem(JWT.tokenAdmin)
    ) {
      console.log('creating headers for admin');
      return req.clone({
        setHeaders: {
          Authorization: localStorage.getItem(JWT.tokenAdmin)
        }
      });
    }
    return req;
  }

  private createUserHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (localStorage.getItem(JWT.username) &&
      localStorage.getItem(JWT.token)) {
      console.log('creating headers for user');
      return req.clone({
        setHeaders: {
          Authorization: localStorage.getItem(JWT.token)
        }
      });
    }
    return req;
  }
}
