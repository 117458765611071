import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../../shared/constant/model/server.constant';
import {
  API_SORTIE,
  API_SORTIE_BILAN,
  API_SORTIE_BILAN_ABONNEMENT,
  API_SORTIE_BILAN_TAKE_AB,
  API_SORTIE_DELETE,
  API_SORTIE_DONNEE_TAKE_AB,
  API_SORTIE_FACTURE,
  API_SORTIE_FACTURE_ABONNEMENT,
  API_SORTIE_FACTURE_TAKE_AB,
  API_SORTIE_MONTANT_TOTAL,
  API_SORTIE_MONTANT_TOTAL_ACHAT,
  API_SORTIE_PAGE,
  API_SORTIE_PROD_VENDU,
  API_SORTIE_PROD_VENDU_TAKE_AB,
  API_SORTIE_PRODUIT_ABONNEMENT,
  API_SORTIE_SEARCH,
  API_SORTIE_SEARCH_AVANCE,
  API_SORTIE_SEARCH_MONTANT_TOTAL,
  API_SORTIE_SEARCH_MONTANT_TOTAL_ACHAT,
  API_SORTIE_SEARCH_MONTANT_TOTAL_ACHAT_AVANCE,
  API_SORTIE_SEARCH_MONTANT_TOTAL_AVANCE
} from '../../shared/constant/model/api.constant';
import {Observable} from 'rxjs';
import {SortieDetails} from '../../shared/models/sortie-details.model';
import {ProduitVendu} from '../../shared/models/produit-vendu.model';
import {Bilan} from '../../shared/models/bilan.model';
import {AbonnementBilan} from '../../shared/models/abonnement-bilan.model';
import {AbonnementFacture} from '../../shared/models/abonnement-facture.model';
import {AbonnementProduitPlusAchete} from '../../shared/models/abonnement-produit-plus-achete.model';
import {DataLimit} from '../../shared/models/data-limit.model';

@Injectable({providedIn: 'root'})
export class SortieService {
  constructor(
    private http: HttpClient,
  ) {
    console.log('construct art service');
  }

  // VAR.
  private resourceUrlDonneeTakeAb = SERVER_API_URL + API_SORTIE_DONNEE_TAKE_AB;

  private resourceUrlSearchMontantTotalAchatAvance = SERVER_API_URL + API_SORTIE_SEARCH_MONTANT_TOTAL_ACHAT_AVANCE;
  private resourceUrlSearchMontantTotalAchat = SERVER_API_URL + API_SORTIE_SEARCH_MONTANT_TOTAL_ACHAT;

  private resourceUrlSearchMontantTotalAvance = SERVER_API_URL + API_SORTIE_SEARCH_MONTANT_TOTAL_AVANCE;
  private resourceUrlSearchMontantTotal = SERVER_API_URL + API_SORTIE_SEARCH_MONTANT_TOTAL;

  private resourceUrlSearchAvance = SERVER_API_URL + API_SORTIE_SEARCH_AVANCE;
  private resourceUrlSearch = SERVER_API_URL + API_SORTIE_SEARCH;

  private resourceUrl = SERVER_API_URL + API_SORTIE;
  private resourceUrlFactureAbonnement = SERVER_API_URL + API_SORTIE_FACTURE_ABONNEMENT;
  private resourceUrlProduitAbonnement = SERVER_API_URL + API_SORTIE_PRODUIT_ABONNEMENT;
  private resourceUrlBilanAbonnement = SERVER_API_URL + API_SORTIE_BILAN_ABONNEMENT;

  private resourceUrlBilan = SERVER_API_URL + API_SORTIE_BILAN;
  private resourceUrlBilanTakeAb = SERVER_API_URL + API_SORTIE_BILAN_TAKE_AB;
  private resourceUrlProdVendu = SERVER_API_URL + API_SORTIE_PROD_VENDU;
  private resourceUrlProdVenduTakeAb = SERVER_API_URL + API_SORTIE_PROD_VENDU_TAKE_AB;
  private resourceUrlFactureTakeAb = SERVER_API_URL + API_SORTIE_FACTURE_TAKE_AB;
  private resourceUrlFacture = SERVER_API_URL + API_SORTIE_FACTURE;
  private resourceUrlDelete = SERVER_API_URL + API_SORTIE_DELETE;
  private resourceUrlPage = SERVER_API_URL + API_SORTIE_PAGE;

  private resourceUrlMontantTotalAchat = SERVER_API_URL + API_SORTIE_MONTANT_TOTAL_ACHAT;
  private resourceUrlMontantTotal = SERVER_API_URL + API_SORTIE_MONTANT_TOTAL;

  private _refreshBtnActive = true;

  get refreshBtnActive(): boolean {
    return this._refreshBtnActive;
  }

  set refreshBtnActive(value: boolean) {
    this._refreshBtnActive = value;
  }


  takeAbonnementDonnee(params): Observable<HttpResponse<DataLimit>> {
    return this.http.get<DataLimit>(
      this.resourceUrlDonneeTakeAb, {
        observe: 'response',
        params
      });
  }

  takeAbonnementProduit(params): Observable<HttpResponse<AbonnementProduitPlusAchete>> {
    return this.http.get<AbonnementProduitPlusAchete>(this.resourceUrlProdVenduTakeAb, {
      observe: 'response',
      params
    });
  }

  takeAbonnementFacture(params): Observable<HttpResponse<AbonnementFacture>> {
    return this.http.get<AbonnementFacture>(this.resourceUrlFactureTakeAb, {
      observe: 'response',
      params
    });
  }

  takeAbonnementBilan(params): Observable<HttpResponse<AbonnementBilan>> {
    return this.http.get<AbonnementBilan>(this.resourceUrlBilanTakeAb, {
      observe: 'response',
      params
    });
  }

  checkAbonnementBilan(params): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(this.resourceUrlBilanAbonnement, {
      observe: 'response',
      params
    });
  }

  checkAbonnementProduit(params): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(this.resourceUrlProduitAbonnement, {
      observe: 'response',
      params
    });
  }

  checkAbonnementFacture(params): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(this.resourceUrlFactureAbonnement, {
      observe: 'response',
      params
    });
  }

  getBilan(params): Observable<HttpResponse<Bilan>> {
    return this.http.get<Bilan>(this.resourceUrlBilan, {
      observe: 'response',
      params
    });
  }

  getProduitVendu(params): Observable<HttpResponse<ProduitVendu[]>> {
    return this.http.get<any>(this.resourceUrlProdVendu, {
      observe: 'response',
      params
    });
  }

  getFacture(params): Observable<HttpResponse<SortieDetails[]>> {
    return this.http.get<SortieDetails[]>(this.resourceUrlFacture, {
      observe: 'response',
      params
    });
  }

  searchAvance(params): Observable<HttpResponse<SortieDetails[]>> {
    return this.http.get<SortieDetails[]>(this.resourceUrlSearchAvance, {
      observe: 'response',
      params
    });
  }

  search(params): Observable<HttpResponse<SortieDetails[]>> {
    return this.http.get<SortieDetails[]>(this.resourceUrlSearch, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalAchatAvance(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalAchatAvance, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalAchat(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalAchat, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalAvance(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalAvance, {
      observe: 'response',
      params
    });
  }

  searchMontantTotal(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotal, {
      observe: 'response',
      params
    });
  }

  create(data): Observable<HttpResponse<SortieDetails[]>> {
    return this.http.post<SortieDetails[]>(this.resourceUrl, data, {
      observe: 'response',
    });
  }

  update(data): Observable<HttpResponse<boolean>> {
    return this.http.put<boolean>(this.resourceUrl, data, {
      observe: 'response',
    });
  }

  delete(params): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>(this.resourceUrlDelete, {
      observe: 'response',
      params
    });
  }

  getList(params): Observable<HttpResponse<SortieDetails>> {
    return this.http.get<SortieDetails>(this.resourceUrlPage, {
      observe: 'response',
      params
    });
  }


  getMontantTotal(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlMontantTotal, {
      observe: 'response',
      params
    });
  }

  getMontantTotalAchat(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlMontantTotalAchat, {
      observe: 'response',
      params
    });
  }
}
