import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {FormReusableService} from '../../reusable/services/form-reusable.service';

/**
 * permet de gérer la validité des informations d'un formulaire.
 */
@Injectable({providedIn: 'root'})
export class FormService {

  constructor(private formReusableService: FormReusableService) {
  }

  formatUs(num: number): string {
    return num.toLocaleString('en-us', {minimumFractionDigits: 2});
  }

  formatUsWithNoFractionDigit(num: number): string {
    return num.toLocaleString('en-us', {minimumFractionDigits: 0});
  }

  formatNomBoutique(s: string): string {
    return this.getNbChar(s, 15);
  }

  getNbChar(s: string, n: number): string {
    if (s) {
      return s.slice(0, n);
    }
    return '';
  }

  formatId(id: string) {
    let result;
    if (id) {
      result = '';
      const limit = 4;
      let j = 0;
      for (const i of id) {
        j += 1;
        if (j > limit) {
          result += '-';
          result += i;
          j = 1;
        } else {
          result += i;
        }
      }
    }
    return result;
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  markAbstractControlTouched(formGroup: FormGroup) {
    this.formReusableService.markAbstractControlTouched(formGroup);
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   */
  isEmailInvalid(field: string, form: AbstractControl): boolean {
    return this.formReusableService.isEmailInvalid(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string, form: AbstractControl): boolean {
    return this.formReusableService.isPristine(field, form);
  }

  /**
   *
   * @param field champ à vérifier.
   * @param form formulaire contenant le champ.
   * @returns ..
   */
  isUnTouched(field: string, form: AbstractControl): boolean {
    return this.formReusableService.isUnTouched(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns true si le champs à perdu le focus.
   */
  isTouched(field: string, form: AbstractControl): boolean {
    return this.formReusableService.isTouched(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @return true si l'utilisateur à effectuer une saisie sur le champs.
   */
  isDirty(field: string, form: AbstractControl): boolean {
    return this.formReusableService.isDirty(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @return true si le champs est invalide.
   */
  isInvalid(field: string, form: AbstractControl): boolean {
    return this.formReusableService.isInvalid(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contnant le champs.
   * @returns true si le formulaire est invalide.
   */
  isInvalidAndDirtyOrTouched(field: string, form: AbstractControl): boolean {
    return this.formReusableService
      .isInvalidAndDirtyOrTouched(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contnant le champs.
   * @returns true si le formulaire est invalide.
   */
  isInvalidAndDirty(field: string, form: AbstractControl): boolean {
    return this.formReusableService.isInvalidAndDirty(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns le nombre maximum de caractères autorisés pour le champ.
   */
  getMaxLength(field: string, form: AbstractControl): number {
    return this.formReusableService.getMaxLength(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string, form: AbstractControl): number {
    return this.formReusableService.getMinLength(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns true si l'utilisateur a dépassé le nombre maximum de caractères autorisés.
   */
  hasErrorMaxLength(field: string, form: AbstractControl): boolean {
    return this.formReusableService.hasErrorMaxLength(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs.
   * @returns true si l'utilisateur a dépassé le nombre minimum de caractères autorisés.
   */
  hasErrorMinLength(field: string, form: AbstractControl): boolean {
    return this.formReusableService.hasErrorMinLength(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs à vérifier.
   * @returns true si le champs field est requis.
   */
  hasErrorRequired(field: string, form: AbstractControl): boolean {
    return this.formReusableService.hasErrorRequired(field, form);
  }

  /**
   * @param field champs à vérifier.
   * @param form formulaire contenant le champs à vérifier.
   * @returns true si le champs field est requis.
   */
  hasErrorPattern(field: string, form: AbstractControl): boolean {
    return this.formReusableService.hasErrorPattern(field, form);
  }

  /**
   * détermine si deux chaînes sont égales.
   * @param ch1 la chaîne à comparer.
   * @param ch2 la chaîne à comparer
   * @returns true si les deux chaines sont égales
   * false sinon.
   */
  isEqual(ch1: string, ch2: string) {
    return this.formReusableService.isEqual(ch1, ch2);
  }

  /**
   * détermine si la chaine en paramètre ne contient que des nombres.
   * @param number la chaîne à vérifier.
   * @returns true si la chaîne passée en paramètre ne contient que
   * des nombres sinon false.
   */
  hasOnlyNumber(nombre: string): boolean {
    return this.formReusableService.hasOnlyNumber(nombre);
  }

  /**
   * permet de récupérer les données d'un formulaire.
   * @param form le formulaire
   * @param name le nom du chanmp dans le formulaire.
   * @param trimSpace par défaut vaut true. si true, enlève les espaces au début et à la fin
   * de la chaîne, si false n'enlève pas les espaces.
   */
  getData(name: string, form: AbstractControl, fields: string[], trimSpace = true) {
    return this.formReusableService.getData(name, form, fields, trimSpace);
  }

  getValue(name: string, form: AbstractControl) {
    return this.formReusableService.getValue(name, form);
  }

  /**
   * permet de récupérer les données d'un formulaire.
   *
   * @param form le formulaire
   * @param name le nom du chanmp dans le formulaire.
   * @param trimSpace par défaut vaut true. si true, enlève les espaces au début et à la fin
   * de la chaîne, si false n'enlève pas les espaces.
   */
  getDataObj(name: string, form: AbstractControl) {
    return this.formReusableService.getDataObj(name, form);
  }
}
