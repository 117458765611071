import {Component, OnDestroy, OnInit} from '@angular/core';
import {CheckingBoutique} from '../../employe/checking-boutique.service';
import {Router} from '@angular/router';
import {AbonnementService} from '../abonnement.service';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {BoutiqueService} from '../../boutique/boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {AccountUser} from '../../../shared/models/account.model';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Uri} from '../../../shared/constant/model/global.constant';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ConstantAbonnementBilanPay} from '../../../shared/constant/abonnement/abonnement-bilan-pay.constant';
import {AppInfos} from '../../../shared/models/app-infos.model';
import {FormService} from '../../../shared/ services/form.service';
import {HomeService} from '../../../pages/home/home.service';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-abonnement-bilan',
  templateUrl: './abonnement-bilan.component.html'
})
export class AbonnementBilanComponent implements OnInit, OnDestroy {
  constructor(
    private homeService: HomeService,
    private formService: FormService,
    private checkingBoutique: CheckingBoutique,
    private router: Router,
    private abonnementService: AbonnementService,
    private constantAbonnementBilanPay: ConstantAbonnementBilanPay,
    private freeMemoryService: FreeMemoryService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
  }

  showLoading = false;
  boutiqueSelected: Boutique;
  appInfos: AppInfos;
  open = true;
  shouldIShow = false;
  accountUser: AccountUser;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('abonnement bilan');
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getUserIdentityObs(this.accountService);
    });
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
    });
    this.accountService.getAppInfos().subscribe(res => {
      this.appInfos = res.body;
    });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  formatId(id: string) {
    return this.formService.formatId(id);
  }

  getAccount(): AccountUser {
    return this.homeService.getAccount();
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  getOffer(nombreBoutique: number) {
    if (this.checkingBoutique.isSelected()) {
      console.log('get offer: ' + nombreBoutique);
      this.showLoading = true;
      const data = {
        nombre: nombreBoutique,
        [Uri.BOUTIQUE]: this.boutiqueSelected
      };
      const result = this.abonnementService.getOfferBilan(data);
      this.subscribeToSaveResponse(result);
    }
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<string>>,
  ) {
    result.subscribe(
      (res: HttpResponse<string>) => {
        console.log('**** success abonnement bilan: ');
        console.log('res: ' + res.body);
        try {
          window.location.href = res.body;
        } catch (e) {
          console.log('erreur validation: ' + (e as Error).message);
        }
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error abonnement bilan: ');
        this.showLoading = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  getAbonnement() {
    return this.constantAbonnementBilanPay;
  }


  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }
}
