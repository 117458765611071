import {Injectable} from '@angular/core';
import {FormService} from '../../../shared/ services/form.service';
import {ConstantUserColumnName} from '../../../shared/constant/model/column-name/user-column-name.constant';
import {ConstantUserValidator} from '../../../shared/constant/validator/user-validator.constant';
import {FormGroup, Validators} from '@angular/forms';
import {IUtilisateur, Utilisateur} from '../../../shared/models/utilisateur.model';
import {ConstantUserSize} from '../../../shared/constant/model/size/user-size.constant';

/**
 * Offre plusieurs types utilisés pour se connecter
 */
export namespace UpdateProfileNm {
  /**
   * Permet d'avoir le nom des champs pour créer un compte.
   */
  export interface ColumnName {
    login: string;
    telephone: string;
    nationalite: string;
    adresse: string;
    dateNaissance: string;
    password: string;
    oldPassword: string;
    nom: string;
    prenom: string;
    sexe: string;
    email: string;
  }
}

/**
 * Permet d'initialiser le component {@link LoginComponent}
 */
@Injectable({providedIn: 'root'})
export class UpdateProfileInit {
  constructor(
    private userSize: ConstantUserSize,
    private formService: FormService,
    private userColName: ConstantUserColumnName,
    private userValidator: ConstantUserValidator
  ) {
  }

  /**
   * récupère les données d'authentification du formulaire.
   */
  public getAuthFromFormEmail(editForm: FormGroup): IUtilisateur {
    const entity = {
      ...new Utilisateur(),
      oldPassword: this.formService.getData(
        this.userColName.OLD_PASSWORD,
        editForm, this.userColName.fields,
        false
      ),
      email: this.formService.getData(this.userColName.EMAIL, editForm, this.userColName.fields)
    };
    return entity;
  }

  /**
   * récupère les données d'authentification du formulaire.
   */
  public getAuthFromForm(editForm: FormGroup): IUtilisateur {
    const entity = {
      ...new Utilisateur(),
      login: this.formService.getData(this.userColName.LOGIN, editForm, this.userColName.fields),
      password: this.formService.getData(
        this.userColName.PASSWORD,
        editForm, this.userColName.fields,
        false
      ),
      nom: this.formService.getData(this.userColName.NOM, editForm, this.userColName.fields),
      prenom: this.formService.getData(this.userColName.PRENOM, editForm, this.userColName.fields),
      email: this.formService.getData(this.userColName.EMAIL, editForm, this.userColName.fields),
      nationalite: this.formService.getData(this.userColName.NATIONALITE, editForm, this.userColName.fields),
      adresse: this.formService.getData(this.userColName.ADRESSE, editForm, this.userColName.fields),
      //   dateNaissance: this.formService.getValue(this.userColName.DATE_NAISSANCE, editForm),
      telephone: this.formService.getData(this.userColName.TELEPHONE, editForm, this.userColName.fields),
      sexe: this.formService.getDataObj(this.userColName.SEXE, editForm)
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour se connecter.
   */
  getAuthColumnName(): UpdateProfileNm.ColumnName {
    return {
      login: this.userColName.LOGIN,
      password: this.userColName.PASSWORD,
      oldPassword: this.userColName.OLD_PASSWORD,
      nom: this.userColName.NOM,
      prenom: this.userColName.PRENOM,
      email: this.userColName.EMAIL,
      nationalite: this.userColName.NATIONALITE,
      telephone: this.userColName.TELEPHONE,
      adresse: this.userColName.ADRESSE,
      dateNaissance: this.userColName.DATE_NAISSANCE,
      sexe: this.userColName.SEXE
    };
  }



  /**
   * @returns Formulaire de connexion.
   */
  getFormEmail(): any {
    return {
      oldPassword: [
        null,
        [
          Validators.required
        ]
      ],
      email: this.userValidator.EMAIL
    };
  }

  /**
   * @returns Formulaire de connexion.
   */
  getForm(): any {
    return {
      login: this.userValidator.LOGIN,
      password: [
        null,
        [
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\'#$%&\'()*+,-./:;<=>?@^_{|}~]).{1,}'),
          Validators.minLength(this.userSize.PASSWORD_MIN_SIZE),
          Validators.maxLength(this.userSize.PASSWORD_MAX_SIZE)
        ]
      ],
      nom: this.userValidator.NOM,
      prenom: this.userValidator.PRENOM,
      email: this.userValidator.EMAIL,
      nationalite: this.userValidator.NATIONALITE,
      telephone: this.userValidator.TELEPHONE,
      adresse: this.userValidator.ADRESSE,
      dateNaissance: this.userValidator.DATE_NAISSANCE,
      sexe: this.userValidator.SEXE
    };
  }
}
