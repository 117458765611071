import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { ConstantCategorieArticleSize } from '../model/size/categorie-article-size.constant';

/**
 * Permet la validation des champs pour une {@link CategorieArticle}.
 */
@Injectable({ providedIn: 'root' })
export class ConstantCategorieArticleValidator {
  constructor(private categorieSize: ConstantCategorieArticleSize) {}

  readonly DESIGNATION = [
    null,
    [
      Validators.required,
      Validators.minLength(this.categorieSize.DESIGNATION_MIN_SIZE),
      Validators.maxLength(this.categorieSize.DESIGNATION_MAX_SIZE)
    ]
  ];

  readonly CODE = [
    null,
    [
      Validators.required,
      Validators.minLength(this.categorieSize.CODE_MIN_SIZE),
      Validators.maxLength(this.categorieSize.CODE_MAX_SIZE)
    ]
  ];

  readonly DESCRIPTION = [
    null,
    []
  ];
}
