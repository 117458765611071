import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormService} from '../../../shared/ services/form.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ConstantUserValidator} from '../../../shared/constant/validator/user-validator.constant';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {UserAccountService} from '../user-account.service';
import {RsaService} from '../../../shared/ services/rsa.service';

/**
 * Permet de créer un compte utilisateur.
 */
@Component({
  selector: 'app-reset-password-validate',
  templateUrl: './reset-password-validate.component.html'
})
export class ResetPasswordValidateComponent implements OnInit, OnDestroy {
  constructor(
    private rsaService: RsaService,
    private userAccountService: UserAccountService,
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private formSignIn: FormBuilder,
    private errorServ: ConstantErrorServ,
    private http: HttpClient,
    private userValidator: ConstantUserValidator
  ) {
  }

  // VAR.
  hasCodeError = false;
  key: string;
  subs$ = new Subject<void>();
  editForm = this.formSignIn.group({
    code: [
      null,
      [
        Validators.required,
      ]
    ],
    password: this.userValidator.PASSWORD
  });
  validationHasSucceed = false;
  codeKey = 'code';
  keyParam = 'key';
  passwordKey = 'password';

  ngOnInit(): void {
    console.log('init reset password validate');
    this.activatedRoute.queryParams.pipe(takeUntil(this.subs$))
      .subscribe(params => {
        this.key = params[this.keyParam];
        console.log('key: ' + this.key);
      });
  }

  ngOnDestroy(): void {
    this.removeMsgError();
    this.validationHasSucceed = false;
  }

  removeMsgError() {
    this.hasCodeError = false;
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit avec le formulaire en tapant au moins un caractère.
   */
  reset(editForm: FormGroup) {
    editForm.get(this.codeKey).markAsPristine();
    editForm.get(this.passwordKey).markAsPristine();
  }

  resetPassword() {
    console.log('reset password');
    this.removeMsgError();
    this.reset(this.editForm);
    const user = {
      code: this.formService.getData(this.codeKey,
        this.editForm, []),
      password: this.formService.getData(this.passwordKey,
        this.editForm, [], false),
      key: this.key
    };
    user.password = this.rsaService.crypt(user.password);
    user.code = this.rsaService.crypt(user.code);
    const result = this.userAccountService.resetPassword(user);
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<any>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<any>) => {
        console.log('**** success reset password: ');
        this.validationHasSucceed = true;
        this.editForm.reset();
      },
      (err: HttpErrorResponse) => {
        console.log('**** error reset password: ');
        this.validationHasSucceed = false;
        this.hasCodeError = true;
      }
    );
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   */
  isEmailInvalid(field: string): boolean {
    return this.formService.isEmailInvalid(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorPattern(field: string): boolean {
    return this.formService.hasErrorPattern(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

}
