import {SearchUserVerification} from './search-user-verification';
import {AbsFactoryVerification, ETypeVerification} from './abs-factory-verification';

/**
 * Permet créer des objets permettant d'effectuer des vérifications
 * lors de la recherche par filtre.
 */
export class FactoryCreateVerification {
  static getCheck(v: ETypeVerification): AbsFactoryVerification {
    if (v === ETypeVerification.SEARCH) {
      return new SearchUserVerification();
    }
    return new SearchUserVerification();
  }
}
