import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SERVER_API_URL} from '../../shared/constant/model/server.constant';
import {
  API_ABONNEMENT_APPLICATION,
  API_ABONNEMENT_APPLICATION_PAY,
  API_ABONNEMENT_APPLICATION_VERIFY,
  API_ABONNEMENT_BILAN,
  API_ABONNEMENT_BILAN_SUCCESS,
  API_ABONNEMENT_BOUTIQUE,
  API_ABONNEMENT_BOUTIQUE_SUCCESS,
  API_ABONNEMENT_FACTURE,
  API_ABONNEMENT_FACTURE_SUCCESS,
  API_ABONNEMENT_PRODUIT,
  API_ABONNEMENT_PRODUIT_SUCCESS,
  API_ABONNEMENT_USER_PAY
} from '../../shared/constant/model/api.constant';
import {AbonnementApplication} from '../../shared/models/abonnement-application.model';
import {Paydto} from '../../shared/models/paydto.model';

@Injectable({providedIn: 'root'})
export class AbonnementService {
  // VAR
  private resourceUrlApplicationPay = SERVER_API_URL + API_ABONNEMENT_APPLICATION_PAY;
  private resourceUrlUserPay = SERVER_API_URL + API_ABONNEMENT_USER_PAY;
  private resourceUrlApplicationVerify = SERVER_API_URL + API_ABONNEMENT_APPLICATION_VERIFY;
  private resourceUrlApplication = SERVER_API_URL + API_ABONNEMENT_APPLICATION;
  private resourceUrlProduit = SERVER_API_URL + API_ABONNEMENT_PRODUIT;
  private resourceUrlBilan = SERVER_API_URL + API_ABONNEMENT_BILAN;
  private resourceUrlFacture = SERVER_API_URL + API_ABONNEMENT_FACTURE;
  private resourceUrlBoutique = SERVER_API_URL + API_ABONNEMENT_BOUTIQUE;
  private resourceUrlBoutiqueSuccess = SERVER_API_URL + API_ABONNEMENT_BOUTIQUE_SUCCESS;
  private resourceUrlProduitSuccess = SERVER_API_URL + API_ABONNEMENT_PRODUIT_SUCCESS;
  private resourceUrlBilanSuccess = SERVER_API_URL + API_ABONNEMENT_BILAN_SUCCESS;
  private resourceUrlFactureSuccess = SERVER_API_URL + API_ABONNEMENT_FACTURE_SUCCESS;

  constructor(
    private http: HttpClient,
  ) {
    console.log('construct abonnement service');
  }

  createAbUser(data?) {
    return this.http.post<Paydto>(this.resourceUrlUserPay, data, {
      observe: 'response'
    });
  }

  createAbApp(data) {
    return this.http.post<Paydto>(this.resourceUrlApplicationPay, data, {
      observe: 'response'
    });
  }

  verifyAbonnementApplication() {
    return this.http.get<boolean>(this.resourceUrlApplicationVerify, {
      observe: 'response',
    });
  }

  getAbonnementApplication() {
    return this.http.get<AbonnementApplication>(this.resourceUrlApplication, {
      observe: 'response',
    });
  }

  validateAbonnementProduit(dataParam) {
    let options = new HttpParams();
    Object.keys(dataParam).forEach(key => {
      options = options.set(key, dataParam[key]);
    });
    return this.http.get<string>(this.resourceUrlProduitSuccess, {
      observe: 'response',
      responseType: 'text' as 'json',
      params: options
    });
  }

  validateAbonnementBilan(dataParam) {
    let options = new HttpParams();
    Object.keys(dataParam).forEach(key => {
      options = options.set(key, dataParam[key]);
    });
    return this.http.get<string>(this.resourceUrlBilanSuccess, {
      observe: 'response',
      responseType: 'text' as 'json',
      params: options
    });
  }

  validateAbonnementFacture(dataParam) {
    let options = new HttpParams();
    Object.keys(dataParam).forEach(key => {
      options = options.set(key, dataParam[key]);
    });
    return this.http.get<string>(this.resourceUrlFactureSuccess, {
      observe: 'response',
      responseType: 'text' as 'json',
      params: options
    });
  }

  validateAbonnementNombreBoutique(dataParam) {
    let options = new HttpParams();
    Object.keys(dataParam).forEach(key => {
      options = options.set(key, dataParam[key]);
    });
    return this.http.get<string>(this.resourceUrlBoutiqueSuccess, {
      observe: 'response',
      responseType: 'text' as 'json',
      params: options
    });
  }

  getOfferBilan(data) {
    return this.http.post<string>(this.resourceUrlBilan, data, {
      observe: 'response',
      responseType: 'text' as 'json'
    });
  }

  getOfferProduit(data) {
    return this.http.post<string>(this.resourceUrlProduit, data, {
      observe: 'response',
      responseType: 'text' as 'json'
    });
  }

  getOfferFacture(data) {
    return this.http.post<string>(this.resourceUrlFacture, data, {
      observe: 'response',
      responseType: 'text' as 'json'
    });
  }

  getOfferBoutique(data) {
    return this.http.post<string>(this.resourceUrlBoutique, data, {
      observe: 'response',
      responseType: 'text' as 'json'
    });
  }
}
