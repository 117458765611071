import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DefaultValue} from '../reusable/data-show.pipe';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {NotificationCs} from './constant/model/notification.constant';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RouterModule} from '@angular/router';
import {CheckboxOneDirective} from '../reusable/checkbox-one-directive';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';
import {MatSpinnerMediumComponent} from '../reusable/services/mat-spinner/mat-spinner-medium/mat-spinner-medium.component';
import {MatSpinnerSmallComponent} from '../reusable/services/mat-spinner/mat-spinner-small/mat-spinner-small.component';
import {MenuToggleDirective} from '../reusable/menu-toggle-directive';
import {NumericDirective} from '../reusable/numeric-directive';
import {RemoveFocusDirective} from '../reusable/services/remove-focus-directive';
import {NegativeNumber} from '../reusable/negative-number.pipe';
import {AddFocusDirective} from '../reusable/services/add-focus-directive';
import {VarDirective} from '../reusable/var-directive';
import {PaginationComponent} from './layouts/pagination/pagination.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// @ts-ignore
@NgModule({
  declarations: [
    VarDirective, PaginationComponent,
    DefaultValue, NegativeNumber, RemoveFocusDirective, NumericDirective, MatSpinnerSmallComponent,
    MatSpinnerMediumComponent, AddFocusDirective, MenuToggleDirective, CheckboxOneDirective],
  imports: [BrowserModule, MatBadgeModule, MatAutocompleteModule, MatSelectModule, MatFormFieldModule,
    MatInputModule, MatGridListModule, MatProgressSpinnerModule, FormsModule, NgbModule,
    ReactiveFormsModule, RouterModule, MatCardModule,
    CommonModule, BrowserAnimationsModule, MatMenuModule, MatToolbarModule,
    MatExpansionModule, MatListModule, MatIconModule, MatSidenavModule, MatButtonModule,
    MatTableModule, MatCheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: NotificationCs.TIMEOUT,
      positionClass: NotificationCs.DEFAULT_POSITION,
      preventDuplicates: NotificationCs.IS_DUPLICATE,
    })],
  exports: [VarDirective, PaginationComponent, FormsModule, MatBadgeModule, MatAutocompleteModule, MatSelectModule, MatFormFieldModule, MatInputModule,
    RemoveFocusDirective, MatGridListModule,
    NumericDirective, AddFocusDirective, MatSpinnerSmallComponent,
    MatSpinnerMediumComponent, MatProgressSpinnerModule, ReactiveFormsModule, RouterModule,
    CommonModule, TranslateModule, NgbModule, BrowserAnimationsModule, NegativeNumber, DefaultValue,
    CheckboxOneDirective, MenuToggleDirective, MatMenuModule, MatToolbarModule, MatExpansionModule,
    MatListModule, MatIconModule, MatSidenavModule, MatButtonModule, MatCardModule,
    MatTableModule, MatCheckboxModule],
  // providers: [GGuardIsLoginService]
})
export class GestionStockSharedModule {

  static forRoot() {
    return {
      ngModule: GestionStockSharedModule
    };
  }
}
