import {Injectable} from '@angular/core';
import {JSEncrypt} from 'jsencrypt';

/**
 * Fournit plusieurs services.
 */
@Injectable({providedIn: 'root'})
export class RsaService {

  // key development
  // private readonly publiqueKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkdMkl6XMvzolM9yDmL1wNAVGtInt15NMUQKGUEoToOr4o7pEHoGDsLt9lzuZNu+8NGCRzP3QJvMiID7juI/yFwZXubkRcTMhfQQyIdI1Rc0JNzx6Kj6o3yJQHZA7M9+VLa9DLXKALX8czSCFb4a0ClqH7ynRl5lD8tW6n15aDTOsc/Mcb69Jjv71Xj7MeLHmXY2G3Y7+fu85jutDLlYpKFTfbL8SvvgfSzogfOEKlVCwpHNxgvP/nRTNjIkCkEyROCW3Qm1CO22icrDeeYTfjjhZw2Hgy8Qkv/v5sIN0+RCbUqDbvNLLRBTC9A6kqY5w3J2vEcNt5HLmhK8KJcuGgwIDAQAB';

  // key for production.
  private readonly publiqueKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxNv293rMSudN3AS5dGM306bLlY4Psf1xMfZ9JsafUXfPQLOeLSDRGLQlIVDL8xyncDnbNfRvxKo9OXkFzyhViDX+6kOXPdi5tCUKwb6lkYJ81zuj6dvUZ/jsVfPuQIzdPaIk1dyxW3smUnyW40T3Ao+/ORw6A2QoobOAH98UFtiFh6DQ7ezMrVE1Szl5DUcVRcFPDW8lQH7vMTnVAMuZoXnh/9f6DjsXP51ydW/fdKUaNoxNI4ZT3w3vHbqrydJsozROoI6EBKohdwgNVh7eEQ8SlF4m6g+2hDPLkwnvALNg+TA/he4qREhNFX2AS6HE3PdIJHbEXdECvybuEBuKPwIDAQAB';

  constructor() {
  }

  /**
   * Permet de crypter des données en RSA.
   * @param data la donnée à crypter
   */
  crypt(data: any): any {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(this.publiqueKey);
    return encrypt.encrypt(data);
  }

}
