import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GestionStockSharedModule} from './main/shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthHttpInterceptorService} from './main/shared/ services/auth/interceptor/auth/auth-interceptor.service';
import {ErrorInterceptor} from './main/shared/ services/auth/interceptor/error/error-interceptor.service';
import {LoginModule} from './main/pages/login/login.module';
import {HomeModule} from './main/pages/home/home.module';
import {LocaleCsService} from './main/shared/ services/localecs.service';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

// const LAYOUT_ROUTES = [...userRoute];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LoginModule,
    HomeModule,
    GestionStockSharedModule.forRoot(),
    BrowserAnimationsModule
  ],
  exports: [],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LocaleCsService],
      useFactory: (LocaleCsService:
                     { locale: string; }) => LocaleCsService.locale
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
