import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {ArticleShowInit} from '../article-show/article-show.init';
import {takeUntil} from 'rxjs/operators';
import {IArticle} from '../../../shared/models/article.model';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {RGlobal} from '../../../shared/constant/global.url';
import {TranslateService} from '@ngx-translate/core';
import {DeleteSuccessService} from '../../../shared/ services/delete/delete-success.service';
import {RestoreArticleService} from '../restore-article.service';
import {IDeleteSuccessService} from '../../../shared/ services/delete/idelete-success.service';
import {ConstantArticleColumnName} from '../../../shared/constant/model/column-name/article-column-name.constant';
import {ArticleService} from '../article.service';
import {BoutiqueService} from '../../boutique/boutique.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {Uri} from '../../../shared/constant/model/global.constant';
import {FormService} from '../../../shared/ services/form.service';
import {DataShareWhenCheckedArticle} from '../data-share-when-checked-article.service';

@Component({
  selector: 'app-article-delete',
  templateUrl: './article-delete.component.html'
})
export class ArticleDeleteComponent implements OnInit, OnDestroy,
  IDeleteSuccessService {
  constructor(
    private boutiqueService: BoutiqueService,
    private articleService: ArticleService,
    private articleColumnName: ConstantArticleColumnName,
    private deleteSuccessService: DeleteSuccessService,
    private restoreArtInfosService: RestoreArticleService,
    private translateService: TranslateService,
    private popupReusableService: PopupReusableService,
    private formService: FormService,
    private dataShare: DataShareWhenCheckedArticle,
    private activeModal: NgbActiveModal,
    private artInfosShowInit: ArticleShowInit) {
  }

  // VAR.
  boutiqueSelected: Boutique;
  open = true;
  subs$ = new Subject<void>();
  activatedValidationButton = true;
  loadWhenDeleting = false;

  ngOnInit() {
    console.log('** init article delete');
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
    this.dataShare.getArticlecheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: IArticle) => {
        if (data) {
          this.restoreArtInfosService.setArticleChecked(data);
        }
      });
  }

  ngOnDestroy(): void {
    this.restore();
  }

  isActivated() {
    return this.activatedValidationButton;
  }

  formatQt(num: number): string {
    return this.formService.formatUsWithNoFractionDigit(num);
  }

  format(num: number): string {
    return this.formService.formatUs(num);
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.artInfosShowInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  getUser() {
    return this.artInfosShowInit.getUser(
      this.restoreArtInfosService.getArticleChecked());
  }

  /**
   * Permet de réinitialiser certaines variable à la fermeture
   * du popup.
   */
  restore() {
    console.log('** restore article delete');
    this.restoreArtInfosService.restore(this.subs$);
  }

  delete() {
    this.activatedValidationButton = false;
    this.loadWhenDeleting = true;
    const loginUserToDelete = this.getUser().id;
    console.log('** suppression article');
    let option = new HttpParams();
    option = option.set(this.articleColumnName.NAME,
      this.getUser().id.toString());
    option = option.set(Uri.BOUTIQUE,
      this.boutiqueSelected.id.toString());
    const result = this.articleService.delete(option);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        const data = res.body;
        if (data) {
          console.log('**** success delete article: ' + res);
          this.deleteSuccessService.deleteSuccess(this, this.subs$,
            'article supprimé');
          this.popupReusableService.dismiss(this.activeModal);
        }
        this.loadWhenDeleting = false;
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        this.activatedValidationButton = true;
        console.log('**** error save article: ' + err);
        this.loadWhenDeleting = false;
      }
    );
  }

  clearItemChecked() {
    this.dataShare.clearArticleChecked();
  }

  getActiveModal(): NgbActiveModal {
    return this.activeModal;
  }

  getPath(): string {
    return RGlobal.urlFull.ARTICLE.LIST;
  }

}
