import {Injectable} from '@angular/core';
import {IrouteCheckService} from './iroute-check.service';
import {ActivatedRoute} from '@angular/router';
import {IrouteCheckInitService} from './iroute-check-init.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DataSharePaginateService} from '../../../layouts/pagination/service/data-share-paginate.service';
import {RouteCheckParam} from './route-check-param';
import {Uri} from '../../../constant/model/global.constant';
import {DateFormat} from '../../../constant/model/date.constant';
import {NotificationService} from '../../../../reusable/services/notification.service';
import {FreeMemoryService} from '../../../../reusable/services/free-memory.service';
import {PaginationService} from '../../../layouts/pagination/service/pagination.service';
import {ManageService} from '../../manage.service';

/**
 *  Permet d'initiliaser les données utilisateurs en fonction de la route
 *  et des paramètres.
 *  NB: Tout service nécessitant la vérification de la route afin d'initialiser
 *  les données utilisateurs devra implémenter cette interface.
 */
@Injectable({providedIn: 'root'})
export class RouteCheckService implements IrouteCheckService {
  constructor(
    private dateFormat: DateFormat,
    private dataSharePaginateService: DataSharePaginateService,
    private notificationService: NotificationService,
    private freeMemoryService: FreeMemoryService,
    private paginationService: PaginationService,
    private manageService: ManageService
  ) {
  }

  private subs$: Subject<void>;

  setDate(params: any, date: any) {
    date.dateDebut = params[Uri.DATE_DEBUT] ? params[Uri.DATE_DEBUT] : this.dateFormat.getLastDate();
    date.dateFin = params[Uri.DATE_FIN] ? params[Uri.DATE_FIN] : this.dateFormat.getNewDate();
    return date;
  }

  checkParams(activatedRoute: ActivatedRoute, paramsExpected: any,
              iRouteCheck: IrouteCheckInitService, path: string,
              subs$: Subject<void>, routeCheckParam: RouteCheckParam) {
    this.subs$ = subs$;
    activatedRoute.queryParams.pipe(takeUntil(this.subs$))
      .subscribe(params => {
        console.log('oui params');
        console.log(this.manageService.getFullPath(activatedRoute) + '==' +
          path);
        if (this.manageService.getFullPath(activatedRoute) === path) {
          console.log('oui2 params');
          this.initTable(routeCheckParam);
          this.manageService.checkParams(paramsExpected, params);
          const paramsCopied = this.manageService.getCopyParam(params);
          iRouteCheck.setParamsRoute(paramsCopied);
          this.dataSharePaginateService.setIPaginate(iRouteCheck.getService());
          this.paginationService.setIPaginateService(iRouteCheck.getService());
          this.notificationService.setTitle(iRouteCheck.getTitle(),
            iRouteCheck.getTitlePath(), subs$);
        }
      });
  }

  private initTable(r: RouteCheckParam) {
    if (r && r.getMatTableService()) {
      r.getMatTableService().initValue();
    }
  }

  /**
   * Permet de libérer la mémoire.
   */
  restore() {
    this.freeMemoryService.free(this.subs$);
  }
}
