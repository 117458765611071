import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AccountUser} from '../../models/account.model';
import {Subject} from 'rxjs';
import {NavService} from './nav.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {BoutiqueListeComponent} from '../../../modules/boutique/boutique-liste/boutique-liste.component';
import {EmpDemandeRecusComponent} from '../../../modules/employe/demande/emp-demande-recus.component';
import {NavInit} from './nav.init';
import {BoutiqueService} from '../../../modules/boutique/boutique.service';
import {CheckingBoutique} from '../../../modules/employe/checking-boutique.service';
import {MeilleurProduitVenduComponent} from '../../../modules/sortie/meilleur-produit-vendu/meilleur-produit-vendu.component';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Uri} from '../../constant/model/global.constant';
import {SortieService} from '../../../modules/sortie/sortie.service';
import {AbonnementProduitComponent} from '../../../modules/abonnement/abonnement-produit/abonnement-produit.component';
import {AbonnementBilanComponent} from '../../../modules/abonnement/abonnement-bilan/abonnement-bilan.component';
import {BilanComponent} from '../../../modules/sortie/bilan/bilan.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html'
})
export class NavComponent implements OnInit, OnDestroy {
  constructor(
    private sortieService: SortieService,
    private checkingBoutique: CheckingBoutique,
    private boutiqueService: BoutiqueService,
    private navInit: NavInit,
    private popupReusableService: PopupReusableService,
    private navService: NavService) {
    this.route = navInit;
    this.width = window.innerWidth;
  }

  // VAR.
  route: NavInit;
  width: number;
  widthToResize = 930;
  opened = false;

  subs$ = new Subject<void>();

  ngOnInit() {
    console.log('** onInit nav');
    this.navService.init(this.subs$);
    this.width = window.innerWidth;
    this.checkingBoutique.init(this.subs$);
  }

  getBilan() {
    console.log('bilan des articles vendus');
    if (this.checkingBoutique.isSelected()) {
      let option = new HttpParams();
      option = option.set(Uri.BOUTIQUE,
        this.checkingBoutique.getBoutiqueSelected().id.toString());
      this.popupReusableService.open(null, BilanComponent);
    }
  }

  listeArticleVendu() {
    console.log('articles vendus');
    if (this.checkingBoutique.isSelected()) {
      let option = new HttpParams();
      option = option.set(Uri.BOUTIQUE,
        this.checkingBoutique.getBoutiqueSelected().id.toString());
      this.popupReusableService.open(null, MeilleurProduitVenduComponent);
    }
  }

  listeSortie() {
    console.log('liste sortie');
    this.checkingBoutique.check(this.route.SORTIE_LIST_URL);
  }

  listeArticle() {
    console.log('liste art');
    this.checkingBoutique.check(this.route.ARTICLE_LIST_URL);
  }

  listeArtInfos() {
    console.log('liste art infos');
    this.checkingBoutique.check(this.route.ART_INFOS_LIST_URL);
  }

  listeCatArt() {
    console.log('liste cat art');
    this.checkingBoutique.check(this.route.CAT_ART_LIST_URL);
  }

  listeEmploye() {
    console.log('liste employe');
    this.checkingBoutique.check(this.route.EMPLOYE_LIST_URL);
  }

  listeDemandeRecus() {
    console.log('demande reçus');
    this.popupReusableService.open(null, EmpDemandeRecusComponent);
  }

  listeBoutique() {
    console.log('liste boutique');
    this.popupReusableService.open(null, BoutiqueListeComponent);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.navService.restore();
  }

  getAccountUser(): AccountUser {
    return this.navService.getAccountUser();
  }
}
