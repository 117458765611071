/**
 * Permet de faire des vérifiations si un champs fait partir des filtres de recherche
 * et de l'ajouter dans les paramètres dans ce cas.
 */
export abstract class AbsVerification {
  protected dataSearch = {}; // paramètres de la recherche.
  private next: AbsVerification;

  /**
   * @returns les paramètres de la recheche.
   */
  getDataSearch(): any {
    return this.dataSearch;
  }

  linkWith(next: AbsVerification): AbsVerification {
    this.next = next;
    return this.next;
  }

  /**
   * Vérifie si un champs fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  abstract check(first: AbsVerification);

  /**
   * Lance la prochaine vérification.
   * Vérifie si un champs fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  checkNext(first: AbsVerification) {
    if (this.next) {
      this.next.check(first);
    }
  }
}
