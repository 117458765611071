import {Routes} from '@angular/router';
import {RGlobal} from '../../shared/constant/global.url';
import {GGuardIsLoginService} from '../../shared/ services/guard/is-login/gguard-is-login.service';
import {GGuardPrivilegeCatArtService} from '../../shared/ services/guard/cat-art/gguard-privilege-cat-art.service';
import {CategorieArticleListComponent} from './categorie-article-list/categorie-article-list.component';
import {GuardCheckingBoutiqueService} from '../../shared/ services/guard/login/guard-checking-boutique.service';

export const categorieArticleRoute: Routes = [
  {
    path: RGlobal.url.CATEGORIE_ARTICLE.CATEGORIE_ARTICLE_,
    canActivate: [GGuardIsLoginService, GGuardPrivilegeCatArtService],
    children: [
      {
        path: RGlobal.url.CATEGORIE_ARTICLE.LIST,
        component: CategorieArticleListComponent,
        canActivate: [GuardCheckingBoutiqueService]
      },
      {
        path: RGlobal.url.CATEGORIE_ARTICLE.SEARCH,
        component: CategorieArticleListComponent,
        canActivate: [GuardCheckingBoutiqueService]
      }
    ]
  }
];
