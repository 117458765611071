import {Injectable} from '@angular/core';
import {formatDate} from '@angular/common';

/**
 * Permet de gérer l'affichage des dates.
 */
@Injectable({providedIn: 'root'})
export class DateFormat {

  readonly FULL = 'fullDate';
  readonly MEDIUM = 'mediumDate';
  readonly HOURS = 'HH:mm:ss';
  readonly SHORT_DATE = 'shortDate';

  getLastDate() {
    return '1970-01-01';
  }

  getNewDate() {
    const date: Date = new Date();
    date.setDate(date.getDate() + 1);
    return formatDate(date, 'yyyy-MM-dd', 'en-us');
  }

  getNewDateByYeader() {
    return formatDate(new Date(), 'yyyy', 'en-us');
  }
}
