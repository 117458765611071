import {Component, Input, OnInit} from '@angular/core';

/**
 * Permet la mise à jour des utilisateurs.
 */
@Component({
  selector: 'app-mat-spinner-small',
  templateUrl: './mat-spinner-small.component.html'
})
export class MatSpinnerSmallComponent implements OnInit {
  constructor() {
    console.log('** on construct mat spinner small.');
  }

  // VAR.
  diameter = 20;
  @Input() show = false;
  @Input() position = 'mx-auto';

  ngOnInit(): void {
    console.log('** on init mat spinner small');
  }
}
