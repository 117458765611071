import {LanguageModel} from '../../models/language.model';

export namespace Uri {
  export const PAGE = 'page';
  export const BOUTIQUE = 'boutique';
  export const DATE_DEBUT = 'dateDebut';
  export const DATE_FIN = 'dateFin';
  export const LOGIN = 'login';
}

export namespace Lang {
  export const DEFAULT = 'fr';
}

export const BOUTIQUE_SELECT = 'select';

export const langs: LanguageModel[] = [
  {labelEn: 'English', labelFr: 'Anglais', key: 'en'},
  {labelEn: 'French', labelFr: 'Français', key: 'fr'}
];

/*export const langs: LanguageModel[] = [
  {labelEn: 'English', labelFr: 'Anglais', key: 'en'},
  {labelEn: 'French', labelFr: 'Français', key: 'fr'},
  {labelEn: 'Wolof', labelFr: 'Wolof', key: 'wo'},
  {labelEn: 'Deutsch', labelFr: 'Allemand', key: 'de'},
  {labelEn: 'Spanish', labelFr: 'Espagnol', key: 'es'},
];*/

export const advancedSearchKey = 'advancedSearch';

/**
 * Permet de savoir si l'utilisateur a choisi une langue
 */
export const storage = {
  myLang: 'myLang'
};
