import {Injectable} from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour un {@link Utilisateur}
 */
@Injectable({providedIn: 'root'})
export class ConstantBoutiqueColumnName {

  readonly NAME = 'boutique';
  readonly ID = 'id';
  readonly NOM = 'nom';
  readonly DEVISE = 'devise';
  readonly NOMBRE_AUTORISE = 'nombre_autorise';
  readonly PAYS = 'pays';
  readonly VILLE = 'ville';
  readonly ADRESSE = 'adresse';
  readonly TELEPHONE1 = 'telephone1';
  readonly TELEPHONE2 = 'telephone2';
  readonly TELEPHONE3 = 'telephone3';
  readonly DESCRIPTION = 'description';
  readonly DATE_ENREGISTREMENT = 'date_enregistrement';
  readonly DATE_MODIFICATION = 'date_modification';

  /**
   * @returns les champs pour lesquels les valeurs du formulaire récupérées
   * seront en minuscules.
   */
  readonly fields = [this.NOM, this.PAYS, this.VILLE, this.ADRESSE,
    this.DESCRIPTION];

}
