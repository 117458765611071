import {Component, OnDestroy, OnInit} from '@angular/core';
import {BoutiqueService} from '../../boutique/boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {AbonnementNombreBoutiqueComponent} from '../abonnement-nombre-boutique/abonnement-nombre-boutique.component';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {AccountUser} from '../../../shared/models/account.model';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {AbonnementFactureComponent} from '../abonnement-facture/abonnement-facture.component';
import {AbonnementProduitComponent} from '../abonnement-produit/abonnement-produit.component';
import {AbonnementBilanComponent} from '../abonnement-bilan/abonnement-bilan.component';
import {AbonnementBilan} from '../../../shared/models/abonnement-bilan.model';
import {SortieService} from '../../sortie/sortie.service';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {Boutique} from '../../../shared/models/boutique.model';
import {Uri} from '../../../shared/constant/model/global.constant';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {AbonnementFacture} from '../../../shared/models/abonnement-facture.model';
import {AbonnementProduitPlusAchete} from '../../../shared/models/abonnement-produit-plus-achete.model';
import {AbonnementService} from '../abonnement.service';
import {AbonnementApplication} from '../../../shared/models/abonnement-application.model';
import {DataLimit} from '../../../shared/models/data-limit.model';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-abonnement-infos',
  templateUrl: './abonnement-infos.component.html'
})
export class AbonnementInfosComponent implements OnInit, OnDestroy {
  constructor(
    private abonnementService: AbonnementService,
    private dateFormatService: DateFormatService,
    private sortieService: SortieService,
    private freeMemoryService: FreeMemoryService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
  }

  maxCanCreate: number;
  nbCreate: number;
  accountUser: AccountUser;
  boutiqueSelected: Boutique;

  abonnementDonnee: DataLimit;

  abonnementApplication: AbonnementApplication;
  abonnementBilan: AbonnementBilan;
  abonnementFacture: AbonnementFacture;
  abonnementProduit: AbonnementProduitPlusAchete;

  open = true;
  shouldIShow = false;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init abonnement');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
    });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(this.subs$))
      .subscribe((res: Boutique) => {
        this.boutiqueSelected = res;
        if (res) {
          this.popupReusableService.getPromiseForRequest().then(r => {
            this.takeAbonnementBilan(res);
          });
          this.popupReusableService.getPromiseForRequest().then(r => {
            this.takeAbonnementFacture(res);
          });
          this.popupReusableService.getPromiseForRequest().then(r => {
            this.takeAbonnementProduit(res);
          });
          this.popupReusableService.getPromiseForRequest().then(r => {
            this.takeAbonnementDonnee(res);
          });
          this.popupReusableService.getPromiseForRequest().then(r => {
            this.getMaxBoutiqueCanCreate(res);
          });
          this.popupReusableService.getPromiseForRequest().then(r => {
            this.getBoutiqueNbCreate(res);
          });
          this.popupReusableService.getPromiseForRequest().then(r => {
            this.takeAbonnementApplication();
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  date() {
    return this.dateFormatService;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

  getBoutiqueNbCreate(b: Boutique) {
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, b.id.toString());
    const result = this.boutiqueService.getNbCreate(option);
    this.subscribeToSaveResponseBoutiqueNbCreate(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseBoutiqueNbCreate(
    result: Observable<HttpResponse<number>>
  ) {
    result.subscribe(
      (res: HttpResponse<number>) => {
        console.log('**** success take abonnement boutique nb create: ');
        this.nbCreate = res.body;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error take abonnement boutique nb create: ' + err);
      }
    );
  }

  getMaxBoutiqueCanCreate(b: Boutique) {
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, b.id.toString());
    const result = this.boutiqueService.getMaxCanCreate(option);
    this.subscribeToSaveResponseBoutiqueMax(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseBoutiqueMax(
    result: Observable<HttpResponse<number>>
  ) {
    result.subscribe(
      (res: HttpResponse<number>) => {
        console.log('**** success take abonnement boutique max create: ');
        this.maxCanCreate = res.body;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error take abonnement boutique max create: ' + err);
      }
    );
  }

  takeAbonnementDonnee(b: Boutique) {
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, b.id.toString());
    const result = this.sortieService.takeAbonnementDonnee(option);
    this.subscribeToSaveResponseDonnee(result);
  }

  takeAbonnementProduit(b: Boutique) {
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, b.id.toString());
    const result = this.sortieService.takeAbonnementProduit(option);
    this.subscribeToSaveResponseProduit(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseDonnee(
    result: Observable<HttpResponse<DataLimit>>
  ) {
    result.subscribe(
      (res: HttpResponse<DataLimit>) => {
        console.log('**** success take abonnement donnee: ');
        this.abonnementDonnee = res.body;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error take abonnement donnee: ' + err);
      }
    );
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseProduit(
    result: Observable<HttpResponse<AbonnementProduitPlusAchete>>
  ) {
    result.subscribe(
      (res: HttpResponse<AbonnementProduitPlusAchete>) => {
        console.log('**** success take abonnement produit: ');
        this.abonnementProduit = res.body;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error take abonnement produit: ' + err);
      }
    );
  }

  takeAbonnementFacture(b: Boutique) {
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, b.id.toString());
    const result = this.sortieService.takeAbonnementFacture(option);
    this.subscribeToSaveResponseFacture(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseFacture(
    result: Observable<HttpResponse<AbonnementFacture>>
  ) {
    result.subscribe(
      (res: HttpResponse<AbonnementFacture>) => {
        console.log('**** success take abonnement facture: ');
        this.abonnementFacture = res.body;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error take abonnement facture: ' + err);
      }
    );
  }

  takeAbonnementApplication() {
    const result = this.abonnementService.getAbonnementApplication();
    this.subscribeToSaveResponseAbApplication(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseAbApplication(
    result: Observable<HttpResponse<AbonnementApplication>>
  ) {
    result.subscribe(
      (res: HttpResponse<AbonnementApplication>) => {
        console.log('**** success take abonnement application: ');
        this.abonnementApplication = res.body;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error take abonnement application: ' + err);
      }
    );
  }

  takeAbonnementBilan(b: Boutique) {
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, b.id.toString());
    const result = this.sortieService.takeAbonnementBilan(option);
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<AbonnementBilan>>
  ) {
    result.subscribe(
      (res: HttpResponse<AbonnementBilan>) => {
        console.log('**** success take abonnement bilan: ');
        this.abonnementBilan = res.body;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error take abonnement bilan: ' + err);
      }
    );
  }

  getBilan() {
    console.log('ajout abonnement bilan');
    this.popupReusableService.open(null, AbonnementBilanComponent);
  }

  getProduit() {
    console.log('ajout abonnement produit');
    this.popupReusableService.open(null, AbonnementProduitComponent);
  }

  getFacture() {
    console.log('ajout abonnement facture');
    this.popupReusableService.open(null, AbonnementFactureComponent);
  }

  ajouterBoutique() {
    console.log('ajout abonnement boutique');
    this.popupReusableService.open(null, AbonnementNombreBoutiqueComponent);
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }
}
