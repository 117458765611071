import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ICategorieArticle} from '../../shared/models/categorie-article.model';
import {FreeMemoryService} from '../../reusable/services/free-memory.service';

/**
 * Permet de gérer les {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class RestoreCatArtService {
  constructor(private freeMemoryService: FreeMemoryService) {
  }

  // VAR.
  private catArtChecked: ICategorieArticle;

  getCatArtChecked(): ICategorieArticle {
    return this.catArtChecked;
  }

  setCatArtChecked(user: ICategorieArticle) {
    this.catArtChecked = user;
  }

  /**
   * Permet de réinitialiser certaines variable à la fermeture
   * du popup.
   */
  restore(subs$: Subject<void>) {
    this.catArtChecked = undefined;
    this.freeMemoryService.free(subs$);
  }
}
