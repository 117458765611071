import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CategorieArticle} from '../../../shared/models/categorie-article.model';
import {ConstantArticleInfosColumnName} from '../../../shared/constant/model/column-name/article-infos-column-name.constant';
import {ConstantArticleInfosValidator} from '../../../shared/constant/validator/article-infos-validator.constant';
import {ArticleInfos, IArticleInfos} from '../../../shared/models/article-infos.model';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Offre plusieurs types utilisés dans la création de catégories d'article.
 */
export namespace ArtInfosCreateForInit {
  /**
   * Permet d'avoir le nom des champs pour une {@link CategorieArticle}.
   */
  export interface ColumnName {
    name: string;
    id: string;
    designation: string;
    description: string;
    code: string;
    quantiteAlerte: string;
    prixUnitaire: string;
    prixUnitaireVente: string;
    idCategorieArticle: string;
  }
}

/**
 * Permet d'initialiser le component {@link CategorieArticleCreateComponent}
 */
@Injectable({providedIn: 'root'})
export class ArtInfosCreateInit {
  constructor(
    private formService: FormService,
    private categorieColName: ConstantArticleInfosColumnName,
    private categorieValidator: ConstantArticleInfosValidator
  ) {
  }

  /**
   * récupère les données de la catégorie du formulaire.
   */
  public getCategorieFromForm(editForm: FormGroup): IArticleInfos {
    const entity = {
      ...new ArticleInfos(),
      designation: this.formService.getData(
        this.categorieColName.DESIGNATION,
        editForm, []
      ),
      code: this.formService.getData(this.categorieColName.CODE, editForm,
        []),
      description: this.formService.getData(this.categorieColName.DESCRIPTION, editForm,
        []),
      quantiteAlerte: +this.formService.getData(this.categorieColName.QUANTITE_ALERTE,
        editForm, []),
      prixUnitaire: +this.formService.getData(this.categorieColName.PRIX_UNITAIRE, editForm,
        []),
      prixUnitaireVente: +this.formService.getData(this.categorieColName.PRIX_UNITAIRE_VENTE, editForm,
        []),
      categorieArticle: {
        id: this.formService.getDataObj(this.categorieColName.ID_CATEGORIE_ARTICLE, editForm).id
      }
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour une {@link CategorieArticle}
   */
  getCategorieArticleColumnName(): ArtInfosCreateForInit.ColumnName {
    return {
      name: this.categorieColName.NAME,
      id: this.categorieColName.ID,
      designation: this.categorieColName.DESIGNATION,
      code: this.categorieColName.CODE,
      description: this.categorieColName.DESCRIPTION,
      quantiteAlerte: this.categorieColName.QUANTITE_ALERTE,
      prixUnitaire: this.categorieColName.PRIX_UNITAIRE,
      prixUnitaireVente: this.categorieColName.PRIX_UNITAIRE_VENTE,
      idCategorieArticle: this.categorieColName.ID_CATEGORIE_ARTICLE
    };
  }

  /**
   * @returns Formulaire de création d'une {@link CategorieArticle}.
   */
  getForm(): any {
    return {
      designation: this.categorieValidator.DESIGNATION,
      code: this.categorieValidator.CODE,
      description: this.categorieValidator.DESCRIPTION,
      quantite_alerte: this.categorieValidator.QUANTITE_ALERTE,
      prix_unitaire: this.categorieValidator.PRIX_UNITAIRE,
      prix_unitaire_vente: this.categorieValidator.PRIX_UNITAIRE_VENTE,
      id_categorie_article: this.categorieValidator.ID_CATEGORIE_ARTICLE
    };
  }
}
