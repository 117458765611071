import {Injectable} from '@angular/core';
import {IArticleInfos} from '../../../shared/models/article-infos.model';
import {DateFormatService} from '../../../shared/ services/date-format.service';

/**
 * Permet d'initialiser le component {@link UserCreateComponent}
 */
@Injectable({providedIn: 'root'})
export class ArtInfosShowInit {
  constructor(
    private date: DateFormatService
  ) {
  }

  /**
   * Permet de convertir une date
   */
  getDate() {
    return this.date;
  }

  /**
   * Permet de récupérer les valeurs d'un utilisateur pouvant
   * être affichées sur du html.
   * @param user l'utilisateur.
   */
  getUser(catArt: IArticleInfos) {
    return {
      id: catArt ? catArt.id : null,
      designation: catArt ? catArt.designation : null,
      code: catArt ? catArt.code : null,
      prixUnitaire: catArt ? catArt.prixUnitaire : null,
      montant: catArt ? catArt.montant : null,
      quantiteDisponible: catArt ? catArt.quantiteDisponible : null,
      categorieArticle: catArt ? catArt.categorieArticle : null,
      quantiteAlerte: catArt ? catArt.quantiteAlerte : null,
      dateEnregistrement: catArt ? catArt.dateEnregistrement : null,
      dateModification: catArt ? catArt.dateModification : null,
      utilisateur: catArt ? catArt.utilisateur : null
    };
  }
}
