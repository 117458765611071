import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {SortieListeInit} from './sortie-liste.init';
import {PaginationService} from '../../../shared/layouts/pagination/service/pagination.service';
import {ISortieDetails} from '../../../shared/models/sortie-details.model';
import {Uri} from '../../../shared/constant/model/global.constant';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {takeUntil} from 'rxjs/operators';
import {GestionType} from '../../../shared/models/action.model';
import {AccountUser} from '../../../shared/models/account.model';
import {RGlobal} from '../../../shared/constant/global.url';
import {IPaginateService} from '../../../shared/layouts/pagination/service/ipaginate.service';
import {MatTableDataSource} from '@angular/material';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {IGettingPrivilegeService} from '../../../shared/ services/privilege/getting/igetting-privilege.service';
import {RouteCheckParam} from '../../../shared/ services/route/checking/route-check-param';
import {DataPageRequest} from '../../../shared/ services/request/data-page-request.service';
import {ActivatedRouteService} from '../../../shared/ services/route/activated-route.service';
import {RouteCheckService} from '../../../shared/ services/route/checking/route-check.service';
import {IrouteCheckService} from '../../../shared/ services/route/checking/iroute-check.service';
import {IrouteCheckInitService} from '../../../shared/ services/route/checking/iroute-check-init.service';
import {ManageService} from '../../../shared/ services/manage.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {IMatTableService} from '../../../shared/ services/mat-table/imat-table.service';
import {GettingPrivilegeService} from '../../../shared/ services/privilege/getting/getting-privilege.service';
import {SortieService} from '../sortie.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {SortieDataShare} from '../sortie-data.share';
import {FormService} from '../../../shared/ services/form.service';
import {DataShareWhenCheckedSortie} from '../data-share-when-checked-sortie.service';
import {MatTableService} from '../../../shared/ services/mat-table/mat-table.service';
import {SortieShowComponent} from '../sortie-show/sortie-show.component';
import {SortieDeleteComponent} from '../sortie-delete/sortie-delete.component';
import {SortieUpdateComponent} from '../sortie-update/sortie-update.component';
import {FactureShowComponent} from '../../abonnement/facture/facture-show/facture-show.component';
import {AbonnementFactureComponent} from '../../abonnement/abonnement-facture/abonnement-facture.component';
import {TIME_WAIT_REFRESH} from '../../../shared/constant/model/server.constant';

@Component({
  selector: 'app-sortie-liste',
  templateUrl: './sortie-liste.component.html'
})
export class SortieListeComponent implements OnInit, OnDestroy,
  IPaginateService,
  IrouteCheckService, IrouteCheckInitService,
  IGettingPrivilegeService, IMatTableService {
  constructor(
    private boutiqueService: BoutiqueService,
    private sortieService: SortieService,
    private popupReusableService: PopupReusableService,
    private gettingPrivilegeService: GettingPrivilegeService,
    private dataPageRequest: DataPageRequest,
    private activatedRouteService: ActivatedRouteService,
    private freeMemoryService: FreeMemoryService,
    private userListRouteCheckService: RouteCheckService,
    private catArtListInit: SortieListeInit,
    private manageService: ManageService,
    private accountService: AccountService,
    public sortieDataShare: SortieDataShare,
    private paginationService: PaginationService,
    private formService: FormService,
    private matTableService: MatTableService,
    public dataShare: DataShareWhenCheckedSortie,
    private title: Title,
    private catArtInit: SortieListeInit,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    console.log('** construct sortie list');
  }

  // VAR.
  boutiqueSelected: Boutique;
  iCanAddCatArt = false;
  iCanSearchCatArt = false;
  iCanModifyCatArt = false;
  iCanDeleteCatArt = false;
  iCanShowCatArt = false;
  catArtList: ISortieDetails[];
  nbCatArt: number;
  totalPages: number;
  accountUser: AccountUser;
  params: Params = {};
  catArtIsChecked = false;
  hasFinishedLoading = false;
  subs$ = new Subject<void>();

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   */
  ngOnInit() {
    this.nbCatArt = 0.;
    this.dataShare.clearSortieChecked();
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getUserIdentityObs(this.accountService, res);
    });
    this.checkParams(this.activatedRoute, {
        [Uri.PAGE]: undefined,
        [Uri.DATE_DEBUT]: undefined, [Uri.DATE_FIN]: undefined
      },
      this, this.getPath(), this.subs$, new RouteCheckParam()
        .setMatTableService(this.matTableService));
    this.verifyIsCatArtIsChecked(this.dataShare, this.subs$);
    this.dataPageRequest.initDataPage(this, this.subs$);
    this.matTableService.init(this);
  }


  /**
   * Permet de réinitialiser les boutons lors du changement de la route.
   */
  ngOnDestroy(): void {
    this.restore();
  }

  isDescriptionAvailable() {
    return (this.getUsersList() || this.getUsersList() === [])
      && (this.getNbUsers() || this.getNbUsers() === 0);
  }

  getMontantTotal() {
    return this.sortieDataShare.montantTotal;
  }

  getBeneficeTotal() {
    return this.sortieDataShare.beneficeTotal;
  }

  getListUsers() {
    console.log('search by date');
    this.paginationService.setPageQueryParams(1);
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.catArtInit.getDate(); // NE PAS SUPPRIMER, utilisé côté Html.
  }

  getPrevious() {
    return this.matTableService.getPrevious();
  }

  getDataSourceMat() {
    return this.matTableService.getDataSource();
  }

  checked(event, row, i) {
    return this.matTableService.checked(event, row, i);
  }

  clicked(row, i) {
    return this.matTableService.clicked(row, i);
  }

  getSelected() {
    return this.matTableService.getSelected();
  }

  getDisplayColumnsMat() {
    return this.matTableService.getDisplayColumns();
  }


  /**
   * Permet de savoir si l'utilisateur est coché.
   */
  isUserChecked(): boolean {
    return this.isCatArtChecked();
  }

  openPopupUserDelete() {
    this.popupReusableService.open(null, SortieDeleteComponent);
    // this.catArtService.openPopupUserDelete(SortieDeleteComponent);
  }

  openPopupUserShow() {
    this.popupReusableService.open(null, SortieShowComponent);
  }

  openPopupFactureShow() {
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
  //  const result = this.sortieService.checkAbonnementFacture(option);
    this.popupReusableService.open(null, FactureShowComponent);
  }

  /**
   * Permet de naviguer vers la page de modification d'un utilisateur.
   */
  goToUserUpdateRoute() {
    this.popupReusableService.open(null, SortieUpdateComponent);
    // this.catArtService.goToUserUpdateRoute();
  }

  /**
   * @returns true l'utilisateur peut ajouter, false sinon.
   */
  iCanAddUser() {
    return this.canIAddCatArt();
  }

  /**
   * Permet d'indiquer à l'enfant que la liste des données a été affichée,
   * afin d'afficher ensuite la pagination.
   */
  finishedLoading() {
    this.hasFinishedLoading = true;
  }

  /**
   * @returns le nombre d'utilisateurs.
   */
  getNbUsers(): number {
    return this.getNbCatArtData();
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getUsersList(): ISortieDetails[] {
    return this.getCatArtListData();
  }


  /**
   * Libère les ressources.
   */
  restore() {
    this.userListRouteCheckService.restore();
    this.matTableService.clearAll();
    this.freeMemoryService.free(this.subs$);
  }

  /**
   * Permet de récupérer au serveur la liste des utilisateurs par page.
   */
  findMontantTotalAchat() {
    let params = new HttpParams();
    params = params.set(Uri.DATE_DEBUT, this.sortieDataShare.dateDebut);
    params = params.set(Uri.DATE_FIN, this.sortieDataShare.dateFin);
    params = params.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.sortieService.getMontantTotalAchat(params);
    result.subscribe(
      (res: any) => {
        console.log('** succès récupération montant total achat sortie');
        this.sortieDataShare.montantTotalAchat = res.body;
        this.sortieDataShare.beneficeTotal = this.sortieDataShare.montantTotal - this.sortieDataShare.montantTotalAchat;
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération montant total achat sortie');
      }
    );
  }

  /**
   * Permet de récupérer au serveur la liste des utilisateurs par page.
   */
  findMontantTotal() {
    let params = new HttpParams();
    params = params.set(Uri.DATE_DEBUT, this.sortieDataShare.dateDebut);
    params = params.set(Uri.DATE_FIN, this.sortieDataShare.dateFin);
    params = params.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.sortieService.getMontantTotal(params);
    result.subscribe(
      (res: any) => {
        console.log('** succès récupération montant total sortie');
        this.sortieDataShare.montantTotal = res.body;
        this.findMontantTotalAchat();
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération montant total sortie');
      }
    );
  }

  verifyIsCatArtIsChecked(dataShare: DataShareWhenCheckedSortie,
                          subs$: Subject<void>) {
    dataShare.getIsSortieCheckedObs()
      .pipe(takeUntil(subs$)).subscribe((v: boolean) => {
      this.catArtIsChecked = v;
    });
  }

  checkParams(activatedRoute: ActivatedRoute, paramsExpected: any,
              iRouteCheck: IrouteCheckInitService, path: string,
              subs$: Subject<void>, r: RouteCheckParam) {
    this.userListRouteCheckService.checkParams(activatedRoute, paramsExpected,
      iRouteCheck, path, this.subs$, r);
  }

  /**
   *
   */
  getTitle(): Title {
    return this.title;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService,
                     boutiqueSelected: Boutique) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
        this.gettingPrivilegeService.getPrivilege(accountUser, this,
          GestionType.SORTIE, boutiqueSelected, this.subs$);
      });
  }

  /**
   * @param catArtList la liste des utilisateurs.
   */
  setUsersList(catArtList: ISortieDetails[]) {
    this.catArtList = catArtList;
  }

  /**
   * @param nbUsers le nombre d'utilisateurs.
   */
  setNbUsers(nbUsers: number) {
    this.nbCatArt = nbUsers;
  }

  /**
   * Permet de savoir si l'utilisateur est coché.
   */
  isCatArtChecked(): boolean {
    return this.catArtIsChecked;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur coché dans la tableau.
   * @param user l'utilisateur coché.
   * @param event permet d'indiquer si la case est cochée ou pas.
   */
  hasChecked(user: any) {
    if (user) {
      this.dataShare.setSortiecheckedObs(user);
      this.dataShare.setIsSortieChecked(true);
    } else {
      this.dataShare.clearSortieChecked();
    }
  }

  /**
   * @returns true l'utilisateur peut ajouter, false sinon.
   */
  canIAddCatArt(): boolean {
    return this.iCanAddCatArt;
  }

  canISearchCatArt(): boolean {
    return this.iCanSearchCatArt;
  }

  canIDelete(): boolean {
    return this.iCanDeleteCatArt;
  }

  canIModify(): boolean {
    return this.iCanModifyCatArt;
  }

  canIShow(): boolean {
    return this.iCanShowCatArt;
  }

  /**
   * @returns le nombre total de pages.
   */
  getTotalPagesData(): number {
    return this.getTotalPages();
  }

  /**
   * @returns le nombre d'utilisateurs.
   */
  getNbCatArtData(): number {
    return this.getNbCatArt();
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getCatArtListData(): ISortieDetails[] {
    return this.getCatArtList();
  }

  /**
   * @returns le nombre total de pages.
   */
  getTotalPages(): number {
    return this.totalPages;
  }

  /**
   * @returns le nombre d'utilisateurs.
   */
  getNbCatArt(): number {
    return this.nbCatArt;
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getCatArtList(): ISortieDetails[] {
    return this.catArtList;
  }

  /**
   * Permet de récupérer au serveur la liste des utilisateurs par page.
   */
  getListCatArt(pageNumber: string) {
    this.sortieDataShare.spinnerTable = true;
    let params = new HttpParams();
    params = params.set(Uri.PAGE, pageNumber);
    params = params.set(Uri.DATE_DEBUT, this.sortieDataShare.dateDebut);
    params = params.set(Uri.DATE_FIN, this.sortieDataShare.dateFin);
    params = params.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.sortieService.getList(params);
    result.subscribe(
      (res: any) => {
        console.log('** succès récupération sortie par page');
        this.sortieDataShare.spinnerTable = false;
        setTimeout(() => {
          this.sortieService.refreshBtnActive = true;
        }, TIME_WAIT_REFRESH);
        this.dataPageRequest.getDataPage(res.body);
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération sortie par page');
        this.sortieDataShare.spinnerTable = false;
        console.log('erreur: ' + JSON.stringify(err));
        this.manageService.redirectToPageNotFound();
      }
    );
  }

  /**
   * @return les données récupérées du serveur.
   */
  getData() {
    return this.getCatArtList();
  }

  /**
   * Permet de récupérer les données du serveur.
   * @param pageNumber le numéro de la page.
   */
  getDataServ(pageNumber: string) {
    this.sortieDataShare.montantTotal = 0;
    this.sortieDataShare.montantTotalAchat = 0;
    this.sortieDataShare.beneficeTotal = 0;
    this.getListCatArt(pageNumber);
    this.popupReusableService.getPromiseForRequest().then(res => {
      this.findMontantTotal();
    });
  }

  /**
   * @return le nombre d'utilisateur
   */
  getNbItems(): number {
    return this.getNbCatArt();
  }

  /**
   * @return les paramètres de l'url.
   */
  getParams(): Params {
    return this.params;
  }

  /**
   * @return le path de la liste des utilisateurs.
   */
  getPath(): string {
    return RGlobal.urlFull.SORTIE.LIST;
  }

  getPathRoute(): string {
    return this.getPath();
  }

  getService(): IPaginateService {
    return this;
  }

  setParamsRoute(params: any) {
    this.params = params;
    const date = this.userListRouteCheckService.setDate(params, {
      dateDebut: this.sortieDataShare.dateDebut,
      dateFin: this.sortieDataShare.dateFin
    });
    this.sortieDataShare.dateDebut = date.dateDebut;
    this.sortieDataShare.dateFin = date.dateFin;
  }

  getTitlePath(): string {
    return 'Liste des sorties';
  }

  setData(data: any[]) {
    this.catArtList = data;
  }

  setNbItems(n: number) {
    this.nbCatArt = n;
  }

  /**
   * @param totalPages le nombre de page.
   */
  setTotalPages(t: number) {
    this.totalPages = t;
  }

  iCanAdd(b: boolean) {
    this.iCanAddCatArt = b;
  }

  iCanDelete(b: boolean) {
    this.iCanDeleteCatArt = b;
  }

  iCanModify(b: boolean) {
    this.iCanModifyCatArt = b;
  }

  iCanSearch(b: boolean) {
    this.iCanSearchCatArt = b;
  }

  iCanShow(b: boolean) {
    if (!b) {
      this.router.navigate([RGlobal.url.PAGE_RESTRICTED]);
    }
    this.iCanShowCatArt = b;
  }

  getDisplayColumns(): any[] {
    return ['select', 'designation', 'code', 'codeSortie', 'qtSortie',
      'prixUnitaireVendu', 'montant', 'prixUnitaireAchat',
      'montantAchat', 'designationCat', 'createBy',
      'date_modification', 'date_enregistrement'];
  }

  getDataSource(): MatTableDataSource<any> {
    return new MatTableDataSource<ISortieDetails>(this.getCatArtList());
  }

  getDateDebut(): string {
    return this.sortieDataShare.dateDebut;
  }

  getDateFin(): string {
    return this.sortieDataShare.dateFin;
  }
}
