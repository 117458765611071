import {Injectable} from '@angular/core';

/**
 * Permet de partager les données dans le module utilisateur.
 */
@Injectable({providedIn: 'root'})
export class CategorieDataShare {
  constructor() {
  }

  spinnerTable = false;
  dateDebut = '';
  dateFin = '';
}
