import {Injectable} from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour une {@link CategorieArticle}
 */
@Injectable({providedIn: 'root'})
export class ConstantSortieColumnName {
  readonly NAME = 'sortie';
  readonly ID = 'id';
  readonly QUANTITE_SORTIE = 'quantite_sortie';
  readonly CODE = 'code';
  readonly PRIX_UNITAIRE_VENDU = 'prix_unitaire_vendu';
  readonly PRIX_UNITAIRE_ACHAT = 'prix_unitaire_achat';
  readonly ID_ARTICLE_INFOS = 'id_article_infos';
}
