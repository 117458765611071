import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {RGlobal} from '../../../constant/global.url';
import {AuthService} from '../../auth/auth.service';

/**
 * Permet de vérifier que l'utilisateur est connecté pour pouvoir
 * accèder aux routes.
 */
@Injectable({providedIn: 'root'})
export class GuardIsLoginImplService {
  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  /**
   *
   * @param route la route.
   * @param state l'état.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('** guard is login impl');
    if (this.authService.isUserLoggedIn()) {
      return true;
    }
    this.router.navigate([RGlobal.url.LOGIN]);
    return false;
  }
}
