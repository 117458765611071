import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appRemoveFocus]'
})
export class RemoveFocusDirective implements AfterViewInit {
  private elementChecked: ElementRef;

  constructor(private el: ElementRef) {
    this.elementChecked = this.el;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementChecked.nativeElement.blur();
    }, 100);
  }
}
