import {Injectable} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

export interface SpinnerObj {
  isOpen: boolean;
}

/**
 * Permet de gérer les popups.
 */
@Injectable({providedIn: 'root'})
export class PopupReusableService {

  constructor(private modalService: NgbModal) {
  }

  getPromise(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      console.log('** in ma promise');
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }

  getPromiseForRequest(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      console.log('** in ma promise request');
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }

  private openWithNoSpinner(component: any) {
    console.log('** open spinner');
    const modalRef = this.modalService.open(component, {
      size: 'lg',
      windowClass: 'modal-cs',
    });
    modalRef.result.then(
      result => {
        console.log('** in result: ' + result);
      },
      reason => {
        console.log('in reason: ' + reason);
      }
    );
    if (!modalRef.componentInstance) {
      modalRef.close();
    }
    return modalRef;
  }

  private openWithSpinner(spinner: SpinnerObj, component: any) {
    spinner.isOpen = true;
    console.log('** open spinner');
    const modalRef = this.modalService.open(component, {
      size: 'lg',
      windowClass: 'modal-cs'
    });
    modalRef.result.then(
      result => {
        console.log('** in result: ' + result);
        spinner.isOpen = false;
      },
      reason => {
        console.log('in reason: ' + reason);
        spinner.isOpen = false;
      }
    );
    if (!modalRef.componentInstance) {
      modalRef.close();
    }
    this.getPromise().then(res => {
        spinner.isOpen = false;
      },
      err => {
        spinner.isOpen = false;
      });
    return modalRef;
  }

  open(spinner: SpinnerObj, component: any): NgbModalRef {
    if (spinner) {
      return this.openWithSpinner(spinner, component);
    }
    return this.openWithNoSpinner(component);
  }

  /**
   * Ferme un popup.
   *
   * @param activeModal la référence du popup à fermer.
   */
  close(activeModal: NgbActiveModal) {
    this.getPromise().then(res => {
      activeModal.close('closed');
    });
  }

  /**
   * Ferme un popup.
   *
   * @param activeModal la référence du popup à fermer.
   */
  dismiss(activeModal: NgbActiveModal) {
    this.getPromise().then(res => {
      activeModal.dismiss('dismissed');
    });
  }


  /**
   * permet d'ouvrir un popup
   * @param showSpinner isOpen = true pour afficher le progress spinner, false sinon.
   * @param component le component à afficher.
   * @returns une référence du popup ouvert.
   */
  /*  open(showSpinner: SpinnerObj, component: any): NgbModalRef {
      console.log('**** enter some argument');
      showSpinner.isOpen = true; // ouvre le progress spinner.
      if (this.isOpen) {
        showSpinner.isOpen = false; // ferme le progress spinner.
        return;
      }
      this.isOpen = true;
      const modalRef = this.modalService.open(component);
      modalRef.result.then(
        result => {
          this.isOpen = false;
          showSpinner.isOpen = false; // ferme le progress spinner.
        },
        reason => {
          this.isOpen = false;
          showSpinner.isOpen = false; // ferme le progress spinner.
        }
      );
      return modalRef;
    }*/
}
