/**
 * Permet de gérer les {@link Utilisateur}.
 */
import {EventEmitter, Injectable} from '@angular/core';
import {IPaginateService} from './ipaginate.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Uri} from '../../../constant/model/global.constant';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DataSharePaginateService} from './data-share-paginate.service';
import {NoPaginateService} from './no-paginate.service';
import {FreeMemoryService} from '../../../../reusable/services/free-memory.service';
import {ManageService} from '../../../ services/manage.service';

/**
 * Permet d'afficher la pagination.
 * NB: Tout service nécessitant la pagination devra implémenter
 * l'interface {@link IPaginateService}
 */
@Injectable({providedIn: 'root'})
export class PaginationService {
  constructor(
    private freeMemoryService: FreeMemoryService,
    private noPaginateService: NoPaginateService,
    private dataSharePaginateService: DataSharePaginateService,
    private manageService: ManageService) {
    this.iPaginateService = noPaginateService;
  }

  // VAR.
  public currentPage = 1;
  public previousPage = 1;

  private hasGetData = false;
  private parentHasFinishedLoading = true;

  private subscribe = false;
  private iPaginateService: IPaginateService;
  private router: Router;
  private activatedRoute: ActivatedRoute;
  private subs$: Subject<void>;

  /* Va permettre de récupérer la liste des données lors d'une action
  * ne nécessitant pas la modification des paramètres. */
  private pageChangeEvent = new EventEmitter<string>();

  onInit(activatedRoute: ActivatedRoute, router: Router,
         subs$: Subject<void>) {
    this.subs$ = subs$;
    console.log('** on init pagination');
    console.log('full path pagi: ' + this.manageService.getFullPath(activatedRoute));
    this.activatedRoute = activatedRoute;
    this.router = router;

    // ADD.
    this.noPaginateService.setActivatedRoute(activatedRoute);
    // END.

    this.subscribeToPaginate(subs$);
    this.pageChangeEvent.pipe(takeUntil(this.subs$)).subscribe(res => {
      if (this.iPaginateService) {
        console.log('emitting: ' + res);
        this.verifyParams({});
      }
    });
  }

  private subscribeToPaginate(subs$: Subject<void>) {
    this.dataSharePaginateService.getIPaginateObs().pipe(takeUntil(subs$))
      .subscribe((data: IPaginateService) => {
        console.log('paginate execute');
        if (data) {
          console.log('get from paginate inside');
          console.log('ipaginate in === ipaginate out' + (this.iPaginateService === data));
          if (this.iPaginateService !== data) {
            this.iPaginateService = data;
            this.verifyParams(data.getParams());
          } else {
            this.verifyParams(data.getParams());
          }
        }
      });
  }

  restore() {
    this.dataSharePaginateService.setIPaginate(undefined);
    this.freeMemoryService.free(this.subs$);
  }

  /**
   * @returns Evènement permettant de recharger
   * la liste des données.
   */
  getPageChangeEvent(): EventEmitter<string> {
    return this.pageChangeEvent;
  }

  /**
   * Récupère la liste des données en fonction des paramètres de la
   * recherche.
   */
  private initPage() {
  }

  private verifyParams(params: any) {
    let page = params[Uri.PAGE];
    if (page) {
      this.paramsHasPage(page);
    } else {
      this.paramsHasNotPage();
    }
  }

  /**
   * Permet d'initialiser les données lorsque le paramètre
   * page existe.
   */
  private paramsHasPage(page) {
    console.log('** param has page');
    if (!this.hasGetData) {
      this.getDataServ(+page);
    } else {
      this.hasGetData = false;
    }
    this.currentPage = isNaN(page) ? this.currentPage : +page;
    this.previousPage = isNaN(page) ? this.previousPage : +page;
  }

  /**
   * Permet d'initialiser les données lorsque le paramètre
   * page n'existe pas.
   */
  private paramsHasNotPage() {
    console.log('** param has not page');
    this.currentPage = 1;
    this.previousPage = 1;
    this.setPageQueryParams(this.currentPage);
    this.getDataServ(this.currentPage);
    this.hasGetData = true;
  }

  /**
   * Permet de récupérer les données du serveur.
   * @param pageNumber le numéro de la page.
   */
  private getDataServ(pageNumber: number) {
    // if (this.iPaginateService) {
    this.iPaginateService.getDataServ(pageNumber + '');
    // }
  }

  /**
   * @return le service nécessitant la pagination.
   */
  getIPaginateService(): IPaginateService {
    return this.iPaginateService;
  }

  setParentHasFinishedLoading(p: boolean) {
    this.parentHasFinishedLoading = p;
  }

  setPreviousPage(previousPage: number) {
    this.previousPage = previousPage;
  }

  getPreviousPageVal() {
    return this.previousPage;
  }

  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }

  getCurrentPage() {
    return this.currentPage;
  }

  setRouter(router: Router) {
    this.router = router;
  }

  setActivatedRoute(activatedRoute: ActivatedRoute) {
    this.activatedRoute = activatedRoute;
  }

  shouldIShow(): boolean {
    return this.iPaginateService && this.parentHasFinishedLoading;
  }

  /**
   * @return le path actif.
   */
  getPath(): string {
    return this.iPaginateService.getPath();
  }

  /**
   * @return les paramètres de l'url.
   */
  getParams(): Params {
    return this.iPaginateService.getParams();
  }

  /**
   * @return le nombre de pages.
   */
  getTotalPages(): number {
    return this.iPaginateService.getTotalPages();
  }

  /**
   * NB: les données vont permettre de savoir si le serveur a put récupérer
   * les données et de pouvoir afficher ou pas l'interface utilisateur.
   * @return les données récupérées du serveur.
   */
  getData() {
    return this.iPaginateService.getData();
  }

  /**
   * Permet d'afficher les paramètres de la page sur l'url.
   * @param pageNumber ..
   */
  setPageQueryParams(pageNumber: number) {
    if (this.iPaginateService) {
      console.log('v: p' + pageNumber);
      console.log('v: path' + this.getPath());
      this.manageService.navigateWithQueryParams(pageNumber, this.iPaginateService.getDateDebut(),
        this.iPaginateService.getDateFin(),
        this.activatedRoute, this.router, this.getPath(), this.getParams());
    }
  }

  /**
   * Permet de récupérer une page.
   * @param pageNumber le numéro de la page à récupérer.
   */
  getPage(pageNumber: number) {
    this.setPageQueryParams(pageNumber);
  }

  /**
   * Permet de récupérer les données de la page précédente.
   */
  getPrevious() {
    if (this.currentPage > 1) {
      --this.currentPage;
      --this.previousPage;
      this.setPageQueryParams(this.currentPage);
    }
  }

  /**
   * Permet de revenir à la page précèdent si l'on est à la dernière
   * page. Ne fais rien si l'on est pas à la dernière page.
   */
  getPreviousPage() {
    const data = this.getData();
    if (data && (this.previousPage === this.getTotalPages())) {
      this.getPrevious();
    }
  }

  /**
   * Permet de rendre le bouton précédent actif.
   * @returns true si le numéro de la page précédente ou courante est supérieure à 1.
   */
  isPreviousGreaterThanOne() {
    return this.previousPage > 1;
  }


  /**
   * @returns true si l'on est pas encore à la dernière page,
   * sinon false.
   */
  isNotLastPage(): boolean {
    let isNotLast = true;
    const data = this.getData();
    if (data && (this.currentPage >= (this.getTotalPages() - 1))) {
      isNotLast = false;
    }
    return isNotLast;
  }


  /**
   * Permet de rendre le bouton suivant actif.
   * @returns true si la page courante est inférieur au nombre total
   * d'éléments.
   */
  isCurrentLessThanMax(): boolean {
    const data = this.getData();
    if (data) {
      return this.currentPage < this.getTotalPages();
    }
    return false;
  }

  /**
   * Permet de rendre le bouton de la page précédente actif.
   * @returns true si on est sur la page courante, false sinon.
   */
  getActive(): boolean {
    if (this.previousPage >= 1 && this.previousPage < this.getTotalPages()) {
      return true;
    }
    return false;
  }

  /**
   * Permet de rendre le bouton de la page suivante actif.
   * @returns true si on est sur la page suivante, false sinon.
   */
  getActiveCurrent() {
    const data = this.getData();
    if (data && (this.currentPage === (this.getTotalPages()))) {
      return true;
    }
    return false;
  }


  /**
   * Permet de récupérer la dernière page.
   */
  getLastPage() {
    const data = this.getData();
    if (data) {
      const lastPageNumber = this.getTotalPages();
      this.setPageQueryParams(lastPageNumber);
      this.currentPage = lastPageNumber;
      this.previousPage = lastPageNumber;
    }
  }

  /**
   * @returns true si il y a un intervalle entre la page courante et la dernière
   * page, sinon false.
   */
  canILetThreeDots() {
    const data = this.getData();
    if (data && (this.previousPage >= (this.getTotalPages() - 2))) {
      return false;
    }
    return true;
  }

  shouldIShowPrevious(): boolean {
    if (this.showPreviousPage() > 0) {
      return true;
    }
    return false;
  }

  /**
   * @returns le numéro de la page précédente.
   */
  showPreviousPage(): number {
    const data = this.getData();
    if (data && (this.previousPage === (this.getTotalPages()))) {
      return this.previousPage - 1;
    }
    return this.previousPage;
  }

  /**
   * @returns le numéro de la page suivante.
   */
  showCurrentPage() {
    const data = this.getData();
    if (data && (this.currentPage === (this.getTotalPages()))) {
      return this.currentPage;
    }
    return this.currentPage + 1;
  }

  /**
   * Permet de récupérer les données de la page suivante.
   */
  getNext() {
    const data = this.getData();
    if (this.currentPage < this.getTotalPages()) {
      this.currentPage++;
      this.previousPage++;
      this.setPageQueryParams(this.currentPage);
    }
  }

  setIPaginateService(i: IPaginateService) {
    this.iPaginateService = i;
  }
}
