import {Injectable} from '@angular/core';
import {IMatTableService} from './imat-table.service';
import {IUtilisateur} from '../../models/utilisateur.model';

/**
 * Permet de gérer les {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class MatTablePopupService {
  constructor() {
  }

  private iMatTableService: IMatTableService;

  private selected = -1;
  private previous = -1;

  init(i: IMatTableService) {
    this.initValue();
    this.iMatTableService = i;
  }

  getDisplayColumns(): any[] {
    return this.iMatTableService.getDisplayColumns();
  }

  getDataSource() {
    return this.iMatTableService.getDataSource();
  }

  getSelected(): number {
    return this.selected;
  }

  getPrevious(): number {
    return this.previous;
  }

  initValue() {
    this.selected = -1;
    this.previous = -1;
    console.log('ini value');
    /*if (this.iMatTableService) {
      //  this.iMatTableService.hasChecked(null);
    }*/
  }

  clearAll() {
    this.initValue();
    this.iMatTableService = undefined;
  }

  clicked(row: any, i) {
    if (this.selected !== i) {
      this.selected = i;
    } else {
      this.selected = -1;
    }
    if (this.previous !== i) {
      this.previous = i;
      // console.log('val2: ' + row.login);
      this.iMatTableService.hasChecked(row, i);
    } else {
      this.previous = -1;
      this.iMatTableService.hasChecked(null, -1);
      console.log('not checked2');
    }
  }

  checked(event, row: IUtilisateur, i) {
    if (event) {
      if (event.checked) {
        this.selected = i;
        this.previous = i;
        this.iMatTableService.hasChecked(row, i);
        console.log('val: ' + row.login);
      } else {
        this.previous = -1;
        this.selected = -1;
        this.iMatTableService.hasChecked(null, -1);
        console.log('not checked');
      }
    }
  }

}
