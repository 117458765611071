import {NgModule} from '@angular/core';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {SortieListeComponent} from './sortie-liste/sortie-liste.component';
import {SortieShowComponent} from './sortie-show/sortie-show.component';
import {SortieDeleteComponent} from './sortie-delete/sortie-delete.component';
import {SortieUpdateComponent} from './sortie-update/sortie-update.component';
import {SortieSearchComponent} from './sortie-search/sortie-search.component';
import {FactureShowComponent} from '../abonnement/facture/facture-show/facture-show.component';
import {MeilleurProduitVenduComponent} from './meilleur-produit-vendu/meilleur-produit-vendu.component';
import {BilanComponent} from './bilan/bilan.component';

/**
 * module pour la gestion des catégories d'article.
 */
@NgModule({
  declarations: [
    BilanComponent,
    MeilleurProduitVenduComponent,
    FactureShowComponent,
    SortieSearchComponent,
    SortieUpdateComponent,
    SortieDeleteComponent,
    SortieShowComponent,
    SortieListeComponent],
  imports: [GestionStockSharedModule],
  entryComponents: [
    BilanComponent,
    MeilleurProduitVenduComponent,
    FactureShowComponent,
    SortieUpdateComponent,
    SortieDeleteComponent,
    SortieShowComponent]
})
export class SortieModule {
}
