import {AbsVerification} from './abs-verification';
import {ConstantUserColumnName} from '../../../../shared/constant/model/column-name/user-column-name.constant';

/**
 * Permet de vérifier si le nom fait partir des filtres de recherche
 * et de l'ajouter dans les paramètres dans ce cas.
 */
export class NomVerification extends AbsVerification {
  constructor() {
    super();
  }

  private nom: string;
  private userColName: ConstantUserColumnName;

  nomSet(nom: string): NomVerification {
    this.nom = nom;
    return this;
  }

  userColNameSet(userColName: ConstantUserColumnName): NomVerification {
    this.userColName = userColName;
    return this;
  }


  /**
   * Vérifie si le nom fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param first Permet de renseigner le résultat de la vérification
   * car la variable contenant le résultat n'est pas static.
   */
  check(first: AbsVerification) {
    if (this.nom) {
      first.getDataSearch()[this.userColName.NOM] = this.nom;
    }
    super.checkNext(first);
  }
}
