import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Event, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Lang, storage} from './main/shared/constant/model/global.constant';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {LocaleCsService} from './main/shared/ services/localecs.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private localeCsService: LocaleCsService,
    private translate: TranslateService) {

    const langKey = localStorage.getItem(storage.myLang);
    if (!langKey) {
      console.log('## change lang key');
      translate.setDefaultLang(Lang.DEFAULT);
      localStorage.setItem(storage.myLang, Lang.DEFAULT);
      this.localeCsService.registerCulture(Lang.DEFAULT);
    } else {
      console.log('## lang key: ', langKey);
      translate.setDefaultLang(langKey);
      this.localeCsService.registerCulture(langKey);
    }

    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoading = true;
        console.log('load start');
      }
      if (routerEvent instanceof NavigationEnd) {
        this.showLoading = false;
        console.log('load end');
      }
    });

    /*setInterval(() => {
      console.clear();
    }, 100);*/

    // As the router loads modules asynchronously (via loadChildren), we're going to
    // keep track of how many asynchronous requests are currently active. If there is
    // at least one pending load request, we'll show the indicator.

    // The Router emits special events for "loadChildren" configuration loading. We
    // just need to listen for the Start and End events in order to determine if we
    // have any pending configuration requests.

  }

  showLoading = false;
  title = 'Vsstocks by Digi mapps';

  ngOnInit(): void {
    console.log('init app');

  }
}
