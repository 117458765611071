import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../../shared/constant/model/server.constant';
import {
  API_ARTICLE,
  API_ARTICLE_DELETE,
  API_ARTICLE_MONTANT_TOTAL,
  API_ARTICLE_MONTANT_TOTAL_VENTE,
  API_ARTICLE_PAGE,
  API_ARTICLE_SEARCH, API_ARTICLE_SEARCH_AVANCE,
  API_ARTICLE_SEARCH_MONTANT_TOTAL,
  API_ARTICLE_SEARCH_MONTANT_TOTAL_AVANCE,
  API_ARTICLE_SEARCH_MONTANT_TOTAL_VENTE,
  API_ARTICLE_SEARCH_MONTANT_TOTAL_VENTE_AVANCE
} from '../../shared/constant/model/api.constant';
import {Observable} from 'rxjs';
import {Article} from '../../shared/models/article.model';

@Injectable({providedIn: 'root'})
export class ArticleService {
  constructor(
    private http: HttpClient,
  ) {
    console.log('construct art service');
  }

  // VAR.
  private resourceUrlDelete = SERVER_API_URL + API_ARTICLE_DELETE;

  private resourceUrlSearchAvance = SERVER_API_URL + API_ARTICLE_SEARCH_AVANCE;
  private resourceUrlSearch = SERVER_API_URL + API_ARTICLE_SEARCH;

  private resourceUrlSearchMontantTotalVenteAvance
    = SERVER_API_URL + API_ARTICLE_SEARCH_MONTANT_TOTAL_VENTE_AVANCE;
  private resourceUrlSearchMontantTotalVente = SERVER_API_URL + API_ARTICLE_SEARCH_MONTANT_TOTAL_VENTE;

  private resourceUrlSearchMontantTotalAvance
    = SERVER_API_URL + API_ARTICLE_SEARCH_MONTANT_TOTAL_AVANCE;
  private resourceUrlSearchMontantTotal = SERVER_API_URL + API_ARTICLE_SEARCH_MONTANT_TOTAL;

  private resourceUrl = SERVER_API_URL + API_ARTICLE;
  private resourceUrlPages = SERVER_API_URL + API_ARTICLE_PAGE;
  private resourceUrlMontantTotalVente = SERVER_API_URL + API_ARTICLE_MONTANT_TOTAL_VENTE;
  private resourceUrlMontantTotal = SERVER_API_URL + API_ARTICLE_MONTANT_TOTAL;

  private _refreshBtnActive = true;

  get refreshBtnActive(): boolean {
    return this._refreshBtnActive;
  }

  set refreshBtnActive(value: boolean) {
    this._refreshBtnActive = value;
  }


  searchAvance(params): Observable<HttpResponse<Article[]>> {
    return this.http.get<Article[]>(this.resourceUrlSearchAvance, {
      observe: 'response',
      params
    });
  }

  search(params): Observable<HttpResponse<Article[]>> {
    return this.http.get<Article[]>(this.resourceUrlSearch, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalVenteAvance(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalVenteAvance, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalVente(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalVente, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalAvance(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalAvance, {
      observe: 'response',
      params
    });
  }

  searchMontantTotal(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotal, {
      observe: 'response',
      params
    });
  }

  create(data): Observable<HttpResponse<Article>> {
    return this.http.post<Article>(this.resourceUrl, data, {
      observe: 'response'
    });
  }

  delete(params): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>(this.resourceUrlDelete, {
      observe: 'response',
      params
    });
  }

  update(data): Observable<HttpResponse<boolean>> {
    return this.http.put<boolean>(this.resourceUrl, data, {
      observe: 'response'
    });
  }

  getList(params): Observable<HttpResponse<Article[]>> {
    return this.http.get<Article[]>(this.resourceUrlPages, {
      observe: 'response',
      params
    });
  }

  getMontantVente(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlMontantTotalVente, {
      observe: 'response',
      params
    });
  }

  getMontant(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlMontantTotal, {
      observe: 'response',
      params
    });
  }
}
