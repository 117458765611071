import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserAccountService} from '../user-account.service';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL, TIME_WAIT_MAX_ATTEMPT} from '../../../shared/constant/model/server.constant';
import {API_AUTH} from '../../../shared/constant/model/api.constant';
import {Observable, Subject} from 'rxjs';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {takeUntil} from 'rxjs/operators';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {AuthService} from '../../../shared/ services/auth/auth.service';
import {RsaService} from '../../../shared/ services/rsa.service';

/**
 * Permet à l'utilisateur de saisir son mot de passe
 * afin de modifier ses informations de profil.
 */
@Component({
  selector: 'app-password-enter',
  templateUrl: './password-enter.component.html'
})
export class PasswordEnterComponent implements OnInit, OnDestroy {
  constructor(
    private rsaService: RsaService,
    private authService: AuthService,
    private accountService: AccountService,
    private modal: NgbModal,
    private activeModal: NgbActiveModal,
    private requestService: RequestService,
    private freeMemoryService: FreeMemoryService,
    private http: HttpClient,
    private errorServ: ConstantErrorServ,
    private userAccountService: UserAccountService,
    private popupReusableService: PopupReusableService
  ) {
  }

  resourceUrlAuth = SERVER_API_URL + API_AUTH;
  hasSetTimeAttempt = false;
  activatedUpdatedButton = true;
  open = true;
  // validationHasSucceed = false;
  shouldIShow = false;
  password: string;
  dataProfile;
  newPasswordCrypted: string;
  hasLoginError = false;
  hasPasswordError = false;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init password enter');
    this.popupReusableService.getPromise().then(res => {
      this.shouldIShow = true;
    });
    this.userAccountService.getUserUpdateDataObs()
      .pipe(takeUntil(this.subs$)).subscribe(res => {
      if (res) {
        this.dataProfile = res;
        if (res.password) {
          this.newPasswordCrypted = this.rsaService.crypt(res.password);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.reset();
    this.freeMemoryService.free(this.subs$);
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  reset() {
    // this.validationHasSucceed = false;
    this.password = undefined;
    this.hasLoginError = false;
    this.hasPasswordError = false;
  }

  authenticate() {
    let password;
    password = this.dataProfile.password ? this.dataProfile.password :
      this.dataProfile.oldPassword;
    this.authService.authenticate(this.dataProfile.login, password,
      this.resourceUrlAuth).subscribe(
      data => {
        console.log('** login success');
        this.accountService.forceIdentity();
      },
      (e: HttpErrorResponse) => {
        console.log('** login failed');
        console.log('erreur: ' + (e as Error).message);
        this.authService.logoutAndRedirectToLogin();
      });
  }

  update() {
    console.log('update');
    this.hasLoginError = false;
    this.hasPasswordError = false;
    // this.validationHasSucceed = false;
    if (this.password) {
      this.dataProfile.oldPassword = this.rsaService.crypt(this.password);
      this.dataProfile.password = this.newPasswordCrypted;
      const result = this.userAccountService.update(this.dataProfile);
      this.subscribeToSaveResponse(result);
    }
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromSavingUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    console.log('err: ' + JSON.stringify(erreur));
    const fields = erreur.fields;
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      this.hasLoginError = fields.login ? true : false;
    } else if (erreur.errorCode === this.errorServ.TOO_ATTEMPT) {
      this.activatedUpdatedButton = false;
      if (!this.hasSetTimeAttempt) {
        setTimeout(() => {
          this.activatedUpdatedButton = true;
          this.hasSetTimeAttempt = false;
        }, TIME_WAIT_MAX_ATTEMPT);
        this.hasSetTimeAttempt = true;
      }
    }
    this.hasPasswordError = fields.jhi_password ? true : false;
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<any>>
  ) {
    result.subscribe(
      (res: HttpResponse<any>) => {
        console.log('**** success update user account: ');
        this.authenticate();
        this.reset();
        // this.validationHasSucceed = true;
        this.requestService.successWithoutPath(NRequest.ERequest.UPDATE,
          this.subs$);
        this.modal.dismissAll();
      },
      (err: HttpErrorResponse) => {
        console.log('**** error update user: ');
        // this.validationHasSucceed = false;
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        try {
          this.manageErrorFromSavingUser(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

}
