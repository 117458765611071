import {Injectable} from '@angular/core';
import {FormService} from '../../../shared/ services/form.service';
import {ConstantUserColumnName} from '../../../shared/constant/model/column-name/user-column-name.constant';
import {FormGroup} from '@angular/forms';
import {IUtilisateur, Utilisateur} from '../../../shared/models/utilisateur.model';
import {ConstantUserValidator} from '../../../shared/constant/validator/user-validator.constant';

/**
 * Offre plusieurs types utilisés pour se connecter
 */
export namespace SignUp {
  /**
   * Permet d'avoir le nom des champs pour créer un compte.
   */
  export interface ColumnName {
    login: string;
    password: string;
    nom: string;
    prenom: string;
    sexe: string;
    email: string;
  }
}

/**
 * Permet d'initialiser le component {@link LoginComponent}
 */
@Injectable({providedIn: 'root'})
export class SignUpInit {
  constructor(
    private formService: FormService,
    private userColName: ConstantUserColumnName,
    private userValidator: ConstantUserValidator
  ) {
  }

  /**
   * récupère les données d'authentification du formulaire.
   */
  public getAuthFromForm2(editForm: FormGroup): IUtilisateur {
    const entity = {
      ...new Utilisateur(),
      login: this.formService.getData(this.userColName.LOGIN, editForm, this.userColName.fields),
      password: this.formService.getData(
        this.userColName.PASSWORD,
        editForm, this.userColName.fields,
        false
      ),
      nom: this.formService.getData(this.userColName.NOM, editForm, this.userColName.fields),
      prenom: this.formService.getData(this.userColName.PRENOM, editForm, this.userColName.fields),
      sexe: this.formService.getDataObj(this.userColName.SEXE, editForm)
    };
    return entity;
  }

  /**
   * récupère les données d'authentification du formulaire.
   */
  public getAuthFromForm(editForm: FormGroup): IUtilisateur {
    const entity = {
      ...new Utilisateur(),
      login: this.formService.getData(this.userColName.LOGIN, editForm, this.userColName.fields),
      password: this.formService.getData(
        this.userColName.PASSWORD,
        editForm, this.userColName.fields,
        false
      ),
      nom: this.formService.getData(this.userColName.NOM, editForm, this.userColName.fields),
      prenom: this.formService.getData(this.userColName.PRENOM, editForm, this.userColName.fields),
      email: this.formService.getData(this.userColName.EMAIL, editForm, this.userColName.fields),
      sexe: this.formService.getDataObj(this.userColName.SEXE, editForm)
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour se connecter.
   */
  getAuthColumnName(): SignUp.ColumnName {
    return {
      login: this.userColName.LOGIN,
      password: this.userColName.PASSWORD,
      nom: this.userColName.NOM,
      prenom: this.userColName.PRENOM,
      email: this.userColName.EMAIL,
      sexe: this.userColName.SEXE
    };
  }

  /**
   * @returns Formulaire de connexion.
   */
  getForm2(): any {
    return {
      login: this.userValidator.LOGIN,
      password: this.userValidator.PASSWORD,
      nom: this.userValidator.NOM,
      prenom: this.userValidator.PRENOM,
      sexe: this.userValidator.SEXE
    };
  }

  /**
   * @returns Formulaire de connexion.
   */
  getForm(): any {
    return {
      login: this.userValidator.LOGIN,
      password: this.userValidator.PASSWORD,
      nom: this.userValidator.NOM,
      prenom: this.userValidator.PRENOM,
      email: this.userValidator.EMAIL,
      sexe: this.userValidator.SEXE
    };
  }
}
