import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RGlobal} from './main/shared/constant/global.url';
import {LoginComponent} from './main/pages/login/login.component';
import {GGuardLoginService} from './main/shared/ services/guard/login/gguard-login.service';

const routes: Routes = [
  {
    path: RGlobal.url.LOGIN,
    component: LoginComponent,
    canActivate: [GGuardLoginService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
