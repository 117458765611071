import {Component, OnDestroy, OnInit} from '@angular/core';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {EmployeService} from '../employe.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';
import {AccountUser} from '../../../shared/models/account.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IPaginateService} from '../../../shared/layouts/pagination/service/ipaginate.service';
import {Uri} from '../../../shared/constant/model/global.constant';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GestionType} from '../../../shared/models/action.model';
import {RGlobal} from '../../../shared/constant/global.url';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ManageService} from '../../../shared/ services/manage.service';
import {Title} from '@angular/platform-browser';
import {MatTableDataSource} from '@angular/material';
import {IrouteCheckService} from '../../../shared/ services/route/checking/iroute-check.service';
import {ActivatedRouteService} from '../../../shared/ services/route/activated-route.service';
import {RouteCheckService} from '../../../shared/ services/route/checking/route-check.service';
import {RouteCheckParam} from '../../../shared/ services/route/checking/route-check-param';
import {IrouteCheckInitService} from '../../../shared/ services/route/checking/iroute-check-init.service';
import {IGettingPrivilegeService} from '../../../shared/ services/privilege/getting/igetting-privilege.service';
import {GettingPrivilegeService} from '../../../shared/ services/privilege/getting/getting-privilege.service';
import {MatTableService} from '../../../shared/ services/mat-table/mat-table.service';
import {IMatTableService} from '../../../shared/ services/mat-table/imat-table.service';
import {DataPageRequest} from '../../../shared/ services/request/data-page-request.service';
import {EmployeDataShare} from '../employe-data-share.service';
import {DataShareWhenCheckedUser} from '../data-share-when-checked-user.service';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {PaginationService} from '../../../shared/layouts/pagination/service/pagination.service';
import {DateFormat} from '../../../shared/constant/model/date.constant';
import {BoutiqueService} from '../../boutique/boutique.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {EmployeShowComponent} from '../employe-show/employe-show.component';
import {EmployeDeleteComponent} from '../employe-delete/employe-delete.component';
import {EmployeUpdateComponent} from '../employte-update/employe-update.component';
import {EmployeAddComponent} from '../employe-add/employe-add.component';


/**
 * Permet de gérer la liste des demandes reçues pour des boutiques.
 */
@Component({
  selector: 'app-employe-list',
  templateUrl: './employe-list.component.html'
})
export class EmployeListComponent implements OnInit, OnDestroy,
  IPaginateService,
  IrouteCheckService, IrouteCheckInitService, IGettingPrivilegeService, IMatTableService {

  constructor(
    private popupReusableService: PopupReusableService,
    private boutiqueService: BoutiqueService,
    private title: Title,
    private dateFormat: DateFormat,
    private paginationService: PaginationService,
    private dateFormatService: DateFormatService,
    private activatedRoute: ActivatedRoute,
    private dataShareWhenCheckedUser: DataShareWhenCheckedUser,
    private employeService: EmployeService,
    public userDataShare: EmployeDataShare,
    private matTableService: MatTableService,
    private gettingPrivilegeService: GettingPrivilegeService,
    private dataPageRequest: DataPageRequest,
    private activatedRouteService: ActivatedRouteService,
    private freeMemoryService: FreeMemoryService,
    private userListRouteCheckService: RouteCheckService,
    private router: Router,
    private manageService: ManageService,
    private accountService: AccountService) {
  }

  // VAR.
  boutiqueSelected: Boutique;
  iCanAddUser = false;
  iCanSearchUser = false;
  iCanModifyUser = false;
  iCanDeleteUser = false;
  iCanShowUser = false;
  usersList: EmployeBoutique[];
  nbUsers: number;
  totalPages: number;
  accountUser: AccountUser;
  params: Params = {};
  userIsChecked = false;
  hasFinishedLoading = false;

  // SERVICES.
  subs$ = new Subject<void>();

  ngOnInit() {
    console.log('** init user list');
    this.dataShareWhenCheckedUser.clearUserChecked();
    this.accountService.identity(this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      if (res) {
        console.log('checking');
        console.log('ident1: ');
        this.getUserIdentityObs(this.accountService, res);
      }
    });
    this.checkParams(this.activatedRoute, {
        [Uri.PAGE]: undefined,
        [Uri.DATE_DEBUT]: undefined, [Uri.DATE_FIN]: undefined
      },
      this, this.getPath(), this.subs$,
      new RouteCheckParam().setMatTableService(this.matTableService));
    this.verifyIsUserIsChecked(this.dataShareWhenCheckedUser, this.subs$);
    this.dataPageRequest.initDataPage(this, this.subs$);
    this.matTableService.init(this);
  }


  /**
   * Libère les ressources.
   */
  ngOnDestroy() {
    console.log('restore user');
    this.restore();
  }

  restore() {
    this.userListRouteCheckService.restore();
    this.matTableService.clearAll();
    this.freeMemoryService.free(this.subs$);
  }

  date() {
    return this.dateFormatService;
  }

  getListUsersDate() {
    console.log('search by date');
    this.paginationService.setPageQueryParams(1);
  }

  /**
   *
   * @param dataShare ..
   * @param subs$ ..
   */
  verifyIsUserIsChecked(dataShare: DataShareWhenCheckedUser,
                        subs$: Subject<void>) {
    dataShare.getIsUserCheckedObs()
      .pipe(takeUntil(subs$)).subscribe((v: boolean) => {
      this.userIsChecked = v;
    });
  }


  checkParams(activatedRoute: ActivatedRoute, paramsExpected: any,
              iRouteCheck: IrouteCheckInitService, path: string,
              subs$: Subject<void>, routeCheckParam: RouteCheckParam) {
    this.userListRouteCheckService.checkParams(activatedRoute, paramsExpected,
      iRouteCheck, path, this.subs$, routeCheckParam);
  }

  /**
   *
   */
  getTitle(): Title {
    return this.title;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService,
                     boutiqueSelected: Boutique) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        console.log('ident0: ');
        this.accountUser = accountUser;
        this.gettingPrivilegeService.getPrivilege(accountUser, this,
          GestionType.USER, boutiqueSelected, this.subs$);
      });
  }


  /**
   * @param usersList la liste des utilisateurs.
   */
  setUsersList(usersList: EmployeBoutique[]) {
    this.usersList = usersList;
  }

  /**
   * @param nbUsers le nombre d'utilisateurs.
   */
  setNbUsers(nbUsers: number) {
    this.nbUsers = nbUsers;
  }

  /**
   * Permet de savoir si l'utilisateur est coché.
   */
  isUserChecked(): boolean {
    return this.userIsChecked;
  }


  /**
   * Permet de récupérer le compte de l'utilisateur coché dans la tableau.
   * @param user l'utilisateur coché.
   * @param event permet d'indiquer si la case est cochée ou pas.
   */
  hasChecked(user: any) {
    if (user) {
      this.dataShareWhenCheckedUser.setUsercheckedObs(user);
      this.dataShareWhenCheckedUser.setIsUserChecked(true);
    } else {
      this.dataShareWhenCheckedUser.clearUserChecked();
    }
  }

  openPopupUserAdd() {
    this.popupReusableService.open(null, EmployeAddComponent);
  }

  /**
   * @returns true l'utilisateur peut ajouter, false sinon.
   */
  canIAddUser(): boolean {
    return this.iCanAddUser;
  }

  /**
   * @returns true l'utilisateur peut ajouter, false sinon.
   */
  canISearchUser(): boolean {
    return this.iCanSearchUser;
  }

  getAccount(): AccountUser {
    return this.accountUser;
  }

  /**
   * @returns le nombre total de pages.
   */
  getTotalPagesData(): number {
    return this.getTotalPages();
  }

  /**
   * @returns le nombre d'utilisateurs.
   */
  getNbUsersData(): number {
    return this.getNbUsers();
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getUsersListData(): EmployeBoutique[] {
    return this.getUsersList();
  }

  /**
   * @returns le nombre total de pages.
   */
  getTotalPages(): number {
    return this.totalPages;
  }

  /**
   * @returns le nombre d'utilisateurs.
   */
  getNbUsers(): number {
    return this.nbUsers;
  }

  /**
   * @returns la liste des utilisateurs par page.
   */
  getUsersList(): EmployeBoutique[] {
    return this.usersList;
  }

  /**
   * Permet de récupérer au serveur la liste des utilisateurs par page.
   */
  getListUsers(pageNumber: string) {
    console.log('try to list users');
    this.userDataShare.spinnerTable = true;
    let params = new HttpParams();
    params = params.set(Uri.PAGE, pageNumber);
    params = params.set(Uri.DATE_DEBUT, this.userDataShare.dateDebut);
    params = params.set(Uri.DATE_FIN, this.userDataShare.dateFin);
    params = params.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.employeService.getListPages(params);
    result.pipe(takeUntil(this.subs$)).subscribe(
      (data: any) => {
        console.log('** succès récupération users par page');
        const res = data.body;
        this.userDataShare.spinnerTable = false;
        this.dataPageRequest.getDataPage(res);
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération users par page');
        this.userDataShare.spinnerTable = false;
        this.manageService.redirectToPageNotFound();
      }
    );
  }

  openPopupUserShow() {
    this.popupReusableService.open(null, EmployeShowComponent);
  }

  goToUserUpdateRoute() {
    this.popupReusableService.open(null, EmployeUpdateComponent);
  }

  openPopupUserDelete() {
    this.popupReusableService.open(null, EmployeDeleteComponent);
  }

  /**
   * @return les données récupérées du serveur.
   */
  getData() {
    return this.getUsersList();
  }

  /**
   * Permet de récupérer les données du serveur.
   * @param pageNumber le numéro de la page.
   */
  getDataServ(pageNumber: string) {
    this.getListUsers(pageNumber);
  }

  /**
   * @return le nombre d'utilisateurs.
   */
  getNbItems(): number {
    return this.getNbUsers();
  }

  /**
   * @return les paramètres de l'url.
   */
  getParams(): Params {
    return this.params;
  }

  /**
   * @return le path de la liste des utilisateurs.
   */
  getPath(): string {
    return RGlobal.urlFull.EMPLOYE.LIST;
  }

  getPathRoute(): string {
    return this.getPath();
  }

  getService(): IPaginateService {
    return this;
  }

  setParamsRoute(params: any) {
    console.log('set param');
    this.params = params;
    const date = this.userListRouteCheckService.setDate(params, {
      dateDebut: this.userDataShare.dateDebut,
      dateFin: this.userDataShare.dateFin
    });
    this.userDataShare.dateDebut = date.dateDebut;
    this.userDataShare.dateFin = date.dateFin;
  }

  getTitlePath(): string {
    return 'Liste des employés';
  }

  setData(data: any[]) {
    this.usersList = data;
  }

  setNbItems(n: number) {
    this.nbUsers = n;
  }

  /**
   * @param totalPages le nombre de page.
   */
  setTotalPages(t: number) {
    this.totalPages = t;
  }

  iCanAdd(b: boolean) {
    this.iCanAddUser = b;
  }

  iCanDelete(b: boolean) {
    this.iCanDeleteUser = b;
  }

  iCanModify(b: boolean) {
    this.iCanModifyUser = b;
  }

  iCanSearch(b: boolean) {
    this.iCanSearchUser = b;
  }

  iCanShow(b: boolean) {
    if (!b) {
      this.router.navigate([RGlobal.url.PAGE_RESTRICTED]);
    }
    this.iCanShowUser = b;
  }

  canIDelete(): boolean {
    return this.iCanDeleteUser;
  }

  canIModify(): boolean {
    return this.iCanModifyUser;
  }

  canIShowUser(): boolean {
    return this.iCanShowUser;
  }

  checked(event, row, i) {
    return this.matTableService.checked(event, row, i);
  }

  getSelected() {
    return this.matTableService.getSelected();
  }

  getDisplayColumns(): any[] {
    return ['select', 'login', 'nom', 'prenom',
      'email', 'telephone', 'sexe', 'nationalite', 'birthdate',
      'adresse', 'date_modification', 'date_enregistrement'];
  }

  getDataSource(): MatTableDataSource<any> {
    return new MatTableDataSource<EmployeBoutique>(this.getUsersList());
  }

  getPrevious() {
    return this.matTableService.getPrevious();
  }

  clicked(row, i) {
    return this.matTableService.clicked(row, i);
  }

  getDateDebut(): string {
    return this.userDataShare.dateDebut;
  }

  getDateFin(): string {
    return this.userDataShare.dateFin;
  }

  /**
   * Permet d'indiquer à l'enfant que la liste des données a été affichée,
   * afin d'afficher ensuite la pagination.
   */
  finishedLoading() {
    this.hasFinishedLoading = true;
  }

}
