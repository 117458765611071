import {slashRoute} from './global.url';

export namespace RGlobalAdmin {
  export const url = {
    LOGIN: 'admin', // page d'authentification administration.
    ACCUEIL: 'admin/accueil',
    ADMINISTRATEUR: {
      ADMINISTRATEUR_: 'admin/utilisateurs',
      LIST: 'liste',
      SEARCH: 'search',
      CREATE: 'create',
      UPDATE: 'update'
    },
    PAGE_NOT_FOUND_ADMIN: 'page-not-found-admin',
  };

  export const urlFull = {
    ADMINISTRATEUR: {
      LIST: RGlobalAdmin.url.ADMINISTRATEUR.ADMINISTRATEUR_
        .concat(slashRoute).concat(RGlobalAdmin.url.ADMINISTRATEUR.LIST),
      SEARCH: RGlobalAdmin.url.ADMINISTRATEUR.ADMINISTRATEUR_
        .concat(slashRoute).concat(RGlobalAdmin.url.ADMINISTRATEUR.SEARCH),
      CREATE: RGlobalAdmin.url.ADMINISTRATEUR.ADMINISTRATEUR_
        .concat(slashRoute).concat(RGlobalAdmin.url.ADMINISTRATEUR.CREATE),
      UPDATE: RGlobalAdmin.url.ADMINISTRATEUR.ADMINISTRATEUR_
        .concat(slashRoute).concat(RGlobalAdmin.url.ADMINISTRATEUR.UPDATE)
    },
  };
}
