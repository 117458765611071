import {AfterViewInit, Directive, ElementRef, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Directive({
  selector: '[appCheckboxOne]'
})
export class CheckboxOneDirective implements OnInit, AfterViewInit {
  private elementChecked: ElementRef; // le checkbox selectionné.

  ngAfterViewInit(): void {
    // permet de ne cocher qu'un seul checkbox à la fois.
    $(this.elementChecked.nativeElement).on('change', function() {
      $('input.data-checkbox')
        .not(this)
        .prop('checked', false);
    });
  }

  constructor(private el: ElementRef) {
    console.log('*** onconstruct checkbox');
    this.elementChecked = this.el;
  }

  ngOnInit() {
    console.log('*** oninit checkbox');
  }
}
