import {Routes} from '@angular/router';
import {RGlobal} from '../../shared/constant/global.url';
import {EmployeListComponent} from './employe-list/employe-list.component';
import {GGuardIsLoginService} from '../../shared/ services/guard/is-login/gguard-is-login.service';
import {GuardCheckingBoutiqueService} from '../../shared/ services/guard/login/guard-checking-boutique.service';
import {GguardPrivilegeEmployeService} from '../../shared/ services/guard/guard-privilege-employe.service';

/**
 * routes de la page d'accueil.
 */
export const employeRoute: Routes = [
  {
    path: RGlobal.url.EMPLOYE.EMPLOYE_,
    canActivate: [GGuardIsLoginService, GguardPrivilegeEmployeService],
    children: [
      {
        path: RGlobal.url.EMPLOYE.LIST, component: EmployeListComponent,
        canActivate: [GuardCheckingBoutiqueService]
      },
      {
        path: RGlobal.url.EMPLOYE.SEARCH, component: EmployeListComponent,
        canActivate: [GuardCheckingBoutiqueService]
      }
    ]
  }
];
