import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {FreeMemoryService} from '../../reusable/services/free-memory.service';
import {IArticleInfos} from '../../shared/models/article-infos.model';

/**
 * Permet de gérer les {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class RestoreArtInfosService {
  constructor(private freeMemoryService: FreeMemoryService) {
  }

  // VAR
  artInfosChecked: IArticleInfos;

  getArtInfosChecked(): IArticleInfos {
    return this.artInfosChecked;
  }

  setArtInfosChecked(user: IArticleInfos) {
    this.artInfosChecked = user;
  }

  /**
   * Permet de réinitialiser certaines variable à la fermeture
   * du popup
   */
  restore(subs$: Subject<void>) {
    this.artInfosChecked = undefined;
    this.freeMemoryService.free(subs$);
  }
}
