import {AfterViewInit, Directive, ElementRef, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Directive({
  selector: '[appMenuToggle]'
})
export class MenuToggleDirective implements OnInit, AfterViewInit {
  private elementClicked: ElementRef;

  ngAfterViewInit(): void {
    // permet d'appliquer l'animation slide au menu.
    $(this.elementClicked.nativeElement).on('click', function() {
      $(this).find(' + .menu-toggle')
        .slideToggle(100);
      const iconElement = $(this).find('.font-menu-right:first-child');
      let val = iconElement.hasClass('fa-angle-right');
      if (val) {
        iconElement.removeClass('fa-angle-right');
        iconElement.addClass('fa-angle-down');
      } else {
        iconElement.removeClass('fa-angle-down');
        iconElement.addClass('fa-angle-right');
      }
    });
  }

  constructor(private el: ElementRef) {
    console.log('*** onconstruct menu toggle');
    this.elementClicked = this.el;
  }

  ngOnInit() {
    console.log('*** oninit menu toggle');
  }
}
