import {Moment} from 'moment';
import {IUtilisateur} from './utilisateur.model';

export interface IBoutique {
  id?: number;
  identifiant?: string;
  nom?: string;
  devise?: string;
  description?: string;
  telephone1?: string;
  telephone2?: string;
  telephone3?: string;
  adresse?: string;
  pays?: string;
  ville?: string;
  isDelete?: string;
  dateEnregistrement?: Moment;
  dateModification?: Moment;
  utilisateur?: IUtilisateur;
}

export class Boutique implements IBoutique {
  constructor(
    public id?: number,
    public identifiant?: string,
    public nom?: string,
    public devise?: string,
    public description?: string,
    public telephone1?: string,
    public telephone2?: string,
    public telephone3?: string,
    public adresse?: string,
    public pays?: string,
    public ville?: string,
    public isDelete?: string,
    public dateEnregistrement?: Moment,
    public dateModification?: Moment,
    public utilisateur?: IUtilisateur
  ) {
  }
}
