import {MatTableService} from '../../mat-table/mat-table.service';

export class RouteCheckParam {

  private matTableService: MatTableService;

  setMatTableService(m: MatTableService) {
    this.matTableService = m;
    return this;
  }

  getMatTableService(): MatTableService {
    return this.matTableService;
  }
}
