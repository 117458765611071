import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'defaultValue' })
export class DefaultValue implements PipeTransform {
  transform(value: any): any {
    if (value === '' || value === null || value === undefined) {
      return 'Aucun(e)';
    }
    return value;
  }
}
