import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {JWT} from '../../constant/model/server.constant';
import {RGlobal, slashRoute} from '../../constant/global.url';
import {AccountShareService} from './account/account-share.service';
import {RGlobalAdmin} from '../../constant/global.url.admin';
import {AccountAdminService} from './account/account-admin.service';

/**
 * Permet de gérer l'authentification des {@link Utilisateur}.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private accountAdminService: AccountAdminService,
    private accountShareService: AccountShareService,
    private router: Router, private httpClient: HttpClient) {
  }

  // VAR.
//  private resourceUrl = SERVER_API_URL + API_AUTH;

  /**
   * Permet d'authentifier un utilisateur.
   * @param username le nom d'utilisateur.
   * @param password le mot de passe.
   */
  authenticate(username, password, resourceUrl: string) {
    console.log('** authentication execute');
    return this.httpClient
      .post<any>(resourceUrl, {
        username,
        password
      })
      .pipe(
        map(userData => {
          if (this.router.url === (slashRoute + RGlobalAdmin.url.LOGIN)) {
            localStorage.clear();
            localStorage.setItem(JWT.usernameAdmin, username);
            const tokenStr = JWT.bearer + userData.token;
            localStorage.setItem(JWT.tokenAdmin, tokenStr);
          } else {
           // localStorage.clear();
            localStorage.setItem(JWT.username, username);
            const tokenStr = JWT.bearer + userData.token;
            localStorage.setItem(JWT.token, tokenStr);
          }
          return userData;
        })
      );
  }

  isUserMachand() {
    const user = localStorage.getItem(JWT.anonyme);
    return !(user === null || user === undefined);
  }

  /**
   * Permet de vérifier si un utilisateur est connecté.
   * @returns true si l'utilisateur est connecté, false
   * dans le cas contraire.
   */
  isUserLoggedIn(): boolean {
    const user = localStorage.getItem(JWT.username);
    console.log('user val: ' + user);
    return !(user === null || user === undefined);
  }

  isAdminLoggedIn(): boolean {
    const user = localStorage.getItem(JWT.usernameAdmin);
    return !(user === null || user === undefined);
  }

  /**
   * Permet de déconnecter un utilisateur.
   */
  logout() {
    // localStorage.removeItem(JWT.username);
    localStorage.clear();
    this.accountShareService.clearUserInfos();
  }

  /**
   * Permet de déconnecter un utilisateur.
   */
  logoutAdmin() {
    localStorage.removeItem(JWT.usernameAdmin);
    this.accountAdminService.clearUserInfos();
  }

  /**
   * Permet de déconnecter un utilisateur et de le rediriger
   * vers la page d'accueil.
   */
  logoutAndRedirectToLogin() {
    console.log('** logout and redirect');
    this.logout();
    this.router.navigate([RGlobal.url.LOGIN]);
  }

  /**
   * Permet de déconnecter un utilisateur et de le rediriger
   * vers la page d'accueil.
   */
  logoutAdminAndRedirectToLogin() {
    console.log('** logout admin and redirect');
    this.logoutAdmin();
    this.router.navigate([RGlobalAdmin.url.LOGIN]);
  }
}
