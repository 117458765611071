/**
 * Permet la modification des types d'utilisateurs.
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AcceptAction, Action, GestionType, IAction, PrivilegeType} from '../../../shared/models/action.model';
import {Observable, Subject} from 'rxjs';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {takeUntil} from 'rxjs/operators';
import {AccountUser} from '../../../shared/models/account.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {ActionManageService} from '../../../shared/ services/action-manage.service';
import {EmployeUpdateForInit} from './employe-update.init';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {DataShareWhenCheckedUser} from '../data-share-when-checked-user.service';
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';
import {EmployeService} from '../employe.service';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {RGlobal} from '../../../shared/constant/global.url';
import {Utilisateur} from '../../../shared/models/utilisateur.model';
import {ConstantUserColumnName} from '../../../shared/constant/model/column-name/user-column-name.constant';
import {ConstantBoutiqueColumnName} from '../../../shared/constant/model/column-name/boutique-column-name.constant';

@Component({
  selector: 'app-employe-update',
  templateUrl: './employe-update.component.html'
})
export class EmployeUpdateComponent implements OnInit, OnDestroy {
  constructor(
    private userColumnName: ConstantUserColumnName,
    private boutiqueColumnName: ConstantBoutiqueColumnName,
    private requestService: RequestService,
    private employeService: EmployeService,
    private dataShare: DataShareWhenCheckedUser,
    private freeMemoryService: FreeMemoryService,
    private popupReusableService: PopupReusableService,
    private actionManageService: ActionManageService,
    private boutiqueService: BoutiqueService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal) {
    console.log('** on construct update type user.');
  }

  // VAR.
  open = true;
  employe: EmployeBoutique;
  iActions: IAction[];
  OUI = AcceptAction.OUI;
  NON = AcceptAction.NON;
  boutiqueSelected: Boutique;
  accountUser: AccountUser;
  subs$ = new Subject<void>();

  /**
   * Récupère les privilèges de l'utilisateur du serveur.
   */
  ngOnInit(): void {
    console.log('** on init update type user');
    this.accountService.identity(this.subs$);
    this.dataShare.getUsercheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((res: EmployeBoutique) => {
        this.employe = res;
        this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
          this.subs$
        )).subscribe((b: Boutique) => {
          this.boutiqueSelected = b;
          if (b && res) {
            this.getUserIdentityObs(this.accountService, b, res.utilisateur);
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  getGestionType(gestionType: GestionType) {
    switch (gestionType) {
      case GestionType.USER:
        return 'Utilisateur';
      case GestionType.ARTICLE:
        return 'Article enregistré';
      case GestionType.ARTICLE_INFOS:
        return 'Article disponible';
      case GestionType.CATEGORIE_ARTICLE:
        return 'Catégorie article';
      case GestionType.SORTIE:
        return 'Sortie';
      case GestionType.BILAN:
        return 'Bilan';
      case GestionType.FACTURE:
        return 'Facture';
      case GestionType.PRODUIT_PLUS_ACHETE:
        return 'Meilleurs produits vendus';
    }
  }

  /**
   * @returns les privilèges de l'utilisateur.
   */
  getActions(): IAction[] {
    return this.iActions;
  }

  /**
   * @returns AcceptAction permettant de tester si l'utilisateur
   * possède le privilège.
   */
  getOui(): AcceptAction {
    return this.OUI;
  }

  /**
   * Permet de modifier la valeur des privilèges de l'utilisateur.
   * @param action le privilège de l'utilisateur.
   * @param event permet de savoir si l'utilisateur a coché ou pas.
   * @param priv le type de privilège.
   */
  getValue(action: IAction, event, priv: PrivilegeType) {
    if (event) {
      const checked = event.checked;
      if (checked) {
        this.affect(action, priv, AcceptAction.OUI);
      } else {
        this.affect(action, priv, AcceptAction.NON);
      }
    }
  }

  /**
   * Permet d'affecter un privilège en fonction du type.
   * @param action le privilège
   * @param priv le type de privilège.
   * @param value la valeur à affecter.
   */
  affect(action: IAction, priv: PrivilegeType, value: AcceptAction) {
    this.actionManageService.affect(action, priv, value);
  }

  /**
   * Permet de récupérer les types de privilèges.
   * @returns les types de privilèges.
   */
  getPriv(): EmployeUpdateForInit.UserPrivType {
    return {
      ajouter: PrivilegeType.AJOUTER,
      modifier: PrivilegeType.MODIFIER,
      supprimer: PrivilegeType.SUPPRIMER,
      consulter: PrivilegeType.CONSULTER,
      rechercher: PrivilegeType.RECHERCHER,
      telecharger: PrivilegeType.TELECHARGER,
      imprimer: PrivilegeType.IMPRIMER,
    };
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   * @param boutiqueSelected ..
   * @param userChecked ..
   */
  getUserIdentityObs(iAccountService: AccountService,
                     boutiqueSelected: Boutique,
                     userChecked: Utilisateur) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
        let option = new HttpParams();
        option = option.set(this.userColumnName.NAME_USER, userChecked.id.toString());
        option = option.set(this.boutiqueColumnName.NAME, boutiqueSelected.id.toString());
        const result = this.employeService.getActions(option);
        this.subscribeToSaveResponseAction(result, boutiqueSelected);
      });
  }

  /**
   * Ferme le popup.
   * @param activeModal ..
   */
  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de modifier les privilèges d'un utilisateur.
   * @param activeModal le popup de modification des privilèges.
   */
  updateAction() {
    const emp = new EmployeBoutique();
    emp.id = this.employe.id;
    const data = {
      employe: emp,
      actions: this.iActions
    };
    const result = this.employeService.update(data);
    this.subscribeToSaveResponse(result);
    /*this.userUpdateService.setAllUserActionsFromServObs(
      this.actionManageService.getCopy(this.iActions));*/
  }

  /**
   * Permet récupérer la réponse lors de la modification
   * d'un {@link Utilisateur}
   *
   * @param result la réponse.
   */
  subscribeToSaveResponseAction(
    result: Observable<HttpResponse<Action[]>>,
    boutiqueSelected: Boutique
  ) {
    result.subscribe(
      (res: HttpResponse<Action[]>) => {
        console.log('**** success get actions user: ' + res);
        const data = res.body;
        if (data) {
          this.iActions = this.actionManageService.getCopyByBoutique(
            data, boutiqueSelected);
        }
      },
      (err: HttpErrorResponse) => {
        console.log('**** error get actions user: ');
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  /**
   * Permet récupérer la réponse lors de la modification
   * d'un {@link Utilisateur}
   *
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        console.log('**** success update user: ' + res);
        const data = res.body;
        if (data) {
          this.popupReusableService.dismiss(this.activeModal);
          this.requestService.success(NRequest.ERequest.UPDATE, null,
            RGlobal.urlFull.EMPLOYE.LIST, this.subs$);
          this.accountService.forceIdentity();
        }
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save user: ');
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

}
