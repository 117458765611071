import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormService} from '../../../shared/ services/form.service';
import {FormBuilder} from '@angular/forms';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {HttpClient} from '@angular/common/http';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AccountUser} from '../../../shared/models/account.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UpdateProfileInit, UpdateProfileNm} from './user-account-update.init';
import {SexeType} from '../../../shared/models/utilisateur.model';
import {PasswordEnterComponent} from '../password-enter/password-enter.component';
import {UserAccountService} from '../user-account.service';
import {UserAccountDeleteComponent} from '../user-account-delete/user-account-delete.component';

/**
 * Permet de modifier le profile un compte utilisateur.
 */
@Component({
  selector: 'app-user-account-update',
  templateUrl: './user-account-update.component.html'
})
export class UserAccountUpdateComponent implements OnInit, OnDestroy {
  constructor(
    private userAccountService: UserAccountService,
    private activeModal: NgbActiveModal,
    private accountService: AccountService,
    private popupReusableService: PopupReusableService,
    private formService: FormService,
    private signInInit: UpdateProfileInit,
    private formSignIn: FormBuilder,
    private errorServ: ConstantErrorServ,
    private http: HttpClient
  ) {
    this.signInColName = this.signInInit.getAuthColumnName();
  }

  // VAR.
  editForm = this.formSignIn.group(this.signInInit.getForm());
  hasLoginError = false;
  hasEmailError = false;
  signInColName: UpdateProfileNm.ColumnName;
  accountUser: AccountUser;
  open = true;
  subs$ = new Subject<void>();
  shouldIShow = false;
  // SEXE
  sexeHomme = SexeType.HOMME;
  sexeFemme = SexeType.FEMME;

  ngOnInit(): void {
    console.log('user update');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
    });
  }

  ngOnDestroy(): void {
  }

  update() {
    console.log('update');
    this.userAccountService.setUserUpdateDataObs(
      this.signInInit.getAuthFromForm(this.editForm));
    this.popupReusableService.open(null, PasswordEnterComponent);
  }

  delete() {
    console.log('delete');
    this.popupReusableService.open(null, UserAccountDeleteComponent);
  }

  getPassword() {
    return this.formService.getData(this.signInColName.password,
      this.editForm, [], false);
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
        this.editForm.patchValue({
          login: accountUser.login,
          nom: accountUser.nom,
          prenom: accountUser.prenom,
          adresse: accountUser.adresse,
          nationalite: accountUser.nationalite,
          telephone: accountUser.telephone,
          dateNaissance: accountUser.dateNaissance,
          sexe: accountUser.sexe
        });
      });
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de vérifier si l'email contient des erreurs.
   * @param field champs à vérifier.
   */
  isEmailInvalid(field: string): boolean {
    return this.formService.isEmailInvalid(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorPattern(field: string): boolean {
    return this.formService.hasErrorPattern(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }
}
