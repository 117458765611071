import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {ConstantBoutiqueSize} from '../model/size/boutique-size.constant';

/**
 * Permet la validation des champs pour un {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class ConstantBoutiqueValidator {
  constructor(private userSize: ConstantBoutiqueSize) {
  }

  readonly NOM = [
    null,
    [
      Validators.required,
      Validators.minLength(this.userSize.NOM_MIN_SIZE),
      Validators.maxLength(this.userSize.NOM_MAX_SIZE)
    ]
  ];

  readonly PAYS = [
    null,
    [
      Validators.required,
      Validators.minLength(this.userSize.PAYS_MIN_SIZE),
      Validators.maxLength(this.userSize.PAYS_MAX_SIZE)
    ]
  ];

  readonly VILLE = [
    null,
    [
      Validators.required,
      Validators.minLength(this.userSize.VILLE_MIN_SIZE),
      Validators.maxLength(this.userSize.VILLE_MAX_SIZE)
    ]
  ];

  readonly ADRESSE = [
    null,
    [
      Validators.required,
      Validators.minLength(this.userSize.ADRESSE_MIN_SIZE),
      Validators.maxLength(this.userSize.ADRESSE_MAX_SIZE)
    ]
  ];

  readonly DEVISE = [
    null,
    [Validators.maxLength(this.userSize.DEVISE_MAX_SIZE)]
  ];

  readonly TELEPHONE = [
    null,
    [Validators.maxLength(this.userSize.TELEPHONE_MAX_SIZE)]
  ];

  readonly DESCRIPTION = [
    null,
    [Validators.maxLength(this.userSize.DESCRIPTION_MAX_SIZE)]
  ];
}
