import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/ services/auth/auth.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {UserAccountService} from '../user-account.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AccountUser} from '../../../shared/models/account.model';
import {RsaService} from '../../../shared/ services/rsa.service';

/**
 * Permet à l'utilisateur de saisir son mot de passe
 * afin de modifier ses informations de profil.
 */
@Component({
  selector: 'app-user-account-delete',
  templateUrl: './user-account-delete.component.html'
})
export class UserAccountDeleteComponent implements OnInit, OnDestroy {
  constructor(
    private rsaService: RsaService,
    private authService: AuthService,
    private modal: NgbModal,
    private requestService: RequestService,
    private userAccountService: UserAccountService,
    private accountService: AccountService,
    private popupReusableService: PopupReusableService,
    private activeModal: NgbActiveModal
  ) {
  }

  accountUser: AccountUser;
  hasLoginError = false;
  hasPasswordError = false;
  hasPasswordServerError = false;
  shouldIShow = false;
  loginKey = 'login';
  passwordKey = 'password';
  loginData;
  passwordData;
  open = true;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    this.popupReusableService.getPromise().then(res => {
      this.shouldIShow = true;
    });
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
  }

  ngOnDestroy(): void {
    this.reset();
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

  reset() {
    this.hasLoginError = false;
    this.hasPasswordError = false;
    this.hasPasswordError = false;
  }

  delete() {
    this.reset();
    if (this.loginData && this.passwordData) {
      if (this.accountUser.login === this.loginData) {
        this.passwordData = this.rsaService.crypt(this.passwordData);
        const result = this.userAccountService.delete(this.passwordData);
        this.subscribeToSaveResponse(result);
      } else {
        this.hasLoginError = true;
      }
    } else {
      this.hasLoginError = !this.loginData ? true : false;
      this.hasLoginError = this.loginData !== this.accountUser.login ? true : false;
      this.hasPasswordError = !this.passwordData ? true : false;
    }
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<any>>
  ) {
    result.subscribe(
      (res: HttpResponse<any>) => {
        console.log('**** success delete user account: ');
        // this.validationHasSucceed = true;
        this.reset();
        this.requestService.successWithoutPath(NRequest.ERequest.DELETE,
          this.subs$);
        this.modal.dismissAll();
        this.authService.logoutAndRedirectToLogin();
      },
      (err: HttpErrorResponse) => {
        console.log('**** error delete user: ');
        this.hasPasswordServerError = true;
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

}
