/**
 * Offre plusieurs types utilisés dans la recherche d'utilisateurs.
 */
import {Injectable} from '@angular/core';
import {Uri} from '../../../shared/constant/model/global.constant';

export namespace UserSearchForInit {

  /**
   * filtres pour la recherche
   * cases cochés par l'utilisateur.
   */
  export interface DataFilter {
    loginCheckbox: boolean;
    nomCheckbox: boolean;
    prenomCheckbox: boolean;
    emailCheckbox: boolean;
    adresseCheckbox: boolean;
    telephoneCheckbox: boolean;
  }

  /**
   * Sert à contenir la valeur de la recherche seulement
   * pour les champs les cochés
   */
  export interface DataFilterValue {
    login: string;
    nom: string;
    prenom: string;
    email: string;
    adresse: string;
    telephone: string;
    page: string;
    [Uri.DATE_DEBUT]: string;
    [Uri.DATE_FIN]: string;
  }
}


/**
 * Permet d'initialiser le component {@link UserSearchComponent}
 */
@Injectable({providedIn: 'root'})
export class EmployeSearchInit {
  constructor() {
  }

  /**
   * @returns filtres pour la recherche
   * cases cochés par l'utilisateur.
   */
  getDataFilter(): UserSearchForInit.DataFilter {
    return {
      loginCheckbox: false,
      nomCheckbox: false,
      prenomCheckbox: false,
      emailCheckbox: false,
      adresseCheckbox: false,
      telephoneCheckbox: false,
    };
  }

  /**
   * @returns Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   */
  getDataFilterValue(): UserSearchForInit.DataFilterValue {
    return {
      login: undefined,
      nom: undefined,
      prenom: undefined,
      email: undefined,
      adresse: undefined,
      telephone: undefined,
      page: undefined,
      dateDebut: undefined,
      dateFin: undefined
    };
  }
}
