import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {RGlobal} from '../../../constant/global.url';

/**
 * Permet de rediriger un utilisateur vers l'accueil si il est connecté.
 */
@Injectable({providedIn: 'root'})
export class GuardLoginImplService {
  constructor(private router: Router, private authService: AuthService) {
  }

  /**
   * Permet de rediriger un utilisateur vers l'accueil si il est connecté.
   * @param route la route.
   * @param state l'état.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('** services service login impl');
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate([RGlobal.url.ACCUEIL]);
      return true;
    }
    return true;
  }
}
