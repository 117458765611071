import {Injectable} from '@angular/core';
import {IArticle} from '../../../shared/models/article.model';
import {DateFormatService} from '../../../shared/ services/date-format.service';

/**
 * Permet d'initialiser le component {@link UserCreateComponent}
 */
@Injectable({providedIn: 'root'})
export class ArticleShowInit {
  constructor(
    private date: DateFormatService
  ) {
  }

  /**
   * Permet de convertir une date
   */
  getDate() {
    return this.date;
  }

  /**
   * Permet de récupérer les valeurs d'un utilisateur pouvant
   * être affichées sur du html.
   * @param user l'utilisateur.
   */
  getUser(catArt: IArticle) {
    return {
      id: catArt ? catArt.id : null,
      quantiteEnregistre: catArt ? catArt.quantiteEnregistre : null,
      designation: catArt ? catArt.articleInfos.designation : null,
      designationCat: catArt ? catArt.articleInfos.categorieArticle.designation : null,
      code: catArt ? catArt.articleInfos.code : null,
      prixUnitaire: catArt ? catArt.articleInfos.prixUnitaire : null,
      montant: catArt ? catArt.montant : null,
      dateEnregistrement: catArt ? catArt.dateEnregistrement : null,
      dateModification: catArt ? catArt.dateModification : null,
      utilisateur: catArt ? catArt.utilisateur : null
    };
  }
}
