import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {ArticleCreateForInit, ArticleCreateInit} from './article-create.init';
import {IArticleInfos} from '../../../shared/models/article-infos.model';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {IArticle} from '../../../shared/models/article.model';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {NRestricted} from '../../../shared/constant/restricted.msg';
import {RGlobal} from '../../../shared/constant/global.url';
import {AccountUser} from '../../../shared/models/account.model';
import {PageRestrictedService} from '../../../shared/layouts/restricted/page-restricted.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../reusable/services/notification.service';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {ArticleService} from '../article.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleInfosService} from '../../article-infos/article-infos.service';
import {Uri} from '../../../shared/constant/model/global.constant';
import {DataShareWhenCheckedArtInfos} from '../../article-infos/data-share-when-checked-art-infos.service';
import {FormService} from '../../../shared/ services/form.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {Utilisateur} from '../../../shared/models/utilisateur.model';

@Component({
  selector: 'app-article-create',
  templateUrl: './article-create.component.html'
})
export class ArticleCreateComponent implements OnInit, OnDestroy {
  constructor(
    private articleInfosService: ArticleInfosService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
    private articleService: ArticleService,
    private boutiqueService: BoutiqueService,
    private pageRestrictedService: PageRestrictedService,
    private requestService: RequestService,
    private router: Router,
    private notificationService: NotificationService,
    private freeMemoryService: FreeMemoryService,
    private catInit: ArticleCreateInit,
    private errorServ: ConstantErrorServ,
    private dataShareArtInfos: DataShareWhenCheckedArtInfos,
    private formService: FormService,
    private cdRef: ChangeDetectorRef,
    private accountService: AccountService,
    private categorieArticleInit: ArticleCreateInit,
    private formCategorieArticleCreate: FormBuilder,
  ) {
    this.artInfosColName = this.categorieArticleInit.getCategorieArticleColumnName();
  }

  // VAR.
  savedDate;
  checkedDate = true;

  isLoading = true;
  open = true;
  boutiqueSelected: Boutique;
  activatedValidationButton = true;
  loadWhenCreating = false;
  hasQtEnregistreError = false;
  serverHasError = false;
  accountUser: AccountUser;
  catArtList: IArticleInfos[];
  needToCreateCatArt = false;
  filteredOptions: Observable<IArticleInfos[]>;
  editForm = this.formCategorieArticleCreate.group(
    this.categorieArticleInit.getForm()
  );
  artInfosColName: ArticleCreateForInit.ColumnName;
  artInfosSelected: IArticleInfos;
  subs$ = new Subject<void>();


  ngOnInit() {
    console.log('*** article create onInit');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.initForm(this.editForm, this.dataShareArtInfos, this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getAllCatArt();
    });
  }

  ngOnDestroy(): void {
    this.artInfosSelected = null;
    this.restore();
  }

  initDate() {
    this.savedDate = undefined;
  }

  displayFn(subject) {
    return subject ? subject.designation : undefined;
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  onSelectionChange(event) {
    console.log('event');
    if (event) {
      const art: IArticleInfos = event.option.value;
      this.artInfosSelected = art;
    }
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'maxlength'.
   */
  hasErrorMaxLength(field: string): boolean {
    return this.formService.hasErrorMaxLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  /**
   * Permet de remplir le formulaire avec les données de l'utilisateur
   * à mettre à jour.
   * NB: le mot de passe n'est pas rempli pour des mesures de sécurité.
   *
   * @param editForm le formulaire.
   * @param subs$ permet de libérer la mémoire.
   */
  initForm(editForm: FormGroup, dataShare: DataShareWhenCheckedArtInfos,
           subs$: Subject<void>) {
    console.log('** on init art infos create');
    dataShare.getCatArtcheckedObs().pipe(takeUntil(subs$))
      .subscribe((data: IArticleInfos) => {
        if (data) {
          console.log('data: ' + data.designation);
          // this.restoreArtInfosService.setArtInfosChecked(data);
          editForm.patchValue({
            [this.artInfosColName.idArticleInfos]: data,
          });
        }
      });
  }

  getFilteredOptions(): Observable<IArticleInfos[]> {
    return this.filteredOptions;
  }

  filterOption() {
    this.filteredOptions = this.editForm.get('id_article_infos').valueChanges.pipe(
      startWith(''),
      map(((value: any) => {
          if (value) {
            if (typeof value === 'object') {
              return this._filter(value.designation);
            } else {
              // value is a string.
              return this._filter(value);
            }
          }
          return this._filter('');
        })
      ));
  }

  _filter(value: string): IArticleInfos[] {
    const filterValue = value.toLocaleLowerCase();
    return this.getCatArtList().filter(option =>
      option.designation.toLowerCase().includes(filterValue));
  }

  restore() {
    this.catArtList = [];
    this.editForm.reset();
    this.removeMsgError();
    this.freeMemoryService.free(this.subs$);
  }

  shouldIShow(): boolean {
    return this.getCatArtList() && this.getCatArtList().length !== 0;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

  /**
   * Annuler la création de l'utilisateur.
   */
  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }


  removeMsgError() {
    this.serverHasError = false;
    this.hasQtEnregistreError = false;
  }


  /**
   * Permet de désactiver le formulaire s'il contient des erreurs.
   */
  validationHasError() {
    // this.serverHasError = form.pristine === false ? false : true;
    return this.editForm.invalid || !this.activatedValidationButton;
  }

  /**
   * récupère les données de la catégorie article du formulaire.
   */
  getCatFromForm(form: FormGroup): IArticle {
    return this.catInit.getCategorieFromForm(form);
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que, comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit(en tapant au moins un caractère) avec le formulaire .
   */
  reset(form: FormGroup) {
    form.get(this.artInfosColName.quantiteEnregistre).markAsPristine();
  }

  getCatArtList(): IArticleInfos[] {
    return this.catArtList;
  }

  doINeedToCreateCatArt(): boolean {
    return this.needToCreateCatArt;
  }

  getAllCatArt() {
    this.needToCreateCatArt = false;
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.articleInfosService.getAll(option);
    result.subscribe(
      (r: HttpResponse<IArticleInfos[]>) => {
        console.log('** succès récupération article infos');
        this.isLoading = false;
        const data = r.body;
        this.catArtList = data;
        this.filterOption();
        if (!data || data.length === 0) {
          this.needToCreateCatArt = true;
        }
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération article infos');
        this.isLoading = false;
        this.pageRestrictedService.goToRestrictedAccess(NRestricted.msgFull.ARTICLE_INFOS.LIST);
      }
    );
  }

  /**
   * Crée un utilisateur.
   * @param user l'utilisateur à créer.
   */
  create() {
    console.log('creating article');
    console.log('date: ', this.savedDate);
    this.activatedValidationButton = false;
    this.loadWhenCreating = true;
    this.removeMsgError();
    this.reset(this.editForm);
    const categorieArt: IArticle = this.getCatFromForm(this.editForm);
    const user = new Utilisateur();
    user.id = this.accountUser.id;
    categorieArt.utilisateur = user;
    categorieArt.boutique = this.boutiqueSelected;
    categorieArt.savedDate = this.savedDate;
    const result = this.articleService.create(categorieArt);
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'une {@link CategorieArticle}.
   * @param err les erreurs.
   */
  manageErrorFromSavingCatArticle(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    this.checkForNegativeValueServ(erreur);
  }

  checkForNegativeValueServ(erreur: any) {
    if (erreur.errorCode === this.errorServ.NEGATIVE_VALUE) {
      const fields = erreur.fields;
      this.hasQtEnregistreError = fields[this.artInfosColName.quantiteEnregistre]
        ? true
        : false;
    }
  }

  /**
   * Permet récupérer la réponse lors de la création d'une {@link CategorieArticle}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<IArticle>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<IArticle>) => {
        console.log('**** success save article: ');
        this.loadWhenCreating = false;
        this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.ARTICLE.LIST, this.subs$);
        this.activatedValidationButton = true;
        this.closePopup();
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save article: ');
        this.activatedValidationButton = true;
        this.loadWhenCreating = false;
        try {
          this.manageErrorFromSavingCatArticle(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

}
