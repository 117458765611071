import {Injectable} from '@angular/core';
import {ISortieDetails} from '../../../shared/models/sortie-details.model';
import {DateFormatService} from '../../../shared/ services/date-format.service';

/**
 * Permet d'initialiser le component {@link UserCreateComponent}
 */
@Injectable({providedIn: 'root'})
export class SortieShowInit {
  constructor(
    private date: DateFormatService
  ) {
  }

  /**
   * Permet de convertir une date
   */
  getDate() {
    return this.date;
  }

  /**
   * Permet de récupérer les valeurs d'un utilisateur pouvant
   * être affichées sur du html.
   * @param user l'utilisateur.
   */
  getUser(catArt: ISortieDetails) {
    return {
      id: catArt ? catArt.id : null,
      quantiteSortie: catArt ? catArt.quantiteSortie : null,
      prixUnitaireVendu: catArt ? catArt.prixUnitaireVendu : null,
      montant: catArt ? catArt.montant : null,
      designation: catArt ? catArt.articleInfos.designation : null,
      codeSortie: catArt ? catArt.code : null,
      code: catArt ? catArt.articleInfos.code : null,
      dateSortie: catArt ? catArt.sortie.dateSortie : null,
      dateModification: catArt ? catArt.dateModification : null,
      articleInfos: catArt ? catArt.articleInfos : null,
      utilisateur: catArt ? catArt.sortie.utilisateur : null
    };
  }
}
