import {Injectable} from '@angular/core';
import {ConstantSortieColumnName} from '../../../shared/constant/model/column-name/sortie-column-name.constant';
import {ConstantSortieValidator} from '../../../shared/constant/validator/sortie-validator.constant';
import {FormGroup} from '@angular/forms';
import {Article} from '../../../shared/models/article.model';
import {Sortie} from '../../../shared/models/sortie.model';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Offre plusieurs types utilisés dans la création de catégories d'article.
 */
export namespace SortieUpdateForInit {
  /**
   * Permet d'avoir le nom des champs pour une {@link CategorieArticle}.
   */
  export interface ColumnName {
    name: string;
    id: string;
    quantiteSortie: string;
    code: string;
    sortie: string;
    prixUnitaireVendu: string;
    prixUnitaireAchat: string;
    idArticleInfos: string;
  }
}

/**
 * Permet d'initialiser le component {@link CategorieArticleUpdateComponent}
 */
@Injectable({providedIn: 'root'})
export class SortieUpdateInit {
  constructor(
    private formService: FormService,
    private categorieColName: ConstantSortieColumnName,
    private categorieValidator: ConstantSortieValidator
  ) {
  }

  /**
   * récupère les données de la catégorie du formulaire.
   */
  public getCategorieFromForm(editForm: FormGroup): Article {
    const entity = {
      ...new Sortie(),
      quantiteSortie: this.formService.getData(this.categorieColName.QUANTITE_SORTIE,
        editForm, []),
      prixUnitaireVendu: this.formService.getData(this.categorieColName.PRIX_UNITAIRE_VENDU,
        editForm, []),
      prixUnitaireAchat: this.formService.getData(this.categorieColName.PRIX_UNITAIRE_ACHAT,
        editForm, []),
      articleInfos: {
        id: this.formService.getDataObj(this.categorieColName.ID_ARTICLE_INFOS, editForm).id
      }
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour une {@link CategorieArticle}
   */
  getCategorieArticleColumnName(): SortieUpdateForInit.ColumnName {
    return {
      name: this.categorieColName.NAME,
      id: this.categorieColName.ID,
      quantiteSortie: this.categorieColName.QUANTITE_SORTIE,
      code: this.categorieColName.CODE,
      sortie: this.categorieColName.NAME,
      prixUnitaireVendu: this.categorieColName.PRIX_UNITAIRE_VENDU,
      prixUnitaireAchat: this.categorieColName.PRIX_UNITAIRE_ACHAT,
      idArticleInfos: this.categorieColName.ID_ARTICLE_INFOS
    };
  }

  /**
   * @returns Formulaire de création d'une {@link CategorieArticle}.
   */
  getForm(): any {
    return {
      quantite_sortie: this.categorieValidator.QUANTITE_SORTIE,
      prix_unitaire_vendu: this.categorieValidator.PRIX_UNITAIRE_VENDU,
      prix_unitaire_achat: this.categorieValidator.PRIX_UNITAIRE_ACHAT,
      id_article_infos: this.categorieValidator.ID_ARTICLE_INFOS
    };
  }
}
