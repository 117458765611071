import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../../shared/constant/model/server.constant';
import {
  API_ART_INFOS,
  API_ART_INFOS_ALL,
  API_ART_INFOS_CALCULATE,
  API_ART_INFOS_DELETE,
  API_ART_INFOS_MONTANT_TOTAL,
  API_ART_INFOS_MONTANT_TOTAL_VENTE,
  API_ART_INFOS_PAGES,
  API_ART_INFOS_SEARCH,
  API_ART_INFOS_SEARCH_AVANCE,
  API_ART_INFOS_SEARCH_MONTANT_TOTAL,
  API_ART_INFOS_SEARCH_MONTANT_TOTAL_AVANCE,
  API_ART_INFOS_SEARCH_MONTANT_TOTAL_VENTE,
  API_ART_INFOS_SEARCH_MONTANT_TOTAL_VENTE_AVANCE
} from '../../shared/constant/model/api.constant';
import {Observable} from 'rxjs';
import {ArticleInfos, IArticleInfos} from '../../shared/models/article-infos.model';
import {advancedSearchKey} from '../../shared/constant/model/global.constant';

@Injectable({providedIn: 'root'})
export class ArticleInfosService {
  constructor(
    private http: HttpClient,
  ) {
    console.log('construct cat art service');

  }

  // VAR.

  private resourceUrlSearchMontantTotalVenteAvance =
    SERVER_API_URL + API_ART_INFOS_SEARCH_MONTANT_TOTAL_VENTE_AVANCE;
  private resourceUrlSearchMontantTotalVente =
    SERVER_API_URL + API_ART_INFOS_SEARCH_MONTANT_TOTAL_VENTE;

  private resourceUrlSearchMontantTotalAvance = SERVER_API_URL + API_ART_INFOS_SEARCH_MONTANT_TOTAL_AVANCE;
  private resourceUrlSearchMontantTotal = SERVER_API_URL + API_ART_INFOS_SEARCH_MONTANT_TOTAL;

  private resourceUrlMontantTotalVente = SERVER_API_URL + API_ART_INFOS_MONTANT_TOTAL_VENTE;
  private resourceUrlMontantTotal = SERVER_API_URL + API_ART_INFOS_MONTANT_TOTAL;
  private resourceUrlDelete = SERVER_API_URL + API_ART_INFOS_DELETE;
  private resourceUrlPages = SERVER_API_URL + API_ART_INFOS_PAGES;

  private resourceUrlSearchAvance = SERVER_API_URL + API_ART_INFOS_SEARCH_AVANCE;
  private resourceUrlSearch = SERVER_API_URL + API_ART_INFOS_SEARCH;

  private resourceUrl = SERVER_API_URL + API_ART_INFOS;
  private resourceUrlCalculate = SERVER_API_URL + API_ART_INFOS_CALCULATE;
  private resourceUrlAll = SERVER_API_URL + API_ART_INFOS_ALL;

  private _refreshBtnActive = true;


  get refreshBtnActive(): boolean {
    return this._refreshBtnActive;
  }

  set refreshBtnActive(value: boolean) {
    this._refreshBtnActive = value;
  }

  getListCalculate(params): Observable<HttpResponse<IArticleInfos[]>> {
    return this.http.get<IArticleInfos[]>(this.resourceUrlCalculate, {
      observe: 'response',
      params
    });
  }

  getAll(params): Observable<HttpResponse<IArticleInfos[]>> {
    return this.http.get<IArticleInfos[]>(this.resourceUrlAll, {
      observe: 'response',
      params
    });
  }

  /**
   *
   * @param v la recherche avancée est activée
   * si true, sinon false
   */
  setAdvancedSearch(v: boolean) {
    if (v) {
      localStorage.setItem(advancedSearchKey, advancedSearchKey);
    } else {
      localStorage.removeItem(advancedSearchKey);
    }
  }

  isSearchAdvanced(): boolean {
    const s = localStorage.getItem(advancedSearchKey);
    return !!s;
  }

  /**
   * Recherche de manière avance un article
   * @param params
   */
  searchAvance(params): Observable<HttpResponse<ArticleInfos>> {
    return this.http.get<ArticleInfos>(this.resourceUrlSearchAvance, {
      observe: 'response',
      params
    });
  }

  /**
   * Recherche avec exactitude un article
   * @param params
   */
  search(params): Observable<HttpResponse<ArticleInfos>> {
    return this.http.get<ArticleInfos>(this.resourceUrlSearch, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalVenteAvance(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalVenteAvance, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalVente(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalVente, {
      observe: 'response',
      params
    });
  }

  searchMontantTotalAvance(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotalAvance, {
      observe: 'response',
      params
    });
  }

  searchMontantTotal(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlSearchMontantTotal, {
      observe: 'response',
      params
    });
  }

  create(data): Observable<HttpResponse<ArticleInfos>> {
    return this.http.post<ArticleInfos>(this.resourceUrl, data, {
      observe: 'response',
    });
  }

  update(data): Observable<HttpResponse<boolean>> {
    return this.http.put<boolean>(this.resourceUrl, data, {
      observe: 'response',
    });
  }

  delete(params): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>(this.resourceUrlDelete, {
      observe: 'response',
      params
    });
  }

  getList(params): Observable<HttpResponse<ArticleInfos[]>> {
    return this.http.get<ArticleInfos[]>(this.resourceUrlPages, {
      observe: 'response',
      params
    });
  }

  getMontantVente(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlMontantTotalVente, {
      observe: 'response',
      params
    });
  }

  getMontant(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlMontantTotal, {
      observe: 'response',
      params
    });
  }
}
