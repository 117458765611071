import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {BoutiqueService} from '../../../../modules/boutique/boutique.service';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Boutique} from '../../../models/boutique.model';
import {RGlobal} from '../../../constant/global.url';

/**
 * Permet de rediriger un utilisateur vers l'accueil si il est connecté.
 */
@Injectable({providedIn: 'root'})
export class GuardCheckingBoutiqueService implements CanActivate {
  constructor(
    private router: Router,
    private boutiqueService: BoutiqueService) {
  }

  private subs$ = new Subject<void>();

  /**
   * Permet de rediriger un utilisateur vers l'accueil si il est connecté.
   * @param route la route.
   * @param state l'état.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('** services guard checking boutique');
    return this.boutiqueService.getBoutiqueSelectDataObs()
      .pipe(map((b: Boutique) => {
        if (b) {
          return true;
        }
        this.router.navigate([RGlobal.url.ACCUEIL]);
        return false;
      }));
  }
}
