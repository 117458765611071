/**
 * type de vérification.
 */
import {AbsVerification} from './abs-verification';
import {ConstantUserColumnName} from '../../../../shared/constant/model/column-name/user-column-name.constant';

export const enum EVerification {
  LOGIN, NOM, PRENOM
}

/**
 * Type de vérification.
 */
export const enum ETypeVerification {
  SEARCH
}

/**
 * Service factory permettant de créer {@link AbsVerification}
 */
export abstract class AbsFactoryVerification {

  /**
   * Vérifie si le login fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param login login de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkLogin(login: string,
                      usercolName: ConstantUserColumnName): AbsVerification;

  /**
   * Vérifie si le nom fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param nom le nom de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkNom(nom: string,
                    usercolName: ConstantUserColumnName): AbsVerification;

  /**
   * Vérifie si le prénom fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param prenom le prénom de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkPrenom(prenom: string,
                       usercolName: ConstantUserColumnName): AbsVerification;

  /**
   * Vérifie si l'email fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param email l'email de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkEmail(email: string,
                      usercolName: ConstantUserColumnName): AbsVerification;

  /**
   * Vérifie si l'adresse fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param adresse l'adresse de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkAdresse(adresse: string,
                        usercolName: ConstantUserColumnName): AbsVerification;

  /**
   * Vérifie si le téléphone fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param telephone le téléphone de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  abstract checkTelephone(telephone: string,
                          usercolName: ConstantUserColumnName): AbsVerification;
}
