import {AbsFactoryVerification} from './abs-factory-verification';
import {AbsVerification} from './abs-verification';
import {LoginVerification} from './login-verification';
import {ConstantUserColumnName} from '../../../../shared/constant/model/column-name/user-column-name.constant';
import {NomVerification} from './nom-verification';
import {PrenomVerification} from './prenom-verification';
import {EmailVerification} from './email-verification';
import {AdresseVerification} from './adresse-verification';
import {TelephoneVerification} from './telephone-verification';

/**
 * Permet d'effectuer des vérifications lors de la recherche par filtre.
 */
export class SearchUserVerification extends AbsFactoryVerification {
  constructor() {
    super();
  }

  /**
   * Vérifie si le login fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param login login de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkLogin(login: string, usercolName: ConstantUserColumnName): AbsVerification {
    return new LoginVerification().loginSet(login).userColNameSet(usercolName);
  }

  /**
   * Vérifie si le nom fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param nom le nom de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkNom(nom: string, usercolName: ConstantUserColumnName): AbsVerification {
    return new NomVerification().nomSet(nom).userColNameSet(usercolName);
  }

  /**
   * Vérifie si le prénom fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param prenom le prénom de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkPrenom(prenom: string, usercolName: ConstantUserColumnName): AbsVerification {
    return new PrenomVerification().prenomSet(prenom).userColNameSet(usercolName);
  }

  /**
   * Vérifie si l'email fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param email l'email de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkEmail(email: string,
             usercolName: ConstantUserColumnName): AbsVerification {
    return new EmailVerification().emailSet(email).userColNameSet(usercolName);
  }

  /**
   * Vérifie si l'adresse fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param adresse l'adresse de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkAdresse(adresse: string,
               usercolName: ConstantUserColumnName): AbsVerification {
    return new AdresseVerification().adresseSet(adresse).userColNameSet(usercolName);
  }

  /**
   * Vérifie si le téléphone fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param telephone le téléphone de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkTelephone(telephone: string,
                 usercolName: ConstantUserColumnName): AbsVerification {
    return new TelephoneVerification().telephoneSet(telephone).userColNameSet(usercolName);
  }

}
