import {Injectable} from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour une {@link CategorieArticle}
 */
@Injectable({providedIn: 'root'})
export class ConstantArticleInfosColumnName {
  readonly NAME = 'article_infos';
  readonly ID = 'id';
  readonly DESIGNATION = 'designation';
  readonly DESCRIPTION = 'description';
  readonly CODE = 'code';
  readonly QUANTITE_ALERTE = 'quantite_alerte';
  readonly PRIX_UNITAIRE = 'prix_unitaire';
  readonly PRIX_UNITAIRE_VENTE = 'prix_unitaire_vente';
  readonly ID_CATEGORIE_ARTICLE = 'id_categorie_article';
}
