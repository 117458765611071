import {Injectable} from '@angular/core';
import {FormService} from '../../../shared/ services/form.service';
import {FormGroup} from '@angular/forms';
import {ValidateEmail} from '../../../shared/models/validate-email.model';
import {ConstantValidateEmailColumnName} from '../../../shared/constant/model/column-name/validate-email.constant';
import {ConstantValidator} from '../../../shared/constant/validator/validator.constant';

/**
 * Offre plusieurs types utilisés pour se connecter
 */
export namespace UserAccountValidateNm {
  /**
   * Permet d'avoir le nom des champs pour créer un compte.
   */
  export interface ColumnName {
    code: string;
    key: string;
  }
}

/**
 * Permet d'initialiser le component {@link LoginComponent}
 */
@Injectable({providedIn: 'root'})
export class UserAccountValidateInit {
  constructor(
    private formService: FormService,
    private userColName: ConstantValidateEmailColumnName,
    private userValidator: ConstantValidator
  ) {
  }

  /**
   * récupère les données d'authentification du formulaire.
   */
  public getAccountFromForm(editForm: FormGroup): ValidateEmail {
    const entity = {
      ...new ValidateEmail(),
      code: this.formService.getData(this.userColName.CODE, editForm, [])
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour se connecter.
   */
  getAuthColumnName(): UserAccountValidateNm.ColumnName {
    return {
      code: this.userColName.CODE,
      key: this.userColName.KEY
    };
  }

  /**
   * @returns Formulaire de connexion.
   */
  getForm(): any {
    return {
      code: this.userValidator.CODE,
    };
  }
}
