import {Injectable} from '@angular/core';
import {IGettingPrivilegeService} from './igetting-privilege.service';
import {takeUntil} from 'rxjs/operators';
import {AccountUser} from '../../../models/account.model';
import {Observable, Subject} from 'rxjs';
import {GestionType, PrivilegeType} from '../../../models/action.model';
import {CheckingPrivilegeService} from '../checking-privilege.service';
import {BoutiqueService} from '../../../../modules/boutique/boutique.service';
import {Boutique} from '../../../models/boutique.model';

/**
 * Permet de vérifier que l'utilisateur connecté possède les privilèges
 * nécessaires pour accéder à certaines pages.
 */
@Injectable({providedIn: 'root'})
export class GettingPrivilegeService {
  constructor(
    private boutiqueService: BoutiqueService,
    private checkingPrivilegeService: CheckingPrivilegeService) {
  }

  canIDoThis(accountUser: AccountUser,
             gestionType: GestionType, priv: PrivilegeType,
             boutiqueSelected: Boutique): Observable<boolean> {
    return new Observable((observer) => {
      if (!accountUser) {
        return observer.next(false);
        // observer.complete();
      }
      const result = this.boutiqueService.isUserOwner(accountUser.boutique,
        boutiqueSelected);
      if (result) {
        return observer.next(true);
      } else {
        const val = this.checkingPrivilegeService.hasPrivilege(accountUser.actions,
          gestionType, priv, boutiqueSelected);
        return observer.next(val);
      }
    });
  }

  /**
   * Permet d'afficher ou pas le bouton d'ajout d'un utilisateur
   * en fonction des privilèges de l'utilisateur connecté
   */
  canIAdd(accountUser: AccountUser, gestionType: GestionType,
          boutiqueSelected: Boutique): Observable<boolean> {
    return this.canIDoThis(accountUser, gestionType, PrivilegeType.AJOUTER,
      boutiqueSelected);
  }

  /**
   * Permet d'afficher ou pas le bouton d'ajout d'un utilisateur
   * en fonction des privilèges de l'utilisateur connecté.
   */
  canISearch(accountUser: AccountUser, gestionType: GestionType,
             boutiqueSelected: Boutique): Observable<boolean> {
    return this.canIDoThis(accountUser, gestionType, PrivilegeType.RECHERCHER,
      boutiqueSelected);
  }

  /**
   * Permet d'afficher ou pas le bouton de modification d'un utilisateur
   * en fonction des privilèges de l'utilisateur connecté.
   */
  canIModify(accountUser: AccountUser, gestionType: GestionType,
             boutiqueSelected: Boutique): Observable<boolean> {
    return this.canIDoThis(accountUser, gestionType, PrivilegeType.MODIFIER,
      boutiqueSelected);
  }

  /**
   * Permet d'afficher ou pas le bouton de suppression d'un utilisateur
   * en fonction des privilèges de l'utilisateur connecté.
   */
  canIDelete(accountUser: AccountUser, gestionType: GestionType,
             boutiqueSelected: Boutique): Observable<boolean> {
    return this.canIDoThis(accountUser, gestionType, PrivilegeType.SUPPRIMER,
      boutiqueSelected);
  }


  /**
   * Permet d'afficher ou pas le bouton d'ajout d'un utilisateur
   * en fonction des privilèges de l'utilisateur connecté.
   */
  canIShow(accountUser: AccountUser, gestionType: GestionType,
           boutiqueSelected: Boutique): Observable<boolean> {
    return this.canIDoThis(accountUser, gestionType, PrivilegeType.CONSULTER,
      boutiqueSelected);
  }

  private checkCanIAdd(accountUser: AccountUser, i: IGettingPrivilegeService,
                       gestionType: GestionType,
                       subs$: Subject<void>, boutiqueSelected: Boutique) {
    this.canIAdd(accountUser, gestionType, boutiqueSelected).pipe(takeUntil(subs$))
      .subscribe(res => {
        i.iCanAdd(res);
      });
  }

  private checkCanISearch(accountUser: AccountUser, i: IGettingPrivilegeService,
                          gestionType: GestionType,
                          subs$: Subject<void>,
                          boutiqueSelected: Boutique) {
    this.canISearch(accountUser, gestionType, boutiqueSelected).pipe(takeUntil(subs$))
      .subscribe(res => {
        i.iCanSearch(res);
      });
  }

  private checkCanIModify(accountUser: AccountUser, i: IGettingPrivilegeService,
                          gestionType: GestionType,
                          subs$: Subject<void>,
                          boutiqueSelected: Boutique) {
    this.canIModify(accountUser, gestionType, boutiqueSelected).pipe(takeUntil(subs$))
      .subscribe(res => {
        i.iCanModify(res);
      });
  }

  private checkCanIDelete(accountUser: AccountUser, i: IGettingPrivilegeService,
                          gestionType: GestionType,
                          subs$: Subject<void>,
                          boutiqueSelected: Boutique) {
    this.canIDelete(accountUser, gestionType, boutiqueSelected).pipe(takeUntil(subs$))
      .subscribe(res => {
        i.iCanDelete(res);
      });
  }

  private checkCanIShow(accountUser: AccountUser, i: IGettingPrivilegeService,
                        gestionType: GestionType,
                        subs$: Subject<void>,
                        boutiqueSelected: Boutique) {
    this.canIShow(accountUser, gestionType,
      boutiqueSelected).pipe(takeUntil(subs$))
      .subscribe(res => {
        console.log('res showww: ' + res);
        i.iCanShow(res);
      });
  }

  private setUserOwner(i: IGettingPrivilegeService) {
    i.iCanModify(true);
    i.iCanSearch(true);
    i.iCanAdd(true);
    i.iCanDelete(true);
    i.iCanShow(true);
  }

  getPrivilege(accountUser: AccountUser, i: IGettingPrivilegeService,
               gestionType: GestionType, boutiqueSelected: Boutique,
               subs$: Subject<void>) {
    if (accountUser) {
      const result = this.boutiqueService.isUserOwner(accountUser.boutique,
        boutiqueSelected);
      if (result) {
        this.setUserOwner(i);
      } else {
        this.checkCanIAdd(accountUser, i, gestionType, subs$,
          boutiqueSelected);
        this.checkCanISearch(accountUser, i, gestionType, subs$,
          boutiqueSelected);
        this.checkCanIModify(accountUser, i, gestionType, subs$,
          boutiqueSelected);
        this.checkCanIDelete(accountUser, i, gestionType, subs$,
          boutiqueSelected);
        this.checkCanIShow(accountUser, i, gestionType, subs$,
          boutiqueSelected);
      }
    }
  }
}
