import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {concatMap} from 'rxjs/operators';
import {BoutiqueService} from '../../../modules/boutique/boutique.service';
import {Observable} from 'rxjs';
import {GuardPrivilegeImplService} from './guard-privilege-impl.service';

/**
 * Permet d'autoriser ou de restreindre l'accès au route du module utilisateur en fonction
 * privilèges de l'utilisateur.
 */
@Injectable({providedIn: 'root'})
export class GGuardPrivilegeArtInfosService implements CanActivate {
  constructor(
    private boutiqueService: BoutiqueService,
    private guardPrivilegeImplService: GuardPrivilegeImplService) {
  }

  /**
   * Autorise ou restreint l'accès au route du module utilisateur en fonction
   * privilèges de l'utilisateur.
   * @param route la route.
   * @param state l'état.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.boutiqueService.getBoutiqueSelectDataObs().pipe(concatMap(res => {
      return this.guardPrivilegeImplService.canActivateArtInfos(route,
        state, res);
    }));
  }
}
