import {Component, OnDestroy, OnInit} from '@angular/core';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {AccountUser} from '../../../shared/models/account.model';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {EmployeService} from '../employe.service';
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';
import {RequestService} from '../../../shared/ services/request/request.service';
import {NotificationService} from '../../../reusable/services/notification.service';

/**
 * Permet de gérer la liste des demandes reçues pour des boutiques.
 */
@Component({
  selector: 'app-emp-demande-recus',
  templateUrl: './emp-demande-recus.component.html'
})
export class EmpDemandeRecusComponent implements OnInit, OnDestroy {
  constructor(
    private notificationService: NotificationService,
    private requestService: RequestService,
    private freeMemoryService: FreeMemoryService,
    private dateFormatService: DateFormatService,
    private employeService: EmployeService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
  ) {
  }

  employeBoutiques: EmployeBoutique[];
  shouldIShow = false;
  accountUser: AccountUser;
  open = true;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init employe demande reçus');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.popupReusableService.getPromise().then(res => {
      console.log('show');
      this.shouldIShow = true;
      this.getListAll();
    });
  }

  ngOnDestroy(): void {
    this.freeMemoryService.free(this.subs$);
  }

  getListAll() {
    const result = this.employeService.getListAll();
    this.subscribeToSaveResponse(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<EmployeBoutique[]>>,
  ) {
    result.subscribe(
      (res: HttpResponse<EmployeBoutique[]>) => {
        console.log('**** success list employe: ');
        this.employeBoutiques = res.body;
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error list employe: ');
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  date() {
    return this.dateFormatService;
  }

  refuser(emp: EmployeBoutique) {
    console.log('refuser');
    const result = this.employeService.deleteDemande(emp.id.toString());
    this.subscribeToSaveResponseDeleteDemande(result);
  }

  accepter(emp: EmployeBoutique) {
    console.log('accepter');
    const result = this.employeService.accept(emp.id.toString());
    this.subscribeToSaveResponseAccept(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseDeleteDemande(
    result: Observable<HttpResponse<boolean>>,
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        console.log('**** success delete demande employe: ');
        const value = res.body;
        if (value) {
          this.notificationService.showSuccess('',
            'Demande supprimée');
          this.getListAll();
        }
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error accept employe: ');
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseAccept(
    result: Observable<HttpResponse<boolean>>,
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        console.log('**** success accept employe: ');
        const value = res.body;
        if (value) {
          this.notificationService.showSuccess('Vous pouvez à présent gérer la boutique',
            'Demande acceptée');
          this.getListAll();
        }
        /*this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.USER.LIST, this.subs$);
        this.activatedValidationButton = true;*/
      },
      (err: HttpErrorResponse) => {
        console.log('**** error accept employe: ');
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }
}
