export const myspace = ' ';
export namespace NRestricted {
  export const msg = {
    USER: 'utilisateur',
    ARTICLE: 'détails articles',
    ARTICLE_INFOS: 'types d\'article(s)',
    CATEGORIE_ARTICLE: 'catégorie article',
    CREATE: 'créer',
    UPDATE: 'modifier',
    DELETE: 'supprimer',
    LIST: 'lister',
    SEARCH: 'rechercher'
  };

  export const msgFull = {
    ARTICLE: {
      CREATE: NRestricted.msg.CREATE.concat(myspace).concat(NRestricted.msg.ARTICLE),
      UPDATE: NRestricted.msg.UPDATE.concat(myspace).concat(NRestricted.msg.ARTICLE),
      DELETE: NRestricted.msg.DELETE.concat(myspace).concat(NRestricted.msg.ARTICLE),
      LIST: NRestricted.msg.LIST.concat(myspace).concat(NRestricted.msg.ARTICLE),
      SEARCH: NRestricted.msg.SEARCH.concat(myspace).concat(NRestricted.msg.ARTICLE)
    },
    ARTICLE_INFOS: {
      CREATE: NRestricted.msg.CREATE.concat(myspace).concat(NRestricted.msg.ARTICLE_INFOS),
      UPDATE: NRestricted.msg.UPDATE.concat(myspace).concat(NRestricted.msg.ARTICLE_INFOS),
      DELETE: NRestricted.msg.DELETE.concat(myspace).concat(NRestricted.msg.ARTICLE_INFOS),
      LIST: NRestricted.msg.LIST.concat(myspace).concat(NRestricted.msg.ARTICLE_INFOS),
      SEARCH: NRestricted.msg.SEARCH.concat(myspace).concat(NRestricted.msg.ARTICLE_INFOS)
    },
    CATEGORIE_ARTICLE: {
      CREATE: NRestricted.msg.CREATE.concat(myspace).concat(NRestricted.msg.CATEGORIE_ARTICLE),
      UPDATE: NRestricted.msg.UPDATE.concat(myspace).concat(NRestricted.msg.CATEGORIE_ARTICLE),
      DELETE: NRestricted.msg.DELETE.concat(myspace).concat(NRestricted.msg.CATEGORIE_ARTICLE),
      LIST: NRestricted.msg.LIST.concat(myspace).concat(NRestricted.msg.CATEGORIE_ARTICLE),
      SEARCH: NRestricted.msg.SEARCH.concat(myspace).concat(NRestricted.msg.CATEGORIE_ARTICLE)
    },
    USER: {
      CREATE: NRestricted.msg.CREATE.concat(myspace).concat(NRestricted.msg.USER),
      UPDATE: NRestricted.msg.UPDATE.concat(myspace).concat(NRestricted.msg.USER),
      DELETE: NRestricted.msg.DELETE.concat(myspace).concat(NRestricted.msg.USER),
      LIST: NRestricted.msg.LIST.concat(myspace).concat(NRestricted.msg.USER),
      SEARCH: NRestricted.msg.SEARCH.concat(myspace).concat(NRestricted.msg.USER)
    },
  };
}
