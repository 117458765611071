import { Injectable } from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour une {@link CategorieArticle}
 */
@Injectable({ providedIn: 'root' })
export class ConstantCategorieArticleColumnName {
  readonly NAME = 'categorie_article';
  readonly ID = 'id';
  readonly DESIGNATION = 'designation';
  readonly DESCRIPTION = 'description';
  readonly CODE = 'code';
  readonly CODE_SORTIE = 'code_sortie';
}
