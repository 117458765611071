import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

/**
 * Permet de libèrer la mémoire utilisée lors d'une souscription.
 */
@Injectable({providedIn: 'root'})
export class FreeMemoryService {
  constructor() {
    console.log('on construct FreeMemory');
  }

  free(subs$: Subject<void>): void {
    subs$.next();
    subs$.complete();
  }
}
