import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {SignIn, SignInInit} from '../login.init';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {RGlobal, slashRoute} from '../../../shared/constant/global.url';
import {SERVER_API_URL, TIME_WAIT_MAX_ATTEMPT} from '../../../shared/constant/model/server.constant';
import {API_AUTH} from '../../../shared/constant/model/api.constant';
import {API_ADMIN_AUTH} from '../../../shared/constant/model/api.admin.constant';
import {RGlobalAdmin} from '../../../shared/constant/global.url.admin';
import {FormService} from '../../../shared/ services/form.service';
import {AuthService} from '../../../shared/ services/auth/auth.service';
import {RsaService} from '../../../shared/ services/rsa.service';
import {ArticleInfosService} from '../../../modules/article-infos/article-infos.service';

// declare var JSEncrypt: any;

/**
 * Permet de s'authentifier vers le serveur.
 */
@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(
    private articleInfosService: ArticleInfosService,
    private rsaService: RsaService,
    private http: HttpClient,
    private errorServ: ConstantErrorServ,
    private signInInit: SignInInit,
    private formService: FormService,
    private router: Router,
    private authService: AuthService
  ) {
    this.signInColName = this.signInInit.getAuthColumnName();
  }

  // VAR.
  private resourceUrl = SERVER_API_URL + API_AUTH;
  private resourceAdminUrl = SERVER_API_URL + API_ADMIN_AUTH;
  spinner = false;
  private hasSetTimeAttempt = false;
  private activatedLoginButton = true;
  private hasAuthError = false;
  private hasAttemptError = false;
  private hasSubscriptionError = false;
  private hasEtatError = false;
  private signInColName: SignIn.ColumnName;
  private successAuth = false;

  /**
   * @return true si l'utilisateur a pu s'authentifier
   * et a accès aux services, false dans le cas contraire.
   */
  getSuccessAuth(): boolean {
    return this.successAuth;
  }

  getSubscriptionError(): boolean {
    return this.hasSubscriptionError;
  }

  /**
   * @returns true si l'utilisateur à authentifier est bloqué.
   */
  getEtatError(): boolean {
    return this.hasEtatError;
  }

  /**
   * @returns true si le bouton d'authentification est activé, sinon
   * false.
   */
  getActivatedLoginButton(): boolean {
    return this.activatedLoginButton;
  }

  /**
   * @returns true si le serveur n'a pas pu identifier l'utilisalteur.
   */
  getAuthError(): boolean {
    return this.hasAuthError;
  }

  /**
   * Gère les erreurs lors de l'authentification d'un utilisateur.
   * @param err les erreurs.
   */
  private manageErrorFromLoginUser(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    console.log('my err: ' + JSON.stringify(erreur));
    if (erreur.errorCode === this.errorServ.BLOCKED) {
      this.hasAuthError = false;
      this.hasSubscriptionError = false;
      const fields = erreur.fields;
      this.hasEtatError = fields.etat ? true : false;
      this.activatedLoginButton = true;
    } else if (erreur.errorCode === this.errorServ.SUBSCRIPTION_EXPIRED) {
      this.hasAuthError = false;
      this.hasEtatError = false;
      this.hasSubscriptionError = true;
      this.activatedLoginButton = true;
    } else if (erreur.errorCode === this.errorServ.TOO_ATTEMPT) {
      console.log('too attempt ');
      this.hasAttemptError = true;
      this.activatedLoginButton = false;
      if (!this.hasSetTimeAttempt) {
        setTimeout(() => {
          this.activatedLoginButton = true;
          this.hasSetTimeAttempt = false;
        }, TIME_WAIT_MAX_ATTEMPT);
        this.hasSetTimeAttempt = true;
      }
    } else {
      this.hasEtatError = false;
      this.hasSubscriptionError = false;
      this.hasAuthError = true;
      this.activatedLoginButton = true;
    }
  }

  /**
   * Permet d'authentifier un utilisateur.
   * @param auth ..
   */
  authenticateAdmin(auth: any) {
    auth.password = this.rsaService.crypt(auth.password);
    this.authService.authenticate(auth.login, auth.password,
      this.resourceAdminUrl).subscribe(
      data => {
        console.log('** login admin success');
        this.spinner = false;
        this.successAuth = true;
        this.router.navigate([RGlobalAdmin.url.ACCUEIL]);
        this.hasAuthError = false;
        this.activatedLoginButton = true;
      },
      (error: HttpErrorResponse) => {
        console.log('** login admin failed');
        this.spinner = false;
        this.activatedLoginButton = true;
        this.hasAuthError = true;
      }
    );
  }

  /**
   * Permet d'authentifier un utilisateur.
   * @param auth ..
   */
  authenticateUser(auth: any) {
    auth.password = this.rsaService.crypt(auth.password);
    this.authService.authenticate(auth.login, auth.password,
      this.resourceUrl).subscribe(
      data => {
        console.log('** login success');
        this.spinner = false;
        this.successAuth = true;
        this.router.navigate([RGlobal.url.ACCUEIL]);
        this.articleInfosService.setAdvancedSearch(true);
        this.hasAuthError = false;
        this.hasEtatError = false;
        this.hasSubscriptionError = false;
        this.activatedLoginButton = true;
      },
      (error: HttpErrorResponse) => {
        console.log('** login failed');
        try {
          this.spinner = false;
          this.manageErrorFromLoginUser(error);
        } catch (e) {
          this.activatedLoginButton = true;
          this.hasEtatError = false;
          this.hasSubscriptionError = false;
          this.hasAuthError = true;
          console.log('erreur: ' + (e as Error).message);
          console.log('erreur: ' + JSON.stringify(e));
        }
      }
    );
  }

  /**
   * Permet d'authentifier un utilisateur.
   * @param form le formulaire contenant les informations d'identification.
   */
  login(form: FormGroup) {
    console.log('** login');
    if (form.invalid) {
      console.log('** form invalid');
      this.formService.markAbstractControlTouched(form);
    } else {
      this.activatedLoginButton = false;
      console.log('** form valide');
      this.spinner = true;
      const auth = this.signInInit.getAuthFromForm(form);
      if (this.router.url === (slashRoute + RGlobalAdmin.url.LOGIN)) {
        this.authenticateAdmin(auth);
      } else {
        this.authenticateUser(auth);
      }
    }
  }
}
