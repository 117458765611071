import {Injectable} from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour une {@link CategorieArticle}
 */
@Injectable({providedIn: 'root'})
export class ConstantArticleColumnName {
  readonly NAME = 'article';
  readonly ID = 'id';
  readonly QUANTITE_ENREGISTRE = 'quantite_enregistre';
  readonly ID_ARTICLE_INFOS = 'id_article_infos';
}
