import {NgModule} from '@angular/core';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {EmpDemandeRecusComponent} from './demande/emp-demande-recus.component';
import {EmployeListComponent} from './employe-list/employe-list.component';
import {EmployeShowComponent} from './employe-show/employe-show.component';
import {EmployeDeleteComponent} from './employe-delete/employe-delete.component';
import {EmployeUpdateComponent} from './employte-update/employe-update.component';
import {EmployeAddComponent} from './employe-add/employe-add.component';
import {EmployeSearchComponent} from './employe-search/employe-search.component';

/**
 * modules pour la page d'accueil.
 */
@NgModule({
  declarations: [
    EmployeSearchComponent,
    EmployeAddComponent,
    EmployeUpdateComponent,
    EmployeDeleteComponent,
    EmployeShowComponent,
    EmployeListComponent,
    EmpDemandeRecusComponent],
  imports: [
    GestionStockSharedModule,
  ],
  exports: [],
  entryComponents: [
    EmployeAddComponent,
    EmployeUpdateComponent,
    EmployeDeleteComponent,
    EmployeShowComponent,
    EmpDemandeRecusComponent]
})
export class EmployeModule {
}
