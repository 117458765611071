import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CategorieArticleCreateInit} from './categorie-article-create.init';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IUtilisateur, Utilisateur} from '../../../shared/models/utilisateur.model';
import {ICategorieArticle} from '../../../shared/models/categorie-article.model';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {RGlobal} from '../../../shared/constant/global.url';
import {AccountUser} from '../../../shared/models/account.model';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../reusable/services/notification.service';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CatArtService} from '../cat-art.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {BoutiqueService} from '../../boutique/boutique.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {FormService} from '../../../shared/ services/form.service';

@Component({
  selector: 'app-categorie-article-create',
  templateUrl: './categorie-article-create.component.html'
})
export class CategorieArticleCreateComponent implements OnInit, OnDestroy {
  constructor(
    private boutiqueService: BoutiqueService,
    private catArtService: CatArtService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
    private requestService: RequestService,
    private router: Router,
    private notificationService: NotificationService,
    private freeMemoryService: FreeMemoryService,
    private catInit: CategorieArticleCreateInit,
    private errorServ: ConstantErrorServ,
    private accountService: AccountService,
    private categorieArticleInit: CategorieArticleCreateInit,
    private formCategorieArticleCreate: FormBuilder,
    private formService: FormService
  ) {
    this.categorieArticleColName = this.categorieArticleInit.getCategorieArticleColumnName();
  }

  // VAR.
  shouldIShow = false;
  boutiqueSelected: Boutique;
  open = true;
  editForm = this.formCategorieArticleCreate.group(
    this.categorieArticleInit.getForm()
  );
  // Ne pas supprimé, utilisé côté Html.
  categorieArticleColName;
  subs$ = new Subject<void>();
  activatedValidationButton = true;
  loadWhenCreating = false;
  hasDesignationError = false;
  hasCodeError = false;
  // permet d'indiquer si la vérification côté serveur contient des erreurs.
  serverHasError = false;
  accountUser: AccountUser;


  ngOnInit() {
    console.log('*** catégorie article onInit');
    this.popupReusableService.getPromise().then(res => {
      this.shouldIShow = true;
    });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
    });
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
  }

  ngOnDestroy(): void {
    this.restore();
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  restore() {
    this.editForm.reset();
    this.removeMsgError();
    this.freeMemoryService.free(this.subs$);
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }


  removeMsgError() {
    this.serverHasError = false;
    this.hasDesignationError = false;
    this.hasCodeError = false;
  }


  /**
   * Permet de désactiver le formulaire s'il contient des erreurs.
   */
  validationHasError() {
    // this.serverHasError = form.pristine === false ? false : true;
    return this.editForm.invalid || !this.activatedValidationButton;
  }

  /**
   * récupère les données de la catégorie article du formulaire.
   */
  getCatFromForm(form: FormGroup): IUtilisateur {
    return this.catInit.getCategorieFromForm(form);
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que, comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit(en tapant au moins un caractère) avec le formulaire .
   */
  reset(form: FormGroup) {
    form.get(this.categorieArticleColName.designation).markAsPristine();
    form.get(this.categorieArticleColName.code).markAsPristine();
  }

  /**
   * Crée un utilisateur.
   * @param user l'utilisateur à créer.
   */
  create(form: FormGroup) {
    this.activatedValidationButton = false;
    this.loadWhenCreating = true;
    this.removeMsgError();
    this.reset(form);
    const categorieArt: ICategorieArticle = this.getCatFromForm(form);
    const user = new Utilisateur();
    user.id = this.accountUser.id;
    categorieArt.utilisateur = user;
    categorieArt.boutique = this.boutiqueSelected;
    const result = this.catArtService.add(categorieArt);
    this.subscribeToSaveResponse(result, form);
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'une {@link CategorieArticle}.
   * @param err les erreurs.
   */
  manageErrorFromSavingCatArticle(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      const fields = erreur.fields;
      this.hasDesignationError = fields[this.categorieArticleColName.designation]
        ? true
        : false;
      this.hasCodeError = fields[this.categorieArticleColName.code] ? true : false;
    }
  }

  /**
   * Affiche une notification de succès.
   */
  notifyCreateSuccessful() {
    this.notificationService.notifyCreateSuccessful(this.subs$);
  }

  /**
   * Permet récupérer la réponse lors de la création d'une {@link CategorieArticle}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<ICategorieArticle>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<ICategorieArticle>) => {
        console.log('**** success save categorie_article: ');
        this.loadWhenCreating = false;
        this.closePopup();
        this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.CATEGORIE_ARTICLE.LIST, this.subs$);
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save categorie_article: ');
        this.activatedValidationButton = true;
        this.loadWhenCreating = false;
        try {
          this.manageErrorFromSavingCatArticle(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

  /**
   * enregistre une {@link CategorieArticle}.
   */
  save() {
    console.log('**** saving categorie_article');
    this.create(this.editForm);
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'maxlength'.
   */
  hasErrorMaxLength(field: string): boolean {
    return this.formService.hasErrorMaxLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }
}
