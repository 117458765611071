import {Component, OnDestroy, OnInit} from '@angular/core';
import {RGlobal, slashRoute} from '../../constant/global.url';
import {PageRestrictedService} from './page-restricted.service';

@Component({
  selector: 'app-page-restricted',
  templateUrl: './page-restricted.component.html'
})
export class PageRestrictedComponent implements OnInit, OnDestroy {
  constructor(
    private pageRestrictedService: PageRestrictedService
  ) {
  }

  // VAR.
  readonly ACCUEIL_URL = slashRoute + RGlobal.url.ACCUEIL;

  ngOnInit() {
    console.log('** onInit page restricted');
  }

  getMotif(): string {
    return this.pageRestrictedService.getMotif();
  }

  ngOnDestroy(): void {
    this.pageRestrictedService.restore();
  }

}
