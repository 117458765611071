import {Moment} from 'moment';
import {IUtilisateur} from './utilisateur.model';
import {IBoutique} from './boutique.model';

export const enum PrivilegeType {
  AJOUTER, MODIFIER, SUPPRIMER, RECHERCHER, CONSULTER,
  IMPRIMER, TELECHARGER
}

export const enum AcceptAction {
  OUI = 'OUI',
  NON = 'NON'
}

export const enum GestionType {
  USER = 'USER',
  CATEGORIE_ARTICLE = 'CATEGORIE_ARTICLE',
  ARTICLE = 'ARTICLE',
  ARTICLE_INFOS = 'ARTICLE_INFOS',
  SORTIE = 'SORTIE',
  FACTURE = 'FACTURE',
  BILAN = 'BILAN',
  PRODUIT_PLUS_ACHETE = 'PRODUIT_PLUS_ACHETE'
}

export interface IAction {
  id?: number;
  ajouter?: AcceptAction;
  modifier?: AcceptAction;
  rechercher?: AcceptAction;
  consulter?: AcceptAction;
  supprimer?: AcceptAction;
  imprimer?: AcceptAction;
  telecharger?: AcceptAction;
  gestion?: GestionType;
  dateEnregistrement?: Moment;
  dateModification?: Moment;
  utilisateur?: IUtilisateur;
  boutique?: IBoutique;
}

export class Action implements IAction {
  constructor(
    public id?: number,
    public ajouter?: AcceptAction,
    public modifier?: AcceptAction,
    public rechercher?: AcceptAction,
    public consulter?: AcceptAction,
    public supprimer?: AcceptAction,
    public imprimer?: AcceptAction,
    public telecharger?: AcceptAction,
    public gestion?: GestionType,
    public dateEnregistrement?: Moment,
    public dateModification?: Moment,
    public utilisateur?: IUtilisateur,
    public boutique?: IBoutique
  ) {
  }
}
