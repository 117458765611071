import {Injectable} from '@angular/core';
import {AccountUser} from '../../models/account.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {AccountService} from '../../ services/auth/account/account.service';

/**
 * Permet de gérer la navigation.
 */
@Injectable({providedIn: 'root'})
export class NavService {
  constructor(
    private freeMemoryService: FreeMemoryService,
    private accountService: AccountService) {
  }

  private subs$: Subject<void>;
  private accountUser: AccountUser; // VAR END.


  /**
   * @returns le compte de l'utilisateur connecté.
   */
  getAccountUser(): AccountUser {
    return this.accountUser;
  }

  /**
   * Permet d'identifier l'utilisateur connecté.
   */
  init(subs$: Subject<void>) {
    this.subs$ = subs$;
    this.accountService.identity(this.subs$);
    this.accountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(account => {
        console.log('** je souscrit le compte nav');
        this.accountUser = account;
      }, error => {
        console.log('** echec récupération compte user: ' + error);
      });
  }

  restore() {
    console.log('** on destroy nav');
    this.freeMemoryService.free(this.subs$);
  }
}
