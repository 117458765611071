import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {ArtInfosCreateForInit, ArtInfosCreateInit} from './art-infos-create.init';
import {CategorieArticle, ICategorieArticle} from '../../../shared/models/categorie-article.model';
import {AccountUser} from '../../../shared/models/account.model';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {IArticleInfos} from '../../../shared/models/article-infos.model';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {NRestricted} from '../../../shared/constant/restricted.msg';
import {Utilisateur} from '../../../shared/models/utilisateur.model';
import {RGlobal} from '../../../shared/constant/global.url';
import {PageRestrictedService} from '../../../shared/layouts/restricted/page-restricted.service';
import {Router} from '@angular/router';
import {FreeMemoryService} from '../../../reusable/services/free-memory.service';
import {ConstantErrorServ} from '../../../shared/constant/error.constant';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {NRequest, RequestService} from '../../../shared/ services/request/request.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CatArtService} from '../../categorie-article/cat-art.service';
import {Uri} from '../../../shared/constant/model/global.constant';
import {BoutiqueService} from '../../boutique/boutique.service';
import {Boutique} from '../../../shared/models/boutique.model';
import {ArticleInfosService} from '../article-infos.service';
import {AccountService} from '../../../shared/ services/auth/account/account.service';
import {FormService} from '../../../shared/ services/form.service';

@Component({
  selector: 'app-art-infos-create',
  templateUrl: './art-infos-create.component.html'
})
export class ArtInfosCreateComponent implements OnInit, OnDestroy {
  constructor(
    private articleInfosService: ArticleInfosService,
    private boutiqueService: BoutiqueService,
    private catArtService: CatArtService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService,
    private pageRestrictedService: PageRestrictedService,
    private requestService: RequestService,
    private router: Router,
    private freeMemoryService: FreeMemoryService,
    private catInit: ArtInfosCreateInit,
    private errorServ: ConstantErrorServ,
    private accountService: AccountService,
    private categorieArticleInit: ArtInfosCreateInit,
    private formCategorieArticleCreate: FormBuilder,
    private formService: FormService
  ) {
    this.artInfosColName = this.categorieArticleInit.getCategorieArticleColumnName();
  }

  // VAR.
  open = true;
  editForm = this.formCategorieArticleCreate.group(
    this.categorieArticleInit.getForm()
  );
  isLoading = true;
  boutiqueSelected: Boutique;
  artInfosColName: ArtInfosCreateForInit.ColumnName;
  subs$ = new Subject<void>();
  selected = true;
  activatedValidationButton = true;
  loadWhenCreating = false;

  hasDesignationError = false;
  hasCodeError = false;
  hasQtAlerteError = false;
  hasPrixUnitaireError = false;

  serverHasError = false;
  hasDataLimitError = false;

  accountUser: AccountUser;
  catArtList: ICategorieArticle[];
  needToCreateCatArt = false;
  filteredOptions: Observable<ICategorieArticle[]>;

  ngOnInit() {
    console.log('*** catégorie article onInit');
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getAllCatArt();
    });
  }

  ngOnDestroy(): void {
    this.selected = true;
    this.restore();
  }

  displayFn(subject) {
    return subject ? subject.designation : undefined;
  }


  doINeedToCreateCatArt(): boolean {
    return this.needToCreateCatArt;
  }

  /**
   * @param field champs à vérifier.
   * @return true si l'utilisateur n'a pas encore effectuer une saisie
   * sur le champs.
   */
  isPristine(field: string) {
    return this.formService.isPristine(field, this.editForm);
  }

  /**
   * Vérifie si le champs contient des erreurs.
   * @param field champs à vérifier.
   */
  isInvalid(field: string) {
    return this.formService.isInvalidAndDirtyOrTouched(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs field est requis.
   */
  hasErrorRequired(field: string): boolean {
    return this.formService.hasErrorRequired(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return le nombre minimum de caractères autorisés pour le champ.
   */
  getMinLength(field: string): number {
    return this.formService.getMinLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'maxlength'.
   */
  hasErrorMaxLength(field: string): boolean {
    return this.formService.hasErrorMaxLength(field, this.editForm);
  }

  /**
   * @param field : nom du champs.
   * @param form : formulaire.
   * @return true si le champs a des erreur de 'minlength'.
   */
  hasErrorMinLength(field: string): boolean {
    return this.formService.hasErrorMinLength(field, this.editForm);
  }

  restore() {
    this.catArtList = [];
    this.editForm.reset();
    this.removeMsgError();
    this.freeMemoryService.free(this.subs$);
  }

  getFilteredOptions(): Observable<ICategorieArticle[]> {
    return this.filteredOptions;
  }

  shouldIShow(): boolean {
    return this.catArtList && this.catArtList.length !== 0;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

  /**
   * Annuler la création de l'utilisateur.
   */
  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  removeMsgError() {
    this.serverHasError = false;
    this.hasDesignationError = false;
    this.hasDataLimitError = false;
    this.hasCodeError = false;
    this.hasQtAlerteError = false;
    this.hasPrixUnitaireError = false;
  }


  /**
   * Permet de désactiver le formulaire s'il contient des erreurs.
   */
  validationHasError() {
    return this.editForm.invalid || !this.activatedValidationButton;
  }

  /**
   * récupère les données de la catégorie article du formulaire.
   */
  getCatFromForm(form: FormGroup): IArticleInfos {
    return this.catInit.getCategorieFromForm(form);
  }

  /**
   * Réinitialise quelques champs lorsque l'utilisateur valide le formulaire.
   * NB: l'utilisation de maskAsPristine() s'explique par le fait que, comme
   * la validation de certains champs sont aussi effectuées côté serveur,
   * on voudrait que le message d'erreur affiché s'efface lorsque l'utilisateur
   * intéragit(en tapant au moins un caractère) avec le formulaire .
   */
  reset(form: FormGroup) {
    form.get(this.artInfosColName.designation).markAsPristine();
    form.get(this.artInfosColName.code).markAsPristine();
    form.get(this.artInfosColName.quantiteAlerte).markAsPristine();
    form.get(this.artInfosColName.prixUnitaire).markAsPristine();
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '0';
  }

  getBenefice() {
    const prixUnitaire = this.editForm.get(this.artInfosColName.prixUnitaire).value;
    const prixVente = this.editForm.get(this.artInfosColName.prixUnitaireVente).value;
    let benefice = 0;
    if(prixUnitaire && prixVente) {
      benefice = prixVente - prixUnitaire;
    }
    return benefice;
  }

  filterOption() {
    this.filteredOptions = this.editForm.get('id_categorie_article').valueChanges.pipe(
      startWith(''),
      map(((value: any) => {
          if (value) {
            if (typeof value === 'object') {
              return this._filter(value.designation);
            } else {
              // value is a string.
              return this._filter(value);
            }
          }
          return this._filter('');
        })
      ));
  }

  _filter(value: string): ICategorieArticle[] {
    const filterValue = value.toLocaleLowerCase();
    return this.catArtList.filter(option =>
      option.designation.toLowerCase().includes(filterValue));
  }

  getAllCatArt() {
    this.needToCreateCatArt = false;
    let option = new HttpParams();
    option = option.set(Uri.BOUTIQUE, this.boutiqueSelected.id.toString());
    const result = this.catArtService.getAll(option);
    result.subscribe(
      (r: HttpResponse<ICategorieArticle[]>) => {
        console.log('** succès récupération categorie article');
        this.isLoading = false;
        const data = r.body;
        this.catArtList = data;
        this.filterOption();
        if (!data || data.length === 0) {
          this.needToCreateCatArt = true;
        }
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération categorie article');
        this.isLoading = false;
        this.pageRestrictedService.goToRestrictedAccess(NRestricted.msgFull.CATEGORIE_ARTICLE.LIST);
        // this.router.navigate([RGlobal.urlFull.ARTICLE_INFOS.LIST]);
      }
    );
  }

  /**
   * Crée un utilisateur.
   * @param user l'utilisateur à créer.
   */
  create() {
    this.activatedValidationButton = false;
    console.log('creating art infos');
    this.loadWhenCreating = true;
    this.removeMsgError();
    this.reset(this.editForm);
    const categorieArt: IArticleInfos = this.getCatFromForm(this.editForm);
    const user = new Utilisateur();
    user.id = this.accountUser.id;
    categorieArt.utilisateur = user;
    categorieArt.boutique = this.boutiqueSelected;
    const result = this.articleInfosService.create(categorieArt);
    this.subscribeToSaveResponse(result, this.editForm);
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'une {@link CategorieArticle}.
   * @param err les erreurs.
   */
  manageErrorFromSavingCatArticle(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    if (erreur.errorCode === this.errorServ.ALREADY_EXIST) {
      const fields = erreur.fields;
      this.hasDesignationError = fields[this.artInfosColName.designation]
        ? true
        : false;
      this.hasCodeError = fields[this.artInfosColName.code] ? true : false;
    }
    if (erreur.errorCode === this.errorServ.DATA_LIMIT_REACHED) {
      this.hasDataLimitError = true;
    }
    this.checkForNegativeValueServ(erreur);
  }

  checkForNegativeValueServ(erreur: any) {
    if (erreur.errorCode === this.errorServ.NEGATIVE_VALUE) {
      const fields = erreur.fields;
      this.hasQtAlerteError = fields[this.artInfosColName.quantiteAlerte]
        ? true
        : false;
      this.hasPrixUnitaireError = fields[this.artInfosColName.prixUnitaire] ? true : false;
    }
  }

  /**
   * Permet récupérer la réponse lors de la création d'une {@link CategorieArticle}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<IArticleInfos>>,
    form: FormGroup
  ) {
    result.subscribe(
      (res: HttpResponse<IArticleInfos>) => {
        console.log('**** success save article infos: ');
        this.loadWhenCreating = false;
        this.closePopup();
        this.requestService.success(NRequest.ERequest.CREATE, form,
          RGlobal.urlFull.ARTICLE_INFOS.LIST, this.subs$);
        this.activatedValidationButton = true;
      },
      (err: HttpErrorResponse) => {
        console.log('**** error save article infos: ');
        this.activatedValidationButton = true;
        this.loadWhenCreating = false;
        try {
          this.manageErrorFromSavingCatArticle(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }
}
