import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {GuardLoginImplService} from './guard-login-impl.service';

/**
 * Permet de rediriger un utilisateur vers l'accueil si il est connecté.
 */
@Injectable({providedIn: 'root'})
export class GGuardLoginService implements CanActivate {
  constructor(private guardLoginImplService: GuardLoginImplService) {
  }

  /**
   * Permet de rediriger un utilisateur vers l'accueil si il est connecté.
   * @param route la route.
   * @param state l'état.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guardLoginImplService.canActivate(route, state);
  }
}
