/**
 * Permet d'authentifier un utilisateur.
 */
export interface IAccountUserAuth {
  login?: string;
  password?: string;
}

/**
 * Permet d'authentifier un utilisateur.
 */
export class AccountUserAuth implements IAccountUserAuth {
  constructor(public login?: string, public password?: string) {}
}
