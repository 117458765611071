import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';

/**
 * Permet la validation des champs pour une {@link CategorieArticle}.
 */
@Injectable({providedIn: 'root'})
export class ConstantSortieValidator {
  constructor() {
  }

  readonly QUANTITE_SORTIE = [
    null,
    [
      Validators.required
    ]
  ];

  readonly PRIX_UNITAIRE_VENDU = [
    null,
    [
      Validators.required
    ]
  ];

  readonly PRIX_UNITAIRE_ACHAT = [
    null,
    [
      Validators.required
    ]
  ];

  readonly ID_ARTICLE_INFOS = [
    null,
    [
      Validators.required
    ]
  ];
}
