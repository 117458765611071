import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../shared/constant/model/server.constant';
import {
  API_ACCOUNT_ACTION,
  API_EMPLOYE,
  API_EMPLOYE_ACCEPTED,
  API_EMPLOYE_ALL,
  API_EMPLOYE_ALL_ACCEPTED,
  API_EMPLOYE_DELETE,
  API_EMPLOYE_DELETE_DEMANDE,
  API_EMPLOYE_PAGES,
  API_EMPLOYE_SEARCH
} from '../../shared/constant/model/api.constant';
import {EmployeBoutique} from '../../shared/models/employe-boutique.model';
import {Action} from '../../shared/models/action.model';

@Injectable({providedIn: 'root'})
export class EmployeService {
  constructor(
    private http: HttpClient,
  ) {
    console.log('construct employe service');

  }

  // VAR.
  private resourceUrlSearch = SERVER_API_URL + API_EMPLOYE_SEARCH;
  private resourceUrl = SERVER_API_URL + API_EMPLOYE;
  private resourceUrlAccountAction = SERVER_API_URL + API_ACCOUNT_ACTION;
  private resourceUrlALL = SERVER_API_URL + API_EMPLOYE_ALL;
  private resourceUrlPages = SERVER_API_URL + API_EMPLOYE_PAGES;
  private resourceUrlALLAccepted = SERVER_API_URL + API_EMPLOYE_ALL_ACCEPTED;
  private resourceUrlAccept = SERVER_API_URL + API_EMPLOYE_ACCEPTED;
  private resourceUrlDeleteDemande = SERVER_API_URL + API_EMPLOYE_DELETE_DEMANDE;
  private resourceUrlDelete = SERVER_API_URL + API_EMPLOYE_DELETE;

  search(params): Observable<HttpResponse<EmployeBoutique[]>> {
    return this.http.get<EmployeBoutique[]>(this.resourceUrlSearch, {
      observe: 'response',
      params
    });
  }

  add(data): Observable<HttpResponse<EmployeBoutique>> {
    return this.http.post<EmployeBoutique>(this.resourceUrl, data, {
      observe: 'response'
    });
  }

  update(data): Observable<HttpResponse<boolean>> {
    return this.http.put<boolean>(this.resourceUrl, data, {
      observe: 'response'
    });
  }

  /**
   * Récupère la liste des demandes reçues.
   */
  getActions(params): Observable<HttpResponse<Action[]>> {
    return this.http.get<Action[]>(this.resourceUrlAccountAction, {
      observe: 'response',
      params
    });
  }

  /**
   * Récupère la liste des demandes reçues.
   */
  getListPages(params): Observable<HttpResponse<EmployeBoutique[]>> {
    return this.http.get<EmployeBoutique[]>(this.resourceUrlPages, {
      observe: 'response',
      params
    });
  }

  delete(params): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>(this.resourceUrlDelete, {
      observe: 'response',
      params
    });
  }

  deleteDemande(idEmploye: string): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>(this.resourceUrlDeleteDemande + idEmploye, {
      observe: 'response'
    });
  }

  accept(idEmploye: string): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(this.resourceUrlAccept + idEmploye, {
      observe: 'response'
    });
  }

  /**
   * Récupère la liste des demandes acceptées.
   */
  getListAllAccepted(): Observable<HttpResponse<EmployeBoutique[]>> {
    return this.http.get<EmployeBoutique[]>(this.resourceUrlALLAccepted, {
      observe: 'response'
    });
  }

  /**
   * Récupère la liste des demandes reçues.
   */
  getListAll(): Observable<HttpResponse<EmployeBoutique[]>> {
    return this.http.get<EmployeBoutique[]>(this.resourceUrlALL, {
      observe: 'response'
    });
  }


}
