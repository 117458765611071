import {Injectable} from '@angular/core';
import {IPaginateService} from './ipaginate.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {NoPaginateService} from './no-paginate.service';

/**
 * Permet d'initialiser la pagination pour qu'elle ne soit pas vide.
 * NB: Tout service nécessitant la pagination devra implémenter
 * l'interface {@link IPaginateService}
 */
@Injectable({providedIn: 'root'})
export class DataSharePaginateService {
  constructor(private noPaginateService: NoPaginateService) {
  }

  private ipaginateServiceObs = new BehaviorSubject<IPaginateService>(this.noPaginateService);

  getIPaginateObs(): Observable<any> {
    return this.ipaginateServiceObs.asObservable();
  }

  setIPaginate(p: IPaginateService) {
    this.ipaginateServiceObs.next(p);
  }
}
