import {Injectable} from '@angular/core';
import {ConstantArticleInfosSize} from '../model/size/article-infos-size.constant';
import {Validators} from '@angular/forms';

/**
 * Permet la validation des champs pour une {@link CategorieArticle}.
 */
@Injectable({providedIn: 'root'})
export class ConstantArticleValidator {
  constructor(private categorieSize: ConstantArticleInfosSize) {
  }

  readonly QUANTITE_ENREGISTRE = [
    null,
    [
      Validators.required
    ]
  ];

  readonly ID_ARTICLE_INFOS = [
    null,
    [
      Validators.required
    ]
  ];
}
