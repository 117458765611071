import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {IRestoreWhenDisconnectService} from '../../shared/ services/restore/irestore-when-disconnect.service';
import {ISortieDetails} from '../../shared/models/sortie-details.model';
import {ISortie} from '../../shared/models/sortie.model';

@Injectable({providedIn: 'root'})
export class DataShareWhenCheckedSortie implements IRestoreWhenDisconnectService {
  constructor(
    private restore: RestoreWhenDisconnectService) {
    this.restore.add(this);
  }

  // VAR.
  private sortieCheckedObs = new BehaviorSubject<ISortie>(undefined);
  private isSortieCheckedObs = new BehaviorSubject<boolean>(false);


  /**
   * @return l'utilisateur coché.
   */
  public getSortiecheckedObs(): Observable<ISortieDetails> {
    return this.sortieCheckedObs.asObservable();
  }

  /**
   * Permet de modifier la valeur de l'utilisateur
   * coché.
   * @param user le nouvel utilisateur.
   */
  public setSortiecheckedObs(user: ISortieDetails) {
    this.sortieCheckedObs.next(user);
  }

  /**
   * @return Observable<boolean> true indique l'utilisateur
   * est coché, false sinon.
   */
  public getIsSortieCheckedObs(): Observable<boolean> {
    return this.isSortieCheckedObs.asObservable();
  }

  /**
   * @param checked true indique l'utilisateur
   * est coché, false sinon.
   */
  public setIsSortieChecked(checked: boolean) {
    this.isSortieCheckedObs.next(checked);
  }

  /**
   * Utilisé à chaque initialisation du component user.
   */
  clearSortieChecked() {
    this.setSortiecheckedObs(undefined);
    this.setIsSortieChecked(false);
  }

  clear() {
    this.clearSortieChecked();
  }
}
