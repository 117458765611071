import {Injectable} from '@angular/core';
import {AccountUser} from './../../../models/account.model';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {AccountShareService} from './account-share.service';
import {SERVER_API_URL} from '../../../constant/model/server.constant';
import {API_ACCOUNT_APP_INFOS_LIST} from '../../../constant/model/api.constant';
import {AppInfos} from '../../../models/app-infos.model';

/**
 * Permet de gérer {@link AccountUser} le compte de l'utilisateur connecté.
 */
@Injectable({providedIn: 'root'})
export class AccountService {
  constructor(
    private accountShareService: AccountShareService,
    private http: HttpClient) {
  }

  /**
   * Récupère le compte de l'utilisateur connecté depuis le serveur.
   */
  getAppInfos(): Observable<HttpResponse<AppInfos>> {
    return this.http.get<AppInfos>(SERVER_API_URL + API_ACCOUNT_APP_INFOS_LIST, {
      observe: 'response'
    });
  }

  getUserIdentityObs(): Observable<AccountUser> {
    return this.accountShareService.getUserIdentityObs();
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté depuis le serveur.
   */
  identityWithPromise(): Promise<HttpResponse<AccountUser>> {
    return this.accountShareService.identityWithPromise();
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté depuis le serveur.
   */
  identityWithObservable(): Observable<HttpResponse<AccountUser>> {
    return this.accountShareService.identityWithObservable();
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté depuis le serveur.
   * Si le compte n'existe pas, redirige vers la page d'authentification.
   */
  identity(subs$: Subject<void>) {
    this.accountShareService.identity(subs$);
  }

  forceIdentity() {
    this.accountShareService.forceIdentity();
  }
}
