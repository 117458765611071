import {Component, Input, OnInit} from '@angular/core';

/**
 * Permet la mise à jour des utilisateurs.
 */
@Component({
  selector: 'app-mat-spinner-medium',
  templateUrl: './mat-spinner-medium.component.html'
})
export class MatSpinnerMediumComponent implements OnInit {
  constructor() {
    console.log('** on construct mat spinner medium.');
  }

  // VAR.
  diameter = 30;
  @Input() show = false;
  @Input() position = 'mx-auto';

  ngOnInit(): void {
    console.log('** on init mat spinner medium');
  }
}
