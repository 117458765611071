import {Injectable} from '@angular/core';
import {IPaginateService} from './ipaginate.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ManageService} from '../../../ services/manage.service';

/**
 * Permet d'initialiser la pagination pour qu'elle ne soit pas vide.
 * NB: Tout service nécessitant la pagination devra implémenter
 * l'interface {@link IPaginateService}
 */
@Injectable({providedIn: 'root'})
export class NoPaginateService implements IPaginateService {

  constructor(
    private manageService: ManageService) {
  }

  private params: Params;
  activatedRoute: ActivatedRoute;
  private subscription = false;

  setActivatedRoute(activatedRoute: ActivatedRoute) {
    console.log('params 1');
    this.activatedRoute = activatedRoute;
    if (!this.subscription) {
      this.subscription = true;
      activatedRoute.queryParams.subscribe(params => {
        this.params = this.manageService.getCopyParam(params);
        console.log('params 2: ' + JSON.stringify(params));
      });
    }

  }

  getData() {
    return [];
  }

  getDataServ(pageNumber: string) {
  }

  getNbItems(): number {
    return 0;
  }

  getParams(): Params {
    return this.params;
  }

  getPath(): string {
    return this.manageService.getFullPath(this.activatedRoute);
  }

  getTotalPages(): number {
    return 0;
  }

  setData(data: any[]) {
  }

  setNbItems(n: number) {
  }

  setTotalPages(t: number) {
  }

  getDateDebut(): string {
    return '';
  }

  getDateFin(): string {
    return '';
  }
}
