import {NgModule} from '@angular/core';
import {GestionStockSharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {UserAccountCreateComponent} from './user-account-create/user-account-create.component';
import {userAccountRoute} from './user-account.route';
import {UserAccountValidateComponent} from './user-account-validate/user-account-validate.component';
import {UserAccountResetPasswordComponent} from './user-account-reset-password/user-account-reset-password.component';
import {ResetPasswordValidateComponent} from './reset-password-validate/reset-password-validate.component';
import {PasswordEnterComponent} from './password-enter/password-enter.component';
import {UserAccountDeleteComponent} from './user-account-delete/user-account-delete.component';
import {AbonnementValidateComponent} from '../abonnement/abonnement-validate/abonnement-validate.component';
import {UserUpdateEmailComponent} from './update-email/user-update-email.component';

/**
 * modules pour la page d'accueil.
 */
@NgModule({
  declarations: [
    AbonnementValidateComponent,
    UserAccountDeleteComponent,
    PasswordEnterComponent,
    ResetPasswordValidateComponent,
    UserAccountCreateComponent,
    UserAccountValidateComponent,
    UserAccountResetPasswordComponent],
  imports: [
    GestionStockSharedModule,
    RouterModule.forRoot([...userAccountRoute])
  ],
  exports: [],
  entryComponents: [UserAccountDeleteComponent, PasswordEnterComponent]
})
export class UserAccountModule {
}
