import {Component, OnInit} from '@angular/core';
import {RGlobal, slashRoute} from '../../constant/global.url';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {
  constructor() {
  }

  // VAR.
  readonly ACCUEIL_URL = slashRoute + RGlobal.url.ACCUEIL;

  ngOnInit() {
    console.log('** onInit page not found');
  }


}
