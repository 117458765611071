import {Component, OnDestroy, OnInit} from '@angular/core';
import {ArticleInfosService} from '../../modules/article-infos/article-infos.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../shared/ services/auth/auth.service';
import {UserAccountService} from '../../modules/user-account/user-account.service';
import {map, takeUntil} from 'rxjs/operators';
import {RGlobal} from '../../shared/constant/global.url';
import {JWT} from '../../shared/constant/model/server.constant';
import {Router} from '@angular/router';
import {PopupReusableService} from '../../reusable/services/popup-reusable.service';
import {AuthPopupService} from './auth-popup.service';
import {Subject} from 'rxjs';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {FreeMemoryService} from '../../reusable/services/free-memory.service';

@Component({
  selector: 'app-auth-popup',
  templateUrl: './auth-popup.component.html'
})
export class AuthPopupComponent implements OnInit, OnDestroy {
  constructor(
    private freeMemoryService: FreeMemoryService,
    private restoreWhenDisconnectService: RestoreWhenDisconnectService,
    private authPopupService: AuthPopupService,
    private router: Router,
    private authService: AuthService,
    private popupReusableService: PopupReusableService,
    private userAccountService: UserAccountService,
    private articleInfosService: ArticleInfosService,
    private activeModal: NgbActiveModal,
  ) {
  }

  open = true;
  subs$ = new Subject<void>();
  isSelect = false;

  ngOnInit(): void {
    console.log('on init popup');
    this.authPopupService.getListEvent().pipe(takeUntil(this.subs$))
      .subscribe(res => {
        console.log('emitting: ' + res);
        this.authenticateMarchand();
      });
  }

  authenticate() {
    console.log('click auth');
    this.isSelect = true;
    this.authService.logout();
    this.restoreWhenDisconnectService.restoreAll();
    localStorage.setItem(JWT.auth, JWT.auth);
    localStorage.setItem(JWT.accueil, JWT.accueil);
    this.router.navigate([RGlobal.url.LOGIN]);
    this.closePopup();
  }

  authenticateMarchand() {
    console.log('click marchand');
    this.isSelect = true;
    const anonyme = localStorage.getItem(JWT.anonyme);
    if (!anonyme) {
      this.userAccountService.saveMarchand().pipe(
        map(data => {
          const userData = data.body;
          localStorage.setItem(JWT.username, JWT.default_name);
          const tokenStr = JWT.bearer + userData.token;
          console.log('userData: ' + JSON.stringify(userData));
          console.log('tokenstr: ' + tokenStr);
          localStorage.setItem(JWT.token, tokenStr);
          localStorage.setItem(JWT.anonyme, JWT.anonyme);
          return userData;
        })
      ).subscribe(res => {
        console.log('success create marchand');
        this.router.navigate([RGlobal.url.ACCUEIL]);
        this.closePopup();
        this.isSelect = false;
      }, error => {
        console.log('error create marchand');
        this.isSelect = false;
      });
    } else {
      this.router.navigate([RGlobal.url.ACCUEIL]);
      this.closePopup();
      this.isSelect = false;
    }
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.close(this.activeModal);
    this.freeMemoryService.free(this.subs$);
  }

  ngOnDestroy(): void {
    console.log('on destroy popup');
  }
}
