import {ConstantCategorieArticleColumnName} from '../../../../shared/constant/model/column-name/categorie-column-name.constant';
import {DesignationVerification} from './designation-verification';
import {CodeVerification} from './code-verification';
import {AbsFactoryCatArtVerification} from './abs-factory-cat-art-verification';
import {AbsCatArtVerification} from './abs-cat-art-verification';
import {CodeSortieVerification} from './code-sortie-verification';

/**
 * Permet d'effectuer des vérifications lors de la recherche par filtre.
 */
export class SearchCatArtVerification extends AbsFactoryCatArtVerification {
  constructor() {
    super();
  }

  /**
   * Vérifie si le login fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param login login de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkDesignation(designation: string, usercolName: ConstantCategorieArticleColumnName): AbsCatArtVerification {
    return new DesignationVerification().designationSet(designation).catArtColNameSet(usercolName);
  }

  /**
   * Vérifie si l'email fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param email l'email de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkCode(code: string,
            usercolName: ConstantCategorieArticleColumnName): AbsCatArtVerification {
    return new CodeVerification().codeSet(code).catArtColNameSet(usercolName);
  }

  /**
   * Vérifie si l'email fait partir des filtres de recherche
   * et de l'ajouter dans les paramètres dans ce cas.
   * @param email l'email de l'utilisateur à rechercher.
   * @param usercolName contient le nom des colonnes de l'entité utilisateur.
   */
  checkCodeSortie(codeSortie: string,
                  usercolName: ConstantCategorieArticleColumnName): AbsCatArtVerification {
    return new CodeSortieVerification().codeSet(codeSortie).catArtColNameSet(usercolName);
  }


}
