import {Injectable} from '@angular/core';
import {IDeleteSuccessService} from './idelete-success.service';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {PaginationService} from '../../layouts/pagination/service/pagination.service';
import {NotificationService} from '../../../reusable/services/notification.service';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';

@Injectable({providedIn: 'root'})
export class DeleteSuccessService {
  constructor(
    private popupReusableService: PopupReusableService,
    private notificationService: NotificationService,
    private paginationService: PaginationService,
    private router: Router,
  ) {
  }

  deleteSuccess(i: IDeleteSuccessService, subs$: Subject<void>,
                msgForEmit: string) {
    this.popupReusableService.dismiss(i.getActiveModal());
    i.clearItemChecked();
    this.notificationService.notifyDeleteSuccessful(subs$);
    this.router.navigate([i.getPath()]);
  }
}
