import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AccountUser} from '../../shared/models/account.model';
import {takeUntil} from 'rxjs/operators';
import {FreeMemoryService} from '../../reusable/services/free-memory.service';
import {AccountService} from '../../shared/ services/auth/account/account.service';
import {AuthService} from '../../shared/ services/auth/auth.service';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {AbonnementService} from '../../modules/abonnement/abonnement.service';
import {PopupReusableService} from '../../reusable/services/popup-reusable.service';

/**
 * Services pour la page d'accueil.
 */
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(
    private popupReusableService: PopupReusableService,
    private abonnementService: AbonnementService,
    private restoreWhenDisconnectService: RestoreWhenDisconnectService,
    private accountService: AccountService,
    private freeMemoryService: FreeMemoryService,
    private authService: AuthService) {
  }

  // VAR.
  private accountUser: AccountUser;
  private subs$: Subject<void>; // VAR END.


  init(subs$: Subject<void>) {
    this.subs$ = subs$;
    this.accountService.identity(this.subs$);
    this.getUserIdentityObs(this.accountService);
    // this.verifyAbonnementApplication();
  }

  getAccount(): AccountUser {
    return this.accountUser;
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  private getUserIdentityObs(iAccountService: AccountService) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
      });
  }

  /**
   * Permet de déconnecter un utilisateur et de le rediriger
   * vers la page d'accueil.
   */
  logout() {
    this.restoreWhenDisconnectService.restoreAll();
    this.authService.logoutAndRedirectToLogin();
  }

  restore() {
    this.freeMemoryService.free(this.subs$);
  }
}
