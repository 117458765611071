import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {IArticle} from '../../shared/models/article.model';
import {FreeMemoryService} from '../../reusable/services/free-memory.service';

/**
 * Permet de gérer les {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class RestoreArticleService {
  constructor(private freeMemoryService: FreeMemoryService) {
  }

  articleChecked: IArticle;

  getArticleChecked(): IArticle {
    return this.articleChecked;
  }

  setArticleChecked(user: IArticle) {
    this.articleChecked = user;
  }

  /**
   * Permet de réinitialiser certaines variable à la fermeture
   * du popup.
   */
  restore(subs$: Subject<void>) {
    this.articleChecked = undefined;
    this.freeMemoryService.free(subs$);
  }
}
