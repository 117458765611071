import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../../shared/constant/model/server.constant';
import {API_BOUTIQUE, API_BOUTIQUE_ALL, API_BOUTIQUE_MAX, API_BOUTIQUE_NB_CREATE} from '../../shared/constant/model/api.constant';
import {BehaviorSubject, Observable} from 'rxjs';
import {Boutique} from '../../shared/models/boutique.model';
import {IRestoreWhenDisconnectService} from '../../shared/ services/restore/irestore-when-disconnect.service';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {BoutiqueListData} from './boutique-liste/boutique-liste.component';

@Injectable({providedIn: 'root'})
export class BoutiqueService implements IRestoreWhenDisconnectService {
  constructor(
    private restore: RestoreWhenDisconnectService,
    private http: HttpClient,
  ) {
    console.log('construct boutique service');
    this.boutiqueListDataObs = new BehaviorSubject<BoutiqueListData>(this.data);
    this.restore.add(this);
  }

  // VAR.
  private listChangeEvent = new EventEmitter<string>();
  private data: BoutiqueListData = {
    isSelected: true
  };
  private boutiqueListDataObs;
  // la boutique sélectionnée par l'utilisateur.
  private boutiqueSelectDataObs = new BehaviorSubject<Boutique>(undefined);
  private boutiqueCheckedDataObs = new BehaviorSubject<Boutique>(undefined);
  private resourceUrlList = SERVER_API_URL + API_BOUTIQUE_ALL;
  private resourceUrlCreate = SERVER_API_URL + API_BOUTIQUE;
  private resourceUrlMAX = SERVER_API_URL + API_BOUTIQUE_MAX;
  private resourceUrlNbCreate = SERVER_API_URL + API_BOUTIQUE_NB_CREATE;

  getNbCreate(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlNbCreate, {
      observe: 'response',
      params
    });
  }

  getMaxCanCreate(params): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrlMAX, {
      observe: 'response',
      params
    });
  }

  getListEvent(): EventEmitter<string> {
    return this.listChangeEvent;
  }

  getBoutiqueListDataObs(): Observable<BoutiqueListData> {
    return this.boutiqueListDataObs.asObservable();
  }

  setBoutiqueListDataObs(boutique: BoutiqueListData) {
    this.boutiqueListDataObs.next(boutique);
  }

  getBoutiqueSelectDataObs(): Observable<Boutique> {
    return this.boutiqueSelectDataObs.asObservable();
  }

  setBoutiqueSelectDataObs(boutique: Boutique) {
    this.boutiqueSelectDataObs.next(boutique);
  }

  getBoutiqueCheckedDataObs(): Observable<Boutique> {
    return this.boutiqueCheckedDataObs.asObservable();
  }

  setBoutiqueCheckedDataObs(boutique: Boutique) {
    this.boutiqueCheckedDataObs.next(boutique);
  }

  delete(idBoutique: string): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>(this.resourceUrlCreate + '/' + idBoutique, {
      observe: 'response',
      responseType: 'text' as 'json'
    });
  }

  update(boutique: Boutique): Observable<HttpResponse<Boutique>> {
    return this.http.put<Boutique>(this.resourceUrlCreate, boutique, {
      observe: 'response'
    });
  }

  create(boutique: Boutique): Observable<HttpResponse<Boutique>> {
    return this.http.post<Boutique>(this.resourceUrlCreate, boutique, {
      observe: 'response'
    });
  }

  getList(): Observable<HttpResponse<Boutique[]>> {
    return this.http.get<Boutique[]>(this.resourceUrlList, {
      observe: 'response'
    });
  }

  isUserOwner(mesBoutique: Boutique[],
              boutiqueSelected: Boutique) {
    let result = false;
    if (mesBoutique && boutiqueSelected) {
      for (const m of mesBoutique) {
        if (m.id === boutiqueSelected.id) {
          result = true;
          break;
        }
      }
    }
    return result;
  }

  clear() {
    this.setBoutiqueSelectDataObs(undefined);
    this.setBoutiqueCheckedDataObs(undefined);
    this.setBoutiqueListDataObs(this.data);
  }

}
