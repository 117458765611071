import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConstantCategorieArticleColumnName} from '../../../shared/constant/model/column-name/categorie-column-name.constant';
import {ConstantCategorieArticleValidator} from '../../../shared/constant/validator/categorie-article-validator.constant';
import {CategorieArticle, ICategorieArticle} from '../../../shared/models/categorie-article.model';
import {FormService} from '../../../shared/ services/form.service';

/**
 * Offre plusieurs types utilisés dans la création de catégories d'article.
 */
export namespace CatArtCreate {
  /**
   * Permet d'avoir le nom des champs pour une {@link CategorieArticle}.
   */
  export interface ColumnName {
    name: string;
    id: string;
    designation: string;
    code: string;
    description: string;
  }
}

/**
 * Permet d'initialiser le component {@link CategorieArticleCreateComponent}
 */
@Injectable({providedIn: 'root'})
export class CategorieArticleCreateInit {
  constructor(
    private formService: FormService,
    private categorieColName: ConstantCategorieArticleColumnName,
    private categorieValidator: ConstantCategorieArticleValidator
  ) {
  }

  /**
   * récupère les données de la catégorie du formulaire.
   */
  public getCategorieFromForm(editForm: FormGroup): ICategorieArticle {
    const entity = {
      ...new CategorieArticle(),
      designation: this.formService.getData(
        this.categorieColName.DESIGNATION,
        editForm, []
      ),
      code: this.formService.getData(this.categorieColName.CODE, editForm,
        []),
      description: this.formService.getData(this.categorieColName.DESCRIPTION, editForm,
        [])
    };
    return entity;
  }

  /**
   * @returns le nom des champs pour une {@link CategorieArticle}
   */
  getCategorieArticleColumnName(): CatArtCreate.ColumnName {
    return {
      name: this.categorieColName.NAME,
      id: this.categorieColName.ID,
      designation: this.categorieColName.DESIGNATION,
      description: this.categorieColName.DESCRIPTION,
      code: this.categorieColName.CODE
    };
  }

  /**
   * @returns Formulaire de création d'une {@link CategorieArticle}.
   */
  getForm(): any {
    return {
      designation: this.categorieValidator.DESIGNATION,
      code: this.categorieValidator.CODE,
      description: this.categorieValidator.DESCRIPTION
    };
  }
}
