import {Moment} from 'moment';
import {IArticleInfos} from './article-infos.model';
import {ISortie} from './sortie.model';
import {Boutique} from './boutique.model';

export interface ISortieDetails {
  id?: number;
  quantiteSortie?: number;
  prixUnitaireVendu?: number;
  prixUnitaireAchat?: number;
  montant?: number;
  montantTotal?: number;
  montantTotalAchat?: number;
  code?: string;
  isDelete?: string;
  sortie?: ISortie;
  dateModification?: Moment;
  boutique?: Boutique;
  pourcentage?: number;
  articleInfos?: IArticleInfos;
  savedDate?: string;
}

export class SortieDetails implements ISortieDetails {
  constructor(
    public id?: number,
    public quantiteSortie?: number,
    public prixUnitaireVendu?: number,
    public prixUnitaireAchat?: number,
    public montant?: number,
    public montantTotal?: number,
    public montantTotalAchat?: number,
    public code?: string,
    public isDelete?: string,
    public sortie?: ISortie,
    public dateModification?: Moment,
    public pourcentage?: number,
    public articleInfos?: IArticleInfos,
    public savedDate?: string,
  ) {
  }
}

export class SortieDTO {
  constructor(
    public id?: number,
    public sortieListDTOS?: ISortieDetails[],
    public boutique?: Boutique,
    public savedDate?: string
  ) {
  }
}
