import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {FreeMemoryService} from '../../reusable/services/free-memory.service';
import {ISortieDetails} from '../../shared/models/sortie-details.model';

/**
 * Permet de gérer les {@link Utilisateur}.
 */
@Injectable({providedIn: 'root'})
export class RestoreSortieService {
  constructor(private freeMemoryService: FreeMemoryService) {
  }

  sortieChecked: ISortieDetails;

  getSortieChecked(): ISortieDetails {
    return this.sortieChecked;
  }

  setSortieChecked(user: ISortieDetails) {
    this.sortieChecked = user;
  }

  /**
   * Permet de réinitialiser certaines variable à la fermeture
   * du popup.
   */
  restore(subs$: Subject<void>) {
    this.sortieChecked = undefined;
    this.freeMemoryService.free(subs$);
  }
}
