import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorInterceptorImpl} from './error-interceptor-impl.service';

/**
 * Permet de traiter une partie des erreurs liées à l'authentification
 * des utilisateurs.
 */
@Injectable({providedIn: 'root'})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorInterceptor: ErrorInterceptorImpl) {
  }

  /**
   * Permet de traiter une partie des erreurs liées à l'authentification
   * des utilisateurs. Par exemple lors de l'envoie d'une requête si
   * le serveur, il y a une erreur liée à l'authentification, on déconnecte
   * l'utilisateur et le redirige vers la page d'accueil.
   *
   * @param request request.
   * @param next next.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this.errorInterceptor.intercept(request, next);
  }
}
