import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Observable, Subject} from 'rxjs';
import {AccountUser} from '../../shared/models/account.model';
import {RGlobal, slashRoute} from '../../shared/constant/global.url';
import {PopupReusableService} from '../../reusable/services/popup-reusable.service';
import {NotificationService} from '../../reusable/services/notification.service';
import {HomeService} from './home.service';
import {UserAccountUpdateComponent} from '../../modules/user-account/user-account-update/user-account-update.component';
import {AbonnementInfosComponent} from '../../modules/abonnement/abonnement-infos/abonnement-infos.component';
import {PanierListeComponent} from '../../modules/sortie/panier/panier-liste/panier-liste.component';
import {DataShareWhenCheckedArtInfos} from '../../modules/article-infos/data-share-when-checked-art-infos.service';
import {CheckingBoutique} from '../../modules/employe/checking-boutique.service';
import {BoutiqueService} from '../../modules/boutique/boutique.service';
import {takeUntil} from 'rxjs/operators';
import {Boutique} from '../../shared/models/boutique.model';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {AbonnementService} from '../../modules/abonnement/abonnement.service';
import {AbonnementApplicationComponent} from '../../modules/abonnement/abonnement-application/abonnement-application.component';
import {AuthService} from '../../shared/ services/auth/auth.service';
import {AuthPopupComponent} from '../auth-popup/auth-popup.component';
import {Router} from '@angular/router';
import {ArtInfosCreateComponent} from '../../modules/article-infos/art-infos-create/art-infos-create.component';
import {BOUTIQUE_SELECT} from '../../shared/constant/model/global.constant';
import {AuthPopupService} from '../auth-popup/auth-popup.service';
import {AccountService} from '../../shared/ services/auth/account/account.service';
import {AppInfos} from '../../shared/models/app-infos.model';
import {FormService} from '../../shared/ services/form.service';
import {UserUpdateEmailComponent} from '../../modules/user-account/update-email/user-update-email.component';

/**
 * Page d'accueil.
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewChecked {
  boutiques: Boutique[];
  boutique: Boutique;
  appInfos: AppInfos;
  youtubeLink = 'https://www.youtube.com/watch?v=fOcZA01MUeM&list=PLuJa5EhUHXi4amy7YoAHwmnzp3Lu7a0NR';
  subs$ = new Subject<void>();
  readonly ACCUEIL_URL = slashRoute + RGlobal.url.ACCUEIL;

  constructor(
    private formService: FormService,
    private accountService: AccountService,
    private authPopupService: AuthPopupService,
    private router: Router,
    private authService: AuthService,
    private abonnementService: AbonnementService,
    private boutiqueService: BoutiqueService,
    private checkingBoutique: CheckingBoutique,
    private dataShareWhenCheckedArtInfos: DataShareWhenCheckedArtInfos,
    private ref: ChangeDetectorRef,
    private popupReusableService: PopupReusableService,
    private notificationService: NotificationService,
    private title: Title,
    private homeService: HomeService) {
  }

  ngOnInit() {
    console.log('*** Oninit home');
    this.homeService.init(this.subs$);
    this.notificationService.setTitle(this.title, 'title.accueil', this.subs$);
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(this.subs$))
      .subscribe((res: Boutique) => {
        this.boutique = res;
        console.log('## boutique: ', res);
      });
    this.popupReusableService.getPromiseForRequest().then(r => {
      this.verifyAbonnementApplication();
    });
    if (this.authService.isUserMachand() && !localStorage.getItem(BOUTIQUE_SELECT)) {
      console.log('user marchand, get boutiques');
      this.getList();
    }
    this.popupReusableService.getPromiseForRequest().then(r => {
      this.accountService.getAppInfos().subscribe(res => {
        this.appInfos = res.body;
        console.log('app: ' + this.appInfos.pageFacebook);
      });
    });
  }

  renewAb() {
    this.popupReusableService.open(null, AbonnementApplicationComponent);
  }

  openYoutube() {
    window.open(this.youtubeLink, '_blanck');
  }

  formatNomBoutique(s: string): string {
    return this.formService.formatNomBoutique(s);
  }

  formatId(id: string) {
    return this.formService.formatId(id);
  }

  getList() {
    const result = this.boutiqueService.getList();
    this.subscribeToSaveResponseBoutique(result);
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponseBoutique(
    result: Observable<HttpResponse<Boutique[]>>,
  ) {
    result.subscribe(
      (res: HttpResponse<Boutique[]>) => {
        console.log('**** success list boutique: ');
        this.boutiques = res.body;
        const first = this.boutiques[0];
        console.log('nom: ' + first);
        if (first) {
          this.boutiqueService.setBoutiqueSelectDataObs(first);
          localStorage.setItem(BOUTIQUE_SELECT, BOUTIQUE_SELECT);
          /* this.popupReusableService.getPromiseForRequest().then(r => {
             this.router.navigate([RGlobal.url.ARTICLE_INFOS.LIST]);
           });*/
          this.popupReusableService.open(null, ArtInfosCreateComponent);
        }
      },
      (err: HttpErrorResponse) => {
        console.log('**** error list boutique: ');
        /*this.activatedValidationButton = true;
        this.loadWhenCreating = false;*/
        console.log('erreur: ' + (err as Error).message);
      }
    );
  }

  openPopupAuth() {
    this.authPopupService.open(AuthPopupComponent);
    // this.popupReusableService.open(null, AuthPopupComponent);
  }

  isUserMarchand() {
    return this.authService.isUserMachand();
  }

  /**
   * Permet récupérer la réponse lors de la création d'un {@link Utilisateur}
   * @param result la réponse.
   */
  subscribeToSaveResponse(
    result: Observable<HttpResponse<boolean>>,
  ) {
    result.subscribe(
      (res: HttpResponse<boolean>) => {
        console.log('**** success abonnement application: ');
        console.log('res: ' + res.body);
        const data = res.body;
        if (!data) {
          this.popupReusableService.open(null, AbonnementApplicationComponent);
          console.log('data app: ' + data);
        }
      },
      (err: HttpErrorResponse) => {
        console.log('**** error abonnement application: ');
        this.popupReusableService.open(null, AbonnementApplicationComponent);
      }
    );
  }

  getNbElementPanier() {
    return this.dataShareWhenCheckedArtInfos.artInfosList.length;
  }

  ngOnDestroy(): void {
    this.homeService.restore();
  }

  getAccount(): AccountUser {
    return this.homeService.getAccount();
  }

  updateEmail() {
    console.log('update email');
    this.popupReusableService.open(null, UserUpdateEmailComponent);
  }

  updateProfile() {
    console.log('update profile');
    this.popupReusableService.open(null, UserAccountUpdateComponent);
  }

  showAbonnement() {
    if (this.checkingBoutique.isSelected()) {
      console.log('show abonnement');
      this.popupReusableService.open(null, AbonnementInfosComponent);
    }
  }

  openCart() {
    console.log('open cart');
    this.popupReusableService.open(null, PanierListeComponent);
  }

  showAccount(): string {
    let tab = '';
    const login = this.getAccount().login;
    let i = 0;
    for (const l of login) {
      tab = tab.concat(l);
      i++;
      if (i === 10) {
        tab = tab.concat('..');
        break;
      }
    }
    return tab;
  }

  /*showInfosEntreprise() {
    console.log('Entreprise');
    this.popupReusableService.open(null, EntrepriseComponent);
  }*/

  /**
   * Permet de déconnecter un utilisateur et de le rediriger
   * vers la page d'accueil.
   */
  logout() {
    console.log('** logout');
    this.homeService.logout();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  private verifyAbonnementApplication() {
    const result = this.abonnementService.verifyAbonnementApplication();
    console.log('en cours');
    this.subscribeToSaveResponse(result);
  }


}
