/**
 * Permet d'avoir le nom des champs pour une {@link CategorieArticle}
 */
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ConstantAbonnementProduitPay {
  readonly VALIDITE_THREE_MONTHS = 3;
  readonly CODE = 'produit';
  readonly PRIX = 5000.00;
  envir;

  calculatePrice(nombre: number) {
    return nombre * this.PRIX;
  }
}
