import {Injectable} from '@angular/core';
import {ConstantAbonnementColumnName} from '../../../shared/constant/model/column-name/abonnement-column-name.constant';

/**
 * Offre plusieurs types utilisés pour se connecter
 */
export namespace AbonnementNm {
  /**
   * Permet d'avoir le nom des champs pour créer un compte.
   */
  export interface ColumnName {
    code: string;
    paymentId: string;
    payerId: string;
    token: string;
  }
}

/**
 * Permet d'initialiser le component {@link LoginComponent}
 */
@Injectable({providedIn: 'root'})
export class AbonnementValidateInit {
  constructor(
    private userColName: ConstantAbonnementColumnName,
  ) {
  }

  /**
   * @returns le nom des champs pour se connecter.
   */
  getAuthColumnName(): AbonnementNm.ColumnName {
    return {
      code: this.userColName.CODE,
      payerId: this.userColName.PAYER_ID,
      paymentId: this.userColName.PAYMENT_ID,
      token: this.userColName.TOKEN
    };
  }
}
