import {Component, OnDestroy, OnInit} from '@angular/core';
import {RestoreEmployeService} from '../restore-employe.service';
import {TranslateService} from '@ngx-translate/core';
import {EtatUserType, SexeType, UtilisateurType} from '../../../shared/models/utilisateur.model';
import {Subject} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../reusable/services/popup-reusable.service';
import {EmployeShowInit} from './component-show.init';
import {DataShareWhenCheckedUser} from '../data-share-when-checked-user.service';
import {ManageService} from '../../../shared/ services/manage.service';
import {takeUntil} from 'rxjs/operators';
import {DateFormatService} from '../../../shared/ services/date-format.service';
import {EmployeBoutique} from '../../../shared/models/employe-boutique.model';

/**
 * Permet de gérer la liste des demandes reçues pour des boutiques.
 */
@Component({
  selector: 'app-employe-show',
  templateUrl: './employe-show.component.html'
})
export class EmployeShowComponent implements OnInit, OnDestroy {
  constructor(
    private dateFormatService: DateFormatService,
    private restoreUserService: RestoreEmployeService,
    private translateService: TranslateService,
    private popupReusableService: PopupReusableService,
    private dataShare: DataShareWhenCheckedUser,
    private activeModal: NgbActiveModal,
    private manageService: ManageService,
    private userInit: EmployeShowInit,
  ) {
  }

  // VAR.
  open = true;
  subs$ = new Subject<void>();

  ngOnInit() {
    console.log('*** user-show onInit');
    this.dataShare.getUsercheckedObs().pipe(takeUntil(this.subs$))
      .subscribe((data: EmployeBoutique) => {
        if (data) {
          this.restoreUserService.setUserChecked(data);
        }
      });
  }

  ngOnDestroy() {
    console.log('*** user-show onDestroy');
    this.restoreUserService.restore(this.subs$);
  }

  getUser(): EmployeBoutique {
    return this.restoreUserService.getUserChecked();
  }

  /**
   * Permet de convertir une date
   */
  date() {
    return this.dateFormatService; // NE PAS SUPPRIMER, utilisé côté Html.
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

  /**
   * Permet de récupérer les données de l'utilisateur recherché.
   */
  user() {
    return this.userInit.getUser(this.getUser());
  }

  /**
   * Permet d'avoir une valeur par défaut si le type n'est pas renseigné.
   * @param type le type d'utilisateur.
   */
  convertType(type: UtilisateurType) {
    return this.manageService.getType(type);
  }

  /**
   * Permet d'avoir une valeur par défaut si l'état n'est pas renseigné.
   * @param etat l'état de l'utilisateur.
   */
  convertEtat(etat: EtatUserType) {
    return this.manageService.getEtat(etat);
  }

  /**
   * Permet d'avoir une valeur par défaut si le sexe n'est pas renseigné.
   * @param sexe le sexe.
   */
  convertSexe(sexe: SexeType) {
    return this.manageService.getSexe(sexe);
  }

}
