import {Injectable} from '@angular/core';

/**
 * Permet d'avoir le nom des champs pour un {@link Utilisateur}
 */
@Injectable({providedIn: 'root'})
export class ConstantUserColumnName {

  readonly NAME = 'employe';
  readonly NAME_USER = 'utilisateur';
  readonly ID = 'id';
  readonly LOGIN = 'login';
  readonly PASSWORD = 'password';
  readonly OLD_PASSWORD = 'oldPassword';
  readonly NOM = 'nom';
  readonly PRENOM = 'prenom';
  readonly DATE_NAISSANCE = 'date_naissance';
  readonly ADRESSE = 'adresse';
  readonly SEXE = 'sexe';
  readonly TYPE = 'type';
  readonly TELEPHONE = 'telephone';
  readonly EMAIL = 'email';
  readonly NATIONALITE = 'nationalite';
  readonly ETAT = 'etat';
  readonly DATE_ENREGISTREMENT = 'date_enregistrement';
  readonly DATE_MODIFICATION = 'date_modification';

  /**
   * @returns les champs pour lesquels les valeurs du formulaire récupérées
   * seront en minuscules.
   */
  readonly fields = [this.NOM, this.PRENOM, this.NATIONALITE, this.ADRESSE];

}
