import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupReusableService} from '../../../../reusable/services/popup-reusable.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {SortieService} from '../../../sortie/sortie.service';
import {BoutiqueService} from '../../../boutique/boutique.service';
import {Boutique} from '../../../../shared/models/boutique.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GettingPrivilegeService} from '../../../../shared/ services/privilege/getting/getting-privilege.service';
import {AccountService} from '../../../../shared/ services/auth/account/account.service';
import {GestionType, PrivilegeType} from '../../../../shared/models/action.model';
import {AccountUser} from '../../../../shared/models/account.model';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ConstantSortieColumnName} from '../../../../shared/constant/model/column-name/sortie-column-name.constant';
import {DataShareWhenCheckedSortie} from '../../../sortie/data-share-when-checked-sortie.service';
import {ISortieDetails, SortieDetails} from '../../../../shared/models/sortie-details.model';
import {Uri} from '../../../../shared/constant/model/global.constant';
import {PageCs} from '../../../../shared/constant/model/page.constant';
import {DateFormatService} from '../../../../shared/ services/date-format.service';
import {ConstantBoutiqueColumnName} from '../../../../shared/constant/model/column-name/boutique-column-name.constant';
import {FormService} from '../../../../shared/ services/form.service';
import {ConstantErrorServ} from '../../../../shared/constant/error.constant';
import {AbonnementFactureComponent} from '../../abonnement-facture/abonnement-facture.component';

@Component({
  selector: 'app-facture-show',
  templateUrl: './facture-show.component.html'
})
export class FactureShowComponent implements OnInit, OnDestroy {

  constructor(
    private errorServ: ConstantErrorServ,
    private formService: FormService,
    private boutiqueColumnName: ConstantBoutiqueColumnName,
    private date: DateFormatService,
    private dataShare: DataShareWhenCheckedSortie,
    private sortieColumnName: ConstantSortieColumnName,
    private accountService: AccountService,
    private gettingPrivilegeService: GettingPrivilegeService,
    private boutiqueService: BoutiqueService,
    private sortieService: SortieService,
    private activeModal: NgbActiveModal,
    private popupReusableService: PopupReusableService
  ) {
  }

  hasAbonnementError = false;
  sortieFacture: SortieDetails[];
  sortieChecked: SortieDetails;
  iCanDownload = false;
  iCanPrint = false;
  accountUser: AccountUser;
  boutiqueSelected: Boutique;
  open = true;
  shouldIShow = false;
  subs$ = new Subject<void>();

  ngOnInit(): void {
    console.log('init facture show');
    this.popupReusableService.getPromise().then(res => {
      this.shouldIShow = true;
    });
    this.boutiqueService.getBoutiqueSelectDataObs().pipe(takeUntil(
      this.subs$
    )).subscribe((res: Boutique) => {
      this.boutiqueSelected = res;
      this.getUserIdentityObs(this.accountService, res);
      this.dataShare.getSortiecheckedObs().pipe(takeUntil(this.subs$))
        .subscribe((data: ISortieDetails) => {
          if (data) {
            this.sortieChecked = data;
            this.getFacture(data, res);
          }
        });
    });
  }

  ngOnDestroy(): void {
  }

  formatQt(num: number): string {
    if (num) {
      return this.formService.formatUsWithNoFractionDigit(num);
    }
    return '';
  }

  format(num: number): string {
    if (num) {
      return this.formService.formatUs(num);
    }
    return '';
  }

  getMontantTotal(sortieFacture: SortieDetails[]) {
    let montantTotal = 0.00;
    if (sortieFacture) {
      for (const f of sortieFacture) {
        montantTotal += f.montant;
      }
    }
    return montantTotal;
  }

  getFacture(sortieChecked: SortieDetails, boutiqueSelected: Boutique) {
    let option = new HttpParams();
    option = option.set(this.sortieColumnName.NAME, sortieChecked.sortie.id.toString());
    option = option.set(Uri.BOUTIQUE, boutiqueSelected.id.toString());
    const result = this.sortieService.getFacture(option);
    result.subscribe(
      (res: any) => {
        console.log('** succès récupération facture sortie');
        this.sortieFacture = res.body[PageCs.DATA_CONTENT];
      },
      (err: HttpErrorResponse) => {
        console.log('** erreur récupération facture sortie');
        try {
          this.manageErrorFromGetFacture(err);
        } catch (e) {
          console.log('erreur: ' + (e as Error).message);
        }
      }
    );
  }

  /**
   * Gère les erreurs lors de l'enregistrement d'un utilisateur.
   * @param err les erreurs.
   */
  manageErrorFromGetFacture(err: HttpErrorResponse) {
    const erreur = err.error.myException[0];
    console.log('err: ' + JSON.stringify(erreur));
    const fields = erreur.fields;
    if (erreur.errorCode === this.errorServ.PAYMENT_ERROR) {
      this.hasAbonnementError = true;
      this.popupReusableService.open(null, AbonnementFactureComponent);
    }
  }

  /**
   * Permet de récupérer le compte de l'utilisateur connecté.
   * @param iAccountService le service Permettant de récupérer
   * le compte de l'utilisateur connecté.
   */
  getUserIdentityObs(iAccountService: AccountService,
                     boutiqueSelected: Boutique) {
    iAccountService.getUserIdentityObs().pipe(takeUntil(this.subs$))
      .subscribe(accountUser => {
        this.accountUser = accountUser;
        this.gettingPrivilegeService.canIDoThis(accountUser, GestionType.FACTURE,
          PrivilegeType.TELECHARGER, boutiqueSelected).pipe(takeUntil(
          this.subs$
        )).subscribe((res: boolean) => {
          this.iCanDownload = res;
        });
        this.gettingPrivilegeService.canIDoThis(accountUser, GestionType.FACTURE,
          PrivilegeType.IMPRIMER, boutiqueSelected).pipe(takeUntil(
          this.subs$
        )).subscribe((res: boolean) => {
          this.iCanPrint = res;
        });
      });
  }

  getTel3() {
    return this.boutiqueSelected.telephone3;
  }

  getTel2() {
    return this.boutiqueSelected.telephone2;
  }

  getTel1() {
    return this.boutiqueSelected.telephone1;
  }

  getEntete(doc) {
    const array = [];
    if (doc) {
      doc.text('Facture', 400, 20);
      doc.text('' + this.boutiqueSelected.nom, 40, 20);
      array.push('Adresse: ' + this.boutiqueSelected.adresse);
      array.push('Ville: ' + this.boutiqueSelected.ville);
      array.push('Pays: ' + this.boutiqueSelected.pays);
      if (this.getTel1() || this.getTel2() || this.getTel3()) {
        array.push('Contact(s): ');
        if (this.getTel1()) {
          array.push(this.getTel1());
        }
        if (this.getTel2()) {
          array.push(this.getTel2());
        }
        if (this.getTel3()) {
          array.push(this.getTel3());
        }
      }
      array.push('Identifiant: ' + this.boutiqueSelected.identifiant);
    }
    return array;
  }

  print() {
    console.log('printing..');
    const doc = new jsPDF('p', 'pt', 'a4');
    const array = this.getEntete(doc);
    doc.text(array, 40, 60);
    doc.autoTable({html: '#facture', startY: 200});
    doc.autoPrint();
    doc.save('facture.pdf');
  }

  download() {
    console.log('saving..');
    const doc = new jsPDF('p', 'pt', 'a4');
    const array = this.getEntete(doc);
    doc.text(array, 40, 60);
    doc.autoTable({html: '#facture', startY: 200});
    doc.save('facture.pdf');
  }

  closePopup() {
    this.open = false;
    this.popupReusableService.dismiss(this.activeModal);
  }

}
