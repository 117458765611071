import {Injectable} from '@angular/core';
import {Uri} from '../../../shared/constant/model/global.constant';

/**
 * Offre plusieurs types utilisés dans la recherche d'utilisateurs.
 */
export namespace SortieSearchForInit {

  /**
   * filtres pour la recherche
   * cases cochés par l'utilisateur.
   */
  export interface DataFilter {
    designation: boolean;
    code: boolean;
    code_sortie: boolean;
    advancedSearch: boolean;
  }

  /**
   * Sert à contenir la valeur de la recherche seulement
   * pour les champs les cochés
   */
  export interface DataFilterValue {
    designation: string;
    code: string;
    code_sortie: string;
    page: string;
    advancedSearch: string;
    [Uri.DATE_DEBUT]: string;
    [Uri.DATE_FIN]: string;
  }
}


/**
 * Permet d'initialiser le component {@link UserSearchComponent}
 */
@Injectable({providedIn: 'root'})
export class SortieSearchInit {
  constructor() {
  }

  /**
   * @returns filtres pour la recherche
   * cases cochés par l'utilisateur.
   */
  getDataFilter(): SortieSearchForInit.DataFilter {
    return {
      designation: false,
      code: false,
      code_sortie: false,
      advancedSearch: false
    };
  }

  /**
   * @returns Sert à contenir la valeur de la recherche
   * seulement pour les champs cochés.
   */
  getDataFilterValue(): SortieSearchForInit.DataFilterValue {
    return {
      designation: undefined,
      code: undefined,
      code_sortie: undefined,
      page: undefined,
      advancedSearch: undefined,
      dateDebut: undefined,
      dateFin: undefined
    };
  }

}
