import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IArticle} from '../../shared/models/article.model';
import {RestoreWhenDisconnectService} from '../../shared/ services/restore/restore-when-disconnect.service';
import {IRestoreWhenDisconnectService} from '../../shared/ services/restore/irestore-when-disconnect.service';

@Injectable({providedIn: 'root'})
export class DataShareWhenCheckedArticle implements IRestoreWhenDisconnectService {
  constructor(
    private restore: RestoreWhenDisconnectService) {
    this.restore.add(this);
  }

  // VAR.
  private articleCheckedObs = new BehaviorSubject<IArticle>(undefined);
  private isArticleCheckedObs = new BehaviorSubject<boolean>(false);

  /**
   * @return l'utilisateur coché.
   */
  public getArticlecheckedObs(): Observable<IArticle> {
    return this.articleCheckedObs.asObservable();
  }

  /**
   * Permet de modifier la valeur de l'utilisateur
   * coché.
   * @param user le nouvel utilisateur.
   */
  public setArticlecheckedObs(user: IArticle) {
    this.articleCheckedObs.next(user);
  }

  /**
   * @return Observable<boolean> true indique l'utilisateur
   * est coché, false sinon.
   */
  public getIsArticleCheckedObs(): Observable<boolean> {
    return this.isArticleCheckedObs.asObservable();
  }

  /**
   * @param checked true indique l'utilisateur
   * est coché, false sinon.
   */
  public setIsArticleChecked(checked: boolean) {
    this.isArticleCheckedObs.next(checked);
  }

  /**
   * Utilisé à chaque initialisation du component user.
   */
  clearArticleChecked() {
    this.setArticlecheckedObs(undefined);
    this.setIsArticleChecked(false);
  }

  clear() {
    this.clearArticleChecked();
  }
}
